import UnifiedApplicationBaseReducer from '../unifiedApplicationBaseReducer'
import {
  IUnifiedApplicationAction,
  UnifiedApplicationActionUpdateState,
  UnifiedApplicationActionEligibilitySkip,
  UnifiedApplicationActionType
} from '../unifiedApplicationActions'
import { IErrorsState } from '../../types/IErrorsState'
import { UnifiedApplicationState } from '../../types/UnifiedApplicationState'
import { tokeniseToString } from '../../shared/content/DynamicContent'
import { validate as validateRequiredFields, FieldWithErrorMessage } from '../../validators/formValidator'
import { UnifiedApplicationSteps } from '../../types/UnifiedApplicationSteps'
import { hasUnifiedEligibilitySkipConditionEligibilityState } from '../../shared/components/eligibilityRenderer/eligibility'
import IUnifiedEligibilityState from 'src/types/unifiedJourney/IUnifiedEligibilityState'

export default class UnifiedEligibilityReducer extends UnifiedApplicationBaseReducer<IUnifiedEligibilityState> {
  private getRequiredFields = (state: IUnifiedEligibilityState) => {
    const content = this.getContent(state).Eligibility
    const requiredFields: Array<FieldWithErrorMessage> = [
      {
        fieldName: 'offersB2bServices',
        errorMessage: tokeniseToString(content.OffersB2bServices.Error!, 'Required')
      },
      {
        fieldName: 'turnoverExceeds100k',
        errorMessage: tokeniseToString(content.TurnoverExceeds100k.Error!, 'Required')
      },
      {
        fieldName: 'fundingNeededIn60d',
        errorMessage: tokeniseToString(content.FundingNeededIn60d.Error!, 'Required')
      },
      {
        fieldName: 'invoicesUpfront',
        errorMessage: tokeniseToString(content.InvoicesUpfront.Error!, 'Required')
      },
      {
        fieldName: 'invoicesInDate',
        errorMessage: tokeniseToString(content.InvoicesInDate.Error!, 'Required')
      },
      {
        fieldName: 'fundingExceeds25k',
        errorMessage: tokeniseToString(content.FundingExceeds25k.Error!, 'Required')
      },
      {
        fieldName: 'invoicesTermsExceed15d',
        errorMessage: tokeniseToString(content.InvoicesTermsExceed15d.Error!, 'Required')
      },
      {
        fieldName: 'invoicesEachExceed10k',
        errorMessage: tokeniseToString(content.InvoicesEachExceed10k.Error!, 'Required')
      },
      {
        fieldName: 'invoiceFinanceDiscussionAwareness',
        errorMessage: tokeniseToString(content.InvoiceFinanceDiscussionAwareness.Error!, 'Required')
      },
      {
        fieldName: 'extendCreditSolutionNeeded',
        errorMessage: tokeniseToString(content.ExtendCreditSolutionNeeded.Error!, 'Required')
      }
    ]

    return {
      content,
      requiredFields
    }
  }

  onLoad = async (
    state: IUnifiedEligibilityState,
    dispatch: React.Dispatch<IUnifiedApplicationAction<object>>
  ): Promise<void> => {
    if (hasUnifiedEligibilitySkipConditionEligibilityState(state)) {
      dispatch(UnifiedApplicationActionEligibilitySkip())
      return
    }

    dispatch(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Pristine))
  }

  onValidate = (state: IUnifiedEligibilityState): IErrorsState => {
    if (hasUnifiedEligibilitySkipConditionEligibilityState(state)) {
      return {
        hasErrors: false
      }
    }

    const { requiredFields } = this.getRequiredFields(state)

    return validateRequiredFields(requiredFields, state)
  }

  handleDefaultAction = (
    prevState: IUnifiedEligibilityState,
    newState: IUnifiedEligibilityState,
    action: IUnifiedApplicationAction<object>
  ) => {
    switch (action.type) {
      case UnifiedApplicationActionType.EligibilitySkip:
        newState.skipToStep = UnifiedApplicationSteps.EligibilityResults
        newState.state = UnifiedApplicationState.Submitting
        break
    }
  }

  onBeforeSubmit = async (state: IUnifiedEligibilityState): Promise<void> => {}
}
