import React from 'react'
import { IErrorsState } from '../../../types/IErrorsState'
import {
  IUnifiedApplicationAction,
  UnifiedApplicationActionValidateField
} from '../../../reducers/unifiedApplicationActions'
import { tokeniseLabelAsString } from '../../content/DynamicContent'
import { GetStartedContent } from 'src/applyfrontendcontent'
import { Input } from 'src/ui-framework'

export type CompanySearchFieldsProps = {
  onCompanyNameChange: Function
  onCompanyNumberChange: Function
  companyName: string
  companyNumber: string
  content: GetStartedContent
  dispatch: React.Dispatch<IUnifiedApplicationAction<object>>
  errors: IErrorsState
  isCompanyReadonly: boolean
}

const CompanySearchFields = (props: CompanySearchFieldsProps) => {
  return (
    <>
      <Input
        id="company-name"
        label={tokeniseLabelAsString(props.content.CompanySearch!.CompanyNameManual!)}
        name="company-name"
        required
        errors={props.errors['companyName'] as string[]}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onCompanyNameChange(event.target.value)}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          props.dispatch(UnifiedApplicationActionValidateField('companyName'))
        }}
        value={props.companyName}
        hotjarUnmask
        readOnly={props.isCompanyReadonly}
        ariaReadOnly={props.isCompanyReadonly}
      />
      <Input
        id="company-number"
        label={tokeniseLabelAsString(props.content.CompanySearch!.CompanyNumber)}
        name="Company number"
        required
        errors={props.errors['companyNumber'] as string[]}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onCompanyNumberChange(event.target.value)}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          props.dispatch(UnifiedApplicationActionValidateField('companyNumber'))
        }}
        value={props.companyNumber}
        hotjarUnmask
        readOnly={props.isCompanyReadonly}
        ariaReadOnly={props.isCompanyReadonly}
      />
    </>
  )
}

export default CompanySearchFields
