import { PageIdentifier } from '../../../../../types/PageIdentifier'
import { UnifiedApplicationSteps } from '../../../../../types/UnifiedApplicationSteps'

export const eligibilityLoadingPageIdentifier: PageIdentifier = {
  id: 'a4111e3d-bde2-4544-8a33-bc1f40c8b793',
  title: 'Eligibility Results - Loading',
  step: UnifiedApplicationSteps.EligibilityResultsLoading,
  order: 26,
  isHidden: true,
  subPageIds: []
}
