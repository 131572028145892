import { media } from 'src/ui-framework'
import styled from 'styled-components'

type StyledListItemProps = {
  theme: any
}

export const StyledListItem = styled.li<any>`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.fontSize.s};
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0;
  color: ${(props) => props.theme.colors.textGrey900};

  ${media.upToTablet`
        font-size: ${(props: StyledListItemProps) => props.theme.fontSize.xs};
    `}
`

export const StyledList = styled.ul<any>`
  margin-bottom: 32px;
  margin-top: 8px;
  padding-left: 28px;

  ${media.upToTablet`
        font-size: ${(props: StyledListItemProps) => props.theme.fontSize.xs};
        margin-bottom: 32px;
    `}
`

export const ReadRequirementsAlert = styled.span<any>`
  display: block;
  font-size: ${(props) => props.theme.fontSize.s};
  color: ${(props) => props.theme.colors.error};
  font-weight: 600;
  margin-bottom: ${(props) => props.theme.spacing.xl};

  ${media.upToTablet`
      font-size: ${(props: StyledListItemProps) => props.theme.fontSize.xs};
  `}
`
