import { EligibilityResultContent } from '../types/eligbilityResult'

export const eligibilityResult: EligibilityResultContent = {
  Heading: 'Eligible',
  ShortHeading: 'Eligible',
  EligibleForInvoiceFinanceOnly: {
    Heading: 'Good News! You’re Eligible',
    Message: 'Congratulations! Your company has met the minimum requirements for an invoice finance application.',
    Text: 'Please ensure you have the following information ready before completing your application:',
    RequirementCheckedPoints: [
      'Applying Director’s date of birth and residential address ',
      'Last 3 months of bank statements ',
      'Latest statutory accounts'
    ],
    ContinueButton: 'Continue application'
  }
}
