import { ThankYouContent } from '../../../../types/thankyou'

export const thankYou: ThankYouContent = {
  SuccessMessage: 'The application has been successfully submitted',
  Heading: 'What happens next?',
  Introduction: [
    'We’ll review your application within next the 2 working days and will be in touch via email.',
    'We’ll keep you updated on the progress of your application at every stage. Please keep an eye on your inbox as we may need to ask you more information to help us make a decision.'
  ],
  InvoiceFinanceBulletPoints: [],
  RecoveryLoanBulletPoints: [],
  RecoveryLoanAndInvoiceFinanceBulletPoints: [],
  Closing: 'Thank you for your application. We look forward to speaking to you soon.'
}
