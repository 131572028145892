import NumberToLocaleString from '../mappers/NumberToLocaleString'

export enum MinMaxResult {
  LessThanMinimum,
  GreaterThanMaximum,
  Ok,
  Invalid,
}

export const minMaxAmountValidator = (
  value: string | undefined,
  minValue: number | undefined,
  maxValue: number | undefined,
): MinMaxResult => {
  if (value === undefined || value === '') {
    return MinMaxResult.Invalid
  }

  const tmp = NumberToLocaleString.reverseMap(value)
  const numberValue = tmp === undefined ? NaN : tmp
  if (isNaN(numberValue)) {
    return MinMaxResult.Invalid
  } else if (minValue !== undefined && numberValue < minValue) {
    return MinMaxResult.LessThanMinimum
  } else if (maxValue !== undefined && numberValue > maxValue) {
    return MinMaxResult.GreaterThanMaximum
  }

  return MinMaxResult.Ok
}
