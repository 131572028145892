import IDirectorDetailsState from '../../../types/IDirectorDetailsState'
import React from 'react'
import { PageIdentifier } from '../../../types/PageIdentifier'
import { UnifiedApplicationSteps } from '../../../types/UnifiedApplicationSteps'
import IUnifiedApplicationStepProps from '../../../types/IUnifiedApplicationStepProps'
import { H3 } from '../../../commonStyles/components/headings'
import { PageContainer, MainDiv } from '../readyToApply/ReadyToApplyStyles'
import AddressForm from '../../../shared/components/address/AddressForm'
import useUnifiedApplicationStep from '../../../shared/hooks/useUnifiedApplicationStep'
import DirectorDetailsReducer from '../../../reducers/directorDetailsReducer'
import { UnifiedApplicationState } from '../../../types/UnifiedApplicationState'
import InputLayout from '../../../commonStyles/components/inputLayout'
import UnifiedApplicationStepGuard from '../UnifiedApplicationStepGuard'
import ContinueAndSaveLater from '../ContinueAndSaveLater'
import {
  UnifiedApplicationActionValidateFields,
  UnifiedApplicationActionSelectDirector,
  UnifiedApplicationActionUpdateState,
  UnifiedApplicationActionUpdateAndValidateField
} from '../../../reducers/unifiedApplicationActions'
import IDateState from '../../../types/IDateState'
import { getSaveForLaterEmail } from '../../../shared/getSaveForLaterEmail'
import { tokeniseToString, tokeniseLabelAsString, DynamicContent } from '../../../shared/content/DynamicContent'
import { Input, Select, InputDate, Checkbox, AnchorLink, media } from 'src/ui-framework'
import { OnChangeValue } from 'react-select'
import { spacing } from 'src/ui-framework/theme/spacing'
import { RepresentativeJourneyType } from 'src/types/RepresentativeJourneyType'
import styled from 'styled-components'

interface IDirectorDetailsProps extends IUnifiedApplicationStepProps<IDirectorDetailsState> {}

const BiggestGap = styled.div`
  margin-top: ${spacing.xl};
  ${media.upToTablet`
    margin-top: ${spacing.xl};
  `}
`
const BigGap = styled.div`
  margin-top: ${spacing.l};
  ${media.upToTablet`
    margin-top: ${spacing.m};
  `}
`
const TopGap = styled.div``
const H3Gap = styled(H3)`
  margin: ${spacing.xl} 0 0;
`

export const directorDetailsPageIdentifier: PageIdentifier = {
  id: 'b4d16b7b-2897-4f2a-87da-1d9582143f7f',
  title: 'Director details',
  step: UnifiedApplicationSteps.DirectorDetails,
  order: 40,
  isHidden: false,
  subPageIds: []
}

const reducer = new DirectorDetailsReducer(directorDetailsPageIdentifier)

const DirectorDetails = (props: IDirectorDetailsProps) => {
  const [state, dispatch, content, onSendLink] = useUnifiedApplicationStep(
    reducer,
    props.initialState,
    props.applicationIdentifier,
    props.onSubmit,
    props.onAutoSave
  )
  const { directors } = state
  const directorContent = content.DirectorDetails
  const legalsConsented =
    state.legalsConsentedTime !== undefined && !Number.isNaN(new Date(state.legalsConsentedTime).getDate())
  return (
    <UnifiedApplicationStepGuard currentState={state}>
      <PageContainer>
        <MainDiv>
          <TopGap>
            {directors.length === 0 ? (
              <InputLayout>
                <Input
                  id="director"
                  label={tokeniseLabelAsString(directorContent.WhichDirector.Manual)}
                  placeholder={directorContent.WhichDirector?.Manual?.Placeholder}
                  required
                  name="director"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    dispatch.valueUpdate('directorName', event.target.value)
                  }
                  onBlur={() => {
                    dispatch.validateField('directorName')
                  }}
                  value={state.directorName}
                  errors={state.errors['directorName'] as string[]}
                />
              </InputLayout>
            ) : (
              <InputLayout>
                <Select
                  id="director"
                  label={tokeniseLabelAsString(directorContent.WhichDirector.Automated)}
                  defaultText={directorContent.WhichDirector?.Automated?.Placeholder}
                  required
                  name="director"
                  options={directors.map((name) => ({ label: name, value: name }))}
                  onChange={(event: OnChangeValue<any, any>) =>
                    dispatch.action(UnifiedApplicationActionSelectDirector(event.value))
                  }
                  value={state.directorName}
                  errors={state.errors['directorName'] as string[]}
                />
              </InputLayout>
            )}
          </TopGap>
          {(directors.length === 0 || state.directorName) && (
            <div style={{ marginTop: 16 }}>
              <InputDate
                id="dob"
                name="dob"
                label={tokeniseLabelAsString(directorContent.DateOfBirth)}
                onChange={(dateValue: IDateState) => dispatch.valueUpdate('dateOfBirth', dateValue)}
                onBlur={() => {
                  dispatch.action(UnifiedApplicationActionValidateFields(['dateOfBirth']))
                }}
                value={state.dateOfBirth}
                required
                errors={state.errors['dateOfBirth'] as string[]}
              />
            </div>
          )}

          {state.representativeJourneyType === RepresentativeJourneyType.Representative &&
            content.DirectorDetails.ContactDetails !== undefined && (
              <>
                <H3Gap>{tokeniseToString(content.DirectorDetails.ContactDetails, 'Heading')}</H3Gap>
                <BigGap>
                  <InputLayout>
                    <Input
                      id="director-email-address"
                      label={tokeniseLabelAsString(content.DirectorDetails.ContactDetails.DirectorEmail)}
                      name="director-email-address"
                      type="email"
                      required
                      value={state.directorEmail}
                      errors={state.errors['directorEmail'] as string[]}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        dispatch.valueUpdate('directorEmail', e.target.value)
                      }}
                      onBlur={() => {
                        dispatch.validateField('directorEmail')
                      }}
                    />
                  </InputLayout>

                  <InputLayout>
                    <Input
                      style={{ margin: 0 }}
                      id="director-phone-number"
                      label={tokeniseLabelAsString(content.DirectorDetails.ContactDetails.DirectorPhoneNumber)}
                      name="director-phone-number"
                      type="tel"
                      required
                      value={state.directorPhoneNumber}
                      errors={state.errors['directorPhoneNumber'] as string[]}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        dispatch.valueUpdate('directorPhoneNumber', e.target.value)
                      }}
                      onBlur={() => {
                        dispatch.validateField('directorPhoneNumber')
                      }}
                    />
                  </InputLayout>
                </BigGap>
              </>
            )}

          <H3Gap>{tokeniseToString(directorContent.ResidentialAddress, 'Title')}</H3Gap>
          <BigGap>
            <AddressForm
              id="residential-address"
              property="residentialAddress"
              content={directorContent.ResidentialAddress}
              errors={state.errors}
              onChange={(field: string, value: string) => {
                dispatch.valueUpdate(field, value)
              }}
              onBlur={(field: string) => {
                dispatch.validateField(field)
              }}
              value={state.residentialAddress!}
              hotjarUnmask={false}
            />
          </BigGap>
          {state.representativeJourneyType !== RepresentativeJourneyType.Representative && (
            <BiggestGap>
              <Checkbox
                id="t-and-cs"
                errors={state.errors['legalsConsentedTime'] as string[]}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.preventDefault()
                  const legalsConsentedTimeValue = legalsConsented ? '' : new Date().toISOString()
                  if (state.errors.hasErrors) {
                    dispatch.action(
                      UnifiedApplicationActionUpdateAndValidateField('legalsConsentedTime', legalsConsentedTimeValue)
                    )
                  } else {
                    dispatch.valueUpdate('legalsConsentedTime', legalsConsentedTimeValue)
                  }
                }}
                required
                checked={legalsConsented}
                label={tokeniseLabelAsString(content.DirectorDetails.TermsAndConditions)}
              />
              <DynamicContent
                content={content.DirectorDetails.TermsAndConditions}
                components={{
                  PrivacyPolicy: {
                    Component: (p: React.PropsWithChildren<any>) => {
                      return <p style={{ margin: `${spacing.m} 0 0` }}>{p.children}</p>
                    }
                  },
                  PrivacyPolicyButton: {
                    Component: AnchorLink,
                    props: {
                      style: {
                        fontSize: 'inherit'
                      },
                      target: '_blank',
                      href: content.Shared.PrivacyPolicyUrl,
                      rel: 'noopener noreferrer'
                    }
                  }
                }}
                ignoreComponents={['PrivacyPolicyButton']}
              />
            </BiggestGap>
          )}
          <BiggestGap>
            <ContinueAndSaveLater
              content={content}
              loading={state.showModal === false && state.state === UnifiedApplicationState.Submitted}
              email={getSaveForLaterEmail(state)}
              id="director-details-submit"
              onSendLink={onSendLink}
              errors={state.errors}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault()
                dispatch.action(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Submitting))
              }}
              showSaveLater={state.representativeJourneyType === RepresentativeJourneyType.Representative}
              overrideContinueLabel={content.DirectorDetails.ContinueButton}
            />
          </BiggestGap>
        </MainDiv>
      </PageContainer>
    </UnifiedApplicationStepGuard>
  )
}

export default DirectorDetails
