import { PartnerType } from './PartnerType'
import { ChannelType } from './ChannelType'
import { RepresentativeJourneyType } from './RepresentativeJourneyType'
import { BusinessType } from './BusinessType'
import { FlowType } from './FlowType'

export enum ButtonType {
  Standard,
  RecoveryLoan
}

export interface IInitialisationParameters {
  journey?: string
  product?: string
  partner?: PartnerType
  channel?: ChannelType
  sales: boolean
  partnerCustomerId?: string
  dashboardId?: string
}

export interface ICreateApplicationState {
  initialisationParameters: IInitialisationParameters
  buttonType: ButtonType
  representativeJourneyType: RepresentativeJourneyType
  businessType?: BusinessType
  canCallClient: boolean
  flowType?: FlowType
}
