import React from 'react'
import { Redirect } from 'react-router-dom'
import ContinueAndSaveLater from 'src/pages/unifiedApplicationJourney/ContinueAndSaveLater'
import { tokeniseLabelAsString, tokeniseOptions, tokeniseToString } from 'src/shared/content/DynamicContent'
import { getSaveForLaterEmail } from 'src/shared/getSaveForLaterEmail'
import useUnifiedApplicationStep from 'src/shared/hooks/useUnifiedApplicationStep'
import IUnifiedApplicationStepProps from 'src/types/IUnifiedApplicationStepProps'
import { UnifiedApplicationState } from 'src/types/UnifiedApplicationState'
import IPartnerReferralRegistrationState from 'src/types/partner/IPartnerReferralRegistrationState'
import UnifiedApplicationStepGuard from 'src/pages/unifiedApplicationJourney/UnifiedApplicationStepGuard'
import PartnerReferralRegistrationReducer from 'src/reducers/partnerReducers/partnerReferralRegistrationReducer'
import { partnerReferralRegistrationPageIdentifier } from './partnerReferralRegistrationIdentifier'
import { Checkbox, Input, media, Select, YesNoInline, TextArea } from 'src/ui-framework'
import { UnifiedApplicationActionUpdateState } from 'src/reducers/unifiedApplicationActions'
import Label from 'src/ui-framework/commonStyles/Label'
import styled from 'styled-components'
import { H3 } from 'src/commonStyles/components/headings'
import { PartnerReferralDetailsContent } from 'src/applyfrontendcontent/types/partnerReferralDetails'
import { OnChangeValue } from 'react-select'
import InputLayout from 'src/commonStyles/components/inputLayout'
import NumberToUnlimitedLocaleString from 'src/mappers/NumberToUnlimitedLocaleString'
import { invoiceFinanceMaxLimit } from 'src/pages/unifiedApplicationJourney/fundingNeeds/fundingAmounts'
import { StyledLabel } from 'src/commonStyles/components/styledLabel'

const StyledLabelLarge = styled(Label)`
  font-size: ${(props) => props.theme.fontSize.s};
  margin-bottom: 16px;

  ${media.upToTablet`
        font-size: ${(props: any) => props.theme.fontSize.xs};
    `}
`

const SubHeading = styled(H3)`
  margin-bottom: 32px;
`

const CustomerDetails = styled.div`
  margin-bottom: 32px;
`

const FormField = styled.div`
  margin-bottom: 16px;
`

const NoMarginInput = styled(Input)`
  margin: 0;
`

const AdditionalInformationLabelList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: 24px;
`

const CheckedListItem = styled.li`
  ${(props) => props.theme.label}
`

interface IPartnerReferralRegistrationProps extends IUnifiedApplicationStepProps<IPartnerReferralRegistrationState> {}
const reducer = new PartnerReferralRegistrationReducer(partnerReferralRegistrationPageIdentifier)

const PartnerReferralRegistration = (p: IPartnerReferralRegistrationProps) => {
  const [state, dispatch, content, onSendLink] = useUnifiedApplicationStep<
    IPartnerReferralRegistrationState,
    PartnerReferralRegistrationReducer
  >(reducer, p.initialState, p.applicationIdentifier, p.onSubmit, p.onAutoSave)

  const applyAmount = (value: string) => {
    let numValue = NumberToUnlimitedLocaleString.reverseMap(value, false)
    dispatch.valueUpdate('fundingAmount', NumberToUnlimitedLocaleString.cleanString(numValue?.toString() || '', false))

    let invoiceFinanceAmount = Math.min(numValue!, invoiceFinanceMaxLimit)
    dispatch.valueUpdate('invoiceFinanceAmount', invoiceFinanceAmount?.toString() || '')
  }

  if (state.state === UnifiedApplicationState.Loading) {
    return <p>{tokeniseToString(content.Shared, 'LoadingPage')}</p>
  }

  let locatedContent: PartnerReferralDetailsContent | undefined
  locatedContent = content.PartnerReferralDetails

  if (!locatedContent) {
    return (
      <Redirect
        to={{
          pathname: '/error',
          state: {
            content
          }
        }}
      />
    )
  }

  return (
    <UnifiedApplicationStepGuard currentState={state}>
      <CustomerDetails>
        <SubHeading>{locatedContent.CustomerHeading}</SubHeading>

        <FormField>
          <StyledLabelLarge htmlFor={'isDirector'}>{'Is the prospect the director of the company?'}</StyledLabelLarge>
          <YesNoInline
            id={'isDirector'}
            name={'isDirector'}
            width="200px"
            key={'isDirector'}
            legend={'Is the prospect the director of the company?'}
            subLegend={'Is the prospect the director of the company? SUB'}
            required
            errors={(state.errors['isDirector'] as string[]) || []}
            onChange={(isChecked: boolean) => {
              dispatch.valueUpdate('isDirector', isChecked)
            }}
            checked={state.isDirector}
            direction={'column'}
          />
        </FormField>

        <Select
          label={tokeniseLabelAsString(locatedContent.Turnover)}
          options={tokeniseOptions(locatedContent.Turnover)}
          id="turnover"
          name="turnover"
          value={state.turnover}
          required
          onChange={(e: OnChangeValue<any, any>) => {
            dispatch.valueUpdate('turnover', e.value)
          }}
          onBlur={() => {
            dispatch.validateField('turnover')
          }}
          errors={(state.errors['turnover'] as string[]) || []}
        />
      </CustomerDetails>
      <div>
        <SubHeading>{locatedContent.ApplicationDetailsHeading}</SubHeading>
        <FormField>
          <StyledLabel htmlFor="funding-amount">{tokeniseLabelAsString(locatedContent.FundingAmount)}</StyledLabel>
          <InputLayout>
            <NoMarginInput
              id="funding-amount"
              name="funding-amount"
              symbol="£"
              pattern="[0-9*]"
              inputMode="numeric"
              required
              value={state.fundingAmount}
              disabled={!(state.invoiceFinance || state.rlsLoan || state.businessLoan)}
              errors={(state.errors['fundingAmount'] as string[]) || []}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch.validateField('fundingAmount')
                applyAmount(e.target.value)
              }}
              onBlur={() => dispatch.validateField('fundingAmount')}
            />
          </InputLayout>
        </FormField>
        <FormField>
          <Select
            label={tokeniseLabelAsString(locatedContent.Timeline)}
            options={tokeniseOptions(locatedContent.Timeline)}
            id="timeline"
            name="timeline"
            value={state.timeline}
            required
            onChange={(e: OnChangeValue<any, any>) => {
              dispatch.valueUpdate('timeline', e.value)
            }}
            onBlur={() => {
              dispatch.validateField('timeline')
            }}
            errors={(state.errors['timeline'] as string[]) || []}
          />
        </FormField>
        <FormField>
          <Checkbox
            checked={state.isExistingCustomer}
            name="is-customer"
            id="is-customer"
            label={locatedContent.PartnerCustomer}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch.valueUpdate('isExistingCustomer', e.target.checked)
              if (!e.target.checked) {
                dispatch.valueUpdate('applicationReference', '')
              }
            }}
            style={{ marginBottom: 16, flexGrow: 10, marginTop: 0 }}
          />
        </FormField>
        {state.isExistingCustomer && (
          <FormField>
            <Input
              id="applicationReference"
              label={tokeniseLabelAsString(locatedContent.ApplicationReference)}
              name="applicationReference"
              maxLength={10}
              required
              errors={state.errors['applicationReference'] as string[]}
              value={state.applicationReference}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch.valueUpdate('applicationReference', e.target.value)
              }}
              onBlur={() => {
                dispatch.validateField('applicationReference')
              }}
            />
          </FormField>
        )}
        <FormField>
          <TextArea
            id="callTimeSlots"
            name="callTimeSlots"
            label={tokeniseLabelAsString(locatedContent.CallTimeSlots)}
            required
            value={state.callTimeSlots}
            errors={state.errors['callTimeSlots'] as string[]}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              dispatch.valueUpdate('callTimeSlots', e.target.value)
            }
            onBlur={() => {
              dispatch.validateField('callTimeSlots')
            }}
          />
        </FormField>
        <FormField>
          <Checkbox
            checked={state.isThreeWayCallRequested}
            name="is-threeway-call"
            id="is-threeway-call"
            label={locatedContent.ThreewayCallConfirmation}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch.valueUpdate('isThreeWayCallRequested', e.target.checked)
            }}
            style={{ marginBottom: '32px', flexGrow: 10, marginTop: 0 }}
          />
        </FormField>
        <FormField>
          <StyledLabel htmlFor="additionalInformation">
            {tokeniseLabelAsString(locatedContent.AdditionalInformation)}
          </StyledLabel>
          <AdditionalInformationLabelList>
            {locatedContent.AdditionalInformation.Suggestions &&
              locatedContent.AdditionalInformation.Suggestions.map((v, i) => (
                <CheckedListItem key={i}>{v}</CheckedListItem>
              ))}
          </AdditionalInformationLabelList>
          <TextArea
            id="additionalInformation"
            name="additionalInformation"
            required
            value={state.additionalInformation}
            errors={state.errors['additionalInformation'] as string[]}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              dispatch.valueUpdate('additionalInformation', e.target.value)
            }
          />
        </FormField>
        <FormField>
          <Input
            id="partnerPhoneNumber"
            label={tokeniseLabelAsString(locatedContent.PartnerPhone)}
            name="partnerPhoneNumber"
            type="tel"
            autoComplete="tel"
            required
            errors={state.errors['partnerPhoneNumber'] as string[]}
            value={state.partnerPhoneNumber}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch.valueUpdate('partnerPhoneNumber', e.target.value)
            }}
            onBlur={() => {
              dispatch.validateField('partnerPhoneNumber')
            }}
          />
        </FormField>
      </div>

      <ContinueAndSaveLater
        content={content}
        loading={state.state === UnifiedApplicationState.Submitted}
        email={getSaveForLaterEmail(state)}
        id="continue-application"
        onSendLink={onSendLink}
        errors={state.errors}
        showSaveLater={false}
        overrideContinueLabel={'Register'}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault()
          dispatch.action(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Submitting))
        }}
      />
    </UnifiedApplicationStepGuard>
  )
}

export default PartnerReferralRegistration
