import styled from 'styled-components'

export const BoxedNote = styled.div`
  background-color: ${(props) => props.theme.colors.brandwebGrey};
  border-radius: 4px;
  display: flex;
  font-weight: 600;
  padding: 10px 16px 16px 16px;
  margin: 16px 0 16px 0;
  line-height: 1.6;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.uiGrey80};
`
