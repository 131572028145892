interface IKeyValueMapping {
  [Key: string]: string
}

const suffixMapping: IKeyValueMapping = {
  'apply.kriya.dev': 'apply',
  'apply.kriya.co': 'apply',
  localhost: 'apply'
}

export const applyServiceSuffix = (): string => {
  const deploymentHost = window.location.hostname
  return suffixMapping[deploymentHost]
}
