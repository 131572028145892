import React from 'react'
import { Paragraph } from 'src/ui-framework'
import {
  ReadyToApplyContent,
  ListItemTooltipContent,
  ProductContent,
  ClosingItemContent
} from 'src/applyfrontendcontent'
import { H2 } from '../../../commonStyles/components/headings'
import * as styles from './ReadyToApplyStyles'
import { setBankStatementsDate } from '../../../shared/components/dynamicDates/dynamicDates'
import { DynamicContent, tokeniseToString } from '../../../shared/content/DynamicContent'
import styled from 'styled-components'
import { RepresentativeJourney } from './RepresentativeJourney'
import { PartnerDetails } from '../../../shared/setPartnerLogo'
import { LinkButton, Button } from 'src/ui-framework'
import { RichContentNote } from 'src/shared/components/contentNote/ContentNote'

interface ReadyToApplyProductContentProps {
  onStandardClick: () => void
  onEmailUsClick?: () => void
  isSalesInitiated: boolean
  onRepresentiveSelection: (value: string) => void
  journeyChoice?: string
  content: ReadyToApplyContent
  errors: Array<string>
  partnerDetails?: PartnerDetails
}

const StyledParagraph = styled(Paragraph)`
  margin: 0;
  margin-bottom: 32px;
`

const StyledSalesHeading = styled(StyledParagraph)`
  font-weight: 600;
  font-size: 16px;
`

const SalesHeading = { Component: StyledSalesHeading }
const Introduction = { Component: React.Fragment }
const IntroductionItem = { Component: StyledParagraph }
const ApplyTitle = { Component: H2 }
const ApplicationTime = { Component: Paragraph }
const ProductIntroduction = {
  Component: Paragraph,
  props: {
    style: {
      fontWeight: 'bold',
      marginBottom: 8,
      marginTop: 16
    }
  }
}
const ProductBulletPoints = { Component: styles.UnorderedList }
const ProductBulletPointsItem = { Component: styles.ListItems }
const Mful = <T extends object>(p: React.PropsWithChildren<T>) => (
  <ul style={{ marginLeft: '2em', marginTop: 0, padding: 0, marginBottom: 0 }}>{p.children}</ul>
)

const ListItemWithTooltip = styled.li`
  list-style-position: outside;
  padding: 0;
  margin-bottom: 8px;
  &:last-child {
    margin: 0;
  }
`

const ListItemWithTooltipText = styled.span`
  display: list-item;
  margin-right: 10px;
`

const FlexDix = styled.div`
  display: flex;
`

const InvoiceFinanceDynamicDates = {
  InvoiceFinance: {
    BankStatementStartDate: setBankStatementsDate(120),
    BankStatementEndDate: setBankStatementsDate(30)
  }
}

const StyledH2 = styled(H2)`
  margin-bottom: 24px;
`

const ClosingItemLinkButton = styled(LinkButton)`
  font-family: ${(props) => props.theme.fontFamily};
  padding: 0;
`

const ClosingItemParagraph = styled(Paragraph)`
  margin-bottom: 0;
`

export const HelpStyledLinkButton = styled(LinkButton)`
  font-family: ${(props) => props.theme.fontFamily};
  padding: 0;
`

export const ReadyToApplyProductContent = (p: ReadyToApplyProductContentProps) => {
  const content = p.content

  let productInvoiceFinanceContent: ProductContent | undefined = undefined

  if (content.Products.InvoiceFinance !== undefined) {
    content.Products.InvoiceFinance.Dynamic = InvoiceFinanceDynamicDates
    productInvoiceFinanceContent = p.content.Products.InvoiceFinance
  }

  if (p.partnerDetails !== undefined && content.LatestRequirements !== undefined) {
    content.Dynamic = InvoiceFinanceDynamicDates
  }

  const IfButton = {
    Component: Button,
    props: {
      type: 'primary',
      id: 'continue-standard-application',
      onClick: p.onStandardClick
    }
  }

  return (
    <div>
      <DynamicContent
        components={{
          SalesHeading,
          Introduction,
          IntroductionItem,
          ProductBulletPoints,
          ProductBulletPointsItem,
          ApplicationTime,
          ApplyTitle
        }}
        ignoreComponents={['HelpButton']}
        content={content}
      />

      {content.RequirementsMainHeading && (
        <DynamicContent
          components={{
            RequirementsMainHeading: { Component: StyledH2 }
          }}
          content={content}
        />
      )}

      {/* Not applicable for Partners (Barclays) */}
      {p.partnerDetails === undefined && productInvoiceFinanceContent !== undefined && (
        <>
          {productInvoiceFinanceContent.Introduction && (
            <div style={{ display: 'flex', fontWeight: 'bold', marginBottom: 8, marginTop: 16 }}>
              <ProductIntroduction.Component style={{ margin: 0 }}>
                {tokeniseToString(productInvoiceFinanceContent, 'Introduction')}
              </ProductIntroduction.Component>
            </div>
          )}
          {productInvoiceFinanceContent.ListWithTooltips && (
            <>
              <Mful>
                {productInvoiceFinanceContent.ListWithTooltips.map((x: ListItemTooltipContent, i: number) => (
                  <ListItemWithTooltip key={`list-item-${i}`}>
                    <FlexDix>
                      <ListItemWithTooltipText>
                        {tokeniseToString(x, 'Text', productInvoiceFinanceContent)}
                      </ListItemWithTooltipText>
                    </FlexDix>
                  </ListItemWithTooltip>
                ))}
              </Mful>

              <styles.Separators style={{ marginTop: 16 }} />
            </>
          )}
        </>
      )}

      {content.Affiliation !== undefined && (
        <RepresentativeJourney
          onRepresentiveSelection={p.onRepresentiveSelection}
          journeyChoice={p.journeyChoice}
          errors={p.errors}
          content={content.Affiliation}
        />
      )}

      <styles.ButtonGroup>
        {productInvoiceFinanceContent !== undefined && (
          <DynamicContent
            components={{
              ButtonText: IfButton
            }}
            content={productInvoiceFinanceContent!}
          />
        )}
      </styles.ButtonGroup>

      {content.ClosingItems && content.ClosingItems.length > 0 && (
        <RichContentNote id="closing-items" style={{ padding: 0, marginTop: '24px' }}>
          {content.ClosingItems.map((x: ClosingItemContent, i: number) => (
            <DynamicContent
              key={i}
              content={x}
              components={{
                Content: {
                  Component: ClosingItemParagraph
                },
                LinkButton: {
                  Component: ClosingItemLinkButton,
                  props: {
                    autoFontSize: true,
                    onClick: () => {
                      if (x.LinkButtonUrl) {
                        window.location.href = x.LinkButtonUrl
                      }
                    }
                  }
                }
              }}
              ignoreComponents={['LinkButton', 'LinkButtonUrl']}
            />
          ))}
        </RichContentNote>
      )}
    </div>
  )
}
