import React, { useState } from 'react'
import { LinkButton, media } from 'src/ui-framework'
import ContinueLaterModal from './ContinueLaterModal'
import styled from 'styled-components'
import SegmentWrapper from '../../../utils/segmentWindow'
import { tokeniseToString } from '../../../shared/content/DynamicContent'
import { getSharedCopy, Config } from 'src/applyfrontendcontent'

const LinkDiv = styled.div`
  flex: 1;
  margin-left: 48px;

  ${media.upToTablet`
    width: 100%;
    margin-top: ${(props: any) => props.theme.spacing.spacingS};
    margin-left: 0;
  `}
`

export const StyledLinkButton = styled(LinkButton)`
  font-family: ${(props) => props.theme.fontFamily};
  padding: 0;
  font-weight: 600;
  ${media.upToTablet`
    margin: 0 auto;
    display: block;
  `}
`
export type OverrideModalContent = {
  Heading: string
  Introduction: string
}

type SaveForLaterProps = {
  email: string
  content: Config
  id?: string
  overrideTitle?: string
  overrideModalContent?: OverrideModalContent
  onSendLink: (email: string) => Promise<void | boolean>
}

const SaveForLater = (p: SaveForLaterProps) => {
  const [showModal, setShowModal] = useState(false)
  const [sendLinkStatus, setStatus] = useState<boolean>(false)

  const handleSendLink = async (email: string): Promise<void> => {
    const status = await p.onSendLink(email).then((res) => res)
    setStatus(status as boolean)
  }

  return (
    <LinkDiv id="save-for-later-wrapper">
      <StyledLinkButton
        id={p.id ? p.id : 'save-for-later-link'}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          setShowModal(true)
          SegmentWrapper.virtualPageView('save-for-later-show', undefined, true)
        }}
      >
        {p.overrideTitle !== undefined
          ? p.overrideTitle
          : tokeniseToString(getSharedCopy(navigator.language), 'SaveForLaterButton')}
      </StyledLinkButton>

      <ContinueLaterModal
        content={p.content}
        displayModal={showModal as boolean}
        onClose={() => {
          setShowModal(false)
          setStatus(false)
        }}
        email={p.email}
        onSendLink={handleSendLink}
        status={sendLinkStatus}
        overrideModalContent={p.overrideModalContent}
      />
    </LinkDiv>
  )
}

export default SaveForLater
