import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import IApplicationUrlParams from './types/IApplicationUrlParams'
import NotFound from './pages/error/NotFound'
import { useGlobalContext } from './GlobalContext'
import { setCookie } from './utils/cookieHelper'
import { applyServiceSuffix } from './urlSettingsExports'
import styled from 'styled-components'

enum PageState {
  Loading,
  Error,
  Done
}

export const Wrapper = styled.div`
  padding-left: 64px;
`

const impersonate = async (id: string, setState: React.Dispatch<React.SetStateAction<PageState>>): Promise<void> => {
  const url = `/${applyServiceSuffix()}/internal/impersonate/${id}`
  const response = await axios.get(url, {
    validateStatus: () => true,
    withCredentials: true
  })

  if (response.status === 204) {
    setCookie('sales', '1', 20 * 365)
    setState(PageState.Done)
  } else {
    setState(PageState.Error)
  }
}

export const ApplicationImpersonator = (p: RouteComponentProps<IApplicationUrlParams>) => {
  const [state, setState] = useState<PageState>(PageState.Loading)
  const { copy } = useGlobalContext()
  const { id } = p.match.params

  useEffect(() => {
    impersonate(id, setState)
  }, [id])

  switch (state) {
    case PageState.Done:
      return <Redirect to={`/application/${id}`} />
    case PageState.Error:
      return <NotFound />
    case PageState.Loading:
    default:
      return <Wrapper>{copy.Shared.LoadingPage}</Wrapper>
  }
}
