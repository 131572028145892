import styled from 'styled-components'
import { media } from '../../../commonStyles/mediaQueries/responsiveDeviceSize'
import { colors } from '../../../theme/colors'

type LabelWrapperStyleProps = {
  theme: {}
  focused: boolean
  mouseDown: boolean
  errors: Array<string>
  animate: boolean
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
}

const LabelWrapper = styled.label<LabelWrapperStyleProps>`
  font-family: 'Matter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  cursor: pointer;
  padding: 8px;
  padding-left: 0;
  flex: 1 1 50%;
  color: ${colors.textGrey900};
  letter-spacing: normal;
  ${media.upToTablet`
    font-size: 14px;
  `}
  & > div > input {
    border-radius: 50%;
    outline: none;
    opacity: 0;
    position: absolute;
    width: 0;
    height: 0;
  }
  & > div > i {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transition: ${(props) => (props.animate ? '0.2s' : 'none')};
    box-shadow: inset 0 0 0 10px ${(props) => props.theme.colors.uiWhite100};
    background-clip: padding-box;
    border: 2px solid ${colors.midnight};
    background: ${colors.midnight};

    &:hover {
      border: 2px solid black;
      box-shadow: inset 0 0 0 8px ${colors.white}, 0px 0px 0px 2px ${colors.green30};
      border-radius: 50%;
      transition: 0.3s;
      opacity: 1;
    }

    ${(props) =>
      props.focused &&
      `
        border: 2px solid black;
        box-shadow: inset 0 0 0 8px ${colors.white}, 0px 0px 0px 5px ${colors.green64};
        border-radius: 50%;
        transition: 0.3s;
        opacity: 1;
      `}
  }
  & > div > i:before {
    content: '';
    display: block;
    width: 80%;
    height: 80%;
    margin: 0%;
    padding: 10%;
    border-radius: 50%;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  & > div > input:checked + i {
    background-clip: padding-box;
    box-shadow: inset 0 0 0 3px ${colors.white};
    background: ${colors.midnight};

    &:hover {
      background-clip: padding-box;
      box-shadow: inset 0 0 0 3px ${colors.white}, 0px 0px 0px 2px ${colors.navyBlue50};
      background: ${colors.midnight};
    }

    ${(props) =>
      props.focused
        ? `
      background-clip: padding-box;
      box-shadow: inset 0 0 0 3px ${colors.white}, 0px 0px 0px 4px ${colors.navyBlue100};
      background: ${colors.midnight};
    `
        : ''}

    &:focus {
      background-clip: padding-box;
      box-shadow: inset 0 0 0 3px ${colors.white}, 0px 0px 0px 4px ${colors.navyBlue100};
      background: ${colors.midnight};
    }
  }
`

export default LabelWrapper
