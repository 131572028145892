import IUnifiedApplicationStateToIUnifiedApplicationStepState from './IUnifiedApplicationStateToIUnifiedApplicationStepState'
import IUnifiedApplicationState from '../types/IUnifiedApplicationState'
import IPartnerEligibilityResultsState from 'src/types/partner/IPartnerEligibilityResultsState'

class IUnifiedApplicationStateToPartnerEligibilityResultsStateImpl extends IUnifiedApplicationStateToIUnifiedApplicationStepState<IPartnerEligibilityResultsState> {
  map(i: IUnifiedApplicationState): IPartnerEligibilityResultsState {
    return {
      ...this.baseMap(i),
      products: i.products || {},
      hasTwoFiledAccounts: i.metadata.hasTwoFiledAccounts === undefined ? null : i.metadata.hasTwoFiledAccounts
    }
  }

  reverseMap = (i: IPartnerEligibilityResultsState, target: IUnifiedApplicationState, autoSave: boolean): void => {
    target.products = i.products
  }
}

const IUnifiedApplicationStateToPartnerEligibilityResultsState =
  new IUnifiedApplicationStateToPartnerEligibilityResultsStateImpl()
export default IUnifiedApplicationStateToPartnerEligibilityResultsState
