export enum UnifiedApplicationState {
  Completed = 'Completed',
  Loading = 'Loading',
  Error = 'Error',
  Pristine = 'Pristine',
  Navigate = 'Navigate',
  Submitting = 'Submitting',
  Submitted = 'Submitted',
  AutoSaving = 'AutoSaving',
  NeedAutoSave = 'NeedAutoSave',
  LongRunning = 'LongRunning',
}
