import { UnifiedApplicationSteps } from '../../../types/UnifiedApplicationSteps'
import IUnifiedApplicationStepProps from '../../../types/IUnifiedApplicationStepProps'
import IFundingNeedsState from '../../../types/IFundingNeedsState'
import useUnifiedApplicationStep from '../../../shared/hooks/useUnifiedApplicationStep'
import {
  UnifiedApplicationActionUpdateState,
  UnifiedApplicationActionReasonForFundingUpdate,
  UnifiedApplicationActionContinueButtonClicked
} from '../../../reducers/unifiedApplicationActions'
import { PageContainer, MainDiv } from '../readyToApply/ReadyToApplyStyles'
import React from 'react'
import FundingNeedsReducer from '../../../reducers/fundingNeedsReducer'
import { UnifiedApplicationState } from '../../../types/UnifiedApplicationState'
import UnifiedApplicationStepGuard from '../UnifiedApplicationStepGuard'
import InputLayout from '../../../commonStyles/components/inputLayout'
import ContinueAndSaveLater from '../ContinueAndSaveLater'
import { getSaveForLaterEmail } from '../../../shared/getSaveForLaterEmail'
import { tokeniseLabelAsString, tokeniseOptions } from '../../../shared/content/DynamicContent'
import { features } from '../../../features'
import { hasUnifiedEligibilitySkipConditionEligibilityState } from '../../../shared/components/eligibilityRenderer/eligibility'
import {
  maximumAmountExclusiveInvoiceFinance,
  minimumAmountInclusiveInvoiceFinance,
  invoiceFinanceMaxLimit
} from './fundingAmounts'
import { fundingNeedsPageIdentifier } from './fundingNeedsPageIdentifier'
import { Input, Select, TextArea } from 'src/ui-framework'
import { OnChangeValue } from 'react-select'
import styled from 'styled-components'
import NumberToUnlimitedLocaleString from 'src/mappers/NumberToUnlimitedLocaleString'
import { StyledLabel } from 'src/commonStyles/components/styledLabel'

const reducer = new FundingNeedsReducer(fundingNeedsPageIdentifier)

const StyledContinueButtonWrapper = styled.div`
  margin-top: 16px;
`

const AdditionalInformationLabelList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: 24px;
`

const CheckedListItem = styled.li`
  ${(props) => props.theme.label}
`

interface IFundingNeedsProps extends IUnifiedApplicationStepProps<IFundingNeedsState> {}

const FundingNeeds = (props: IFundingNeedsProps) => {
  const [state, dispatch, content, onSendLink] = useUnifiedApplicationStep<IFundingNeedsState, FundingNeedsReducer>(
    reducer,
    props.initialState,
    props.applicationIdentifier,
    props.onSubmit,
    props.onAutoSave
  )
  const fundingNeedsContent = content.FundingNeeds

  if (fundingNeedsContent.InvoiceFinance != null) {
    fundingNeedsContent.InvoiceFinance.BorrowingAmount.Dynamic = {
      MinimumAmountInclusive: minimumAmountInclusiveInvoiceFinance,
      MaximumAmountInclusive: maximumAmountExclusiveInvoiceFinance
    }
  }

  const applyAmount = (value: string) => {
    let numValue = NumberToUnlimitedLocaleString.reverseMap(value, false)
    dispatch.valueUpdate('fundingAmount', NumberToUnlimitedLocaleString.cleanString(numValue?.toString() || '', false))

    let invoiceFinanceAmount = Math.min(numValue!, invoiceFinanceMaxLimit)
    dispatch.valueUpdate('invoiceFinanceAmount', invoiceFinanceAmount.toString())
  }

  return (
    <UnifiedApplicationStepGuard currentState={state}>
      <PageContainer>
        <MainDiv>
          <div style={{ marginBottom: 16 }}>
            <InputLayout lg={12} md={12}>
              <Select
                label={tokeniseLabelAsString(fundingNeedsContent.ReasonForFunding)}
                options={tokeniseOptions(fundingNeedsContent.ReasonForFunding)}
                id="funding-reason"
                name="funding-reason"
                value={state.reasonForFunding}
                required
                onChange={(e: OnChangeValue<any, any>) => {
                  dispatch.action(UnifiedApplicationActionReasonForFundingUpdate('reasonForFunding', e.value))
                }}
                onBlur={() => {
                  dispatch.validateField('reasonForFunding')
                }}
                errors={(state.errors['reasonForFunding'] as string[]) || []}
              />
              {state.reasonForFunding === 'Other' ? (
                <Input
                  id="other-reason"
                  label=""
                  name="other-reason"
                  ariaLabel="other-reason-for-funding"
                  required
                  errors={state.errors['reasonForFundingOtherDetails'] as string[]}
                  value={state.reasonForFundingOtherDetails}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch.valueUpdate('reasonForFundingOtherDetails', e.target.value)
                  }}
                  onBlur={() => {
                    dispatch.validateField('reasonForFundingOtherDetails')
                  }}
                />
              ) : null}
            </InputLayout>
          </div>

          <div style={{ marginBottom: 16 }}>
            <InputLayout xs={12} sm={12} md={12} lg={12}>
              <Select
                label={tokeniseLabelAsString(fundingNeedsContent.Turnover)}
                options={tokeniseOptions(fundingNeedsContent.Turnover)}
                id="turnover"
                name="turnover"
                value={state.turnover}
                required
                onChange={(e: OnChangeValue<any, any>) => {
                  dispatch.valueUpdate('turnover', e.value)
                }}
                onBlur={() => {
                  dispatch.validateField('turnover')
                }}
                errors={(state.errors['turnover'] as string[]) || []}
              />
            </InputLayout>
          </div>

          <>
            <StyledLabel htmlFor="funding-amount">
              {tokeniseLabelAsString(fundingNeedsContent.FundingAmount)}
            </StyledLabel>
            <InputLayout xs={12} sm={12} md={12} lg={12}>
              <Input
                id="funding-amount"
                name="funding-amount"
                symbol="£"
                pattern="[0-9*]"
                inputMode="numeric"
                required
                value={state.fundingAmount}
                disabled={!(state.invoiceFinance || state.rlsLoan || state.businessLoan)}
                errors={(state.errors['fundingAmount'] as string[]) || []}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  dispatch.validateField('fundingAmount')
                  applyAmount(e.target.value)
                }}
                onBlur={() => dispatch.validateField('fundingAmount')}
              />
            </InputLayout>
          </>

          <div style={{ marginBottom: 16 }}>
            <InputLayout xs={12} sm={12} md={12} lg={12}>
              <Select
                label={tokeniseLabelAsString(fundingNeedsContent.Timeline)}
                options={tokeniseOptions(fundingNeedsContent.Timeline)}
                id="timeline"
                name="timeline"
                value={state.timeline}
                required
                onChange={(e: OnChangeValue<any, any>) => {
                  dispatch.valueUpdate('timeline', e.value)
                }}
                onBlur={() => {
                  dispatch.validateField('timeline')
                }}
                errors={(state.errors['timeline'] as string[]) || []}
              />
            </InputLayout>
          </div>

          <StyledLabel htmlFor="additionalInformation">
            {tokeniseLabelAsString(fundingNeedsContent.AdditionalInformation)}
          </StyledLabel>
          <AdditionalInformationLabelList>
            {fundingNeedsContent.AdditionalInformation.Suggestions &&
              fundingNeedsContent.AdditionalInformation.Suggestions.map((v, i) => (
                <CheckedListItem key={i}>{v}</CheckedListItem>
              ))}
          </AdditionalInformationLabelList>
          <TextArea
            id="additionalInformation"
            name="additionalInformation"
            required
            value={state.additionalInformation}
            errors={state.errors['additionalInformation'] as string[]}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
              dispatch.valueUpdate('additionalInformation', e.target.value)
            }
            onBlur={() => {
              dispatch.validateField('additionalInformation')
            }}
          />

          <StyledContinueButtonWrapper>
            <ContinueAndSaveLater
              content={content}
              loading={state.state === UnifiedApplicationState.Submitted}
              email={getSaveForLaterEmail(state)}
              id="funding-needs-submit"
              onSendLink={onSendLink}
              errors={state.errors}
              showSaveLater={false}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault()
                if (!features.BusinessLoanEnabled) dispatch.valueUpdate('businessLoan', false) // a check for already existing business loans after disabling
                dispatch.action(UnifiedApplicationActionContinueButtonClicked())
                if (hasUnifiedEligibilitySkipConditionEligibilityState(state)) {
                  dispatch.valuesUpdate([
                    { field: 'skipToStep', value: UnifiedApplicationSteps.EligibilityResults },
                    { field: 'state', value: UnifiedApplicationState.Submitting }
                  ])
                } else {
                  dispatch.action(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Submitting))
                }
              }}
            />
          </StyledContinueButtonWrapper>
        </MainDiv>
      </PageContainer>
    </UnifiedApplicationStepGuard>
  )
}

export default FundingNeeds
