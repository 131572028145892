import { AvailableOptionsContent, ProductsContent } from 'src/applyfrontendcontent'
import { EligibilityOutcome } from '../../../types/EligibilityOutcome'
import { IUnifiedProductType, UnifiedJourneyProductType } from '../../../types/ProductType'

export interface IOutcomeResults {
  product: keyof ProductsContent
  isEligible: boolean
}
interface IOutcomeTrigger {
  productType: UnifiedJourneyProductType
  isSelected: boolean
  eligibilityStates: Array<EligibilityOutcome>
}
export interface IOutcomeOptions {
  trigger: Array<IOutcomeTrigger>
  results: Array<IOutcomeResults>
  addProductOnContinue: ((products: IUnifiedProductType) => void) | null
  content: keyof AvailableOptionsContent
}

export const getOutcome = (
  products: IUnifiedProductType,
  outcomesLookupOrder: Array<IOutcomeOptions>
): IOutcomeOptions | undefined => {
  if (!products) {
    return undefined
  }

  for (let i = 0; i < outcomesLookupOrder.length; i++) {
    const outcomeData = outcomesLookupOrder[i]

    if (outcomeData.trigger.every((trigger) => shouldTriggerOutcome(products, trigger))) {
      return outcomeData
    }
  }
}

function shouldTriggerOutcome(products: IUnifiedProductType, trigger: IOutcomeTrigger): boolean {
  return (
    products[trigger.productType] &&
    trigger.eligibilityStates.includes(products[trigger.productType].eligibility) &&
    products[trigger.productType].isSelected === trigger.isSelected
  )
}
