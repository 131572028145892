import React from 'react'
import styled from 'styled-components'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined'

const IconWrapper = styled.div`
  display: inline-block;
  position: relative;
  top: 2px;
`

type FileIconProps = {
  color: string
}

export default ({ color }: FileIconProps) => (
  <IconWrapper>
    <FileCopyOutlinedIcon style={{ color: `${color}` }} />
  </IconWrapper>
)
