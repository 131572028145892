import React, { useCallback } from 'react'
import {
  AbsoluteDiv,
  AddManually,
  AddManuallyLabel,
  MenuItemsLink,
  MenuItemSub,
  MenuItemTitle,
  OptionDiv,
  OptionIconDiv,
  StyledLabel,
  StyledMainInput,
  StyledMenu,
  StyledMenuItem,
  StyledTypeahead
} from './themedTypeahead'
import { Check } from '@material-ui/icons'
import { ErrorList, LinkButton } from 'src/ui-framework'
import { DynamicContent } from 'src/shared/content/DynamicContent'
import { Search } from '@material-ui/icons'

type CompanySearchProps = {
  setManuallyAdd?: (txt?: string) => void
  handleLiClick: (selectedValue: any) => void
  handleKeyCapture: (e: React.KeyboardEvent<HTMLLIElement>, selectedValue: any) => void
  handleAddManuallyBlur?: (e: any) => void
  content?: any
  inputId: string
  id: string
  label: string
  onSearch: (name: string) => any
  onBlur: () => void
  options: any[] | undefined
  onChange: (selected: any) => any
  onFocus: () => void
  isInvalid: boolean
  isFocused: boolean
  selected?: any
  isLoading: boolean
  isOpen: boolean
  errors: string[]
  isReadonly?: boolean
  onKeyDown?: (e: React.KeyboardEvent<HTMLLIElement>) => void
  searchText: string | string[]
  itemContentTitle?: (result: any) => {}
  itemContentSub?: (result: any) => {}
  labelKey: string
  filterBy: (item: any) => {}
  emptyLabel: string
  placeholder?: string
  required?: boolean
}

const MenuAddManuallyLabel = { Component: AddManuallyLabel }

const Typeahead = (props: CompanySearchProps) => {
  const {
    setManuallyAdd,
    handleLiClick,
    handleKeyCapture,
    handleAddManuallyBlur,
    content,
    inputId,
    id,
    label,
    onSearch,
    onBlur,
    options,
    onChange,
    onFocus,
    isInvalid,
    isFocused,
    errors,
    isReadonly,
    onKeyDown,
    selected,
    isLoading,
    isOpen,
    searchText,
    itemContentTitle,
    itemContentSub,
    labelKey,
    filterBy,
    emptyLabel,
    placeholder,
    required
  } = props

  const AddManuallyButton = useCallback(
    (txt: string) => {
      return {
        Component: LinkButton,
        props: {
          id: 'cs-add-manually',
          onClick: () => {
            setManuallyAdd!(txt)
          },
          role: 'button'
        }
      }
    },
    [setManuallyAdd]
  )

  const renderMenu = useCallback(
    (results: any, menuProps: any, abc: any) => {
      return (
        <AbsoluteDiv>
          <StyledMenu {...menuProps} hideManualInput={!setManuallyAdd}>
            {results.map((result: any, index: number) => (
              <StyledMenuItem
                key={index}
                tabIndex={0}
                role="option"
                onClick={() => handleLiClick(result)}
                onKeyUp={(e: React.KeyboardEvent<HTMLLIElement>) => handleKeyCapture(e, result)}
              >
                <OptionDiv>
                  <OptionIconDiv className="check-icon">
                    <Check style={{ height: '15px' }} />
                  </OptionIconDiv>
                  <MenuItemsLink href="#" tabIndex={-1} onClick={(e) => e.preventDefault()}>
                    {itemContentTitle && <MenuItemTitle>{itemContentTitle(result)}</MenuItemTitle>}
                    {itemContentSub && <MenuItemSub>{itemContentSub(result)}</MenuItemSub>}
                  </MenuItemsLink>
                </OptionDiv>
              </StyledMenuItem>
            ))}
          </StyledMenu>
          {setManuallyAdd && (
            <AddManually onBlur={handleAddManuallyBlur}>
              <DynamicContent
                content={content}
                components={{
                  AddManually: MenuAddManuallyLabel,
                  AddManuallyButton: AddManuallyButton(abc.text)
                }}
                ignoreComponents={['AddManuallyButton']}
              />
            </AddManually>
          )}
        </AbsoluteDiv>
      )
    },
    [
      AddManuallyButton,
      content,
      handleAddManuallyBlur,
      handleKeyCapture,
      handleLiClick,
      itemContentSub,
      itemContentTitle,
      setManuallyAdd
    ]
  )

  return (
    <>
      <StyledLabel htmlFor={inputId}>{label}</StyledLabel>
      <StyledTypeahead
        id={id}
        isLoading={isLoading}
        useCache={false}
        labelKey={labelKey}
        minLength={2}
        delay={500}
        open={isOpen}
        onSearch={onSearch}
        onBlur={onBlur}
        options={options}
        onChange={onChange}
        searchText={searchText}
        selected={selected}
        renderMenu={renderMenu}
        filterBy={filterBy}
        inputProps={{
          id: inputId,
          autoComplete: 'nope',
          'data-hj-allow': '',
          'aria-label': inputId,
          'aria-invalid': isInvalid,
          'aria-describedby': ErrorList.getDescribedByAttribute(errors),
          'aria-readonly': isReadonly,
          readOnly: isReadonly,
          placeholder: placeholder
        }}
        onFocus={onFocus}
        isFocused={isFocused}
        isInvalid={isInvalid}
        onKeyDown={onKeyDown}
        emptyLabel={emptyLabel}
        renderInput={({ inputRef, referenceElementRef, ...inputProps }: any) => (
          <StyledMainInput
            {...inputProps}
            errors={errors}
            hotjarUnmask={true}
            ariaLabel={inputId}
            ariaInvalid={isInvalid}
            ariaDescribedby={ErrorList.getDescribedByAttribute(errors)}
            ariaReadOnly={isReadonly}
            symbol={<Search></Search>}
            inputRef={inputRef}
            style={{ marginBottom: 0 }}
            required={required}
          />
        )}
      />
    </>
  )
}

export default Typeahead
