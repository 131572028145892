import { FairProcessingNoticeContent } from '../../types/fairProcessingNotice'

export const fairProcessingNotice: FairProcessingNoticeContent = {
  Heading: 'How Will We Use Your Data?',
  DataProcessing: {
    Title: 'Data processing',
    Introduction:
      'We will use your data to process your application, to allow us to provide finance products to your business and improve our sites. We may share your data with third parties to achieve these purposes. We will not share your data for marketing purposes. We will keep your data safe in accordance with data protection legislation.'
  },
  CreditDecisions: {
    Title: 'Credit decisions and the prevention of fraud and money laundering',
    CreditReference:
      'We may use credit reference and fraud prevention agencies to help us make decisions. This will involve us sharing your information and information relating to your business partners with 3rd parties. If we discover that you have provided us with false or inaccurate information and fraud is identified, your details will be passed to fraud prevention agencies to prevent fraud and money laundering.',
    Paragraphs: [
      'If you would like further information on how your data may be used, please view our {TermsAndConditionsButton} or email us at {LegalEmail} for a guide on how agencies use your information.',
      'By continuing with your application, you are accepting that we may each use your information and information relating to your business partners in this way. If you are not authorised to give agreement on behalf of your business partners for such checks to be carried out, they will also need to give their agreement to proceed.',
      'You can change your mind at any time by emailing {LegalEmail}.'
    ],
    TermsAndConditionsButton: 'Terms and Conditions',
    LegalEmail: 'legal@kriya.co'
  },
  AcceptButton: 'Accept'
}
