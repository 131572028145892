import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import IUnifiedApplicationState from '../types/IUnifiedApplicationState'
import IApplicationToIUnifiedApplicationState from '../mappers/IApplicationToIUnifiedApplicationState'
import { UnifiedApplicationState } from '../types/UnifiedApplicationState'
import IApplication from '../types/IApplication'
import { cookieExists } from '../utils/cookieHelper'
import { applyServiceSuffix } from 'src/urlSettingsExports'

type ContinueLaterData = {
  continueLaterEmail: string | undefined
  sales?: string
}
const validateStatus = (status: number): boolean => {
  return status >= 200 && status < 500
}

// Global variable (deliberately outside of React) to indicate
// Whether an autoSave is in progress
// This is unfortunately needed because the parent unifiedApplication
// state can be autoSaving whilst the child state submits
// leading to a race condition
// TODO: AutoSave should live in the child state!!
let autoSaving = false
export const isCurrentlyAutoSaving = (val?: boolean) => {
  if (val !== undefined) {
    autoSaving = val
  }

  return autoSaving
}

export default async (
  state: IUnifiedApplicationState
): Promise<{ success: boolean; application: IApplication | undefined; status?: number }> => {
  const axiosConfig: AxiosRequestConfig = {
    withCredentials: true,
    validateStatus,
    method: state.isNew ? 'POST' : 'PATCH',
    params: state.state === UnifiedApplicationState.AutoSaving ? { autosave: true } : null,
    data: IApplicationToIUnifiedApplicationState.reverseMap(state)
  }

  try {
    const response = await axios(`/${applyServiceSuffix()}/applications`, axiosConfig)
    return {
      success: response.status === (state.isNew ? 201 : 200),
      application: response.data,
      status: response.status
    }
  } catch (error) {
    const err = error as AxiosError

    return { success: false, application: undefined, status: err.response?.status }
  }
}

export const ContinueLater = async (id: string, email?: string): Promise<boolean> => {
  let data: ContinueLaterData = { continueLaterEmail: email }

  let queryParams: { [key: string]: string } = {}
  if (cookieExists('sales') === true && !email!.toLowerCase().endsWith('@kriya.co')) {
    queryParams['sales'] = ''
  }

  try {
    const response = await axios.post(`/${applyServiceSuffix()}/applications/${id}/continueLater`, data, {
      withCredentials: true,
      validateStatus,
      params: queryParams
    })
    return response.status === 200
  } catch {
    return false
  }
}

export const createApplicationFromInitialisation = async (
  axiosInstance: AxiosInstance,
  state: IApplication
): Promise<IApplication> => {
  const applicationResponse = await axiosInstance.post<IApplication>(`/${applyServiceSuffix()}/applications`, state, {
    withCredentials: true,
    validateStatus: (status: number): boolean => {
      return status === 201
    },
    headers: {
      'Mf-Referrer': document.referrer
    }
  })

  return applicationResponse.data
}
