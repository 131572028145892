import React from 'react'
import { components, IndicatorSeparatorProps, DropdownIndicatorProps, OptionProps, ControlProps } from 'react-select'
import styled from 'styled-components'
import { Check } from '@material-ui/icons'
import HandleErrorAndFocusedColors from '../../../commonStyles/ErrorAndFocusedColors'
import ErrorList from '../ErrorList'

type OptionDivStyleProps = {
  theme: any
  isFocused: boolean
  isSelected: boolean
}

const OptionDiv = styled.div<OptionDivStyleProps>`
  border-radius: 4px;
  min-height: 32px;
  ${(props) => {
    if (props.isSelected) {
      return `
                background-color: ${props.theme.colors.info};
                color: ${props.theme.colors.white};
            `
    }

    return ''
  }}
  ${(props) => {
    if (props.isFocused) {
      return `
                background-color: ${props.theme.colors.grey200};
                color: ${props.theme.colors.textGrey900};
            `
    }

    return ''
  }}
    ${(props) => {
    if (props.isFocused && props.isSelected) {
      return `
                background-color: ${props.theme.colors.info70};
                color: ${props.theme.colors.white};
            `
    }

    return ''
  }}
`

type OptionIconDivStyleProps = {
  theme: any
  isFocused: boolean
  isSelected: boolean
}

const OptionIconDiv = styled.div<OptionIconDivStyleProps>`
  ${(props) => {
    if (!props.isFocused && !props.isSelected) {
      return `
                display: none;
            `
    }

    return ''
  }}

  position: absolute;
  padding-left: 8px;
  align-self: center;
`

const OptionLabelDiv = styled.div`
  width: 100%;
`

export const Option = (props: OptionProps<any>) => {
  return (
    <OptionDiv isFocused={props.isFocused} isSelected={props.isSelected}>
      {/*//@ts-ignore*/}
      {!props.selectProps.hideOptionIcon && (
        <OptionIconDiv isFocused={props.isFocused} isSelected={props.isSelected}>
          <Check style={{ height: '15px', position: 'absolute', top: '8px' }} />
        </OptionIconDiv>
      )}
      <OptionLabelDiv className="react-select-option-text" data-value={props.data.value}>
        <components.Option {...props} />
      </OptionLabelDiv>
    </OptionDiv>
  )
}

export const IndicatorSeparator = (props: IndicatorSeparatorProps<any, true>) => {
  return (
    <div style={{ display: 'none' }}>
      <components.IndicatorSeparator {...props} />
    </div>
  )
}

export const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
  return (
    <div style={{ display: 'none' }}>
      <components.DropdownIndicator {...props}></components.DropdownIndicator>
    </div>
  )
}

export const MenuList = (props: any) => {
  return (
    <div style={{ padding: '3px 6px 3px 6px', backgroundColor: '#F5F5F5', borderRadius: '8px' }}>
      <components.MenuList {...props}>{props.children}</components.MenuList>
    </div>
  )
}

type ControlDivStyleProps = {
  theme: any
  focused: boolean
  hasErrors: boolean
}

const ControlDiv = styled.div<ControlDivStyleProps>`
  .react-select-control {
    border-color: ${(props) => props.theme.colors.grey200};
    height: 48px;
    border-radius: 8px;
    &:hover {
      background-color: ${(props) => props.theme.colors.grey10};
      border-color: ${(props) => props.theme.colors.grey200};
    }

    ${({ hasErrors, focused, theme }: { hasErrors: boolean; theme: any; focused: boolean }) =>
      HandleErrorAndFocusedColors(hasErrors, focused, theme)}
  }
`

export const Control = ({ children, ...props }: ControlProps<any, true, any>) => {
  return (
    <ControlDiv
      focused={props.isFocused}
      //@ts-ignore
      hasErrors={props.selectProps.errors && props.selectProps.errors.length > 0}
      className="react-select-control-div"
      data-value={props.selectProps.value && props.selectProps.value.value}
    >
      <components.Control {...props} className="react-select-control">
        {children}
      </components.Control>
    </ControlDiv>
  )
}

export const Input = (props: any) => {
  const ariaProps = {
    'aria-describedby': ErrorList.getDescribedByAttribute(props.selectProps.errors),
    'aria-invalid': props.selectProps.errors?.length > 0
  }

  return <components.Input {...props} {...ariaProps} />
}
