import React, { useEffect } from 'react'
import { colors, media } from 'src/ui-framework'
import { RepresentativeJourneyType } from '../../../types/RepresentativeJourneyType'
import { Redirect, RouteComponentProps, useHistory, useParams } from 'react-router-dom'
import useUnifiedApplicationJourney from '../../../shared/hooks/useUnifiedApplicationJourney'
import { IUnifiedApplicationParams } from '../../../types/IUnifiedApplicationParams'
import saveUnifiedApplication from '../../../shared/saveUnifiedApplication'
import { UnifiedApplicationState } from '../../../types/UnifiedApplicationState'
import { DynamicContent, tokeniseToString } from '../../../shared/content/DynamicContent'
import { PartnerType } from '../../../types/PartnerType'
import { useGlobalContext } from '../../../GlobalContext'
import { barclaysLogoDetails } from '../../../shared/setPartnerLogo'
import { ErrorType } from '../../../reducers/unifiedApplicationActions'
import UnauthorizedErrorPage from '../../error/UnauthorizedError'
import { IRepresentativeJourneyInfoType } from '../../../types/IRepresentativeJourneyInfoType'
import styled from 'styled-components'
import { H2, H3 } from 'src/commonStyles/components/headings'
import { readyToApplyCustomer } from 'src/applyfrontendcontent/en-GB/BarclaysJourney/CustomerReadyToApply'
import { ReadyToApplyMainContentWrapper, RequirementsListWrapper, StyledLi, StyledUl } from './ReadyToApplyStyles'
import StyledButton from 'src/ui-framework/components/Button/styledButton'
import {
  BritishBankImage,
  FooterMarketing,
  TrustPilotImageWrapper
} from 'src/commonStyles/components/unifiedJourneyFooter'
import { BritishBankLogo } from 'src/commonStyles/images/images'
import TrustPilotBox from 'src/shared/components/footer/TrustPilot'
import getJourneyById from 'src/pages/journeyFactory'
import { JourneyType } from 'src/types/JourneyType'
import { EligibilityOutcome } from 'src/types/EligibilityOutcome'
import { UnifiedJourneyProductType } from 'src/types/ProductType'
import { SetHotjarPageData } from './hotjarJourneyTypePageView'

// This page is only applicable for Barclays users who have received a referral link
// Other partners logic should be added in the relevant ReadyToApply pages
interface PartnerParams {
  id: string
}

interface IPartnerApplication {
  journey: JourneyType
  isRevolvingCreditSelected: boolean
  isStandardIFSelected: boolean
  representativeJourney: RepresentativeJourneyType
}

const StyledH2 = styled(H2)`
  margin-bottom: 48px;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  ${media.upToTablet`
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 32px;
  `}
`

const ReadyToApplyListItem = styled(StyledLi)`
  margin-top: 0px;
  padding-top: 0px;
`

const ReadyToApplyList = styled(StyledUl)``

const PartnerReadyToApplyRequirementsWrapper = styled(RequirementsListWrapper)`
  margin-bottom: 48px;
  font-weight: 400;
  line-height: 24px;
  color: ${colors.textGrey900};
  font-size: 16px;
  ${media.upToTablet`
    margin-bottom: 32px;    
    font-size: 14px;
    letter-spacing: 0.16px;
    line-height: 20px;
  `}
`

const PartnerReadyToApplyMainContainer = styled.div`
  margin-top: 48px;
  ${media.upToTablet`
    margin-top: 16px;
  `}
`

const MainContainer = styled.div`
  bottom: 0;
  display: flex;
  justify-content: center;
`

const SmallSpacerDiv = styled.div`
  margin: 20px 0;
`

const MedmiumSpacerDiv = styled.div`
  margin: 32px 0;
`

export const MainDiv = styled.div`
  width: 560px;
`

const PartnerReadyToApply = (p: RouteComponentProps<IUnifiedApplicationParams>) => {
  const { setPartnerData, copy } = useGlobalContext()
  const params = useParams<PartnerParams>()
  const history = useHistory()
  const [state] = useUnifiedApplicationJourney(p)

  state.id = params.id
  useEffect(() => {
    if (state.partnerType === PartnerType.Barclays) {
      setPartnerData(barclaysLogoDetails)
    }
  }, [state.partnerType, setPartnerData])

  let content = copy.ReadyToApplyInvoiceFinance
  let readyToApplyCustomerContent = readyToApplyCustomer

  const updateApplication = async (p: IPartnerApplication) => {
    SetHotjarPageData(state.hasSalesCookie, state.partnerType, state.channelType, state.representativeJourneyType)

    state.journeyType = p.journey
    if (state.products !== undefined) {
      if (!state.products[UnifiedJourneyProductType.SelectInvoiceDiscounting]) {
        state.products[UnifiedJourneyProductType.SelectInvoiceDiscounting] = {
          isSelected: p.isStandardIFSelected,
          eligibility: EligibilityOutcome.InsufficientData
        }
      } else {
        state.products[UnifiedJourneyProductType.SelectInvoiceDiscounting].isSelected = p.isStandardIFSelected
      }
    }

    state.representativeJourneyType = p.representativeJourney

    const response = await saveUnifiedApplication(state)

    if (!response.success || !response.application) {
      history.push({
        pathname: '/error'
      })
      return
    }
    history.push({
      pathname: `/application/${params.id}`
    })
  }

  const startApplication = () => {
    let application: IPartnerApplication = {
      journey: JourneyType.BarclaysStandard,
      isRevolvingCreditSelected: false,
      isStandardIFSelected: true,
      representativeJourney: state.representativeJourneyType!
    }
    updateApplication(application)
  }

  const StartApplicationButton = {
    Component: StyledButton,
    props: {
      type: 'primary',
      id: 'continue-standard-application',
      onClick: startApplication
    }
  }

  if (state.state === UnifiedApplicationState.Loading) {
    return <p>{tokeniseToString(copy.Shared, 'LoadingPage')}</p>
  }

  const repJourneyInfo: IRepresentativeJourneyInfoType = {
    partnerIdentifier: state.partnerIdentifier,
    representativeJourneyType: state.representativeJourneyType
  }

  if (state.errorType === ErrorType.Unauthorized) {
    return <UnauthorizedErrorPage content={copy} showSendEmailLink representativeJourneyInfo={repJourneyInfo} />
  }

  if (state.errorType === ErrorType.Forbidden) {
    return <UnauthorizedErrorPage content={copy} showSendEmailLink={false} representativeJourneyInfo={repJourneyInfo} />
  }

  if (state.journeyType !== JourneyType.Unknown && state.isApplicationStarted) {
    const journey = getJourneyById(state.journeyType, state.status, state.channelType)
    const maxPageAllowed =
      state.lastCompletedStep === undefined
        ? journey.first()
        : journey.getNextStep(state.lastCompletedStep.id) || journey.last()
    const to = `/application/${p.match.params.id}/${maxPageAllowed.step}`
    return <Redirect to={to} />
  }

  return (
    <MainContainer>
      <MainDiv>
        <PartnerReadyToApplyMainContainer>
          <ReadyToApplyMainContentWrapper>
            <StyledH2>{readyToApplyCustomerContent.Heading}</StyledH2>
            <PartnerReadyToApplyRequirementsWrapper>
              <H3>{readyToApplyCustomerContent.AboutUs}</H3>
              <ReadyToApplyList>
                {readyToApplyCustomerContent.AboutUsList?.map((r, index) => (
                  <ReadyToApplyListItem key={index}>{r}</ReadyToApplyListItem>
                ))}
              </ReadyToApplyList>
              <SmallSpacerDiv>{readyToApplyCustomerContent.RequirementsHeading}</SmallSpacerDiv>
              <SmallSpacerDiv>
                <ReadyToApplyList>
                  {readyToApplyCustomerContent.Requirements?.map((r, index) => (
                    <ReadyToApplyListItem key={index}>{r}</ReadyToApplyListItem>
                  ))}
                </ReadyToApplyList>
              </SmallSpacerDiv>
            </PartnerReadyToApplyRequirementsWrapper>
            <DynamicContent
              components={{
                StartApplicationButton
              }}
              content={readyToApplyCustomerContent}
            />
            <DynamicContent content={content} components={{}} />
            <MedmiumSpacerDiv>
              <FooterMarketing>
                <BritishBankImage src={BritishBankLogo} alt={readyToApplyCustomerContent.BritishBankImageAltText} />
                <TrustPilotImageWrapper>
                  <TrustPilotBox />
                </TrustPilotImageWrapper>
              </FooterMarketing>
            </MedmiumSpacerDiv>
          </ReadyToApplyMainContentWrapper>
        </PartnerReadyToApplyMainContainer>
      </MainDiv>
    </MainContainer>
  )
}

export default PartnerReadyToApply
