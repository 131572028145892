const isDate = (inp: any): inp is Date => {
  return inp instanceof Date
}

const deepCopy = <T extends object>(source: T | null): T | null => {
  if (source === null) {
    return null
  }

  const isArray = source instanceof Array
  const clone: any = isArray ? [] : {}
  for (const prop in source) {
    if (Object.prototype.hasOwnProperty.call(source, prop)) {
      const val: any = source[prop]
      if (typeof val === 'object' && val !== null) {
        if (isArray) {
          if (isDate(val)) {
            clone.push(new Date(val))
          } else {
            clone.push(deepCopy<any>(val))
          }
        } else {
          if (isDate(val)) {
            clone[prop] = new Date(val)
          } else {
            clone[prop] = deepCopy<any>(val)
          }
        }
      } else {
        if (isArray) {
          clone.push(val)
        } else {
          clone[prop] = val
        }
      }
    }
  }
  return clone as T
}

export default deepCopy
