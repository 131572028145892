import IUnifiedApplicationState from '../types/IUnifiedApplicationState'
import IFundingNeedsState from '../types/IFundingNeedsState'
import IUnifiedApplicationStateToIUnifiedApplicationStepState from './IUnifiedApplicationStateToIUnifiedApplicationStepState'
import { UnifiedJourneyProductType } from '../types/ProductType'
import { EligibilityOutcome } from '../types/EligibilityOutcome'
import safeNumberConverter from './safeNumberConverter'
import NumberToLocaleString from './NumberToLocaleString'
import deepCopy from '../utils/deepCopy'

class IUnifiedApplicationStateToIFundingNeedsStateImpl extends IUnifiedApplicationStateToIUnifiedApplicationStepState<IFundingNeedsState> {
  map = (i: IUnifiedApplicationState): IFundingNeedsState => {
    const isProducts = i.products !== undefined

    const invoiceFinanceAmount = isProducts
      ? i.products![UnifiedJourneyProductType.SelectInvoiceDiscounting]?.amount || 0
      : 0

    let fundingAmount: number | undefined = Math.max(invoiceFinanceAmount || 0)

    if (fundingAmount === 0) {
      fundingAmount = undefined
    }
    return {
      ...this.baseMap(i),
      rlsLoan: false,
      rlsLoanAmount: '',
      preferredLoanDuration: '',
      preferredRepaymentType: '',
      invoiceFinance: isProducts && i.products![UnifiedJourneyProductType.SelectInvoiceDiscounting]?.isSelected,
      invoiceFinanceAmount: invoiceFinanceAmount?.toString() || '',
      businessLoan: false,
      businessLoanAmount: '',
      reasonForFunding: i.reasonForFunding,
      reasonForFundingOtherDetails: i.reasonForFundingOtherDetails,
      hasTwoFiledAccounts: i.metadata.hasTwoFiledAccounts === undefined ? null : i.metadata.hasTwoFiledAccounts,
      fundingAmount: NumberToLocaleString.map(fundingAmount) || '',
      isContinueButtonClicked: false,
      showProductSelectionModal: false,
      turnover: i.turnover,
      timeline: i.timeline,
      additionalInformation: i.additionalInformation
    }
  }

  reverseMap = (i: IFundingNeedsState, target: IUnifiedApplicationState, autoSave: boolean): void => {
    // These if statement should never be hit
    // but keep it in for safety
    if (target.products === undefined) {
      target.products = {}
    }

    target.reasonForFunding = i.reasonForFunding
    target.reasonForFundingOtherDetails = i.reasonForFundingOtherDetails
    target.turnover = i.turnover
    target.timeline = i.timeline
    target.additionalInformation = i.additionalInformation

    const products = deepCopy(target.products || {})!

    products[UnifiedJourneyProductType.SelectInvoiceDiscounting] = {
      amount: safeNumberConverter(i.invoiceFinanceAmount) ? NumberToLocaleString.reverseMap(i.invoiceFinanceAmount) : 0,
      isSelected: i.invoiceFinance,
      eligibility: EligibilityOutcome.InsufficientData
    }

    target.products = products
  }
}

const IUnifiedApplicationStateToIFundingNeedsState = new IUnifiedApplicationStateToIFundingNeedsStateImpl()
export default IUnifiedApplicationStateToIFundingNeedsState
