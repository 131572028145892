import React from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'

import MenuItemComponent from './MenuItemComponent'

type OwnProps = {
  index: number
  item:
    | {
        url: string
        label: string
      }
    | (() => JSX.Element)
}

export default function ThemedMenuitem(props: OwnProps) {
  const { index, item } = props

  const useStyles = makeStyles({
    root: {
      fontFamily: '"Open Sans",helvetica,arial,sans-serif',
      fontSize: '15px',
      height: '64px',
      paddingLeft: '0',
      paddingRight: '0'
    },
    link: {
      height: '16px',
      padding: '20px 20px 24px 21px',
      border: 'solid 2px transparent',
      textDecoration: 'none',
      color: 'white',
      '&:hover': {
        padding: '20px 20px 24px 21px',
        borderRadius: '4px 4px 0 0',
        border: 'solid 2px #566493',
        backgroundColor: '#566493'
      },
      '&:active': {
        padding: '20px 20px 24px 21px',
        borderRadius: '4px 4px 0 0',
        border: 'solid 2px #ffffff'
      }
    },
    activeLink: {
      padding: '20px 20px 22px 21px',
      borderRadius: '4px 4px 0 0',
      border: 'solid 2px transparent',
      borderBottom: 'solid 4px #00cdbe'
    }
  })

  const classes = useStyles()

  return (
    <MenuItem
      id={`item-${index}`}
      key={`item-${index}`}
      classes={{
        root: classes.root
      }}
    >
      <MenuItemComponent item={item} className={classes.link} />
    </MenuItem>
  )
}
