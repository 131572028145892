import React from 'react'
import { colors } from 'src/ui-framework'
import styled from 'styled-components'

const StyledBlurb = styled.div`
  display: table;
  height: 100%;
  margin: auto;
`

const Centered = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 100%;
`

const StyledBlurbText = styled.div<StyledBlurbProps>`
  font-family: 'Matter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  letter-spacing: 0.16px;
  color: ${colors.textGrey700};

  ${(props) => {
    if (props.disabled) {
      return {
        color: colors.buttonTextOnDisabled
      }
    }
  }}
`

type StyledBlurbProps = {
  disabled: boolean
}

type UploadDocumentsBlurbProps = {
  compact: boolean
  uploadInProgress: boolean
  focused: boolean
}

export default (p: UploadDocumentsBlurbProps) => (
  <StyledBlurb>
    <Centered>
      {p.uploadInProgress && (
        <StyledBlurbText disabled={true}>
          Your files are just uploading... <br />
          Wait for upload to finish
        </StyledBlurbText>
      )}
      {!p.uploadInProgress && (
        <StyledBlurbText disabled={false}>
          Drag and drop file(s) here or <u>click to upload</u>
        </StyledBlurbText>
      )}
    </Centered>
  </StyledBlurb>
)
