import UnifiedApplicationBaseReducer from './unifiedApplicationBaseReducer'
import { IErrorsState, flattenErrors, syncHasErrors } from '../types/IErrorsState'
import {
  IUnifiedApplicationAction,
  UnifiedApplicationActionType,
  UnifiedApplicationActionUpdateState,
  IUnifiedApplicationActionSelectDirector
} from './unifiedApplicationActions'
import IDirectorDetailsState from '../types/IDirectorDetailsState'
import { validate as validateRequiredFields, FieldWithErrorMessage } from '../validators/formValidator'
import { UnifiedApplicationState } from '../types/UnifiedApplicationState'
import { getCompanyDirectors } from '../shared/companyDataApis'
import { IUnifiedApplicationActionDirectorDetailsLoaded } from './directorDetailsActions'
import dateValidator, { DateValidatorResult } from '../validators/dateValidator'
import { IDirectorProfile } from '../types/IDirectorProfile'
import { tokeniseToString } from '../shared/content/DynamicContent'
import { RepresentativeJourneyType } from '../types/RepresentativeJourneyType'
import emailValidator from '../validators/emailValidator'
import phoneNumberValidator from '../validators/phoneNumberValidator'
import { trackAppInsightsEvent } from '../utils/appInsights/AppInsights'

export default class DirectorDetailsReducer extends UnifiedApplicationBaseReducer<IDirectorDetailsState> {
  private getRequiredFields = (state: IDirectorDetailsState) => {
    const content = this.getContent(state)

    const requiredFields: Array<FieldWithErrorMessage> = [
      {
        fieldName: 'directorName',
        errorMessage: tokeniseToString(content.DirectorDetails.WhichDirector.Automated.Error || {}, 'Required')
      }
    ]

    const addressRequiredFields: Array<FieldWithErrorMessage> = [
      {
        fieldName: 'addressLine1',
        errorMessage: tokeniseToString(
          content.DirectorDetails.ResidentialAddress.AddressLineOne.Error || {},
          'Required'
        )
      },
      {
        fieldName: 'city',
        errorMessage: tokeniseToString(content.DirectorDetails.ResidentialAddress.City.Error || {}, 'Required')
      },
      {
        fieldName: 'country',
        errorMessage: tokeniseToString(content.DirectorDetails.ResidentialAddress.Country.Error || {}, 'Required')
      },
      {
        fieldName: 'postCode',
        errorMessage: tokeniseToString(content.DirectorDetails.ResidentialAddress.Postcode.Error || {}, 'Required')
      }
    ]

    return {
      content,
      requiredFields,
      addressRequiredFields
    }
  }

  onValidate = (state: IDirectorDetailsState): IErrorsState => {
    const { content, requiredFields, addressRequiredFields } = this.getRequiredFields(state)
    const errors = validateRequiredFields(requiredFields, state)

    if (state.residentialAddress) {
      const directorAddressErrors = validateRequiredFields(addressRequiredFields, state.residentialAddress)
      errors.residentialAddress = directorAddressErrors
      errors.hasErrors = directorAddressErrors.hasErrors || errors.hasErrors
    }

    const result = dateValidator(state.dateOfBirth, undefined, undefined)
    if (result === DateValidatorResult.Incomplete) {
      errors.dateOfBirth = [tokeniseToString(content.DirectorDetails.DateOfBirth.Error || {}, 'Required')]
    } else if (result === DateValidatorResult.InvalidDate) {
      errors.dateOfBirth = [tokeniseToString(content.DirectorDetails.DateOfBirth.Error || {}, 'Invalid')]
    }

    if (state.representativeJourneyType === RepresentativeJourneyType.Representative) {
      if (state.directorEmail === undefined || state.directorEmail.length === 0) {
        errors.hasErrors = true
        errors['directorEmail'] = [
          tokeniseToString(content.DirectorDetails.ContactDetails?.DirectorEmail.Error || {}, 'Required')
        ]
      } else if (!emailValidator(state.directorEmail!)) {
        errors.hasErrors = true
        errors['directorEmail'] = [
          tokeniseToString(content.DirectorDetails.ContactDetails?.DirectorEmail.Error || {}, 'Invalid')
        ]
      }

      if (state.directorPhoneNumber === undefined || state.directorPhoneNumber.length === 0) {
        errors.hasErrors = true
        errors['directorPhoneNumber'] = [
          tokeniseToString(content.DirectorDetails.ContactDetails?.DirectorPhoneNumber.Error || {}, 'Required')
        ]
      } else if (!phoneNumberValidator(state.directorPhoneNumber!)) {
        errors.hasErrors = true
        errors['directorPhoneNumber'] = [
          tokeniseToString(content.DirectorDetails.ContactDetails?.DirectorPhoneNumber.Error || {}, 'Invalid')
        ]
      }
    }

    if (state.representativeJourneyType !== RepresentativeJourneyType.Representative) {
      if (Number.isNaN(new Date(state.legalsConsentedTime ?? '').getDate())) {
        errors.hasErrors = true
        errors['legalsConsentedTime'] = [
          tokeniseToString(content.DirectorDetails.TermsAndConditions.Error || {}, 'Required')
        ]
      }
    }

    if (errors.hasErrors) {
      trackAppInsightsEvent('DirectorDetailsError', {
        directorDetailsState: state,
        errors,
        locale: navigator.language
      })
    }
    return flattenErrors(errors)
  }

  onBeforeSubmit = async (state: IDirectorDetailsState): Promise<void> => {}

  onLoad = async (
    state: IDirectorDetailsState,
    dispatch: React.Dispatch<IUnifiedApplicationAction<object>>
  ): Promise<void> => {
    if (state.companyIdentifier) {
      try {
        const directors = await getCompanyDirectors(state.companyIdentifier)
        if (directors) {
          dispatch({
            type: UnifiedApplicationActionType.Loaded,
            payload: {
              directors: directors
            }
          } as IUnifiedApplicationActionDirectorDetailsLoaded)
          return
        }
      } catch {
        dispatch(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Error))
      }
    }
    dispatch(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Pristine))
  }

  handleDefaultAction = (
    prevState: IDirectorDetailsState,
    newState: IDirectorDetailsState,
    action: IUnifiedApplicationAction<object>
  ): void => {
    switch (action.type) {
      case UnifiedApplicationActionType.Loaded:
        const loadAction = action as IUnifiedApplicationActionDirectorDetailsLoaded
        const { directors } = loadAction.payload
        newState.state = UnifiedApplicationState.Pristine
        newState.directors = []
        directors
          ?.filter((f) => f.appointmentType === 'DIR')
          .forEach((f: IDirectorProfile) => {
            newState.directors.push(f.nameAsTitleCase)
            newState.profiles[f.nameAsTitleCase] = {
              address: {
                addressLine1: f.address?.addressLine1 || '',
                addressLine2: f.address?.addressLine2 || '',
                city: f.address?.city || '',
                country: f.address?.country || '',
                postCode: f.address?.postCode || '',
                region: f.address?.region || ''
              },
              dateOfBirth: {
                day: f.dateOfBirthNormalized.day || undefined,
                month: f.dateOfBirthNormalized.month || undefined,
                year: f.dateOfBirthNormalized.year || undefined
              }
            }
          })
        break

      case UnifiedApplicationActionType.DirectorDetailsSelectDirector:
        const selectDirector = action as IUnifiedApplicationActionSelectDirector
        const director = newState.profiles[selectDirector.payload.director]
        this.updateAndValidateField(newState, 'directorName', selectDirector.payload.director, newState)
        this.updateFieldValue(newState, 'dateOfBirth', director.dateOfBirth)
        // this.updateFieldValue(newState, 'residentialAddress', director.address)
        break

      case UnifiedApplicationActionType.AcceptTermsAndConditions:
        const errors = this.onValidate(newState)
        const { content } = this.getRequiredFields(newState)

        syncHasErrors(errors)
        if (errors.hasErrors) {
          newState.errors = errors
          newState.errors.globalErrors = [tokeniseToString(content.Shared, 'PageError')]
        } else {
          newState.errors = {
            hasErrors: false
          }

          if (!newState.hasSalesCookie) {
            newState.showModal = true
          } else {
            newState.state = UnifiedApplicationState.Submitting
          }
        }
        break
    }
  }
}
