import IGetStartedState from '../types/IGetStartedState'
import UnifiedApplicationBaseReducer from './unifiedApplicationBaseReducer'
import { IErrorsState } from '../types/IErrorsState'
import {
  UnifiedApplicationActionUpdateState,
  IUnifiedApplicationAction,
  UnifiedApplicationActionType,
  IUnifiedApplicationActionGetStartedLoaded
} from './unifiedApplicationActions'
import { UnifiedApplicationState } from '../types/UnifiedApplicationState'
import { validate as validateRequiredFields, FieldWithErrorMessage } from '../validators/formValidator'
import {
  unknownCompanyIdentifier,
  getCompanyDetails,
  saveCompanyDetails,
  companyHasAtLeast2FiledAccounts
} from '../shared/companyDataApis'
import ICompaniesApiCreateRequest from '../types/ICompaniesApiCreateRequest'
import emailValidator from '../validators/emailValidator'
import phoneNumberValidator from '../validators/phoneNumberValidator'

const CRN_LENGTH = 8

export default class GetStartedReducer extends UnifiedApplicationBaseReducer<IGetStartedState> {
  handleDefaultAction = (
    prevState: IGetStartedState,
    newState: IGetStartedState,
    action: IUnifiedApplicationAction<object>
  ): void => {
    switch (action.type) {
      case UnifiedApplicationActionType.Loaded:
        const loadedAction = action as IUnifiedApplicationActionGetStartedLoaded
        const { company } = loadedAction.payload
        newState.state = UnifiedApplicationState.Pristine
        newState.companyNumber = company.crn
        newState.companyName = company.name
        break
      case UnifiedApplicationActionType.GetStartedAddCompanyManually:
        newState.addCompanyManually = true
        newState.address = {
          addressLine1: '',
          addressLine2: '',
          city: '',
          postCode: '',
          region: '',
          country: ''
        }
        newState.companyName = ''
        newState.companyNumber = ''

        if (!prevState.addCompanyManually) {
          newState.resetDirector = true
        }

        break
    }
  }

  onLoad = async (
    state: IGetStartedState,
    dispatch: React.Dispatch<IUnifiedApplicationAction<object>>
  ): Promise<void> => {
    if (state.companyIdentifier !== undefined && state.companyIdentifier !== unknownCompanyIdentifier) {
      const company = await getCompanyDetails(state.companyIdentifier)
      if (company === undefined) {
        dispatch(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Error))
      } else {
        dispatch({
          type: UnifiedApplicationActionType.Loaded,
          payload: {
            company
          }
        } as IUnifiedApplicationActionGetStartedLoaded)
      }
    } else {
      dispatch(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Pristine))
    }
  }

  onValidate = (state: IGetStartedState): IErrorsState => {
    const content = this.getContent(state).GetStarted
    const errorMessages = {
      name: content.Name.Error?.Required,
      email: content.EmailAddress.Error?.Required,
      phoneNumber: content.PhoneNumber.Error?.Required,
      companyName: content.CompanySearch?.CompanyName.Error?.Required,
      companyNumber: content.CompanySearch?.CompanyNumber.Error?.Required
    }

    if (state.addCompanyManually) {
      errorMessages.companyName = content.CompanySearch?.CompanyNameManual.Error?.Required
    }

    const requiredFields: Array<FieldWithErrorMessage> = [
      { fieldName: 'name', errorMessage: errorMessages.name },
      { fieldName: 'email', errorMessage: errorMessages.email },
      { fieldName: 'phoneNumber', errorMessage: errorMessages.phoneNumber },
      { fieldName: 'companyName', errorMessage: errorMessages.companyName }
    ]

    requiredFields.push({ fieldName: 'companyNumber', errorMessage: errorMessages.companyNumber })

    const errors = validateRequiredFields(requiredFields, state)

    if (errors['email'] === undefined || (errors['email'] as string[]).length === 0) {
      if (!emailValidator(state.email)) {
        errors.hasErrors = true
        errors['email'] = [content.EmailAddress.Error?.InvalidFormat]
      }

      if (state.partnerEmail && state.email && state.partnerEmail.toUpperCase() === state.email.toUpperCase()) {
        errors.hasErrors = true
        errors['email'] = [content.EmailAddress.Error?.EmailMatchesPartner]
      }
    }

    if (errors['phoneNumber'] === undefined || (errors['phoneNumber'] as string[]).length === 0) {
      if (!phoneNumberValidator(state.phoneNumber) || state.phoneNumber.length > 15) {
        errors.hasErrors = true
        errors['phoneNumber'] = [errorMessages.phoneNumber]
      }
    }

    if (errors['companyNumber'] === undefined || (errors['companyNumber'] as string[]).length === 0) {
      if (state.addCompanyManually && state.companyNumber.trim().length !== CRN_LENGTH) {
        errors.hasErrors = true
        errors['companyNumber'] = [content.CompanySearch?.CompanyNumber.Error?.LengthIncorrect]
      }
    } else if ((errors['companyNumber'] as string[])?.length > 0 && state.addCompanyManually === false) {
      errors['companyNumber'] = []
    }

    return errors
  }

  onBeforeSubmit = async (state: IGetStartedState): Promise<void> => {
    if (!state.companyName || !state.companyNumber) {
      state.companyIdentifier = unknownCompanyIdentifier
      return
    }

    if (state.companyIdentifier !== unknownCompanyIdentifier) {
      return
    }

    const crn = state.companyNumber.trim()

    const company: ICompaniesApiCreateRequest = {
      crn,
      name: state.companyName.trim(),
      countryCode: 'GB' // CompaniesAPI only allows three characters and no spaces.
    }

    const saveTask = saveCompanyDetails(company)

    state.hasTwoFiledAccounts = (await companyHasAtLeast2FiledAccounts(crn)) ?? null
    const [companyIdentifier, countryCode] = await saveTask
    state.companyIdentifier = companyIdentifier

    if (!state.address.country) {
      state.address.country = countryCode
    }
  }
}
