import { media } from '../../commonStyles/mediaQueries/responsiveDeviceSize'
import styled from 'styled-components'
import { colors } from '../../theme/colors'

type LinkButtonStyleProps = {
  theme: any
}

const StyledLinkButton = styled.button<LinkButtonStyleProps>`
  background: none;
  border: none;

  font-family: 'Matter';
  font-style: normal;
  font-weight: 400;
  font-size: ${(props: LinkButtonStyleProps) => props.theme.fontSize.s};
  line-height: 20px;
  text-decoration-line: underline;
  border: 1px solid transparent;
  color: ${colors.linkDefault};
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  &:hover {
    color: ${colors.linkHover};
    cursor: pointer;
  }
  &:active {
    color: ${colors.linkActive};
  }
  &:focus {
    color: ${colors.linkFocused};
    border: 1px solid ${colors.linkFocusedBorder};
    outline: none;
  }

  ${media.upToTablet`
    font-size: ${(props: LinkButtonStyleProps) => props.theme.fontSize.xs};
  `}
`

export default StyledLinkButton
