import IUnifiedApplicationStateToIUnifiedApplicationStepState from './IUnifiedApplicationStateToIUnifiedApplicationStepState'
import IUnifiedApplicationState from '../types/IUnifiedApplicationState'
import { UnifiedJourneyProductType } from '../types/ProductType'
import IUnifiedEligibilityState from 'src/types/unifiedJourney/IUnifiedEligibilityState'

class IUnifiedApplicationStateToUnifiedEligibilityStateImpl extends IUnifiedApplicationStateToIUnifiedApplicationStepState<IUnifiedEligibilityState> {
  map(i: IUnifiedApplicationState): IUnifiedEligibilityState {
    const isStandardIfApplication =
      i.products !== undefined &&
      UnifiedJourneyProductType.SelectInvoiceDiscounting in i.products &&
      i.products[UnifiedJourneyProductType.SelectInvoiceDiscounting].isSelected

    const eligibilityQuestionAnswer = i.generalEligibility === true ? true : undefined

    return {
      ...this.baseMap(i),
      isStandardIfApplication,
      applicationIdentifier: i.id,
      hasTwoFiledAccounts: i.metadata.hasTwoFiledAccounts === undefined ? null : i.metadata.hasTwoFiledAccounts,
      offersB2bServices: eligibilityQuestionAnswer,
      turnoverExceeds100k: eligibilityQuestionAnswer,
      fundingNeededIn60d: eligibilityQuestionAnswer,
      invoicesUpfront: eligibilityQuestionAnswer,
      invoicesInDate: eligibilityQuestionAnswer,
      fundingExceeds25k: eligibilityQuestionAnswer,
      invoicesTermsExceed15d: eligibilityQuestionAnswer,
      invoicesEachExceed10k: eligibilityQuestionAnswer,
      invoiceFinanceDiscussionAwareness: eligibilityQuestionAnswer,
      extendCreditSolutionNeeded: eligibilityQuestionAnswer
    }
  }

  reverseMap = (i: IUnifiedEligibilityState, target: IUnifiedApplicationState, autoSave: boolean): void => {
    const generalEligibility =
      i.offersB2bServices &&
      i.turnoverExceeds100k &&
      i.fundingNeededIn60d &&
      i.invoicesUpfront &&
      i.invoicesInDate &&
      i.fundingExceeds25k &&
      i.invoicesTermsExceed15d &&
      i.invoicesEachExceed10k &&
      i.invoiceFinanceDiscussionAwareness &&
      i.extendCreditSolutionNeeded

    target.generalEligibility = generalEligibility
  }
}

const IUnifiedApplicationStateToUnifiedEligibilityState = new IUnifiedApplicationStateToUnifiedEligibilityStateImpl()
export default IUnifiedApplicationStateToUnifiedEligibilityState
