import { Config } from '../../../types/locale'
import { readyToApply } from '../ReadyToApply'
import { readyToApplyInvoiceFinance } from '../ReadyToApplyInvoiceFinanceOnly'
import { saveForLater } from '../SaveForLater'
import { getStarted } from './GetStarted'
import { exitApplication } from '../ExitApplication'

export const representativeJourneySales: Partial<Config> = {
  GetStarted: getStarted,
  ReadyToApply: readyToApply,
  ReadyToApplyInvoiceFinance: readyToApplyInvoiceFinance,
  SaveForLater: saveForLater,
  ExitApplication: exitApplication
}
