import { useEffect, useReducer, useRef, useCallback, useMemo } from 'react'
import UnifiedApplicationBaseReducer from '../../reducers/unifiedApplicationBaseReducer'
import { IUnifiedApplicationStepState, UnifiedApplicationDataValue } from '../../types/IUnifiedApplicationState'
import { UnifiedApplicationState } from '../../types/UnifiedApplicationState'
import {
  UnifiedApplicationActionUpdateFields,
  UnifiedApplicationActionUpdateState,
  IUnifiedApplicationAction,
  IUpdateFieldPayload,
  UnifiedApplicationActionUpdateField,
  UnifiedApplicationActionValidateField
} from '../../reducers/unifiedApplicationActions'
import { PageIdentifier } from '../../types/PageIdentifier'
import useAutoSave from './useAutoSave'
import { ContinueLater, isCurrentlyAutoSaving } from '../saveUnifiedApplication'
import deepCopy from '../../utils/deepCopy'
import { tokeniseToString } from '../content/DynamicContent'
import { syncHasErrors } from '../../types/IErrorsState'
import { Config, SharedContent } from 'src/applyfrontendcontent'
import { useApplicationPageTracker } from '../../trackPages'
import { UnifiedApplicationSteps } from '../../types/UnifiedApplicationSteps'

export interface IDispatcher {
  action: React.Dispatch<IUnifiedApplicationAction<object>>
  valueUpdate: (field: string, value: UnifiedApplicationDataValue) => void
  valuesUpdate: (fieldValues: IUpdateFieldPayload[]) => void
  validateField: (field: string) => void
}

export const handleOnSubmitting = async <TState extends IUnifiedApplicationStepState>(
  reducer: UnifiedApplicationBaseReducer<TState>,
  dispatch: React.Dispatch<IUnifiedApplicationAction<object>>,
  state: TState,
  content: SharedContent,
  onSubmit: (state: TState, pageIdentifier: PageIdentifier, nextStep: UnifiedApplicationSteps | undefined) => void
) => {
  const stateCopy = deepCopy<TState>(state) as TState
  const errors = reducer.onValidate(stateCopy)
  syncHasErrors(errors)
  if (errors.hasErrors) {
    errors.globalErrors = [tokeniseToString(content, 'PageError')]
    const nextState = reducer.isCurrentlyLongRunning()
      ? UnifiedApplicationState.LongRunning
      : UnifiedApplicationState.NeedAutoSave
    dispatch(
      UnifiedApplicationActionUpdateFields([
        { field: 'errors', value: errors },
        { field: 'state', value: nextState }
      ])
    )
  } else {
    try {
      await reducer.onBeforeSubmit(stateCopy, dispatch)
      onSubmit(stateCopy, reducer.pageIdentifier, state.skipToStep)
    } catch {
      dispatch(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Error))
    }
  }
}

const useUnifiedApplicationStep = <
  TState extends IUnifiedApplicationStepState,
  TReducer extends UnifiedApplicationBaseReducer<TState>
>(
    reducer: TReducer,
    initialState: TState,
    applicationId: string,
    onSubmit: (state: TState, pageIdentifier: PageIdentifier, nextStep: UnifiedApplicationSteps | undefined) => void,
    onAutoSave: (state: TState, pageIdentifier: PageIdentifier) => void
  ): [TState, IDispatcher, Config, (email: string) => Promise<boolean>] => {
  const [state, dispatch] = useReducer(reducer.reducer, initialState)
  const dispatchHelpers = useRef<IDispatcher>({
    action: dispatch,
    valueUpdate: (field: string, value: UnifiedApplicationDataValue): void => {
      dispatch(UnifiedApplicationActionUpdateField(field, value))
    },
    valuesUpdate: (fieldValues: IUpdateFieldPayload[]): void => {
      dispatch(UnifiedApplicationActionUpdateFields(fieldValues))
    },
    validateField: (field: string): void => {
      dispatch(UnifiedApplicationActionValidateField(field))
    }
  })

  useApplicationPageTracker(reducer.pageIdentifier, applicationId)

  const content = useMemo<Config>(() => {
    return reducer.getContent(state)
  }, [state, reducer])

  const autoSaveCallback = useCallback(async () => {
    if (state.state !== UnifiedApplicationState.NeedAutoSave) {
      return
    }

    const stateCopy = deepCopy<TState>(state) as TState
    await reducer.onBeforeSubmit(stateCopy, dispatch)
    onAutoSave(stateCopy, reducer.pageIdentifier)
    dispatch(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Pristine))
  }, [state, reducer, onAutoSave])

  const cancelAutoSave = useAutoSave(autoSaveCallback)

  useEffect(() => {
    if (state.state !== UnifiedApplicationState.Loading) {
      return
    }
    const handleOnLoading = async () => {
      try {
        const stateCopy = deepCopy<TState>(state) as TState
        await reducer.onLoad(stateCopy, dispatch)
      } catch (error) {
        dispatch(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Error))
      }
    }

    handleOnLoading()
  }, [state.state, reducer, state])

  useEffect(() => {
    if (state.state !== UnifiedApplicationState.Submitting) {
      return
    }

    if (isCurrentlyAutoSaving()) return
    cancelAutoSave()
    dispatch(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Submitted))

    handleOnSubmitting(reducer, dispatch, state, content.Shared, onSubmit)
  }, [state, reducer, onSubmit, content.Shared, cancelAutoSave])

  const onSendLink = useCallback(
    async (email: string): Promise<boolean> => {
      cancelAutoSave()
      await autoSaveCallback()
      return await ContinueLater(applicationId, email)
    },
    [applicationId, cancelAutoSave, autoSaveCallback]
  )

  return [state, dispatchHelpers.current, content, onSendLink]
}

export default useUnifiedApplicationStep
