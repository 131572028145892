import { hideAll, PageIdentifier } from '../../../../../types/PageIdentifier'
import { UnifiedApplicationSteps } from '../../../../../types/UnifiedApplicationSteps'

export const ineligiblePageIdentifier: PageIdentifier = {
  id: '9c5a1035-db20-4cbd-a1c5-67030b031f9f',
  title: 'Eligibility Results - Ineligible',
  step: UnifiedApplicationSteps.EligibilityResultsIneligible,
  order: 27,
  isHidden: true,
  subPageIds: [],
  hidden: hideAll,
  fillParent: true
}
