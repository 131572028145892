import IUnifiedApplicationState from '../types/IUnifiedApplicationState'
import IGetStartedState from '../types/IGetStartedState'
import IApplicantState from '../types/IApplicantState'
import deepCopy from '../utils/deepCopy'
import IAddressState, { emptyAddress } from '../types/IAddressState'
import { unknownCompanyIdentifier } from '../shared/companyDataApis'
import IUnifiedApplicationStateToIUnifiedApplicationStepState from './IUnifiedApplicationStateToIUnifiedApplicationStepState'
import { ApplicationSource } from '../types/ApplicationSource'
import { PartnerType } from '../types/PartnerType'
import { ChannelType } from '../types/ChannelType'

class IUnifiedApplicationStateToIGetStartedStateImpl extends IUnifiedApplicationStateToIUnifiedApplicationStepState<IGetStartedState> {
  map = (i: IUnifiedApplicationState): IGetStartedState => {
    const applicant = i.applicant

    const isReadOnly = i.metadata.source !== undefined && i.metadata.source === ApplicationSource.UnifiedDashboard
    const isBarclays = i.partnerType === PartnerType.Barclays && i.channelType === ChannelType.Api
    const base = this.baseMap(i)
    return {
      ...base,
      name: applicant?.name || '',
      phoneNumber: applicant?.phoneNumber || '',
      address: i.registeredAddress || (deepCopy<IAddressState>(emptyAddress) as IAddressState),
      companyIdentifier: i.companyIdentifier || unknownCompanyIdentifier,
      addCompanyManually: applicant?.addedCompanyManually || false,
      resetDirector: false,
      companyName: i.companyName || '',
      companyNumber: i.companyNumber || '',
      initialCompanyNumber: i.initialCompanyNumber || '',
      showContinueLaterModal: false,
      resetCompanyAddress: false,
      hasTwoFiledAccounts: i.metadata.hasTwoFiledAccounts === undefined ? null : i.metadata.hasTwoFiledAccounts,
      isCompanyReadonly: (isBarclays && !!i.initialCompanyNumber) || isReadOnly,
      isReadOnly,
      canCallClient: i.canCallClient || false
    }
  }

  reverseMap = (i: IGetStartedState, target: IUnifiedApplicationState, autoSave: boolean): void => {
    const { email, name, phoneNumber } = i
    const applicant: IApplicantState = {
      name,
      email: email.trim(),
      phoneNumber: phoneNumber.trim(),
      addedCompanyManually: i.addCompanyManually
    }

    target.applicant = applicant

    if (i.resetCompanyAddress) {
      if (i.addCompanyManually) {
        const address = deepCopy<IAddressState>(emptyAddress) as IAddressState
        address.country = i.address?.country ?? address.country
        target.registeredAddress = deepCopy<IAddressState>(address) as IAddressState
        target.tradingAddress = deepCopy<IAddressState>(address) as IAddressState
      } else {
        target.registeredAddress = i.address
        target.tradingAddress = i.address
      }
    }

    target.companyIdentifier = i.companyIdentifier
    target.companyName = i.companyName.trim()
    target.companyNumber = i.companyNumber?.trim()

    target.initialCompanyNumber = i.initialCompanyNumber?.trim()

    if (i.resetDirector && target.director) {
      target.director.name = ''
    }
    target.metadata.hasTwoFiledAccounts = i.hasTwoFiledAccounts
    target.canCallClient = i.canCallClient
  }
}

const IUnifiedApplicationStateToIGetStartedState = new IUnifiedApplicationStateToIGetStartedStateImpl()
export default IUnifiedApplicationStateToIGetStartedState
