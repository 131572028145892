import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'

import Drawer from './Drawer'
import DropdownMenu from './DropdownMenu'
import ThemedMenuItem from './ThemedMenuItem'
import {
  PartnerDiv,
  StyledNavigationLink,
  StyledNavigationPartnerImage,
  StyledNavigationPartnerLink
} from './themedNavigation'

type OwnProps = {
  items?: (
    | {
        label: string
        url: string
      }
    | (() => JSX.Element)
  )[]
  dropdownItems?: {
    label: string
    url: string
  }[]
  username?: string
  classes?: {
    navigation?: string
    logo?: string
  }
  logoAlt?: string
  logoSrc?: string
  partnerData?: {
    linkUrl?: string
    logoAlt?: string
    logoUrl?: string
  }
}

type State = {
  open: boolean
}

export type Props = OwnProps & typeof StyledNavigation.defaultProps

class StyledNavigation extends React.Component<Props, State> {
  static defaultProps: OwnProps = {
    items: undefined,
    dropdownItems: undefined,
    username: undefined,
    classes: {},
    logoAlt: 'Kriya',
    logoSrc: 'https://cdn.kriya.co/images/kriya-logo-putty.svg',
    partnerData: undefined
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      open: false
    }

    this.toggleDrawer = this.toggleDrawer.bind(this)
    this.handleCloseDrawer = this.handleCloseDrawer.bind(this)
  }

  toggleDrawer(event: React.KeyboardEvent | React.MouseEvent) {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    const { open } = this.state
    this.setState({ open: !open })
  }

  handleCloseDrawer() {
    this.setState({ open: false })
  }

  render() {
    const { classes = {}, items, username, dropdownItems, logoAlt, logoSrc, partnerData } = this.props

    const { open } = this.state

    return (
      <AppBar position="static" className={classes.navigation}>
        <Container maxWidth="lg" style={{ paddingLeft: '0px' }}>
          <Toolbar disableGutters>
            <Box display="flex" flexGrow={1}>
              <div style={{ marginRight: '20px' }}>
                <StyledNavigationLink href="https://kriya.co" target="_blank">
                  <img src={logoSrc} alt={logoAlt} className={classes.logo} width="280px" />
                </StyledNavigationLink>
              </div>
              {partnerData !== undefined && Object.keys(partnerData).length > 0 && (
                <PartnerDiv>
                  <StyledNavigationPartnerLink>
                    <StyledNavigationPartnerImage src={partnerData.logoUrl} alt={partnerData.logoAlt} />
                  </StyledNavigationPartnerLink>
                </PartnerDiv>
              )}
              {items?.length && (
                <Hidden smDown>
                  {items.map((item, index) => (
                    <ThemedMenuItem key={`themed-menu-${index}`} index={index} item={item} />
                  ))}
                </Hidden>
              )}
            </Box>
            {dropdownItems?.length && username && (
              <Hidden smDown>
                <DropdownMenu dropdownItems={dropdownItems} username={username}></DropdownMenu>
              </Hidden>
            )}
            {(items || dropdownItems) && (
              <Hidden mdUp>
                {(items || dropdownItems) && (
                  <>
                    <MenuIcon onClick={this.toggleDrawer} />

                    <Drawer
                      open={open}
                      items={[...(items || []), ...(dropdownItems || [])]}
                      onClose={this.handleCloseDrawer}
                    />
                  </>
                )}
              </Hidden>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    )
  }
}

export default StyledNavigation
