const getDecimal = (): string => {
  const v = 1.1
  return v.toLocaleString()?.split('1')[1] || '.'
}
const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || 9007199254740991
const MIN_SAFE_INTEGER = Number.MIN_SAFE_INTEGER || -9007199254740991
const decimal = getDecimal()
const cleanRegex = new RegExp(`[^0-9${decimal}]`, 'gi')

class NumberToLocaleStringImpl {
  map(i: number | undefined): string {
    if (i === undefined) return ''
    return i.toLocaleString()
  }

  reverseMap(i: string, allowNegative: boolean = true, allowFloats: boolean = false): number | undefined {
    let cleanedInput = i.replace(cleanRegex, '')
    if (cleanedInput === '') {
      return undefined
    }
    if (allowNegative && i.indexOf('-') !== -1 && i.indexOf('-') < i.indexOf(cleanedInput[0])) {
      cleanedInput = '-' + cleanedInput
    }
    const floatValue = Math.max(MIN_SAFE_INTEGER, Math.min(Number(cleanedInput), MAX_SAFE_INTEGER))
    return isNaN(floatValue) ? undefined : allowFloats ? floatValue : Math.floor(floatValue)
  }

  cleanString(i: string, allowNegative: boolean = true): string {
    if (i === '') return i
    if (i === '-') return allowNegative ? '-' : ''
    const numValue = this.reverseMap(i, allowNegative)
    return numValue === undefined ? '' : this.map(numValue)
  }
}

const NumberToLocaleString = new NumberToLocaleStringImpl()
export default NumberToLocaleString
