import { MultipleApplicationErrorContent } from '../types/multipleApplicationError'

export const multipleApplicationError: MultipleApplicationErrorContent = {
  Heading: "We'll be in touch",
  Introduction: 'Sorry, we need to speak to you about your application. Some common reasons for this are:',
  BulletPoints: [
    'You have another active application with us.',
    'We would like some further information before you continue.',
    'Something went wrong in our system.'
  ],
  Closing: 'We’ll be in touch soon, in the meantime, you can contact us using {SalesEmail}.',
  SalesEmail: 'sales@kriya.co'
}
