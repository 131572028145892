import ICompaniesApiResponse from '../types/ICompaniesApiResponse'
import axios, { AxiosRequestConfig } from 'axios'
import ICompaniesApiCreateRequest from '../types/ICompaniesApiCreateRequest'
import { IDirectorProfile } from '../types/IDirectorProfile'

const validateStatus = (status: number): boolean => {
  return status >= 200 && status < 500
}
const axiosConfig: AxiosRequestConfig = { validateStatus }

export const unknownCompanyIdentifier = '00000000-0000-0000-0000-000000000000'
export const baseUrlPath = '/company-info'

export const getCompanyDetails = async (identifier: string): Promise<ICompaniesApiResponse | undefined> => {
  const response = await axios.get<ICompaniesApiResponse>(`${baseUrlPath}/companies/${identifier}`, axiosConfig)
  return response.status === 200 ? response.data : undefined
}

interface ICompaniesApiSearchResponse {
  kriyaCompanyIdentifier: string
  address: {
    country: string
  }
}

export const saveCompanyDetails = async (company: ICompaniesApiCreateRequest): Promise<[string, string]> => {
  const response = await axios.post<ICompaniesApiSearchResponse[]>(`${baseUrlPath}/search`, {
    fuzzyTerm: company.crn,
    address: {
      country: company.countryCode
    }
  })

  if (response.status === 200 && response.data.length) {
    const match = response.data[0]
    return [match.kriyaCompanyIdentifier, match.address.country]
  }

  return [unknownCompanyIdentifier, 'GB']
}

export type IDirectorsListResponse = Array<IDirectorProfile> | undefined
export const getCompanyDirectors = async (identifier: string): Promise<IDirectorsListResponse> => {
  const response = await axios.get(`${baseUrlPath}/companies/${identifier}/directorswithprofile`, {
    validateStatus: (status: number): boolean => status === 200 || status === 400
  })

  return response.status === 200 ? response.data : undefined
}

export const getCompanyFilings = async (crn: string): Promise<CompanyFiling[] | undefined> => {
  const filingsResponse = await axios.get<CompanyFiling[]>(`/company-info/companieshouse/gb/ltd/${crn}/filings`, {
    validateStatus: () => true
  })
  return Array.isArray(filingsResponse.data) ? filingsResponse.data : undefined
}

type CompanyFiling = {
  date: string
  type: string
}

export const companyHasAtLeast2FiledAccounts = async (crn: string): Promise<boolean | undefined> => {
  const filings = await getCompanyFilings(crn)
  if (filings === undefined) return undefined

  let accountsFiledCount = 0
  filings.forEach((filing) => {
    if (filing.type === 'AA') {
      accountsFiledCount++
    }
  })

  const minimumAccountsFiledCount = 2

  return accountsFiledCount >= minimumAccountsFiledCount
}
