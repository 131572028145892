import { ReadyToApplyContent } from '../types/readyToApply'

export const readyToApply: ReadyToApplyContent = {
  Heading: 'Are you ready to apply?',
  Introduction: [
    "We've kept our application as short and simple as possible, with no obligations or hidden fees. It only takes around 10 minutes to apply."
  ],
  SubIntroduction:
    "If you'd like to chat to our team about your finance needs and the solutions best suited to your business before applying simply {HelpButton} to arrange a call.",
  Help: "We're always here to help. {HelpButton} to get in touch.",
  HelpButton: 'Click here',
  DoubleCheck: "Double check that you're ready before you start.",
  RequirementsHeading: 'Please ensure you have the following information ready:',
  Requirements: [
    'Company name and registration number',
    "Applying Director's date of birth and residential address",
    'Last 3 months of bank statements',
    'Latest statutory accounts'
  ],
  Products: {
    InvoiceFinance: {
      Introduction: 'And non-CBILS Invoice Finance applications need:',
      IntroductionToolTip:
        'Unlock the cash tied up in your invoices and boost your working capital. We offer subscription and pay-as-you-go facilities, depending on your cash flow needs. So you can focus on the bigger picture and get on with running your business.',
      ListWithTooltips: [
        {
          Text: 'Bank statements covering the period from {Dynamic.InvoiceFinance.BankStatementStartDate} until at least {Dynamic.InvoiceFinance.BankStatementEndDate}'
        }
      ],
      ButtonText: 'Start application'
    }
  },
  Affiliation: {
    Title: "What's your relationship with the company?",
    Text: "What's your relationship with the company?",
    Options: {
      Director: { Text: "I'm a director of the limited company" },
      Representative: { Text: "I'm employed by the limited company" }
    },
    Tooltip:
      "Knowing whether you're a registered director with Companies House, or someone else applying on behalf of the business, enables us to give you the simplest application form.",
    Error: {
      Required: "Please tell us your relationship with the business you're applying for"
    }
  },
  Closing: ['You can save and continue later at any time.'],
  BritishBankImageAltText: 'British Business Bank',
  AboutUsHeading: 'About us',
  AboutUsItems: ['10 minutes to apply', 'No obligations', 'No hidden fee'],
  ClosingItems: []
}
