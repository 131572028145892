import { IErrorsState, syncHasErrors } from '../types/IErrorsState'

export const reasonForFundingValidator = (
  reasonForFunding: string | undefined,
  reasonForFundingOtherDetails: string | undefined,
  errors: IErrorsState,
  localisedMessage: string,
): IErrorsState => {
  if (
    reasonForFunding === 'Other' &&
    (reasonForFundingOtherDetails === undefined || reasonForFundingOtherDetails.length === 0)
  ) {
    errors['reasonForFundingOtherDetails'] = [localisedMessage]
    errors.hasErrors = true
  } else {
    errors['reasonForFundingOtherDetails'] = []
    syncHasErrors(errors)
  }
  return errors
}
