import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'

let reactPlugin: ReactPlugin = new ReactPlugin()
let appInsights: ApplicationInsights

const telemetryInitializer = (envelope: ITelemetryItem) => {
  if (envelope.tags !== undefined) {
    envelope.tags['ai.cloud.role'] = 'FrontEnd.Apply'
    envelope.tags['ai.cloud.roleInstance'] = 'FrontEnd.Apply'
  }
}

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {
  const initialize = (instrumentationKey: string, browserHistory: {}) => {
    if (!browserHistory) {
      throw new Error('Could not initialize Telemetry Service')
    }
    if (!instrumentationKey) {
      throw new Error('Instrumentation key not provided')
    }

    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: browserHistory
          }
        }
      }
    })

    appInsights.loadAppInsights()
    appInsights.addTelemetryInitializer(telemetryInitializer)
  }

  return { reactPlugin, initialize }
}

export const telemetryService = createTelemetryService()
export const getAppInsights = () => appInsights
export const trackAppInsightsEvent = (name: string, measurements: { [Key: string]: any }): void => {
  if (appInsights === undefined || appInsights.trackEvent === undefined) {
    return
  }

  appInsights.trackEvent({ name }, measurements)
}

export const trackAppInsightsException = (error: Error): void => {
  if (appInsights === undefined || appInsights.trackEvent === undefined) {
    return
  }

  appInsights.trackException({ error })
}
