import { RecoveryLoanFundingNeedsContent } from '../../types/fundingNeeds'

export const recoveryLoanFundingNeeds: RecoveryLoanFundingNeedsContent = {
  Heading: 'Funding Needs',
  ShortHeading: 'Funding needs',
  PageIntroduction: 'Tell us about your funding needs. Fields with a * are required.',
  ReasonForFunding: {
    Text: 'What do you want to use the funding for?',
    Options: {
      WorkingCapital: {
        Text: 'Working capital'
      },
      Investment: {
        Text: 'Investment/growth'
      },
      RefinancingRLSFacility: {
        Text: 'Refinancing a RLS facility'
      },
      RefinancingMarketFinanceDebt: {
        Text: 'Refinancing Kriya (formerly MarketFinance) debt'
      },
      RefinancingAnyOtherDebt: {
        Text: 'Refinancing any other debt'
      },
      Other: {
        Text: 'Other'
      }
    },
    Error: { Required: 'Please select a reason for funding' }
  },
  FundingOther: {
    Text: '',
    Error: {
      Required: 'Please enter a reason for funding'
    }
  },
  FundingSolutionsTitle: 'Funding Solutions',
  FundingSolutionsLoanGroup: {
    Text: 'Are you interested in taking out a loan?',
    Options: {
      RecoveryLoansScheme: {
        Text: 'Recovery Loan Scheme'
      },
      BusinessLoan: {
        Text: 'Business loan'
      }
    }
  },
  FundingSolutionsInvoiceFinanceGroup: {
    Text: 'Are you interested in taking out invoice financing?',
    Options: {
      InvoiceFinance: {
        Text: 'Invoice finance'
      }
    }
  },
  FundingSolutions: {
    Text: 'Please select the funding solution(s) you’d like to apply for',
    Options: {
      InvoiceFinance: {
        Text: 'Invoice Finance',
        Tooltip:
          'A flexible way to bridge gaps in cash flow by advancing the funds already owed to the company in outstanding invoices.'
      },
      RecoveryLoansScheme: {
        Text: 'Business loan (incl. RLS)'
      },
      BusinessLoan: {
        Text: 'Business Loan'
      }
    },
    Error: { Required: 'Please select at least one funding solution.' }
  },
  InvoiceFinance: {
    BorrowingAmount: {
      Text: 'How much do you want to borrow?',
      Tooltip:
        "Not sure of exactly how much funding is needed? As a guide, the funding limit is usually a similar size to the company's average receivables book or 5-10% of the company's annualised revenue.",
      Error: {
        Required:
          'Please enter a funding amount between {Dynamic.MinimumAmountInclusive} and {Dynamic.MaximumAmountInclusive}'
      }
    }
  },
  RecoveryLoansScheme: {
    BorrowingAmount: {
      Text: 'How much do you want to borrow? We provide business loans from {Dynamic.MinimumAmountInclusive} to {Dynamic.MaximumAmountInclusive}.',
      Error: {
        Required: 'Please enter an amount between {Dynamic.MinimumAmountInclusive} and {Dynamic.MaximumAmountInclusive}'
      }
    },
    Duration: {
      Text: 'What is your preferred loan duration?',
      Options: {
        '48': {
          Text: '4 years'
        },
        '60': {
          Text: '5 years'
        },
        '72': {
          Text: '6 years'
        }
      },
      Error: { Required: 'Please select a loan duration' }
    },
    Repayment: {
      Introduction:
        'When you repay a loan each payment you make is made up of an interest payment and a principal payment. In this RLS business loan, you will make interest only repayments during the first 6 months.',
      SubIntroduction:
        "Before accepting any offer, we will show you a full loan repayment schedule so you can see exactly what you'll need to pay and when."
    }
  }
}
