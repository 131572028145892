import { FooterContent } from './footer'
import { LinkExpiredContent } from './linkExpiredError'
import { ApplicationDeadlineErrorContent } from './applicationDeadlineError'
import { ReadyToApplyContent } from './readyToApply'
import { MultipleApplicationErrorContent } from './multipleApplicationError'
import { SaveForLaterContent } from './saveForLater'
import { SharedContent } from './shared'
import { UnauthorisedErrorContent } from './unauthorisedApplicationError'
import {
  GetStartedContent,
  YourCompanyContent,
  DirectorDetailsContent,
  SupportingDocumentsContent,
  FairProcessingNoticeContent
} from '../main'
import { FundingNeedsContent, RecoveryLoanFundingNeedsContent } from './fundingNeeds'
import { ThankYouContent } from './thankyou'
import { GenericErrorContent } from './genericError'
import { ExitApplicationContent } from './exitApplication'
import { PartnerReferralIdMissingErrorContent } from './partnerReferralIdMissingError'
import { RequirementsContent, GenericRequirementContent } from './requirements'
import { NotSupportedThankYouContent } from './notSupportedThankYou'
import { EligibilityContent } from './eligibility'
import { EligibilityResultContent, IneligibleResultContents } from './eligbilityResult'
import { ReadyToApplyPartnerContent } from './readyToApplyPartner'
import { PartnerReferralDetailsContent } from './partnerReferralDetails'
import { UnauthenticatedErrorContent } from './unauthenticatedError'

export type Config = {
  Footer: FooterContent
  ReadyToApply: ReadyToApplyContent
  ReadyToApplyInvoiceFinance: ReadyToApplyContent
  GetStarted: GetStartedContent
  RecoveryLoanFundingNeeds: RecoveryLoanFundingNeedsContent
  FundingNeeds: FundingNeedsContent
  YourCompany: YourCompanyContent
  DirectorDetails: DirectorDetailsContent
  SupportingDocuments: SupportingDocumentsContent
  ThankYou: ThankYouContent
  FairProcessingNotice: FairProcessingNoticeContent
  LinkExpiredError: LinkExpiredContent
  ApplicationDeadlineError: ApplicationDeadlineErrorContent
  MultipleApplicationError: MultipleApplicationErrorContent
  GenericError: GenericErrorContent
  UnauthorisedError: UnauthorisedErrorContent
  SaveForLater: SaveForLaterContent
  Shared: SharedContent
  PartnerReferralIdMissing: PartnerReferralIdMissingErrorContent
  ExitApplication: ExitApplicationContent | null
  Requirements: RequirementsContent
  GenericProductRequirements: GenericRequirementContent
  NotSupportedThankYou: NotSupportedThankYouContent
  Eligibility: EligibilityContent
  EligibilityResult: EligibilityResultContent
  Ineligible: IneligibleResultContents
  ReadyToApplyPartner: ReadyToApplyPartnerContent
  PartnerReferralDetails: PartnerReferralDetailsContent
  UnauthenticatedError: UnauthenticatedErrorContent
}

type Indexable = {
  [key: string]: Partial<Config>[keyof Partial<Config>]
}

export type ConfigPart = Indexable & Partial<Config>

export enum RepresentativeType {
  Director = 'Director',
  Representative = 'Representative'
}

export enum PartnerType {
  Barclays = 'Barclays'
}

export type ConditionInput = {
  representativeType: RepresentativeType
  sales: boolean
  partner?: PartnerType
  businessType?: BusinessType
  flowType?: FlowType
  channelType?: ChannelType
}

export type Node = {
  children?: Node[]
  content: ConfigPart
  condition: (input: ConditionInput) => boolean
}

// store as lowercase as browser implementations
// vary so we should standardise to domain type
export type Locale = {
  'en-gb': Node
}

export enum BusinessType {
  LimitedCompany = 'LimitedCompany'
}

export enum FlowType {
  StrategicPartner = 'StrategicPartner',
  Standard = 'Standard'
}

export enum ChannelType {
  Digital = 'Digital',
  RelationshipManager = 'RelationshipManager',
  Api = 'Api'
}
