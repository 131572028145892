import { PartnerReferralIdMissingErrorContent } from '../types/partnerReferralIdMissingError'

export const partnerReferralIdMissing: PartnerReferralIdMissingErrorContent = {
  Heading: 'Are you already a referral partner?',
  Paragraphs: [
    'Thank you for starting an application with Kriya.',
    'As a referral partner, you should have your own designated link for applying on behalf of your clients. Please make sure you use it so we can track your referral.',
    "If you no longer have access to your designated link, or you haven't received one yet, please email {PartnerContactUsEmail} and we'll be happy to help.",
    'If you’re a new partner, please allow for 15 minutes before starting an application.'
  ],
  PartnerContactUsEmail: 'partner@kriya.co',
  Button: 'Return to Kriya'
}
