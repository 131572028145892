import React, { Component } from 'react'
import { Grid, GridProps, GridSize } from '@material-ui/core'

type OwnProps = {
  xs?: boolean | GridSize
  sm?: boolean | GridSize
  md?: boolean | GridSize
  lg?: boolean | GridSize
  xl?: boolean | GridSize
  className?: string
  style?: React.CSSProperties
} & GridProps

type Props = OwnProps & typeof Column.defaultProps

class Column extends Component<Props> {
  static defaultProps: OwnProps = {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
    className: '',
    style: {}
  }

  render() {
    const { children, className, style, xs, sm, md, lg, xl } = this.props

    return (
      <Grid {...this.props} item className={className} style={style} xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
        {children}
      </Grid>
    )
  }
}

export default Column
