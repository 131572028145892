interface IInstrumentationKeyMapping {
  [Key: string]: string
}

export const localinstrumentationKey = '852da9b8-2b4a-422e-9b2b-49a2bf9a285c'
export const stagingInstrumentationKey = '593d73de-6ca0-4640-86f7-f68f9e3943af'
export const prodInstrumentationKey = '700a8075-afa4-4a9e-b7cb-fe0863eaa33b'

const keyMapping: IInstrumentationKeyMapping = {
  'apply.kriya.dev': stagingInstrumentationKey,
  'apply.kriya.co': prodInstrumentationKey,
  'localhost:3000': localinstrumentationKey
}

const instrumentationKey = (currentUrl: string): string => {
  const host = new URL(currentUrl).host
  return keyMapping[host]
}

export default instrumentationKey
