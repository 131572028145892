import React, { useState } from 'react'

import { Config, getCopy } from 'src/applyfrontendcontent'
import { Layout } from 'src/ui-framework'

import { getConfigForPartnerType } from './shared/setPartnerLogo'
import { GlobalContext, PartnerConfig } from './GlobalContext'
import { useInitialisationParameters } from './pages/unifiedApplicationJourney/readyToApply/useInitialisationParameters'
import { RepresentativeJourneyType } from './types/RepresentativeJourneyType'
import { cookieExists } from './utils/cookieHelper'

export const AppLayout = <T extends object>(p: React.PropsWithChildren<T>) => {
  const initialisationParams = useInitialisationParameters()
  const partnerConfig = getConfigForPartnerType(initialisationParams.partner, initialisationParams.channel)

  const [partnerData, setPartnerData] = useState<PartnerConfig | undefined>(partnerConfig?.config)
  const [copy, setCopy] = useState<Config>(
    getCopy(navigator.language, {
      representativeType: RepresentativeJourneyType.Director,
      sales: cookieExists('sales'),
      partner: partnerConfig?.partnerType
    })
  )

  return (
    <GlobalContext.Provider value={{ copy, setCopy, partnerData, setPartnerData }}>
      <Layout contentClasses={{ root: 'main-content' }} partnerData={partnerData}>
        {React.Children.only(p.children)}
      </Layout>
    </GlobalContext.Provider>
  )
}
