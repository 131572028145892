import { ReadyToApplyCustomerPartnerContent } from 'src/applyfrontendcontent/types/readyToApplyCustomerPartner'

export const readyToApplyCustomer: ReadyToApplyCustomerPartnerContent = {
  Heading: 'Before you start',
  Help: "We're always here to help. {HelpButton} to get in touch.",
  HelpButton: 'Click here',
  AboutUs:'About us',
  AboutUsList: [
    'Keep invoices & payments flowing brilliantly for your business.',
    "Access Selective Invoice Finance funding alongside embedded B2B Payments solutions, via Kriya's Merchant Portal.",
    '5 minutes to apply',
    'No obligations',
    'No hidden fee'
  ],
  RequirementsHeading: 'To complete your application, please ensure you have the following information ready:',
  Requirements: [
    "Applying Director's date of birth and residential address",
    'Last 3 months of bank statements',
    'Latest statutory accounts'
  ],
  BritishBankImageAltText: 'British Business Bank',
  StartApplicationButton: 'Start application'
}
