import IUnifiedApplicationStateToIDirectorDetailsState from '../../mappers/IUnifiedApplicationStateToIDirectorDetailsState'
import IUnifiedApplicationStateToIGetStartedState from '../../mappers/IUnifiedApplicationStateToIGetStartedState'
import { IJourneyStepRenderer } from '../../types/IJourneyStepRenderer'
import { PageIdentifier } from '../../types/PageIdentifier'
import deepCopy from '../../utils/deepCopy'
import { getPageHeading, getPageShortHeading, JourneyDefinition } from '../journey'
import DirectorDetails, {
  directorDetailsPageIdentifier
} from '../unifiedApplicationJourney/directorDetails/DirectorDetails'
import GetStarted from '../unifiedApplicationJourney/getStarted/GetStarted'
import getStartedPageIdentifier from '../unifiedApplicationJourney/getStarted/getStartedPageIdentifier'
import { eligibilityPageIdentifier } from '../unifiedApplicationJourney/eligibility/eligibilityPageIdentifier'
import UnifiedEligibility from '../unifiedApplicationJourney/eligibility'
import IUnifiedApplicationStateToUnifiedEligibilityState from 'src/mappers/IUnifiedApplicationStateToUnifiedEligibilityState'
import { eligibilityResultsPageIdentifier } from '../unifiedApplicationJourney/eligibility/results/eligible/eligibilityResultsPageIdentifier'
import EligibilityResults from '../unifiedApplicationJourney/eligibility/results/eligible/eligibilityResults'
import IUnifiedApplicationStateToUnifiedEligibilityResultsState from 'src/mappers/IUnifiedApplicationStateToUnifiedEligibilityResultsState'
import { ineligiblePageIdentifier } from '../unifiedApplicationJourney/eligibility/results/ineligible/ineligiblePageIdentifier'
import { eligibilityLoadingPageIdentifier } from '../unifiedApplicationJourney/eligibility/results/loading/eligibilityLoadingPageIdentifier'
import EligibilityLoading from '../unifiedApplicationJourney/eligibility/results/loading'
import EligibilityResultIneligible from '../unifiedApplicationJourney/eligibility/results/ineligible'
import FundingNeeds from '../unifiedApplicationJourney/fundingNeeds/FundingNeeds'
import IUnifiedApplicationStateToIFundingNeedsState from 'src/mappers/IUnifiedApplicationStateToFundingNeedsState'
import { fundingNeedsPageIdentifier } from '../unifiedApplicationJourney/fundingNeeds/fundingNeedsPageIdentifier'
import SupportingDocuments, {
  supportingDocumentsPageIdentifier
} from '../unifiedApplicationJourney/supportingDocuments/SupportingDocuments'
import IUnifiedApplicationStateToISupportingDocumentsState from 'src/mappers/IUnifiedApplicationStateToISupportingDocumentsState'

export const partnerStandardJourney: JourneyDefinition[] = [
  {
    page: deepCopy(getStartedPageIdentifier) as PageIdentifier,
    renderer: {
      Component: GetStarted,
      mapper: IUnifiedApplicationStateToIGetStartedState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('GetStarted'),
    pageShortHeading: getPageShortHeading('GetStarted')
  },
  {
    page: deepCopy(fundingNeedsPageIdentifier) as PageIdentifier,
    renderer: {
      Component: FundingNeeds,
      mapper: IUnifiedApplicationStateToIFundingNeedsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('FundingNeeds'),
    pageShortHeading: getPageShortHeading('FundingNeeds')
  },
  {
    page: deepCopy(eligibilityPageIdentifier) as PageIdentifier,
    renderer: {
      Component: UnifiedEligibility,
      mapper: IUnifiedApplicationStateToUnifiedEligibilityState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('Eligibility'),
    pageShortHeading: getPageShortHeading('Eligibility')
  },
  {
    page: deepCopy(eligibilityLoadingPageIdentifier) as PageIdentifier,
    renderer: {
      Component: EligibilityLoading,
      mapper: IUnifiedApplicationStateToUnifiedEligibilityResultsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('Eligibility'),
    pageShortHeading: getPageShortHeading('Eligibility')
  },
  {
    page: deepCopy(ineligiblePageIdentifier) as PageIdentifier,
    renderer: {
      Component: EligibilityResultIneligible,
      mapper: IUnifiedApplicationStateToUnifiedEligibilityResultsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('Ineligible'),
    pageShortHeading: getPageShortHeading('Ineligible')
  },
  {
    page: deepCopy(eligibilityResultsPageIdentifier) as PageIdentifier,
    renderer: {
      Component: EligibilityResults,
      mapper: IUnifiedApplicationStateToUnifiedEligibilityResultsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('EligibilityResult'),
    pageShortHeading: getPageShortHeading('EligibilityResult')
  },
  {
    page: deepCopy(directorDetailsPageIdentifier) as PageIdentifier,
    renderer: {
      Component: DirectorDetails,
      mapper: IUnifiedApplicationStateToIDirectorDetailsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('DirectorDetails'),
    pageShortHeading: getPageShortHeading('DirectorDetails')
  },
  {
    page: deepCopy(supportingDocumentsPageIdentifier) as PageIdentifier,
    renderer: {
      Component: SupportingDocuments,
      mapper: IUnifiedApplicationStateToISupportingDocumentsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('SupportingDocuments'),
    pageShortHeading: getPageShortHeading('SupportingDocuments')
  }
]
