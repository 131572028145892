import { JourneyIdentifier } from 'src/applyfrontendcontent'
import { FlowType } from 'src/applyfrontendcontent/types/locale'
import { UnifiedApplicationStatus } from 'src/types/IUnifiedApplicationState'
import IApplication from '../types/IApplication'
import { cookieExists } from '../utils/cookieHelper'

export const map = (i: IApplication): JourneyIdentifier => {
  return {
    representativeType: i.representativeJourneyType || '?',
    sales: cookieExists('sales'),
    partner: i.partnerType,
    businessType: i.businessType,
    flowType: i.status === UnifiedApplicationStatus.PartnerDraft ? FlowType.StrategicPartner : FlowType.Standard,
    channelType: i.channelType
  }
}
