import IUnifiedApplicationState from '../types/IUnifiedApplicationState'
import ISupportingDocumentsState from '../types/ISupportingDocumentsState'
import { defaultFileConstraints } from '../types/IUploadFileConstraints'
import IUnifiedApplicationStateToIUnifiedApplicationStepState from './IUnifiedApplicationStateToIUnifiedApplicationStepState'
import { FileStatusState } from '../types/IFileState'
import deepCopy from '../utils/deepCopy'

class IUnifiedApplicationStateToISupportingDocumentsStateImpl extends IUnifiedApplicationStateToIUnifiedApplicationStepState<ISupportingDocumentsState> {
  map = (i: IUnifiedApplicationState): ISupportingDocumentsState => {
    return {
      ...this.baseMap(i),
      applicationIdentifier: i.id,
      bankStatements: i.bankStatements || [],
      statutoryAccounts: i.statutoryAccounts || [],
      additionalDocuments: i.additionalDocuments || [],
      additionalInformation: i.additionalInformation,
      hmrcTaxCalculationDocuments: i.hmrcTaxCalculationDocuments || [],
      constraints: defaultFileConstraints,
      products: i.products,
      companyNumber: i.companyNumber,
      hasAnyFilings: undefined,
      lastAccountsFilingMadeUptoDate: undefined,
      isStatutoryAccountsRequired: true
    }
  }

  reverseMap = (i: ISupportingDocumentsState, target: IUnifiedApplicationState, autoSave: boolean): void => {
    target.products = deepCopy(i.products || {})!

    target.additionalInformation = i.additionalInformation

    target.bankStatements = i.bankStatements?.filter(
      (f) => f.status !== FileStatusState.Rejected && f.status !== FileStatusState.Pending
    )
    target.statutoryAccounts = i.statutoryAccounts?.filter(
      (f) => f.status !== FileStatusState.Rejected && f.status !== FileStatusState.Pending
    )
    target.additionalDocuments = i.additionalDocuments?.filter(
      (f) => f.status !== FileStatusState.Rejected && f.status !== FileStatusState.Pending
    )
    target.hmrcTaxCalculationDocuments = i.hmrcTaxCalculationDocuments?.filter(
      (f) => f.status !== FileStatusState.Rejected && f.status !== FileStatusState.Pending
    )
  }
}

const IUnifiedApplicationStateToISupportingDocumentsState =
  new IUnifiedApplicationStateToISupportingDocumentsStateImpl()
export default IUnifiedApplicationStateToISupportingDocumentsState
