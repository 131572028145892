import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import { H2 } from './headings'
import styled from 'styled-components'
import { Button, media } from 'src/ui-framework'

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 16px 0;
`

export const StyledActions = styled(DialogActions)`
  padding: 32px 0 0 0;
  flex-direction: row-reverse;
  justify-content: end;
  column-gap: 8px;
  ${media.upToTablet`
    display: block
  `}
`
export const StyledDialogContent = styled(DialogContent)`
  padding: 0;
`

export const StyledHeading = styled((props) => <H2 {...props}>{props.children}</H2>)`
  margin: 0;
`
export const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    display: block;
    background-color: ${(props) => props.theme.colors.uiWhite100};
    padding: 32px 24px;

    ${media.upToTablet`
      padding: 24px 24px;
    `}
  }
`
export const ModalButton = styled(Button)`
  min-width: unset;
  width: unset;
  height: 36px;
  padding: 8px 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.16px;
  ${media.upToTablet`
    min-width: 100%;
    margin: 10px 0px 0px 0px;
  `}
`
export const ModalSecondaryButton = styled(Button)`
  min-width: unset;
  width: unset;
  height: 36px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.16px;
  font-size: 14px;
  padding: 8px 12px;
  ${media.upToTablet`
    min-width: 100%;
    margin: 0px;
  `}
`

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  color: ${(props) => props.theme.colors.focus.backgroundColor};
  &:hover {
    background-color: ${(props) => props.theme.colors.focus.backgroundColor};
  }
  &:focus {
    color: transparent;
    border: solid 2px #001061;
    background-color: ${(props) => props.theme.colors.focus.backgroundColor};
  }
`
