import React from 'react'
import IGetStartedState from 'src/types/IGetStartedState'
import styled from 'styled-components'
import { spacing } from 'src/ui-framework/theme/spacing'
import { colors } from 'src/ui-framework/theme/colors'
import { tokeniseLabelAsString } from 'src/shared/content/DynamicContent'
import { GetStartedContent } from 'src/applyfrontendcontent'
import { media } from 'src/ui-framework'

type IReadOnlyGetStartedProps = {
  formState: IGetStartedState
  content: GetStartedContent
}

const ReadOnlyLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${colors.textGrey900};
`

const ReadOnlyInput = styled.div`
  font-family: 'Matter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  height: 30px;
  line-height: 30px;
  color: ${colors.textGrey900};
  margin-top: 4px;
`

const ReadOnlyField = styled.div`
  margin-top: ${spacing.m};
  ${media.upToTablet`
        margin-top: ${spacing.xl};
    `}
  &:first-child {
    margin-top: 0;
  }
`

export const ReadOnlyGetStarted = (p: IReadOnlyGetStartedProps) => {
  return (
    <>
      <ReadOnlyField>
        <ReadOnlyLabel>{tokeniseLabelAsString(p.content.CompanySearch?.CompanyNameManual!)}</ReadOnlyLabel>
        <ReadOnlyInput>{p.formState.companyName}</ReadOnlyInput>
      </ReadOnlyField>
      <ReadOnlyField>
        <ReadOnlyLabel>{tokeniseLabelAsString(p.content.CompanySearch?.CompanyNumber!)}</ReadOnlyLabel>
        <ReadOnlyInput>{p.formState.companyNumber}</ReadOnlyInput>
      </ReadOnlyField>
      <ReadOnlyField>
        <ReadOnlyLabel>{tokeniseLabelAsString(p.content.Name)}</ReadOnlyLabel>
        <ReadOnlyInput>{p.formState.name}</ReadOnlyInput>
      </ReadOnlyField>
      <ReadOnlyField>
        <ReadOnlyLabel>{tokeniseLabelAsString(p.content.EmailAddress)}</ReadOnlyLabel>
        <ReadOnlyInput>{p.formState.email}</ReadOnlyInput>
      </ReadOnlyField>
      <ReadOnlyField>
        <ReadOnlyLabel>{tokeniseLabelAsString(p.content.PhoneNumber)}</ReadOnlyLabel>
        <ReadOnlyInput>{p.formState.phoneNumber}</ReadOnlyInput>
      </ReadOnlyField>
    </>
  )
}
