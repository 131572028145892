import IUnifiedApplicationStateToIUnifiedApplicationStepState from './IUnifiedApplicationStateToIUnifiedApplicationStepState'
import IUnifiedApplicationState from '../types/IUnifiedApplicationState'
import IUnifiedEligibilityResultsState from 'src/types/unifiedJourney/IUnifiedEligibilityResultsState'

class IUnifiedApplicationStateToUnifiedEligibilityResultsStateImpl extends IUnifiedApplicationStateToIUnifiedApplicationStepState<IUnifiedEligibilityResultsState> {
  map(i: IUnifiedApplicationState): IUnifiedEligibilityResultsState {
    return {
      ...this.baseMap(i),
      products: i.products || {},
      hasTwoFiledAccounts: i.metadata.hasTwoFiledAccounts === undefined ? null : i.metadata.hasTwoFiledAccounts,
      hasBackgroundChecksTimeout: i.hasBackgroundChecksTimeout,
      backgroundChecksRequestedTime: i.backgroundChecksRequestedTime
        ? new Date(`${i.backgroundChecksRequestedTime}Z`)
        : undefined,
      generalEligibility: i.generalEligibility
    }
  }

  reverseMap = (i: IUnifiedEligibilityResultsState, target: IUnifiedApplicationState, autoSave: boolean): void => {
    target.products = i.products
    target.hasBackgroundChecksTimeout = i.hasBackgroundChecksTimeout
    target.backgroundChecksRequestedTime = i.backgroundChecksRequestedTime
      ? i.backgroundChecksRequestedTime.toISOString()
      : undefined
    target.generalEligibility = i.generalEligibility
  }
}

const IUnifiedApplicationStateToUnifiedEligibilityResultsState =
  new IUnifiedApplicationStateToUnifiedEligibilityResultsStateImpl()
export default IUnifiedApplicationStateToUnifiedEligibilityResultsState
