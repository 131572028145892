import React, { useState } from 'react'

import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Menu from '@material-ui/core/Menu'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import { StyledMenuItem, StyledMenuItemLink } from './themedNavigation'

type OwnProps = {
  username: string
  dropdownItems?: {
    url: string
    label: string
  }[]
}

export default function DropdownMenu(props: OwnProps) {
  const { dropdownItems, username } = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenu = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <>
      <Link variant="body2" color="inherit" onClick={handleMenu}>
        {username}
        <IconButton
          edge="start"
          color="inherit"
          style={{ backgroundColor: 'transparent' }}
          disableRipple
          disableFocusRipple
        >
          <ArrowDropDownIcon />
        </IconButton>
      </Link>

      <Menu
        id="nav-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {dropdownItems &&
          dropdownItems.map((item, index) => (
            <StyledMenuItem id={`menu-item-${index}`} key={`menu-item-${index}`}>
              <StyledMenuItemLink href={item.url}>{item.label}</StyledMenuItemLink>
            </StyledMenuItem>
          ))}
      </Menu>
    </>
  )
}
