import UnifiedApplicationBaseReducer from '../unifiedApplicationBaseReducer'
import {
  IUnifiedApplicationAction,
  UnifiedApplicationActionType,
  UnifiedApplicationActionUpdateState
} from '../unifiedApplicationActions'
import { flattenErrors, IErrorsState } from '../../types/IErrorsState'
import { UnifiedApplicationState } from '../../types/UnifiedApplicationState'
import { FieldWithErrorMessage, validate as validateRequiredFields } from 'src/validators/formValidator'
import IPartnerReferralRegistrationState from 'src/types/partner/IPartnerReferralRegistrationState'
import NumberToLocaleString from 'src/mappers/NumberToLocaleString'
import { getFundingNeedsAmountMinMax } from '../fundingNeedsAmountMinMax'
import phoneNumberValidator from 'src/validators/phoneNumberValidator'
export default class PartnerReferralRegistrationReducer extends UnifiedApplicationBaseReducer<IPartnerReferralRegistrationState> {
  onLoad = async (
    state: IPartnerReferralRegistrationState,
    dispatch: React.Dispatch<IUnifiedApplicationAction<object>>
  ): Promise<void> => {
    dispatch(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Pristine))
  }

  onValidate = (state: IPartnerReferralRegistrationState): IErrorsState => {
    const content = this.getContent(state).PartnerReferralDetails

    let { min, max } = getFundingNeedsAmountMinMax(state)

    content.FundingAmount.Dynamic = {
      MinimumAmount: `£${NumberToLocaleString.map(min)}`,
      MaximumAmount: `£${NumberToLocaleString.map(max)}`
    }

    const requiredFields: Array<FieldWithErrorMessage> = [
      { fieldName: 'turnover', errorMessage: content.Turnover.Error?.Required },
      { fieldName: 'timeline', errorMessage: content.Timeline.Error?.Required },
      { fieldName: 'fundingAmount', errorMessage: content.FundingAmount.Error?.Required },
      { fieldName: 'callTimeSlots', errorMessage: content.CallTimeSlots.Error?.Required },
      { fieldName: 'partnerPhoneNumber', errorMessage: content.PartnerPhone.Error?.Required },
      { fieldName: 'additionalInformation', errorMessage: content.AdditionalInformation.Error?.Required }
    ]
    let errors = validateRequiredFields(requiredFields, state)

    if (errors['fundingAmount'] === undefined || (errors['fundingAmount'] as string[]).length === 0) {
      if (state.fundingAmount !== undefined || state.fundingAmount !== '') {
        var fundingAmount = NumberToLocaleString.reverseMap(state.fundingAmount) || 0

        if (fundingAmount < min || fundingAmount > max) {
          errors.hasErrors = true
          errors['fundingAmount'] = [
            `Please enter a funding amount between £${NumberToLocaleString.map(min)} and £${NumberToLocaleString.map(
              max
            )}`
          ]
        }
      }
    }

    if (
      state.isExistingCustomer &&
      (errors['applicationReference'] === undefined || (errors['applicationReference'] as string[]).length === 0)
    ) {
      if (state.applicationReference !== undefined && state.applicationReference !== '') {
        const is10Digits = /^\d{10}$/.test(state.applicationReference)

        if (!is10Digits) {
          errors.hasErrors = true
          errors['applicationReference'] = [content.ApplicationReference.Error?.Invalid]
        }
      } else {
        errors.hasErrors = true
        errors['applicationReference'] = [content.ApplicationReference.Error?.Required]
      }
    }

    if (errors['partnerPhoneNumber'] === undefined || (errors['partnerPhoneNumber'] as string[]).length === 0) {
      if (
        state.partnerPhoneNumber !== undefined &&
        (!phoneNumberValidator(state.partnerPhoneNumber) || state.partnerPhoneNumber!.length > 15)
      ) {
        errors.hasErrors = true
        errors['partnerPhoneNumber'] = [content.PartnerPhone.Error?.Invalid]
      }
    }

    return flattenErrors(errors)
  }

  handleDefaultAction = (
    prevState: IPartnerReferralRegistrationState,
    newState: IPartnerReferralRegistrationState,
    action: IUnifiedApplicationAction<object>
  ): void => {
    switch (action.type) {
      case UnifiedApplicationActionType.Loaded:
        newState.state = UnifiedApplicationState.Pristine
        break
    }
  }
  onBeforeSubmit = async (state: IPartnerReferralRegistrationState): Promise<void> => {}
}
