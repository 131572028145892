import { SharedContent } from '../types/shared'

export const sharedContent: SharedContent = {
  PageError: 'Please fix the above errors',
  LoadingPage: 'Loading...',
  LoadingButton: 'Loading...',
  SaveForLaterButton: 'Save for later',
  ContinueButton: 'Continue',
  TermsAndConditionsUrl: 'https://kriya.co/terms-and-conditions',
  PrivacyPolicyUrl: 'https://www.kriya.co/privacy-policy',
  MarketFinanceWebsiteUrl: 'https://kriya.co',
  SalesPhoneNumber: '02039664964',
  SalesEmail: 'sales@kriya.co',
  LegalEmail: 'legal@kriya.co',
  SomethingWentWrongError: 'Oh dear, it looks like something went wrong.',
  LogError: 'We’ve logged the error and will get it fixed as soon as possible.'
}
