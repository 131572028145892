import React, { useEffect } from 'react'
import { media, AnchorLink } from 'src/ui-framework'
import { DynamicContent } from '../../shared/content/DynamicContent'
import { getCopy } from 'src/applyfrontendcontent'
import { PartnerType } from 'src/types/PartnerType'
import { useGlobalContext } from 'src/GlobalContext'
import { FlowType } from 'src/types/FlowType'
import { cookieExists } from 'src/utils/cookieHelper'
import styled from 'styled-components'
import { RepresentativeJourneyType } from 'src/types/RepresentativeJourneyType'

interface IUnauthenticatedErrorPage {
  partner?: PartnerType
}

export const ColoredH1 = styled.h1<any>`
  margin: 0;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.error};
  font-family: 'Matter', 'Roboto Condensed', sans-serif !important;
  font-size: ${(props) => props.theme.fontSize.xl};
  line-height: 36px;
  margin-bottom: 32px;

  ${media.upToTablet`
    font-size: ${(props: any) => props.theme.fontSize.l};
    line-height: 28px;
    margin-bottom: 24px;
  `}
`

const StyledParagraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  ${media.upToTablet`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
  `}
`

const Heading = { Component: ColoredH1 }
const IntroductionItem = { Component: StyledParagraph }
const Introduction = {
  Component: <T extends object>(p: React.PropsWithChildren<T>) => <div id="unauthenticated">{p.children}</div>
}

// Atm only covers RM Authentication texts. Further work is required to extend it to support other type of journeys
const PartnerUnauthenticatedErrorPage = (p: IUnauthenticatedErrorPage) => {
  const { copy, setCopy } = useGlobalContext()

  useEffect(() => {
    const newCopy = getCopy(navigator.language, {
      representativeType: RepresentativeJourneyType.Director,
      partner: p.partner,
      flowType: FlowType.StrategicPartner,
      sales: cookieExists('sales')
    })
    setCopy(newCopy)
  }, [setCopy, p.partner])

  return (
    <>
      <DynamicContent
        content={copy.UnauthenticatedError}
        components={{
          Heading,
          Introduction,
          IntroductionItem,
          ContactEmail: {
            Component: AnchorLink,
            props: {
              autoFontSize: true,
              href: `mailto:${copy.UnauthenticatedError?.ContactEmail}`
            }
          }
        }}
        ignoreComponents={['ContactEmail']}
      />
    </>
  )
}

export default PartnerUnauthenticatedErrorPage
