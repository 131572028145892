import React from 'react'
import { DynamicContent } from 'src/shared/content/DynamicContent'
import { media } from 'src/ui-framework'
import styled from 'styled-components'
import { useGlobalContext } from '../../GlobalContext'
import { DynamicHeading, DynamicParagraph } from './ErrorPageStyles'

export const MainContentWrapper = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;
`

export const MainContent = styled.div`
  background-color: white;
  margin-top: 16px;
  width: 560px;
  padding: 32px 28px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;

  ${media.upToTablet`
    margin-top: 0;
    padding: 24px 16px; 
  `}
`

export const ExitApplication = () => {
  const { copy } = useGlobalContext()
  const content = copy.ExitApplication

  return (
    <MainContentWrapper>
      <MainContent>
        <DynamicContent
          content={content!}
          components={{
            Heading: DynamicHeading,
            Introduction: DynamicParagraph
          }}
        />
      </MainContent>
    </MainContentWrapper>
  )
}
