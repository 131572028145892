import { fontSize } from './fontSize'
import { colors } from './colors'

export interface InputFields {
  width: string
  borderRadius: string
  height: string
  fontSize: string
  lineHeight: number
  letterSpacing: string
  border: string
  boxSizing: string
  backgroundColor: string
  color: string
  outline: string
}

export type Label = {
  fontSize: string
  color: string
}

export const inputFields: InputFields = {
  width: '100%',
  borderRadius: '8px',
  height: '48px',
  fontSize: `${fontSize.s}`,
  lineHeight: 1.5,
  letterSpacing: 'normal',
  border: `solid 1px ${colors.grey200}`,
  boxSizing: 'border-box',
  backgroundColor: `${colors.white}`,
  color: `${colors.textGrey900}`,
  outline: 'none'
}

export const label: Label = {
  fontSize: `${fontSize.xs}`,
  color: `${colors.textGrey900}`
}
