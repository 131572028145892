import IPartnerReferralRegistrationState from 'src/types/partner/IPartnerReferralRegistrationState'
import {
  businessLoanMaxLimit,
  businessLoanMinLimit,
  invoiceFinanceMaxLimit,
  invoiceFinanceMinLimit,
  rlsLoanMaxLimit,
  rlsLoanMinLimit
} from '../pages/unifiedApplicationJourney/fundingNeeds/fundingAmounts'
import IFundingNeedsState from '../types/IFundingNeedsState'

export const getFundingNeedsAmountMinMax = (state: IFundingNeedsState | IPartnerReferralRegistrationState) => {
  let min = 0
  let max = 0

  if (state.rlsLoan) {
    min = Math.max(min, rlsLoanMinLimit)
    max = Math.max(max, rlsLoanMaxLimit)
  }

  if (state.invoiceFinance) {
    min = Math.max(min, invoiceFinanceMinLimit)
    max = Math.max(max, invoiceFinanceMaxLimit)
  }

  if (!state.rlsLoan && state.businessLoan) {
    min = Math.max(min, businessLoanMinLimit)
    max = Math.max(max, businessLoanMaxLimit)
  }

  return {
    min: min,
    max: max
  }
}
