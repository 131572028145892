import React from 'react'
import { IUnifiedApplicationStepState } from '../../types/IUnifiedApplicationState'
import { UnifiedApplicationState } from '../../types/UnifiedApplicationState'
import { tokeniseToString } from '../../shared/content/DynamicContent'
import { getSharedCopy } from 'src/applyfrontendcontent'
import { trackAppInsightsEvent } from '../../utils/appInsights/AppInsights'

interface IUnifiedApplicationStepGuardProps {
  currentState: IUnifiedApplicationStepState
}

const UnifiedApplicationStepGuard = (p: React.PropsWithChildren<IUnifiedApplicationStepGuardProps>) => {
  if (p.currentState.state === UnifiedApplicationState.Loading) {
    return <p>{tokeniseToString(getSharedCopy(navigator.language), 'LoadingPage')}</p>
  }

  if (p.currentState.state === UnifiedApplicationState.Error) {
    trackAppInsightsEvent('UAJ error', {
      error: p.currentState.errors,
      applicationId: p.currentState.id
    })
    return <p>{tokeniseToString(getSharedCopy(navigator.language), 'SomethingWentWrongError')}</p>
  }

  return <>{p.children}</>
}

export default UnifiedApplicationStepGuard
