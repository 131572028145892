import { EligibilityResultContent } from 'src/applyfrontendcontent/types/eligbilityResult'

export const eligibilityResult: EligibilityResultContent = {
  Heading: 'Eligible',
  ShortHeading: 'Eligible',
  EligibleForInvoiceFinanceOnlyByPartner: {
    Message: 'Congratulations! Your customer has met the minimum requirements for an invoice finance application.',
    Heading: 'Please ensure the following statement is read to the customer',
    Statements: {
      Consent: [
        'You should know that Barclays has a commercial interest in Kriya and, if you decide to enter into a contract with them, we will receive a referral fee (commission) from them. Kriya ask external investors (including Barclays) to fund individual trades. Barclays may choose to act as an investor and Barclays would receive an income from this. Once the trade is confirmed the investors that are funding the trade will be shown to you on the Kriya customer portal which may include Barclays. This won’t impact the amount you have to pay or the amount you are charged.',
        'To be able to refer you to Kriya, we will share your contact details and reason for making the referral.',
        'Do you agree to me now providing your information to them?',
        'You should also be aware that if you take a product from Kriya you will become a customer of theirs. You are then subject to their terms and conditions which include what will happen if you are unable to repay. This includes the payment of a flat rate fee if repayment does not occur within a specific timeframe. You should read and understand those terms and conditions.'
      ],
      ChecksAndFraud: [
        'If false or inaccurate information is provided to us and fraud is identified, details may be passed to credit reference and fraud prevention agencies to prevent fraud and money laundering.',
        'In order for Barclays to also consider your application, we will supply you and your business partner’s personal information to credit reference agencies and fraud prevention agencies and they will give us information about you, such as about your financial history. These agencies may in turn share your personal information with other organisations. If fraud is detected, you could be refused certain services, finance or employment. You confirm that the directors, owners or business partners are aware of how we will use their personal data.'
      ],
      SupportRequirements: [
        'Unfortunately, because of data protection legislation, we can’t give Kriya information about any existing support requirements that you’ve told us about. To make sure you get the support you need, you’ll need to tell Kriya about any requirements you have after you’ve been referred.'
      ]
    },
    SupportRequirementsHeading: 'You’ll need to tell Kriya about any support requirements you have',
    ChecksAndFraudHeading: 'Credit Reference Agencies and Fraud Prevention Agencies',
    ConfirmationHeading: 'Please ensure the customer gives consent to ALL of the checkboxes below',
    ConsentQuestions: {
      Notepad: {
        Text: 'I have reviewed Notepad entries for the last 24 months and confirm there are no notice to close, checkpoint referrals that have been issued on this customer and no other factors indicating that a referral would not be appropriate.',
        Error: {
          Required: 'Required'
        }
      },
      Overdraft: {
        Text: 'I have explained to the customer that if they have an overdraft, it may need to be reviewed and may potentially be reduced or removed.',
        Error: {
          Required: 'Required'
        }
      },
      Disclosure: {
        Text: 'I have read the above mandatory disclosure to the customer(s) and I have the customer(s) consent to make a referral.',
        Error: {
          Required: 'Required'
        }
      },
      BbbInfo: {
        Text: 'I confirm that I have informed the customer that they should refer to the British Business Bank’s website for further information.',
        Error: {
          Required: 'Required'
        }
      }
    },
    ErrorAlert: 'Please give consent to all of the checkboxes above'
  }
}
