import styled from 'styled-components'
import { media, colors } from 'src/ui-framework'

export const MainDiv = styled.div`
  width: 100%;
`

export const PageContainer = styled.div`
  z-index: 99;
  bottom: 0;
  display: flex;
  justify-content: start;
`

export const ClosingWrapper = styled.div``

export const Subtext = styled.div`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${colors.textGrey600};
  top: -15px;
  position: relative;
`

export const H3 = styled.h3`
  font-family: 'Matter', 'Roboto Condensed', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.16px;
  margin-bottom: 32px;
  margin-top: 0;
  color: ${(props) => props.theme.colors.textGrey900};

  ${media.upToTablet`
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 24px;
  `}
`
