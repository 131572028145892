import React from 'react'
import { media, Paragraph } from 'src/ui-framework'
import styled from 'styled-components'

const StyledNote = styled.div`
  padding: 10px 16px 16px 16px;
  margin-top: ${(props) => (props.style?.marginTop ? props.style?.marginTop : 25)};
`
export const CongratulatoryNote = styled(StyledNote)`
  background-color: ${(props: any) => props.theme.colors.success08};
  font-weight: 700;
  padding: 16px 38px 16px 38px;
  margin-bottom: ${(props: any) => props.theme.spacing.xl};
  letter-spacing: normal;
  color: ${(props: any) => props.theme.colors.success};
  font-size: ${(props: any) => props.theme.fontSize.s};
  line-height: 24px;
  border: 1px solid ${(props: any) => props.theme.colors.success30};
  border-radius: 8px;

  ${media.upToTablet`
    padding: 16px 24px 16px 24px;
    font-size: ${(props: any) => props.theme.fontSize.xs};
    margin-bottom: ${(props: any) => props.theme.spacing.xl};
  `}
`

interface IRichContentNoteProps {
  id: string
  title?: string
  style?: React.CSSProperties
}

export interface IContentNoteProps extends IRichContentNoteProps {
  note: string
}

const ContentNote = (props: IContentNoteProps) => {
  return (
    <StyledNote id={props.id} style={props.style}>
      <Paragraph id="content-note-title" style={{ marginBottom: '4px', marginTop: '0', fontWeight: 'bold' }}>
        {props.title}
      </Paragraph>
      <Paragraph id="content-note-text" style={{ marginBottom: '0', marginTop: '0' }}>
        {props.note}
      </Paragraph>
    </StyledNote>
  )
}

export default ContentNote

export const RichContentNote = (props: React.PropsWithChildren<IRichContentNoteProps>) => {
  return (
    <StyledNote id={props.id} style={props.style}>
      {props.title && (
        <Paragraph id="content-note-title" style={{ marginBottom: '5px', marginTop: '0', fontWeight: 'bold' }}>
          {props.title}
        </Paragraph>
      )}
      {props.children}
    </StyledNote>
  )
}
