export enum PageComponents {
  Stepper = 'stepper',
  Back = 'back',
  Heading = 'heading',
  Footer = 'footer'
}

export type PageIdentifier = {
  id: string
  title: string
  step: string
  order: number
  isHidden: boolean
  //Allows for these pages to be displayed under the step it belongs to
  subPageIds: Array<string>
  // hides elements defined on page
  // Set is not working, therefore Array
  hidden?: Array<PageComponents>
  // Resized content to match parent
  fillParent?: boolean
}

export const hideHeading: Array<PageComponents> = new Array<PageComponents>(PageComponents.Heading)
export const hideAll: Array<PageComponents> = new Array<PageComponents>(
  PageComponents.Stepper,
  PageComponents.Back,
  PageComponents.Heading,
  PageComponents.Footer
)
export const hideAllButHeading: Array<PageComponents> = new Array<PageComponents>(
  PageComponents.Stepper,
  PageComponents.Back,
  PageComponents.Footer
)
export const hideAllButBack: Array<PageComponents> = new Array<PageComponents>(
  PageComponents.Heading,
  PageComponents.Stepper,
  PageComponents.Footer
)
export const hideStepperFooter: Array<PageComponents> = new Array<PageComponents>(
  PageComponents.Stepper,
  PageComponents.Footer
)
