import IUnifiedEligibilityState from 'src/types/unifiedJourney/IUnifiedEligibilityState'
import { IContent, ContentType } from '../../../shared/components/eligibilityRenderer/IContent'

const eligibilityContent: IContent<IUnifiedEligibilityState>[] = [
  {
    id: 'offersB2bServices',
    type: ContentType.YesNo,
    dynamicContentKey: 'OffersB2bServices'
  },
  {
    id: 'turnoverExceeds100k',
    type: ContentType.YesNo,
    dynamicContentKey: 'TurnoverExceeds100k'
  },
  {
    id: 'fundingNeededIn60d',
    type: ContentType.YesNo,
    dynamicContentKey: 'FundingNeededIn60d'
  },
  {
    id: 'invoicesUpfront',
    type: ContentType.YesNo,
    dynamicContentKey: 'InvoicesUpfront'
  },
  {
    id: 'invoicesInDate',
    type: ContentType.YesNo,
    dynamicContentKey: 'InvoicesInDate'
  },
  {
    id: 'fundingExceeds25k',
    type: ContentType.YesNo,
    dynamicContentKey: 'FundingExceeds25k'
  },
  {
    id: 'invoicesTermsExceed15d',
    type: ContentType.YesNo,
    dynamicContentKey: 'InvoicesTermsExceed15d'
  },
  {
    id: 'invoicesEachExceed10k',
    type: ContentType.YesNo,
    dynamicContentKey: 'InvoicesEachExceed10k'
  },
  {
    id: 'invoiceFinanceDiscussionAwareness',
    type: ContentType.YesNo,
    dynamicContentKey: 'InvoiceFinanceDiscussionAwareness'
  },
  {
    id: 'extendCreditSolutionNeeded',
    type: ContentType.YesNo,
    dynamicContentKey: 'ExtendCreditSolutionNeeded'
  }
]

export default eligibilityContent
