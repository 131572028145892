import { tokeniseToString } from '../shared/content/DynamicContent'
import { getSharedCopy } from 'src/applyfrontendcontent'

export interface IErrorsState {
  [Key: string]: Array<string> | boolean | object | undefined
  hasErrors: boolean
  globalErrors?: Array<string>
}

export const flattenErrors = (errors: IErrorsState): IErrorsState => {
  const result: IErrorsState = { hasErrors: false }
  const keys = Object.keys(errors)
  for (let k of keys) {
    if (k === 'hasErrors') {
      result.hasErrors = result.hasErrors || errors.hasErrors
    } else if (typeof errors[k] === 'object' && !Array.isArray(errors[k])) {
      const subErrors = flattenErrors(errors[k] as IErrorsState)
      for (let subKey of Object.keys(subErrors)) {
        if (subKey === 'hasErrors') continue
        result[`${k}.${subKey}`] = subErrors[subKey]
      }
      result.hasErrors = result.hasErrors || subErrors.hasErrors
    } else {
      result[k] = errors[k]
    }
  }

  syncHasErrors(result)
  return result
}

export const syncHasErrors = (errors: IErrorsState): void => {
  const keys = Object.keys(errors)
  let hasErrors = false
  for (let k of keys) {
    if (!Array.isArray(errors[k]) || k === 'globalErrors') continue

    if ((errors[k] as string[]).length > 0) {
      hasErrors = true
      break
    }
  }

  errors.hasErrors = hasErrors
  errors.globalErrors = hasErrors ? [tokeniseToString(getSharedCopy(navigator.language), 'PageError')] : undefined
}
