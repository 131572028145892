import React from 'react'
import StyledLinkButton from './styledLinkButton'

type Props = React.ComponentPropsWithoutRef<'button'>

const LinkButton: React.FC<Props> = (props: Props) => {
  return <StyledLinkButton {...props} tabIndex={0} role="button" type="button" />
}

export default LinkButton
