import {
  IUnifiedApplicationStepState,
  UnifiedApplicationDataValue,
  UnifiedApplicationStatus
} from '../types/IUnifiedApplicationState'
import {
  IUnifiedApplicationAction,
  UnifiedApplicationActionType,
  IUnifiedApplicationActionUpdateField,
  IUnifiedApplicationActionUpdateFields,
  IUpdateFieldPayload,
  IUnifiedApplicationActionValidateField,
  IFilesUploadingAction,
  IUnifiedApplicationActionUpdateAndValidateField,
  IUnifiedApplicationActionFilesUploadedAction,
  IUnifiedApplicationActionValidateFields
} from './unifiedApplicationActions'
import deepCopy from '../utils/deepCopy'
import { IErrorsState, syncHasErrors } from '../types/IErrorsState'
import { PageIdentifier } from '../types/PageIdentifier'
import { FieldState } from '../types/IFieldState'
import setReloadSafety from '../shared/setReloadSafety'
import IFileState, { FileStatusState } from '../types/IFileState'
import { UnifiedApplicationState } from '../types/UnifiedApplicationState'
import { getCopy, JourneyIdentifier } from 'src/applyfrontendcontent'
import { FlowType } from 'src/types/FlowType'

const safeFields: string[] = ['state', 'errors', 'fieldState']
export default abstract class UnifiedApplicationBaseReducer<TState extends IUnifiedApplicationStepState> {
  private counter: number = 0
  constructor(public pageIdentifier: PageIdentifier) {}

  public isCurrentlyLongRunning = (): boolean => this.counter > 0
  public getContent = (state: IUnifiedApplicationStepState) => {
    const journeyType: JourneyIdentifier = {
      representativeType: state.representativeJourneyType,
      sales: state.hasSalesCookie,
      partner: state.partnerType,
      businessType: state.businessType,
      flowType: state.status === UnifiedApplicationStatus.PartnerDraft ? FlowType.StrategicPartner : FlowType.Standard,
      channelType: state.channelType
    }
    return getCopy(navigator.language, journeyType)
  }

  reducer = (prevState: TState, action: IUnifiedApplicationAction<object>): TState => {
    const newState = deepCopy<TState>(prevState) as TState

    switch (action.type) {
      case UnifiedApplicationActionType.UpdateField:
        const updateFieldAction = action as IUnifiedApplicationActionUpdateField
        this.updateFieldValue(newState, updateFieldAction.payload.field, updateFieldAction.payload.value)
        break

      case UnifiedApplicationActionType.UpdateFields:
        const updateFieldsAction = action as IUnifiedApplicationActionUpdateFields
        updateFieldsAction.payload.fields.forEach((i: IUpdateFieldPayload): void => {
          this.updateFieldValue(newState, i.field, i.value)
        })
        break

      case UnifiedApplicationActionType.ValidateField:
        const validateFieldAction = action as IUnifiedApplicationActionValidateField
        const { field } = validateFieldAction.payload
        this.validateField(prevState, [field], newState)
        break

      case UnifiedApplicationActionType.ValidateFields:
        const validateFieldsAction = action as IUnifiedApplicationActionValidateFields
        const { fields } = validateFieldsAction.payload
        this.validateField(prevState, fields, newState)
        break

      case UnifiedApplicationActionType.UpdateAndValidateField:
        const updateAndValidateFieldAction = action as IUnifiedApplicationActionUpdateAndValidateField
        this.updateAndValidateField(
          prevState,
          updateAndValidateFieldAction.payload.field,
          updateAndValidateFieldAction.payload.value,
          newState
        )
        break

      case UnifiedApplicationActionType.FilesUploading:
        const filesUploadingAction = action as IFilesUploadingAction
        const nonRejectedFiles: IFileState[] = []
        ;((newState as IUnifiedApplicationStepState)[filesUploadingAction.payload.field] as IFileState[]).forEach(
          (f) => {
            if (f.status !== FileStatusState.Rejected) {
              nonRejectedFiles.push(f)
            }
          }
        )
        ;(newState as IUnifiedApplicationStepState)[filesUploadingAction.payload.field] = nonRejectedFiles.concat(
          filesUploadingAction.payload.files
        )
        newState.errors[filesUploadingAction.payload.field] = []
        syncHasErrors(newState.errors)
        newState.state = UnifiedApplicationState.LongRunning
        ++this.counter
        break

      case UnifiedApplicationActionType.FilesUploaded:
        const filesUploadedAction = action as IUnifiedApplicationActionFilesUploadedAction
        this.updateFieldValue(newState, filesUploadedAction.payload.field, filesUploadedAction.payload.value)
        newState.errors[filesUploadedAction.payload.field] = []
        syncHasErrors(newState.errors)
        this.counter = Math.max(0, this.counter - 1)
        if (this.counter === 0) {
          newState.state = UnifiedApplicationState.NeedAutoSave
        }
        break

      case UnifiedApplicationActionType.ApplicationSaved:
        newState.state = UnifiedApplicationState.Pristine
        break

      default:
        this.handleDefaultAction(prevState, newState, action)
        break
    }
    return newState
  }

  abstract onLoad: (state: TState, dispatch: React.Dispatch<IUnifiedApplicationAction<object>>) => Promise<void>
  abstract onValidate: (state: TState) => IErrorsState
  abstract onBeforeSubmit: (
    state: TState,
    dispatch?: React.Dispatch<IUnifiedApplicationAction<object>>
  ) => Promise<void>
  abstract handleDefaultAction: (prevState: TState, newState: TState, action: IUnifiedApplicationAction<object>) => void

  protected markFieldAsTouched = (field: string, state: IUnifiedApplicationStepState): void => {
    if (safeFields.includes(field)) {
      return
    }

    this.setFieldState(field, state, FieldState.Touched)
    if (this.counter > 0) {
      state.state = UnifiedApplicationState.LongRunning
    } else {
      state.state = UnifiedApplicationState.NeedAutoSave
    }
    setReloadSafety(true)
  }

  protected updateFieldValue = (newState: TState, field: string, value: UnifiedApplicationDataValue): void => {
    this.recursiveSetValue(field, value as TState[keyof TState], newState)
    this.markFieldAsTouched(field, newState)
  }

  private recursiveSetValue = (
    field: keyof TState,
    value: TState[keyof TState],
    state: TState,
    deep: string = ''
  ): boolean => {
    if (state[field] !== undefined) {
      state[field] = value
      return true
    }

    // This intentionally will not create intermediate values
    // because it cannot create the types of all objects in between
    const keys = Object.keys(state)
    for (let k of keys) {
      // It would be trivial to add support for arrays here
      if (state[k] !== null && typeof state[k] === 'object' && !safeFields.includes(k)) {
        if (this.recursiveSetValue(field, value, state[k] as TState, `${deep}${k}.`)) return true
      } else if (deep + k === field) {
        state[k as keyof TState] = value
        return true
      }
    }

    if (deep !== '') {
      return false
    }

    state[field] = value
    return true
  }

  protected markFieldAsPristine = (field: string, state: IUnifiedApplicationStepState): void => {
    this.setFieldState(field, state, FieldState.Pristine)
  }

  protected updateAndValidateField(
    stateToValidate: TState,
    field: string,
    value: UnifiedApplicationDataValue,
    stateToUpdate: TState
  ): void {
    this.updateFieldValue(stateToUpdate, field, value)
    this.validateField(stateToUpdate, [field], stateToUpdate)
  }

  protected validateField(stateToValidate: TState, fields: string[], stateToUpdate: TState): void {
    let shouldValidate = false
    fields.forEach((field) => {
      shouldValidate =
        shouldValidate ||
        (stateToValidate.fieldState !== undefined && stateToValidate.fieldState[field] === FieldState.Touched)
    })

    if (!shouldValidate) return

    const errors = this.onValidate(stateToValidate)
    fields.forEach((field) => {
      // edit the errors if the state is not pristine or it is pristine and there is an error
      if (
        (Array.isArray(stateToValidate.errors[field]) && (stateToValidate.errors[field] as string[]).length > 0) ||
        (stateToValidate.fieldState !== undefined && stateToValidate.fieldState[field] === FieldState.Touched)
      ) {
        stateToUpdate.errors[field] = errors[field]
      }
    })
    syncHasErrors(stateToUpdate.errors)
  }

  private setFieldState = (field: string, state: IUnifiedApplicationStepState, fieldState: FieldState): void => {
    if (state.fieldState === undefined) {
      state.fieldState = {}
    }

    state.fieldState[field] = fieldState
  }
}
