import React from 'react'

import { ThemeProvider } from 'styled-components'

import { mergeDeep } from '../../utils/deepMerge'
import { borderStyles } from './borderStyles'
import { colors } from './colors'
import DefaultTheme from './defaultTheme'
import { fontSize } from './fontSize'
import { inputFields, label } from './inputFieldsLabel'
import { spacing } from './spacing'

export const theme: DefaultTheme = {
  colors,
  spacing,
  fontSize,
  fontFamily: "'Matter', sans-serif",
  inputFields,
  borderStyles,
  label
}

type ThemeProps = {
  children: React.ReactNode
  localTheme: any
}

const Theme = ({ children, localTheme }: ThemeProps) => {
  const newTheme = mergeDeep(localTheme, theme)
  return <ThemeProvider theme={newTheme}>{children}</ThemeProvider>
}

export default Theme
