import { directorDetails } from './DirectorDetails'
import { fundingNeeds } from './FundingNeeds'
import { getStarted } from './GetStarted'
import { yourCompany } from './YourCompany'
import { supportingDocuments } from './SupportingDocuments'
import { fairProcessingNotice } from './FairProcessingNotice'
import { recoveryLoanFundingNeeds } from './RecoveryLoanFundingNeeds'
import { Config } from '../../types/locale'
import { thankYou } from './ThankYou'

export const directorJourney: Partial<Config> = {
  GetStarted: getStarted,
  RecoveryLoanFundingNeeds: recoveryLoanFundingNeeds,
  FundingNeeds: fundingNeeds,
  YourCompany: yourCompany,
  DirectorDetails: directorDetails,
  SupportingDocuments: supportingDocuments,
  FairProcessingNotice: fairProcessingNotice,
  ThankYou: thankYou
}
