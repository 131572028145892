export type Features = {
  [key: string]: boolean | Date | null | string | number
  BarclaysJourneyEnabled: boolean
  RecoveryLoansSchemeJourneyEnabled: boolean
  Override2FiledAccountsEligibilityRule: boolean
  BusinessLoanEnabled: boolean
  AddressLookupEnabled: boolean
  EnableEligibilityCheckViaLendingFlow: boolean
  LendingFlowTimeoutInMilliseconds: number
}

export let features: Features = {
  BarclaysJourneyEnabled: false,
  RecoveryLoansSchemeJourneyEnabled: false,
  Override2FiledAccountsEligibilityRule: false,
  BusinessLoanEnabled: false,
  AddressLookupEnabled: false,
  EnableEligibilityCheckViaLendingFlow: false,
  LendingFlowTimeoutInMilliseconds: 90000
}
