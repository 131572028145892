import React from 'react'
import { IneligibleResultContent } from 'src/applyfrontendcontent/types/eligbilityResult'
import UnifiedEligibilityResultsReducer from 'src/reducers/unifiedReducers/unifiedEligibilityResultsReducer'
import { BasicComponentWithProps, DynamicContent, tokeniseToString } from 'src/shared/content/DynamicContent'
import useUnifiedApplicationStep from 'src/shared/hooks/useUnifiedApplicationStep'
import IUnifiedApplicationStepProps from 'src/types/IUnifiedApplicationStepProps'
import { UnifiedApplicationState } from 'src/types/UnifiedApplicationState'
import IUnifiedEligibilityResultsState from 'src/types/unifiedJourney/IUnifiedEligibilityResultsState'
import { LinkButton, media, Paragraph } from 'src/ui-framework'
import styled from 'styled-components'
import { ineligiblePageIdentifier } from './ineligiblePageIdentifier'

interface IEligibilityResultsProps extends IUnifiedApplicationStepProps<IUnifiedEligibilityResultsState> {}
const reducer = new UnifiedEligibilityResultsReducer(ineligiblePageIdentifier)

export const ColoredH1 = styled.h1<any>`
  margin: 0;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.error};
  font-family: 'Matter', 'Roboto Condensed', sans-serif !important;
  font-size: ${(props) => props.theme.fontSize.xl};
  line-height: 36px;
  margin-bottom: 32px;

  ${media.upToTablet`
    font-size: ${(props: any) => props.theme.fontSize.l};
    line-height: 28px;
    margin-bottom: 24px;
  `}
`

const StyledIntroduction = styled(Paragraph)`
  margin-bottom: ${(props: any) => props.theme.spacing.xl};
`

const Heading: BasicComponentWithProps = {
  Component: ColoredH1
}

const Content: BasicComponentWithProps = {
  Component: styled('div')``
}

const ContentItem: BasicComponentWithProps = {
  Component: StyledIntroduction
}

const StyledContactUs = styled(Paragraph)`
  margin-bottom: ${(props: any) => props.theme.spacing.xxxl};
  &:last-child {
    margin: 0;
  }
`

const ContactUs: BasicComponentWithProps = {
  Component: StyledContactUs
}

const ClosingItemLinkButton = styled(LinkButton)`
  font-family: ${(props) => props.theme.fontFamily};
  padding: 0;
`

const EligibilityResultIneligible = (p: IEligibilityResultsProps) => {
  const [state, , content] = useUnifiedApplicationStep<
    IUnifiedEligibilityResultsState,
    UnifiedEligibilityResultsReducer
  >(reducer, p.initialState, p.applicationIdentifier, p.onSubmit, p.onAutoSave)

  if (state.state === UnifiedApplicationState.Loading) {
    return <p>{tokeniseToString(content.Shared, 'LoadingPage')}</p>
  }

  let locatedContent: IneligibleResultContent | undefined
  locatedContent = state.generalEligibility === true ? content.Ineligible.Autorejected : content.Ineligible.Default

  return (
    <>
      <DynamicContent
        content={locatedContent}
        components={{
          Heading,
          Content,
          ContentItem,
          ContactUs,
          ContactUsEmail: {
            Component: ClosingItemLinkButton,
            props: {
              autoFontSize: true,
              onClick: () => {
                window.location.href = locatedContent!.ContactUsEmailLink
              }
            }
          }
        }}
        ignoreComponents={['ContactUsEmail']}
      />
    </>
  )
}

export default EligibilityResultIneligible
