import styled from 'styled-components'
import { media } from 'src/ui-framework'

export const GreenTickIcon = 'https://cdn.kriya.co/images/green-tick-icon.png'
export const CyanTickIcon = 'https://cdn.kriya.co/images/cyan-tick-icon.png'

export const UnorderedList = styled.ul`
  padding-inline-start: 16px;
  margin: 16px 0;
`
export const ListItems = styled.li`
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: rgba(0, 0, 0, 0.7);
  padding-top: 16px;

  &:first-child {
    padding-top: 0;
  }
`
export const ListItemsWithoutBold = styled(ListItems)`
  font-weight: normal;
`
export const SubListItems = styled(ListItems)`
  font-weight: 400;
  padding-top: 16px;
  ${media.xsOnly`
    padding-top: 8px;
`}
`
export const SubBulletsUnorderedList = styled.ul`
  padding-inline-start: 50px;
  ${media.xsOnly`
    margin-block-start: 0;  
`}
`
export const CheckedItemWrapper = styled.div`
  margin: 16px 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;

  ${media.xsOnly`
  &:nth-child(5n){
    margin-bottom: 8px;
  }
  
`}
`

export const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 24px;
  height: 24px;
`

export const PageContainer = styled.div`
  z-index: 99;
  bottom: 0;
  display: flex;
  justify-content: start;
`

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0 48px;
  ${media.upToTablet`
    flex-direction: column-reverse;
  `}
`

export const Separators = styled.div`
  border-bottom: 1px solid #ccc;
`
export const ImageWrapper = styled.div`
  margin-top: 50px;
`
export const MainDiv = styled.div`
  width: 100%;
`

export const Welcome = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-top: ${(props) => props.theme.spacing.spacingS};
`

export const ReadyToApplyWrapper = styled.div`
  display: flex;
  ${media.upToTablet`
    display: block;
  `}
`

export const ReadyToApplyMainContentWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSize.s};
  width: 560px;
  padding: 48px 28px;
  margin-right: 16px;
  box-sizing: border-box;
  height: fit-content;

  ${media.upToTablet`
    font-size: ${(props) => props.theme.fontSize.xs};
    padding: 24px 16px;
    margin-right: 0;
    width: 100%;
  `}
`

export const ReadyToApplySideContentWrapper = styled.div`
  font-size: ${(props) => props.theme.fontSize.s};
  background-color: ${(props) => props.theme.colors.white};
  padding: 48px 28px;
  height: fit-content;
  flex-grow: 1;
  box-sizing: border-box;

  ${media.upToTablet`
    font-size: ${(props) => props.theme.fontSize.xs};
    padding: 24px 16px;
    margin-top: 16px;
    width: 100%;
  `}
`

export const StyledHeader = styled.div`
  display: block;
`

export const StyledUl = styled.ul`
  margin-top: 4px;
  line-height: 24px;
  margin-bottom: 0;
  padding-left: 24px;

  ${media.upToTablet`
    line-height: 20px;  
  `}
`

export const StyledLi = styled.li``

export const RequirementsListWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.xl};

  ${media.upToTablet`
    margin-bottom: ${(props) => props.theme.spacing.l};
  `}
`

export const AboutUsListWrapper = styled.div`
  margin-bottom: ${(props) => props.theme.spacing.m};

  ${media.upToTablet`
    margin-bottom: ${(props) => props.theme.spacing.l};
  `}
`
