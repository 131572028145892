import styled from 'styled-components'
import { media } from 'src/ui-framework/commonStyles/mediaQueries/responsiveDeviceSize'
import { H1 } from '../../commonStyles/components/headings'
import { BasicComponentWithProps } from '../../shared/content/DynamicContent'
import { Paragraph, colors } from 'src/ui-framework'

export const MainContentWrapper = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;
`

export const MainContent = styled.div`
  background-color: white;
  margin-top: 16px;
  width: 560px;
  padding: 32px 28px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;

  ${media.upToTablet`
    margin-top: 0;
    padding: 24px 16px; 
  `}
`

export const ColoredH1 = styled(H1)`
  font-family: ${(props) => props.theme.fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #073dc9;
  margin: 0;
  padding-left: 0;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 32px;
  margin-top: 24px;
`

export const UnorderedList = styled.ul`
  padding-inline-start: 16px;
  margin: 24px 0;
`

export const ListItems = styled.li`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.fontSize.fontS};
  color: ${colors.textGrey900};
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  padding-top: 10px;

  &:first-child {
    padding-top: 0;
  }

  ${media.upToTablet`
    font-size: ${(props: any) => props.theme.fontSize.xs};
  `}
`

export const DynamicHeading: BasicComponentWithProps = {
  Component: ColoredH1
}

export const DynamicParagraph: BasicComponentWithProps = {
  Component: Paragraph
}

export const DynamicUnorderedList: BasicComponentWithProps = {
  Component: UnorderedList
}

export const DynamicListItems: BasicComponentWithProps = {
  Component: ListItems
}
