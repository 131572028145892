import React from 'react'
import styled from 'styled-components'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlined'

type ErrorMessageProps = {
  theme: any
}

const ErrorIcon = styled(ErrorOutlineOutlinedIcon)<ErrorMessageProps>`
  vertical-align: middle;
  padding-bottom: 2px;
  color: ${(props) => props.theme.colors.error};
  height: 17px;
  width: 17px;
`
const ErrorMessage = styled.div<ErrorMessageProps>`
  display: inline-block;
  padding-left: 3px;
  color: ${(props) => props.theme.colors.error};
  font-size: ${(props) => props.theme.fontSize.xxs};
  font-weight: 600;
  line-height: 16px;
  white-space: normal;
`

type ErrorProps = {
  id: string
  index: number
  theme?: any
} & React.ComponentPropsWithoutRef<'div'>

const Error: React.FC<ErrorProps> = (props: ErrorProps) => {
  return (
    <>
      <ErrorIcon />
      <ErrorMessage {...props} id={ErrorList.getErrorIdentifier(props.id, props.index)} role="alert">
        {props.children}
      </ErrorMessage>
    </>
  )
}

const StyledErrorList = styled.ul`
  list-style: none;
  padding: 0px;
  font-size: 13px;
  font-weight: 400;
  margin-top: ${(props) => props.theme.spacing.spacingXxs};
  color: ${(props) => props.theme.colors.errorRed};
  margin-bottom: 16px;
  font-family: ${(props) => props.theme.fontFamily};
  white-space: nowrap;
`

const StyledErrorItem = styled.li`
  display: flex;
`

type Props = {
  id: string
  errors?: Array<string>
}

type IErrorList<P> = React.FC<P> & {
  getErrorIdentifier: (id: string, index: number) => string
  getDescribedByAttributes: (id: string, errors: Array<string>) => string
  getDescribedByAttribute: (errors: Array<string>) => string
}

const ErrorList: IErrorList<Props> = ({ errors, id }: Props) => {
  if (errors === undefined || errors === null || errors.length === 0) {
    return <></>
  }

  return (
    <StyledErrorList>
      {errors.map((error, index) => (
        <StyledErrorItem key={`error-${index}`}>
          <Error id={id} index={index}>
            {error}
          </Error>
        </StyledErrorItem>
      ))}
    </StyledErrorList>
  )
}

ErrorList.getErrorIdentifier = (id: string, index: number): string => {
  return id + '_error_' + index
}

ErrorList.getDescribedByAttributes = (id: string, errors: Array<string>) => {
  if (errors == null) {
    return ''
  }
  return errors.map((_, index) => ErrorList.getErrorIdentifier(id, index)).join(' ')
}

ErrorList.getDescribedByAttribute = (errors: Array<string>) => {
  if (errors == null) {
    return ''
  }
  return errors.map((_, index) => errors[index]).join(' ')
}

export default ErrorList
