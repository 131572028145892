import styled, { InterpolationValue } from 'styled-components'
import HandleErrorAndFocusedColors from './ErrorAndFocusedColors'

type SelectDivStyleProps = {
  theme: any
}

export const SelectDiv = styled.div<SelectDivStyleProps>`
  position: relative;
  &:after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 22px;
    right: 14px;
    border-top: 6px solid ${(props) => props.theme.colors.uiGrey90};
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    pointer-events: none;
  }
`

type ThemedSelectOptionProps = {}

export const ThemedSelectOption = styled.option<ThemedSelectOptionProps>`
  ${(props) => props.theme.inputFields as InterpolationValue}
  color: black;
  background: white;
  display: flex;
  white-space: pre;
  min-height: 20px;
  padding: 0px 2px 1px;
`

type SelectStyleProps = {
  focused: boolean
  errors: Array<string>
  touchedIncomplete: boolean
  theme?: any
}

const ThemedSelect = styled.select<SelectStyleProps>`
  ${(props) => props.theme.inputFields as InterpolationValue}
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: ${(props) => props.theme.spacing.s};
  padding-left: 14px;
  font-family: ${(props) => props.theme.fontFamily};
  &:hover {
    background-color: ${(props) => props.theme.colors.grey10};
  }
  ${({ errors, focused, theme }: { errors: string[]; theme: any; focused: boolean }) =>
    HandleErrorAndFocusedColors(errors && errors.length > 0, focused, theme)}
`
export default ThemedSelect
