import IUnifiedApplicationState from '../types/IUnifiedApplicationState'
import IDirectorDetailsState from '../types/IDirectorDetailsState'
import IUnifiedApplicationStateToIUnifiedApplicationStepState from './IUnifiedApplicationStateToIUnifiedApplicationStepState'

class IUnifiedApplicationStateToIDirectorDetailsStateImpl extends IUnifiedApplicationStateToIUnifiedApplicationStepState<IDirectorDetailsState> {
  map = (i: IUnifiedApplicationState): IDirectorDetailsState => {
    return {
      ...this.baseMap(i),
      directorName: i.director?.name,
      dateOfBirth: i.director?.dateOfBirth,
      directorEmail: i.director?.email,
      directorPhoneNumber: i.director?.phoneNumber,
      residentialAddress: i.director?.address || {
        addressLine1: '',
        addressLine2: '',
        city: '',
        country: '',
        postCode: '',
        region: ''
      },
      timeAtAddress: {
        yearsAtAddress: i.director?.yearsAtAddress?.toString(),
        monthsAtAddress: i.director?.monthsAtAddress?.toString()
      },
      companyIdentifier: i.companyIdentifier,
      legalsConsentedTime: i.director?.legalsConsentedTime,
      directors: [],
      showModal: false,
      profiles: {},
      hasSalesCookie: i.hasSalesCookie
    }
  }

  reverseMap = (i: IDirectorDetailsState, target: IUnifiedApplicationState, autoSave: boolean): void => {
    target.director = {
      name: i.directorName,
      dateOfBirth: i.dateOfBirth,
      address: i.residentialAddress,
      email: i.directorEmail,
      phoneNumber: i.directorPhoneNumber,
      yearsAtAddress: isNaN(Number(i.timeAtAddress.yearsAtAddress))
        ? undefined
        : Number(i.timeAtAddress.yearsAtAddress),
      monthsAtAddress: isNaN(Number(i.timeAtAddress.monthsAtAddress))
        ? undefined
        : Number(i.timeAtAddress.monthsAtAddress),
      legalsConsentedTime: i.legalsConsentedTime
    }
  }
}

const IUnifiedApplicationStateToIDirectorDetailsState = new IUnifiedApplicationStateToIDirectorDetailsStateImpl()
export default IUnifiedApplicationStateToIDirectorDetailsState
