import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import IApplicationUrlParams from '../../types/IApplicationUrlParams'
import { ErrorType } from '../../reducers/unifiedApplicationActions'
import UnauthorizedErrorPage from './UnauthorizedError'
import { Config } from 'src/applyfrontendcontent'
import { DynamicContent, tokeniseToString } from '../../shared/content/DynamicContent'
import SegmentWrapper from '../../utils/segmentWindow'
import { useGlobalContext } from '../../GlobalContext'
import { trackAppInsightsEvent } from '../../utils/appInsights/AppInsights'
import { AnchorLink } from 'src/ui-framework'
import { IRepresentativeJourneyInfoType } from '../../types/IRepresentativeJourneyInfoType'
import { DynamicHeading, DynamicParagraph, MainContent, MainContentWrapper } from './ErrorPageStyles'
import PartnerUnauthenticatedErrorPage from './UnauthenticatedError'
import { PartnerType } from 'src/types/PartnerType'

type ErrorPageProps = {
  errorType?: ErrorType
  content?: Config
  representativeJourneyInfo?: IRepresentativeJourneyInfoType
  partner?: PartnerType
}

const ErrorPage = (props: ErrorPageProps) => {
  const params = useParams<IApplicationUrlParams>()
  const { copy } = useGlobalContext()

  useEffect(() => {
    SegmentWrapper.page('error', {
      errorType: props.errorType,
      applicationId: params.id
    })

    if (props.errorType === undefined) {
      SegmentWrapper.virtualPageView('error-generic', undefined, false)
    } else {
      let error = props.errorType
      let hotjarErrorTag = error.toLowerCase().substring(error.indexOf('/') + 1)
      SegmentWrapper.virtualPageView(`error-${hotjarErrorTag}`, undefined, false)
    }
  }, [props.errorType, params.id])

  useEffect(() => {
    if (!(props.errorType === undefined || props.errorType === ErrorType.Generic)) {
      return
    }

    trackAppInsightsEvent('UajErrorPage', {
      id: params?.id,
      url: window.location.toString(),
      type: props.errorType
    })
  }, [params, props.errorType])

  let comp: React.ReactNode

  switch (props.errorType) {
    case ErrorType.Unauthenticated:
      comp = <PartnerUnauthenticatedErrorPage partner={props.partner} />
      break
    case ErrorType.Unauthorized:
      comp = (
        <UnauthorizedErrorPage
          content={copy}
          showSendEmailLink
          representativeJourneyInfo={props.representativeJourneyInfo}
        />
      )
      break
    case ErrorType.Forbidden:
      comp = (
        <UnauthorizedErrorPage
          content={copy}
          showSendEmailLink={false}
          representativeJourneyInfo={props.representativeJourneyInfo}
        />
      )
      break
    default:
      copy.GenericError.Dynamic = {
        ErrorEmail: tokeniseToString(copy.GenericError, 'GenericEmail')
      }

      comp = (
        <>
          <DynamicContent
            content={copy.GenericError}
            components={{
              Heading: DynamicHeading,
              Introduction: DynamicParagraph,
              ErrorMessage: DynamicParagraph,
              'Dynamic.ErrorEmail': {
                Component: AnchorLink,
                props: {
                  href: `mailto:${tokeniseToString(copy.GenericError.Dynamic, 'ErrorEmail')}`
                }
              }
            }}
          />
        </>
      )
      break
  }

  return (
    <MainContentWrapper>
      <MainContent>{comp}</MainContent>
    </MainContentWrapper>
  )
}

export default ErrorPage
