import { Journey, JourneyDefinition, getPageHeading, getPageShortHeading } from './journey'
import { JourneyType } from '../types/JourneyType'
import deepCopy from '../utils/deepCopy'
import getStartedPageIdentifier from './unifiedApplicationJourney/getStarted/getStartedPageIdentifier'
import { PageIdentifier } from '../types/PageIdentifier'
import GetStarted from './unifiedApplicationJourney/getStarted/GetStarted'
import IUnifiedApplicationStateToIGetStartedState from '../mappers/IUnifiedApplicationStateToIGetStartedState'
import { IJourneyStepRenderer } from '../types/IJourneyStepRenderer'
import IUnifiedApplicationStateToIFundingNeedsState from '../mappers/IUnifiedApplicationStateToFundingNeedsState'
import DirectorDetails, {
  directorDetailsPageIdentifier
} from './unifiedApplicationJourney/directorDetails/DirectorDetails'
import IUnifiedApplicationStateToIDirectorDetailsState from '../mappers/IUnifiedApplicationStateToIDirectorDetailsState'
import SupportingDocuments, {
  supportingDocumentsPageIdentifier
} from './unifiedApplicationJourney/supportingDocuments/SupportingDocuments'
import IUnifiedApplicationStateToISupportingDocumentsState from '../mappers/IUnifiedApplicationStateToISupportingDocumentsState'
import { partnerStandardJourney } from './partnerJourney/partnerJourney'
import { recoveryLoansJourney } from './recoveryLoansJourney/recoveryLoansJourney'
import { fundingNeedsPageIdentifier } from './unifiedApplicationJourney/fundingNeeds/fundingNeedsPageIdentifier'
import FundingNeeds from './unifiedApplicationJourney/fundingNeeds/FundingNeeds'
import { eligibilityPageIdentifier } from './unifiedApplicationJourney/eligibility/eligibilityPageIdentifier'
import UnifiedEligibility from './unifiedApplicationJourney/eligibility'
import IUnifiedApplicationStateToUnifiedEligibilityState from 'src/mappers/IUnifiedApplicationStateToUnifiedEligibilityState'
import { eligibilityResultsPageIdentifier } from './unifiedApplicationJourney/eligibility/results/eligible/eligibilityResultsPageIdentifier'
import EligibilityResults from './unifiedApplicationJourney/eligibility/results/eligible/eligibilityResults'
import IUnifiedApplicationStateToUnifiedEligibilityResultsState from 'src/mappers/IUnifiedApplicationStateToUnifiedEligibilityResultsState'
import { ineligiblePageIdentifier } from './unifiedApplicationJourney/eligibility/results/ineligible/ineligiblePageIdentifier'
import { eligibilityLoadingPageIdentifier } from './unifiedApplicationJourney/eligibility/results/loading/eligibilityLoadingPageIdentifier'
import EligibilityLoading from './unifiedApplicationJourney/eligibility/results/loading'
import EligibilityResultIneligible from './unifiedApplicationJourney/eligibility/results/ineligible'
import { UnifiedApplicationStatus } from 'src/types/IUnifiedApplicationState'
import { barclaysStandardJourney } from './partnerJourney/barclaysJourney/barclaysJourneys'
import { FlowType } from 'src/types/FlowType'
import { barclaysCustomerJourney } from './partnerJourney/barclaysJourney/barclaysCustomerJourney'
import { ChannelType } from 'src/types/ChannelType'

type JourneyLookup = {
  readonly [k in JourneyType]: Journey
}

type PartnerJourneyLookup = {
  readonly [k in JourneyType]: { [f in FlowType]: Journey }
}

const directJourney: JourneyDefinition[] = [
  {
    page: deepCopy(getStartedPageIdentifier) as PageIdentifier,
    renderer: {
      Component: GetStarted,
      mapper: IUnifiedApplicationStateToIGetStartedState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('GetStarted'),
    pageShortHeading: getPageShortHeading('GetStarted')
  },
  {
    page: deepCopy(fundingNeedsPageIdentifier) as PageIdentifier,
    renderer: {
      Component: FundingNeeds,
      mapper: IUnifiedApplicationStateToIFundingNeedsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('FundingNeeds'),
    pageShortHeading: getPageShortHeading('FundingNeeds')
  },
  {
    page: deepCopy(eligibilityPageIdentifier) as PageIdentifier,
    renderer: {
      Component: UnifiedEligibility,
      mapper: IUnifiedApplicationStateToUnifiedEligibilityState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('Eligibility'),
    pageShortHeading: getPageShortHeading('Eligibility')
  },
  {
    page: deepCopy(eligibilityLoadingPageIdentifier) as PageIdentifier,
    renderer: {
      Component: EligibilityLoading,
      mapper: IUnifiedApplicationStateToUnifiedEligibilityResultsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('Eligibility'),
    pageShortHeading: getPageShortHeading('Eligibility')
  },
  {
    page: deepCopy(ineligiblePageIdentifier) as PageIdentifier,
    renderer: {
      Component: EligibilityResultIneligible,
      mapper: IUnifiedApplicationStateToUnifiedEligibilityResultsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('Ineligible'),
    pageShortHeading: getPageShortHeading('Ineligible')
  },
  {
    page: deepCopy(eligibilityResultsPageIdentifier) as PageIdentifier,
    renderer: {
      Component: EligibilityResults,
      mapper: IUnifiedApplicationStateToUnifiedEligibilityResultsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('EligibilityResult'),
    pageShortHeading: getPageShortHeading('EligibilityResult')
  },
  {
    page: deepCopy(directorDetailsPageIdentifier) as PageIdentifier,
    renderer: {
      Component: DirectorDetails,
      mapper: IUnifiedApplicationStateToIDirectorDetailsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('DirectorDetails'),
    pageShortHeading: getPageShortHeading('DirectorDetails')
  },
  {
    page: deepCopy(supportingDocumentsPageIdentifier) as PageIdentifier,
    renderer: {
      Component: SupportingDocuments,
      mapper: IUnifiedApplicationStateToISupportingDocumentsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('SupportingDocuments'),
    pageShortHeading: getPageShortHeading('SupportingDocuments')
  }
]

const journeys: JourneyLookup = {
  [JourneyType.Standard]: new Journey(directJourney),
  [JourneyType.BarclaysStandard]: new Journey(partnerStandardJourney),
  [JourneyType.RecoveryLoans]: new Journey(recoveryLoansJourney),
  [JourneyType.Unknown]: new Journey([])
}

const partnerJourneys: PartnerJourneyLookup = {
  [JourneyType.Standard]: { Standard: new Journey([]), StrategicPartner: new Journey([]) },
  [JourneyType.BarclaysStandard]: {
    Standard: new Journey(barclaysCustomerJourney),
    StrategicPartner: new Journey(barclaysStandardJourney)
  },
  [JourneyType.RecoveryLoans]: { Standard: new Journey([]), StrategicPartner: new Journey([]) },
  [JourneyType.Unknown]: { Standard: new Journey([]), StrategicPartner: new Journey([]) }
}

const getJourneyById = (id: JourneyType, status?: UnifiedApplicationStatus, channel?: ChannelType): Journey => {
  if (status === UnifiedApplicationStatus.PartnerDraft) {
    return partnerJourneys[id]!.StrategicPartner
  } else if (status === UnifiedApplicationStatus.Draft && channel === ChannelType.RelationshipManager) {
    return partnerJourneys[id]!.Standard
  }

  return journeys[id]!
}
export default getJourneyById
