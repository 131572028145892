import { IneligibleResultContents } from '../types/eligbilityResult'

export const ineligibilityResult: IneligibleResultContents = {
  Heading: 'Ineligible',
  ShortHeading: 'Ineligible',
  Autorejected: {
    Heading: "We're sorry",
    ShortHeading: 'Ineligible',
    Content: [
      "Thank you for completing the initial eligibility form. On this occasion, the information you've provided does not meet our eligibility criteria."
    ],
    ContactUs:
      'If you have any questions about the result, please contact {ContactUsEmail} and a member of the team will be happy to assist you.',
    ContactUsEmail: 'sales@kriya.co',
    ContactUsEmailLink: 'mailto:sales@kriya.co',
    GoBackButton: 'Go back to eligibility check'
  },
  Default: {
    Heading: "We're sorry",
    ShortHeading: 'Ineligible',
    Content: [
      "Thank you for completing the initial eligibility form. On this occasion, the information you've provided does not meet our eligibility criteria."
    ],
    ContactUs:
      'If you have any questions about the result, please contact {ContactUsEmail} and a member of the team will be happy to assist you.',
    ContactUsEmail: 'sales@kriya.co',
    ContactUsEmailLink: 'mailto:sales@kriya.co',
    GoBackButton: 'Go back to eligibility check'
  }
}
