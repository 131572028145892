import { UnauthenticatedErrorContent } from 'src/applyfrontendcontent/types/unauthenticatedError'

export const unauthenticatedError: UnauthenticatedErrorContent = {
  Heading: 'Oops, something went wrong!',
  Introduction: [
    "It seems like you've stumbled upon a page you don't have access to yet. Please double-check that you've signed in with the right credentials.",
    "If that doesn't work, don't worry. We're here to help! Please contact {ContactEmail} and a member of the team will be happy to assist you."
  ],
  ContactEmail: 'sales@kriya.co'
}
