import {
  IUnifiedApplicationAction,
  IUnifiedApplicationActionSupportingDocumentsLoaded,
  UnifiedApplicationActionType
} from './unifiedApplicationActions'
import UnifiedApplicationBaseReducer from './unifiedApplicationBaseReducer'
import { IErrorsState } from '../types/IErrorsState'
import ISupportingDocumentsState from '../types/ISupportingDocumentsState'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import IUploadFileConstraints, { defaultFileConstraints } from '../types/IUploadFileConstraints'
import IFile from '../types/IFile'
import { UnifiedApplicationState } from '../types/UnifiedApplicationState'
import IFileState, { FileStatusState } from '../types/IFileState'
import { IFileCache } from '../types/IFileCache'
import { FieldWithErrorMessage, validate as validateRequiredFields } from '../validators/formValidator'
import { ILatestFilingAccountsResponse } from '../types/ILatestFilingAccountsResponse'
import { tokeniseToString } from '../shared/content/DynamicContent'
import { UnifiedJourneyProductType } from '../types/ProductType'
import { EligibilityOutcome } from '../types/EligibilityOutcome'

type ILoadedResponse = IUploadFileConstraints | IFile[] | ILatestFilingAccountsResponse

const validateStatus = (status: number): boolean => {
  return status >= 200 && status < 500
}

const axiosConfig: AxiosRequestConfig = {
  withCredentials: true,
  validateStatus
}

export default class SupportingDocumentsReducer extends UnifiedApplicationBaseReducer<ISupportingDocumentsState> {
  handleDefaultAction = (
    prevState: ISupportingDocumentsState,
    newState: ISupportingDocumentsState,
    action: IUnifiedApplicationAction<object>
  ): void => {
    switch (action.type) {
      case UnifiedApplicationActionType.Loaded:
        const loadedAction = action as IUnifiedApplicationActionSupportingDocumentsLoaded
        const { constraints, files, latestCompanyFilings } = loadedAction.payload
        newState.state = UnifiedApplicationState.Pristine
        newState.constraints = constraints
        newState.lastAccountsFilingMadeUptoDate = latestCompanyFilings?.filingsMadeUptoDate
        newState.hasAnyFilings = latestCompanyFilings?.hasAnyFilings

        const fileCache: IFileCache = {}

        files &&
          files.forEach((file: IFile): void => {
            fileCache[file.id] = file
          })

        if (newState.bankStatements) {
          this.updateFiles(newState.bankStatements, fileCache)
        }

        if (newState.statutoryAccounts) {
          this.updateFiles(newState.statutoryAccounts, fileCache)
        }

        if (newState.additionalDocuments) {
          this.updateFiles(newState.additionalDocuments, fileCache)
        }

        if (newState.hmrcTaxCalculationDocuments) {
          this.updateFiles(newState.hmrcTaxCalculationDocuments, fileCache)
        }
        break
    }
  }

  onLoad = async (
    state: ISupportingDocumentsState,
    dispatch: React.Dispatch<IUnifiedApplicationAction<object>>
  ): Promise<void> => {
    const responses = await axios.all([
      axios.get<ILoadedResponse>(`/files/list/${state.applicationIdentifier}`, axiosConfig),
      axios.get<ILoadedResponse>('/files/upload/constraints', axiosConfig),
      axios.get<ILoadedResponse>(
        `/company-info/companieshouse/gb/ltd/${state.companyNumber}/latestaccountsfilings`,
        axiosConfig
      )
    ])

    const action = axios.spread<AxiosResponse<any>, IUnifiedApplicationActionSupportingDocumentsLoaded>(
      (
        files: AxiosResponse<IFile[]>,
        constraints: AxiosResponse<IUploadFileConstraints>,
        latestCompanyFilings: AxiosResponse<ILatestFilingAccountsResponse>
      ): IUnifiedApplicationActionSupportingDocumentsLoaded => {
        return {
          type: UnifiedApplicationActionType.Loaded,
          payload: {
            constraints: constraints.data || defaultFileConstraints,
            files: files.data,
            latestCompanyFilings: latestCompanyFilings.data
          }
        }
      }
    )(responses)

    dispatch(action)
  }

  onValidate = (state: ISupportingDocumentsState): IErrorsState => {
    const content = this.getContent(state).SupportingDocuments
    const bankStatementsField = {
      fieldName: 'bankStatements',
      errorMessage: tokeniseToString(content.BankAccount.BankStatements.Error || {}, 'Required')
    }
    const statutoryAccountsField = {
      fieldName: 'statutoryAccounts',
      errorMessage: tokeniseToString(content.StatutoryAccounts.StatutoryAccounts.Error || {}, 'Required')
    }
    let requiredFields: Array<FieldWithErrorMessage> = [bankStatementsField]

    if (state.isStatutoryAccountsRequired) {
      requiredFields = [bankStatementsField, statutoryAccountsField]
    }

    const errors = validateRequiredFields(requiredFields, state)

    this.setErrorsOnNoUploadedFiles(
      state.bankStatements,
      'bankStatements',
      errors,
      tokeniseToString(content.BankAccount.BankStatements.Error || {}, 'Required')
    )
    this.setErrorsOnFilesUploading(
      state.bankStatements,
      'bankStatements',
      errors,
      tokeniseToString(content.BankAccount.BankStatements.Error || {}, 'Uploading')
    )

    if (content.AdditionalDocuments) {
      this.setErrorsOnFilesUploading(
        state.additionalDocuments,
        'additionalDocuments',
        errors,
        tokeniseToString(content.AdditionalDocuments.Error || {}, 'Uploading')
      )
    }

    if (content.HmrcTaxCalculationDocuments) {
      this.setErrorsOnNoUploadedFiles(
        state.hmrcTaxCalculationDocuments,
        'hmrcTaxCalculationDocuments',
        errors,
        tokeniseToString(content.HmrcTaxCalculationDocuments.Error || {}, 'Required')
      )
      this.setErrorsOnFilesUploading(
        state.additionalDocuments,
        'hmrcTaxCalculationDocuments',
        errors,
        tokeniseToString(content.HmrcTaxCalculationDocuments.Error || {}, 'Uploading')
      )
    }

    if (state.isStatutoryAccountsRequired) {
      this.setErrorsOnNoUploadedFiles(
        state.statutoryAccounts,
        'statutoryAccounts',
        errors,
        tokeniseToString(content.StatutoryAccounts.StatutoryAccounts.Error || {}, 'Required')
      )
      this.setErrorsOnFilesUploading(
        state.statutoryAccounts,
        'statutoryAccounts',
        errors,
        tokeniseToString(content.StatutoryAccounts.StatutoryAccounts.Error || {}, 'Uploading')
      )
    }

    return errors
  }

  onBeforeSubmit = async (state: ISupportingDocumentsState): Promise<void> => {
    if (state.state !== UnifiedApplicationState.Submitting) {
      return
    }
    const recoveryLoan = state.products?.[UnifiedJourneyProductType.RecoveryLoan]
    const businessLoan = state.products?.[UnifiedJourneyProductType.Loans]
    if (!recoveryLoan?.isSelected || !businessLoan) {
      return
    }
    if (recoveryLoan.eligibility === EligibilityOutcome.Ineligible) {
      recoveryLoan.isSelected = false
    } else {
      businessLoan.isSelected = false
    }
  }

  private updateFiles(filesTarget: IFileState[], filesSource: IFileCache): void {
    filesTarget.forEach((file: IFileState) => {
      const matchedFile = (file.id && filesSource[file.id]) || undefined
      file.name = matchedFile !== undefined ? matchedFile.name : 'unknown'
      file.status = matchedFile === undefined ? FileStatusState.Deleted : FileStatusState.Uploaded
    })
  }

  private setErrorsOnNoUploadedFiles(
    files: IFileState[] | undefined,
    propertyName: string,
    errors: IErrorsState,
    message: string
  ): void {
    const nonDeletedFiles = files?.filter((f) => f.status === FileStatusState.Uploaded).length
    if (!nonDeletedFiles || nonDeletedFiles === 0) {
      errors.hasErrors = true
      errors[propertyName] = [message]
    }
  }

  private setErrorsOnFilesUploading(
    files: IFileState[] | undefined,
    propertyName: string,
    errors: IErrorsState,
    message: string
  ): void {
    if (!files) {
      return
    }

    const numFilesUploading = files.filter((f) => f.status === FileStatusState.Pending).length

    if (numFilesUploading > 0) {
      errors.hasErrors = true
      errors[propertyName] = [message]
    }
  }
}
