import React, { useState, useCallback, useEffect } from 'react'
import axios from 'axios'
import CompanySearchFields from './CompanySearchFields'
import apiBaseUrl from '../../../utils/apiBaseUrl'
import { IErrorsState } from '../../../types/IErrorsState'
import {
  IUnifiedApplicationAction,
  UnifiedApplicationActionGetStartedAddCompanyManually,
  UnifiedApplicationActionValidateField
} from '../../../reducers/unifiedApplicationActions'
import { GetStartedContent } from 'src/applyfrontendcontent'
import { tokeniseLabelAsString } from '../../content/DynamicContent'
import { Typeahead } from 'src/ui-framework'

type CompanySearchProps = {
  onChange: (i: CompanySearchResult) => void
  value: CompanySearchResult
  errors: IErrorsState
  addManually: boolean
  dispatch: React.Dispatch<IUnifiedApplicationAction<object>>
  content: GetStartedContent
  isCompanyReadonly: boolean
}

type CompanySearchApiResult = {
  companyNumber: string
  companyName: string
  registeredAddress: {
    addressLine1: string
    addressLine2: string
    city: string
    postCode: string
    country: string
  }
}

const enterKeyCode = 13
const escapeKeyCode = 27

const isCompanySearchApiResult = (i: any): i is CompanySearchApiResult => {
  return i.companyNumber !== undefined && i.companyName !== undefined && i.registeredAddress !== undefined
}

export type CompanySearchResult = {
  companyName: string
  companyNumber: string
  address: {
    addressLine1?: string
    addressLine2?: string
    city?: string
    postCode?: string
    country?: string
  }
}

const CompanySearch = (props: CompanySearchProps) => {
  const [results, setResults] = useState<Array<CompanySearchApiResult>>([])
  const [select, setSelected] = useState([props.value])
  const [loading, setLoading] = useState(false)
  const [focused, setFocused] = useState(false)
  const [open, setOpen] = useState(false)

  const { dispatch } = props
  const setManuallyAdd = useCallback(
    (typedInCompanyName?: string) => {
      dispatch(UnifiedApplicationActionGetStartedAddCompanyManually())
    },
    [dispatch]
  )

  const handle = (e: any) => {
    if (e.target.id !== 'company-name-input') {
      setFocused(false)
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handle)

    return (): void => {
      document.removeEventListener('click', handle)
    }
  }, [])

  const handleSearch = async (name: string) => {
    const url = `${apiBaseUrl(window.location.href)}/company-info/search`

    try {
      setOpen(true)
      setLoading(true)
      setResults([])

      // TODO: This should be handled outside the component, pass in an onSearch
      const response = await axios.post(url, {
        fuzzyTerm: name,
        address: {
          country: 'GB'
        }
      })
      const results = (response.data as any[]).map((r: { name: string; auxiliaryIdentifiers: any[]; address: any }) => {
        return {
          ...r,
          ...{
            companyName: r.name,
            companyNumber: r.auxiliaryIdentifiers
              .filter((p: { isNationalId: boolean }) => p.isNationalId === true)
              .map((p: { identifier: string }) => p.identifier)[0],
            registeredAddress: {
              ...r.address,
              ...{
                addressLine1: r.address.addressLine1?.toUpperCase()
              }
            }
          }
        }
      })

      const mappedResults: CompanySearchApiResult[] = []
      results?.forEach((e: any) => {
        if (isCompanySearchApiResult(e)) {
          mappedResults.push(e)
        }
      })

      setResults(mappedResults)
      setLoading(false)
    } catch (error) {
      setManuallyAdd()
      setLoading(false)
      setOpen(false)
    }
  }

  const handleChange = useCallback(
    (selectedValue) => {
      setSelected(selectedValue)
      setOpen(false)

      let companySearchResult: CompanySearchResult = {
        address: {},
        companyNumber: '',
        companyName: ''
      }

      if (selectedValue && selectedValue.length > 0) {
        companySearchResult = selectedValue.map((f: CompanySearchApiResult): CompanySearchResult => {
          return {
            companyName: f.companyName,
            address: f.registeredAddress,
            companyNumber: f.companyNumber
          }
        })[0]
      }

      props.onChange(companySearchResult)
    },
    [props]
  )

  const handleCompanySelection = useCallback(
    (selectedValue: any) => {
      let selectedCompany = []
      selectedCompany.push(selectedValue)
      handleChange(selectedCompany)
    },
    [handleChange]
  )

  const handleKeyCapture = useCallback(
    (e: React.KeyboardEvent<HTMLLIElement>, selectedValue: any) => {
      if (e.keyCode === enterKeyCode) {
        e.preventDefault()
        handleCompanySelection(selectedValue)
      }
    },
    [handleCompanySelection]
  )

  const handleLiClick = useCallback(
    (selectedValue: any) => {
      handleCompanySelection(selectedValue)
    },
    [handleCompanySelection]
  )

  const handleKeyDown = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Escape' || e.keyCode === escapeKeyCode) {
      setOpen(false)
      return
    }
  }

  const handleAddManuallyBlur = (e: any) => {
    //relatedTarget is the next element to receive focus and target is the element which lost focus
    if (e !== null && e.relatedTarget?.tagName.toLowerCase() !== 'li' && e.target?.id === 'cs-add-manually') {
      setOpen(false)
    }
  }

  const companySearchContent = props.content.CompanySearch!

  if (props.addManually) {
    return (
      <CompanySearchFields
        companyNumber={props.value.companyNumber}
        companyName={props.value.companyName}
        onCompanyNameChange={(value: string) => props.onChange({ ...props.value, companyName: value })}
        onCompanyNumberChange={(value: string) => props.onChange({ ...props.value, companyNumber: value })}
        errors={props.errors}
        dispatch={props.dispatch}
        content={props.content}
        isCompanyReadonly={props.isCompanyReadonly}
      />
    )
  }

  const isInvalid =
    (props.errors['companyName'] as string[]) &&
    (props.errors['companyName'] as string[]).length > 0 &&
    props.errors.hasErrors
      ? true
      : false

  const errors = (props.errors['companyName'] as string[]) || []

  const id = 'company-search'

  return (
    <div style={{ marginBottom: '16px' }}>
      <Typeahead
        labelKey="companyName"
        content={companySearchContent}
        inputId={'company-name-input'}
        id={id}
        label={tokeniseLabelAsString(props.content.CompanySearch!.CompanyName)}
        emptyLabel={props.content.CompanySearch!.EmptyResult}
        isInvalid={isInvalid}
        isFocused={focused}
        selected={select}
        isLoading={loading}
        errors={errors}
        isOpen={open}
        isReadonly={props.isCompanyReadonly}
        handleLiClick={handleLiClick}
        handleKeyCapture={handleKeyCapture}
        handleAddManuallyBlur={handleAddManuallyBlur}
        onSearch={handleSearch}
        onBlur={() => {
          props.dispatch(UnifiedApplicationActionValidateField('companyName'))
          setFocused(false)
        }}
        options={results}
        onChange={(selected: any) => handleChange(selected)}
        onFocus={() => setFocused(true)}
        onKeyDown={handleKeyDown}
        searchText={props.content.CompanySearch!.Searching}
        itemContentTitle={(result) => result.companyName}
        itemContentSub={(result) => result.registeredAddress?.addressLine1}
        filterBy={(a: any) => {
          return a.registeredAddress !== undefined && a.companyNumber !== undefined
        }}
        required
      />
    </div>
  )
}

export default CompanySearch
