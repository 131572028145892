export default interface IAddressState {
  [Key: string]: string
  addressLine1: string
  addressLine2: string
  city: string
  postCode: string
  region: string
  country: string
}

export const emptyAddress: IAddressState = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  region: '',
  country: '',
  postCode: ''
}
