import React, { Component } from 'react'
import LabelWrapper from './labelWrapper'
import InputWrapper from './inputWrapper'
import TextWrapper from './textWrapper'
import ErrorList from '../ErrorList'
import Container from './container'
import OuterWrapper from './outerWrapper'

type OwnProps = {
  options: Array<{
    value: string
    label: string
  }>
  animate?: boolean
  errors?: Array<string>
  dataTest?: string
} & React.ComponentPropsWithoutRef<'input'>

type Props = OwnProps & typeof RadioButton.defaultProps

type State = {
  focused?: number
  mouseDown?: number
}

class RadioButton extends Component<Props, State> {
  static defaultProps: OwnProps = {
    name: 'radion-button',
    onChange: () => {},
    value: undefined,
    options: [],
    animate: false,
    errors: [],
    style: {},
    id: 'radio-button',
    dataTest: undefined
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      focused: null!,
      mouseDown: null!
    }

    this.handleMouseDown = this.handleMouseDown.bind(this)
    this.handleMouseUp = this.handleMouseUp.bind(this)
    this.handleOnBlur = this.handleOnBlur.bind(this)
    this.handleOnFocus = this.handleOnFocus.bind(this)
  }

  handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    this.setState({
      focused: +e.target.dataset.index!
    })
  }

  handleOnBlur = () => {
    this.setState({
      focused: null!
    })
  }

  handleMouseDown = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    this.setState({
      mouseDown: +e.currentTarget.dataset.index!
    })
  }

  handleMouseUp = () => {
    this.setState({
      mouseDown: null!
    })
  }

  render() {
    const { name, options, animate, errors, onChange, value, style, id, dataTest } = this.props

    const { focused, mouseDown } = this.state

    const localOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange!(e)
    }

    return (
      <Container errors={errors!} style={style} id={id} data-test={dataTest} role="radiogroup">
        {options.map((o, i) => (
          <React.Fragment key={`${name}-${i}`}>
            <OuterWrapper index={i}>
              <LabelWrapper
                data-index={i}
                animate={animate!}
                focused={i === focused}
                errors={errors!}
                mouseDown={i === mouseDown}
                onMouseDown={this.handleMouseDown}
                onMouseUp={this.handleMouseUp}
              >
                <InputWrapper>
                  <input
                    data-index={i}
                    name={name}
                    type="radio"
                    value={o.value}
                    onChange={localOnChange}
                    checked={value === o.value}
                    aria-checked={value === o.value}
                    onFocus={this.handleOnFocus}
                    onBlur={this.handleOnBlur}
                    id={`${id}-${i}`}
                    aria-invalid={errors!.length > 0}
                    aria-describedby={ErrorList.getDescribedByAttributes(id!, errors!)}
                  />
                  <i></i>
                </InputWrapper>
                <TextWrapper htmlFor={`${id}-${i}`}>{o.label}</TextWrapper>
              </LabelWrapper>
            </OuterWrapper>
            {value === o.value && this.props.children}
          </React.Fragment>
        ))}
        <ErrorList errors={errors} id={id!} />
      </Container>
    )
  }
}

export default RadioButton
