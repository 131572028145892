//@ts-nocheck
import { css } from 'styled-components'

export const size = {
  mobileXS: '320px',
  mobileS: '360px',
  mobileM: '375px',
  mobileL: '414px',
  iphoneXRPortrait: '425px',
  tabletS: '768px',
  tabletM: '834px',
  iphoneXRLandscape: '896px',
  laptop: '1024px',
  laptopL: '1280px',
  desktop: '1440px'
}

export const device = {
  mobileXS: `(min-width: ${size.mobileXS})`,
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  tabletS: `(min-width: ${size.tabletS})`,
  tabletM: `(min-width: ${size.tabletM})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`
}

export const media = {
  xsOnly: (...args: any[]) => css`
    @media (max-width: ${size.mobileXS}) {
      ${css(...args)}
    }
  `,
  xsOnlyLandscapePortrait: (...args) => css`
    @media only screen and (max-width: ${size.iphoneXRPortrait}) and (orientation: portrait),
      screen and (max-width: ${size.iphoneXRLandscape}) and (orientation: landscape) {
      ${css(...args)}
    }
  `,
  upToMobileL: (...args: any[]) => css`
    @media (max-width: ${size.mobileL}) {
      ${css(...args)}
    }
  `,
  upToTablet: (...args: any[]) => css`
    @media (max-width: ${size.tabletS}) {
      ${css(...args)}
    }
  `,
  upToLaptopSmall: (...args: any[]) => css`
    @media (max-width: ${size.laptop}) {
      ${css(...args)}
    }
  `,
  upToLaptop: (...args: any[]) => css`
    @media (max-width: ${size.laptopL}) {
      ${css(...args)}
    }
  `,
  excludeMobile: (...args: any[]) => css`
    @media (min-width: ${size.tabletS}) {
      ${css(...args)}
    }
  `
}
