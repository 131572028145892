//Footer
export const Representative = 'https://cdn.kriya.co/images/representative.png'
export const BritishBankLogo = 'https://cdn.kriya.co/images/BBB_Partner_Badge_2020.png'
export const TrustPilotLogo = 'https://cdn.kriya.co/images/Trustpilot.svg'
//Illustrations
export const Touchscreen = 'https://cdn.kriya.co/images/illustrations-composed-touchscreen.svg'
export const Flipchart = 'https://cdn.kriya.co/images/illustrations-composed-flipchart.svg'
export const Science = 'https://cdn.kriya.co/images/illustrations-composed-science.svg'
export const Apron = 'https://cdn.kriya.co/images/illustrations-composed-apron.svg'
export const SofaSurfer = 'https://cdn.kriya.co/images/illustrations-composed-sofasurfer.svg'
export const Products = 'https://cdn.kriya.co/images/illustrations-composed-products.svg'
export const Running = 'https://cdn.kriya.co/images/illustrations-composed-running.svg'
export const Trolley = 'https://cdn.kriya.co/images/illustrations-composed-trolley.svg'
export const DeskSide = 'https://cdn.kriya.co/images/illustrations-composed-deskside.svg'
export const DeskFacing = 'https://cdn.kriya.co/images/illustrations-composed-deskfacing.svg'
export const Sculptor = 'https://cdn.kriya.co/images/illustrations-composed-sculptor.svg'
export const Thanks1 = 'https://cdn.kriya.co/images/illustrations-composed-thanks-1.svg'
export const Thanks2 = 'https://cdn.kriya.co/images/illustrations-composed-thanks-2.svg'
export const Thanks3 = 'https://cdn.kriya.co/images/illustrations-composed-thanks-3.svg'
export const Thanks4 = 'https://cdn.kriya.co/images/illustrations-composed-thanks-4.svg'
export const Eligible = 'https://cdn.kriya.co/images/illustrations-eligible.svg'
export const PeopleCouch = 'https://cdn.kriya.co/images/illustrations-composed-people-couch.svg'
export const KriyaHeroLanding = 'https://cdn.kriya.co/images/Kriya-Hero-Landing.png'
export const KriyaLogoMidnight = 'https://cdn.kriya.co/images/kriya-logo-midnight-cropped.svg'
export const BarclaysPartnership = 'https://cdn.kriya.co/images/barclays-partnership.png'

//Dialog
export const CloseImage = 'https://cdn.kriya.co/images/ic-clear.svg'
