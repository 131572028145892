import IAddress from '../types/IAddress'
import IAddressState, { emptyAddress } from '../types/IAddressState'
import deepCopy from '../utils/deepCopy'

class IAddressToIAddressStateImpl {
  map = (i?: IAddress): IAddressState => {
    if (i === undefined) {
      return deepCopy(emptyAddress)!
    }

    const { addressLine1, addressLine2, city, country, postCode, region } = i
    return {
      addressLine1,
      addressLine2,
      city,
      country,
      postCode,
      region,
    }
  }

  reverseMap = (i?: IAddressState): IAddress => {
    if (i === undefined) {
      const { addressLine1, addressLine2, city, country, postCode, region } = emptyAddress
      return {
        addressLine1,
        addressLine2,
        city,
        country,
        postCode,
        region,
      }
    }

    const { addressLine1, addressLine2, city, country, postCode, region } = i
    return {
      addressLine1,
      addressLine2,
      city,
      country,
      postCode,
      region,
    }
  }
}

const IAddressToIAddressState = new IAddressToIAddressStateImpl()
export default IAddressToIAddressState
