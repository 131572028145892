import React from 'react'
import { Redirect } from 'react-router-dom'
import { ChannelType } from 'src/types/ChannelType'
import ReadyToApply from './ReadyToApply'
import { useInitialisationParameters } from './useInitialisationParameters'

const ReadyToApplyRouter = () => {
  const initialisationParameters = useInitialisationParameters()

  if (initialisationParameters.channel === ChannelType.RelationshipManager) {
    return <Redirect to={`/application/partner/${initialisationParameters.partner}`} />
  }

  return <ReadyToApply />
}

export default ReadyToApplyRouter
