import IDateState from '../types/IDateState'

class IDateStateToIsoDateMapperImpl {
  map = (i?: IDateState): string | null => {
    if (i && i.year && i.month && i.day) {
      const val = new Date(Date.UTC(i.year, i.month - 1, i.day)).toISOString()
      // In certain situations when creating an ISO
      // string e.g.. 99/99/9999 the date starts with
      // +012323xxxx.... in this case we consider it
      // invalid and simply return null
      return val.startsWith('+') ? null : val
    }

    return null
  }

  reverseMap = (i?: string | null): IDateState | undefined => {
    if (i) {
      const parsedIsoDate = new Date(i)
      return {
        day: parsedIsoDate.getUTCDate(),
        month: parsedIsoDate.getUTCMonth() + 1,
        year: parsedIsoDate.getUTCFullYear()
      }
    }
  }
}

const IDateStateToIsoDateMapper = new IDateStateToIsoDateMapperImpl()
export default IDateStateToIsoDateMapper
