import { useEffect } from 'react'
import SegmentWrapper from './utils/segmentWindow'
import { PageIdentifier } from './types/PageIdentifier'

export const useApplicationPageTracker = (page: PageIdentifier | string, applicationId: string | null): void => {
  const pageTitle = typeof page === 'string' ? page : page.step
  useEffect(() => {
    SegmentWrapper.page(pageTitle, {
      // we send undefined so that it does not get sent
      applicationId: applicationId === null ? undefined : applicationId,
    })
  }, [pageTitle, applicationId])
}
