import React, { useEffect, useState } from 'react'
import { useParams, Redirect, useHistory } from 'react-router-dom'
import axios from 'axios'
import QueryString from 'query-string'
import ErrorPage from '../../error/ErrorPage'
import LinkExpiredError from '../../error/LinkExpiredError'
import ApplicationDeadlineError from '../../error/ApplicationDeadline'
import { tokeniseToString } from '../../../shared/content/DynamicContent'
import { getCopy } from 'src/applyfrontendcontent'
import { useGlobalContext } from '../../../GlobalContext'
import { getConfigForPartnerType } from '../../../shared/setPartnerLogo'
import IApplication from '../../../types/IApplication'
import { map } from '../../../mappers/IApplicationToJourneyIdentifier'
import { JourneyType } from '../../../types/JourneyType'
import { ApplicationSource } from '../../../types/ApplicationSource'
import { applyServiceSuffix } from 'src/urlSettingsExports'

export interface ContinueLaterParams {
  id: string
}

const validateStatus = (status: number) => true

enum Result {
  Initial = 'Initial',
  Success = 'Success',
  Expired = 'Expired/Used',
  Error = 'Error',
  Redirect = 'Redirect',
  RedirectPartner = 'RedirectPartner',
  RedirectDashboard = 'RedirectDashboard'
}

export const ApplicationRedirect = (application: IApplication) => {
  if (
    application.journeyType === JourneyType.RecoveryLoans &&
    application.metadata?.source === ApplicationSource.UnifiedDashboard &&
    (application.lastCompletedStep?.length || 0) === 0
  ) {
    window.sessionStorage.removeItem('sessionFields')

    return Result.RedirectDashboard
  }
  return Result.Success
}

const ContinueLater = () => {
  const params = useParams<ContinueLaterParams>()
  const {
    location: { search }
  } = useHistory()
  const paramKey = QueryString.parse(search, { decode: false })
  const { setPartnerData } = useGlobalContext()
  const [result, setResult] = useState<Result>(Result.Initial)
  const { copy, setCopy } = useGlobalContext()

  useEffect(() => {
    const handleContinueLater = async () => {
      if (paramKey.key === undefined || typeof paramKey.key !== 'string') {
        setResult(Result.Error)
        return
      }

      const response = await axios.get<IApplication>(
        `/${applyServiceSuffix()}/applications/${params.id}/retrieve?key=${encodeURIComponent(paramKey.key)}`,
        { withCredentials: true, validateStatus }
      )

      switch (response.status) {
        case 200:
          if (response.data?.partnerType != null) {
            const partnerDetails = getConfigForPartnerType(response.data.partnerType, response.data.channelType)
            if (partnerDetails?.config !== undefined) setPartnerData(partnerDetails?.config)
          }
          const newCopy = getCopy(navigator.language, map(response.data))
          setCopy(newCopy)
          const applicationResult = ApplicationRedirect(response.data)
          setResult(applicationResult)
          break
        case 403:
          setResult(Result.Expired)
          break
        default:
          setResult(Result.Error)
          break
      }
    }

    handleContinueLater()
  }, [params.id, paramKey.key, setPartnerData, setCopy])

  if (result === Result.Initial) {
    return <p>{tokeniseToString(copy.Shared, 'LoadingPage')}</p>
  } else if (result === Result.RedirectPartner) {
    return <ApplicationDeadlineError />
  } else if (result === Result.RedirectDashboard) {
    return <Redirect to={`/application/${params.id}/dashboardreadytoapply`} />
  } else if (result === Result.Redirect) {
    return <Redirect to={'/application/readyToApply'} />
  } else if (result === Result.Success) {
    return <Redirect to={`/application/${params.id}`} />
  } else if (result === Result.Expired) {
    return <LinkExpiredError content={copy} />
  } else {
    return <ErrorPage content={copy} />
  }
}

export default ContinueLater
