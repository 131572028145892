import React, { useState } from 'react'
import { Row, Column, Button } from 'src/ui-framework'
import * as styles from '../unifiedApplicationJourney/readyToApply/ReadyToApplyStyles'
import { useHistory, useParams } from 'react-router-dom'
import { ContinueLater } from '../../shared/saveUnifiedApplication'
import IApplicationUrlParams from '../../types/IApplicationUrlParams'
import { DynamicContent, tokeniseToString } from '../../shared/content/DynamicContent'
import { Config } from 'src/applyfrontendcontent'
import { IRepresentativeJourneyInfoType } from '../../types/IRepresentativeJourneyInfoType'
import { DynamicHeading, DynamicParagraph } from './ErrorPageStyles'

interface IUnauthorizedErrorPage {
  content: Config
  showSendEmailLink: boolean
  representativeJourneyInfo?: IRepresentativeJourneyInfoType
}

const UnauthorizedErrorPage = (p: IUnauthorizedErrorPage) => {
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const history = useHistory()
  const params = useParams<IApplicationUrlParams>()

  const startNewApplication = () => {
    history.push('/application/readytoapply')
  }

  const sendSecureLink = async () => {
    setEmailSent(await ContinueLater(params.id))
  }

  return (
    <Row>
      <Column xs={12} sm={12}>
        {emailSent === false && (
          <>
            {p.showSendEmailLink && (
              <DynamicContent
                content={p.content.UnauthorisedError}
                components={{
                  Heading: DynamicHeading,
                  Introduction: DynamicParagraph
                }}
              />
            )}
            {p.showSendEmailLink === false && (
              <DynamicContent
                content={p.content.UnauthorisedError}
                components={{
                  Heading: DynamicHeading,
                  ForbiddenIntro: DynamicParagraph
                }}
              />
            )}

            <styles.ButtonGroup>
              {p.showSendEmailLink && (
                <Button id="sendEmail" color="primary" onClick={sendSecureLink}>
                  {tokeniseToString(p.content.UnauthorisedError, 'SecureLinkButton')}
                </Button>
              )}
              <Button id="new-application" color="secondary" onClick={startNewApplication}>
                {tokeniseToString(p.content.UnauthorisedError, 'NewApplicationButton')}
              </Button>
            </styles.ButtonGroup>
          </>
        )}
        {emailSent && (
          <DynamicContent
            content={p.content.UnauthorisedError}
            components={{
              CheckYourInboxHeading: DynamicHeading,
              CheckYourInboxParagraph: DynamicParagraph
            }}
          />
        )}
      </Column>
    </Row>
  )
}

export default UnauthorizedErrorPage
