import { Dispatch, Reducer, useEffect, useReducer } from 'react'
import IUnifiedApplicationState, { UnifiedApplicationStatus } from '../../types/IUnifiedApplicationState'
import {
  IUnifiedApplicationAction,
  UnifiedApplicationActionAfterNavigate,
  UnifiedApplicationActionApplicationSaved,
  UnifiedApplicationActionError,
  UnifiedApplicationActionLoaded,
  ErrorType
} from '../../reducers/unifiedApplicationActions'
import unifiedApplicationReducer from '../../reducers/unifiedApplicationReducer'
import { UnifiedApplicationState } from '../../types/UnifiedApplicationState'
import IApplication from '../../types/IApplication'
import saveUnifiedApplication, { isCurrentlyAutoSaving } from '../saveUnifiedApplication'
import axios, { AxiosRequestConfig } from 'axios'
import { RouteComponentProps } from 'react-router-dom'
import setReloadSafety from '../setReloadSafety'
import { IUnifiedApplicationParams } from '../../types/IUnifiedApplicationParams'
import { JourneyType } from '../../types/JourneyType'
import { RepresentativeJourneyType } from '../../types/RepresentativeJourneyType'
import { Config, getCopy } from 'src/applyfrontendcontent'
import { useGlobalContext } from '../../GlobalContext'
import { getConfigForPartnerType } from '../setPartnerLogo'
import { map } from '../../mappers/IApplicationToJourneyIdentifier'
import { applyServiceSuffix } from 'src/urlSettingsExports'
import { UserRole } from 'src/types/UserRole'

const validateStatus = (status: number): boolean => {
  return status >= 200 && status < 500
}

const axiosConfig: AxiosRequestConfig = {
  withCredentials: true,
  validateStatus
}

const defaultApplicationState: IUnifiedApplicationState = {
  isNew: true,
  id: '',
  journeyType: JourneyType.Standard,
  state: UnifiedApplicationState.Loading,
  status: UnifiedApplicationStatus.Unknown,
  representativeJourneyType: RepresentativeJourneyType.Director,
  hasSalesCookie: false,
  metadata: {},
  userRole: UserRole.Standard,
  hasBackgroundChecksTimeout: false
}

const useUnifiedApplicationJourney = (
  p: RouteComponentProps<IUnifiedApplicationParams>
): [IUnifiedApplicationState, Dispatch<IUnifiedApplicationAction<object>>, Config] => {
  const [state, dispatch] = useReducer<Reducer<IUnifiedApplicationState, IUnifiedApplicationAction<object>>>(
    unifiedApplicationReducer,
    defaultApplicationState
  )

  const { setPartnerData, setCopy, copy } = useGlobalContext()
  const dispatchErrors = (statusCode?: number) => {
    if (!statusCode || statusCode === 200 || statusCode === 201) {
      return
    }

    if (statusCode === 401) {
      dispatch(UnifiedApplicationActionError(ErrorType.Unauthorized))
    } else if (statusCode === 403) {
      dispatch(UnifiedApplicationActionError(ErrorType.Forbidden))
    } else {
      dispatch(UnifiedApplicationActionError(ErrorType.Generic))
    }
  }

  useEffect(() => {
    const handle = () => {
      // TODO: Decide how to handle the back button
      console.log('Handle back button here :)')
    }

    window.addEventListener('popstate', handle)

    return (): void => {
      window.removeEventListener('popstate', handle)
    }
  }, [])

  useEffect(() => {
    let unmounted = false
    const initialise = async () => {
      try {
        const response = await axios.get<IApplication>(
          `/${applyServiceSuffix()}/applications/${p.match.params.id}`,
          axiosConfig
        )

        if (unmounted) {
          return
        }

        let userRole: UserRole = UserRole.Standard
        if (response.headers && response.headers['user']) {
          userRole = response.headers['user']
        }

        if (response.status === 204) {
          dispatch(
            UnifiedApplicationActionLoaded(
              {
                applicationIdentifier: p.match.params.id,
                status: UnifiedApplicationStatus.Unknown,
                metadata: {}
              },
              true,
              userRole
            )
          )
        } else if (response.status === 200 && response.data) {
          if (response.data?.partnerType != null) {
            const partnerDetails = getConfigForPartnerType(state.partnerType, state.channelType)
            if (partnerDetails?.config !== undefined) setPartnerData(partnerDetails?.config)
          }
          const newCopy = getCopy(navigator.language, map(response.data))
          setCopy(newCopy)
          dispatch(UnifiedApplicationActionLoaded(response.data, false, userRole))
        } else {
          dispatchErrors(response.status)
        }
      } catch {
        dispatch(UnifiedApplicationActionError())
      }
    }

    initialise()
    return () => {
      unmounted = true
    }
  }, [p.match.params.id, setPartnerData, setCopy, state.partnerType, state.channelType])

  useEffect(() => {
    if (state.state !== UnifiedApplicationState.Navigate) {
      return
    }

    window.scroll(0, 0)
    dispatch(UnifiedApplicationActionAfterNavigate())
  }, [state.state])

  useEffect(() => {
    if (state.state !== UnifiedApplicationState.Submitting) {
      return
    }

    const save = async () => {
      const response = await saveUnifiedApplication(state)
      if (!response.success || !response.application) {
        dispatchErrors(response.status)
        return
      }
      setReloadSafety(false)
      dispatch(UnifiedApplicationActionApplicationSaved(response.success, response.application))
    }

    save()
  }, [state])

  useEffect(() => {
    if (state.state !== UnifiedApplicationState.AutoSaving) {
      return
    }

    isCurrentlyAutoSaving(true)
    const save = async () => {
      const response = await saveUnifiedApplication(state)
      dispatch(UnifiedApplicationActionApplicationSaved(response.success, null))
    }

    save()
  }, [state])

  return [state, dispatch, copy]
}

export default useUnifiedApplicationJourney
