export type Colors = {
  // Product UI colors
  midnight: string
  midnight08: string
  midnight12: string
  midnight16: string
  midnight20: string
  midnight25: string
  midnight50: string
  midnight70: string
  putty: string
  putty34: string
  putty46: string
  putty64: string
  putty78: string
  putty88: string
  purple: string
  purple12: string
  purple30: string
  purple46: string
  purple64: string
  purple78: string
  green: string
  green12: string
  green30: string
  green46: string
  green64: string
  green78: string
  yellow: string
  yellow12: string
  yellow30: string
  yellow46: string
  yellow64: string
  yellow78: string

  // Grey scale
  white: string
  black: string
  textGrey900: string
  textGrey800: string
  textGrey700: string
  textGrey600: string
  grey500: string
  grey400: string
  grey300: string
  grey200: string
  grey100: string
  grey50: string
  grey10: string
  navyBlue100: string
  navyBlue50: string

  // States
  info: string
  info08: string
  info16: string
  info24: string
  info30: string
  info70: string
  warning: string
  warning08: string
  warning16: string
  warning24: string
  warning30: string
  success: string
  success08: string
  success16: string
  success24: string
  success30: string
  error: string
  error08: string
  error16: string
  error24: string
  error30: string

  buttonDefault: string
  buttonFocusedBorder: string
  buttonActive: string
  buttonHover: string
  buttonFocused: string
  buttonTextDefault: string
  buttonOutlineDefault: string
  buttonOutlineDefaultText: string
  buttonTextOnDisabled: string
  buttonGhostOnActive: string

  linkDefault: string
  linkFocusedBorder: string
  linkActive: string
  linkHover: string
  linkVisited: string
  linkDisabled: string
  linkFocused: string
  borderFocused: string
  background: string
}

export const colors: Colors = {
  midnight: '#00283C',
  midnight08: 'rgba(0, 40, 60, 0.08)',
  midnight12: 'rgba(0, 40, 60, 0.12)',
  midnight16: 'rgba(0, 40, 60, 0.16)',
  midnight20: 'rgba(0, 40, 60, 0.20)',
  midnight25: 'rgba(0, 40, 60, 0.25)',
  midnight50: 'rgba(0, 40, 60, 0.50)',
  midnight70: 'rgba(0, 40, 60, 0.70)',
  putty: '#FFF5E3',
  putty34: 'rgba(255, 245, 227, 0.34)',
  putty46: 'rgba(255, 245, 227, 0.46)',
  putty64: 'rgba(255, 245, 227, 0.64)',
  putty78: 'rgba(255, 245, 227, 0.78)',
  putty88: 'rgba(255, 245, 227, 0.88)',
  purple: '#D059FF',
  purple12: 'rgba(208, 89, 255, 0.12)',
  purple30: 'rgba(208, 89, 255, 0.30)',
  purple46: 'rgba(208, 89, 255, 0.46)',
  purple64: 'rgba(208, 89, 255, 0.64)',
  purple78: 'rgba(208, 89, 255, 0.78)',
  green: '#00E043',
  green12: 'rgba(0, 224, 67, 0.12)',
  green30: 'rgba(0, 224, 67, 0.3)',
  green46: 'rgba(0, 224, 67, 0.46)',
  green64: 'rgba(0, 224, 67, 0.64)',
  green78: 'rgba(0, 224, 67, 0.78)',
  yellow: '#FFC800',
  yellow12: 'rgba(255, 200, 0, 0.12)',
  yellow30: 'rgba(255, 200, 0, 0.30)',
  yellow46: 'rgba(255, 200, 0, 0.46)',
  yellow64: 'rgba(255, 200, 0, 0.64)',
  yellow78: 'rgba(255, 200, 0, 0.78)',
  white: '#FFFFFF',
  black: '#000000',
  textGrey900: '#090909',
  textGrey800: '#222222',
  textGrey700: '#3C3C3C',
  textGrey600: '#606060',
  grey500: '#818181',
  grey400: '#979797',
  grey300: '#C2C2C2',
  grey200: '#DEDEDE',
  grey100: '#F0F0F0',
  grey50: '#F5F5F5',
  grey10: '#FAFAFA',
  navyBlue50: 'rgba(190, 207, 223, 0.5)',
  navyBlue100: 'rgba(190, 207, 223, 1)',
  info: '#073DC9',
  info08: 'rgba(7, 61, 201, 0.08)',
  info16: 'rgba(7, 61, 201, 0.16)',
  info24: 'rgba(7, 61, 201, 0.24)',
  info30: 'rgba(7, 61, 201, 0.30)',
  info70: 'rgba(7, 61, 201, 0.70)',
  warning: '#FFC107',
  warning08: 'rgba(255, 193, 7, 0.08)',
  warning16: 'rgba(255, 193, 7, 0.16)',
  warning24: 'rgba(255, 193, 7, 0.24)',
  warning30: 'rgba(255, 193, 7, 0.30)',
  success: '#198754',
  success08: 'rgba(25, 135, 84, 0.08)',
  success16: 'rgba(25, 135, 84, 0.16)',
  success24: 'rgba(25, 135, 84, 0.24)',
  success30: 'rgba(25, 135, 84, 0.30)',
  error: '#DC3545',
  error08: 'rgba(220, 53, 69, 0.08)',
  error16: 'rgba(220, 53, 69, 0.16)',
  error24: 'rgba(220, 53, 69, 0.24)',
  error30: 'rgba(220, 53, 69, 0.30)',

  buttonDefault: '#00283C',
  buttonFocusedBorder: '#00CF3B',
  buttonFocused: '#00283C',
  buttonActive: '#3F7090',
  buttonHover: '#32627F',
  buttonTextDefault: '#FFFFFF',
  buttonOutlineDefault: '#FFFFFF',
  buttonOutlineDefaultText: '#00283C',
  buttonTextOnDisabled: '#C2C2C2',
  buttonGhostOnActive: '#DCD7D7',

  linkDefault: '#090909',
  linkActive: '#3F7090',
  linkHover: '#32627F',
  linkVisited: '#7100E1',
  linkDisabled: '#C2C2C2',
  linkFocused: '#3F7090',
  linkFocusedBorder: '#00CF3B',
  borderFocused: '#3F7090',
  background: '#F2F2F2'
}
