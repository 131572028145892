import NumberToLocaleString from './NumberToLocaleString'

export const MAX_SAFE_INTEGER = Number.MAX_SAFE_INTEGER || 9007199254740991
const safeNumberConverter = (value: string | undefined): string | undefined => {
  if (value === undefined) return undefined
  const tmp = NumberToLocaleString.reverseMap(value)
  const num = tmp === undefined ? NaN : tmp
  if (isNaN(num) || num > MAX_SAFE_INTEGER || num < -MAX_SAFE_INTEGER) {
    return undefined
  }

  return tmp?.toString()
}

export default safeNumberConverter
