import { GetStartedContent } from '../../types/getStarted'

export const getStarted: GetStartedContent = {
  Heading: "Let's get started with your contact information",
  ShortHeading: 'Company details',
  Introduction: "Let's get started with your basic contact information. Fields with a * are required.",
  Name: {
    Text: 'Your name',
    Error: { Required: 'Please enter your name' }
  },
  EmailAddress: {
    Text: 'Your email address',
    Error: {
      Required: 'Please enter your email address',
      EmailMatchesPartner: 'Please enter the applying directors details',
      InvalidFormat: 'Please enter a valid email address'
    }
  },
  PhoneNumber: {
    Text: 'Your mobile phone number',
    Error: { Required: 'Please enter your phone number' }
  },
  CompanySearch: {
    CompanyName: {
      Text: 'Your company name or registration number',
      Error: { Required: 'Please provide the full name or registration number of the company' }
    },
    CompanyNameManual: {
      Text: 'Full company name',
      Error: { Required: 'Please provide the full name of the company' }
    },
    CompanyNumber: {
      Text: 'Company registration number',
      Tooltip: 'This is the unique 8 figure number assigned by Companies House when the company was formed.',
      Error: {
        Required: 'Please provide the company number',
        LengthIncorrect: 'Please enter exactly 8 characters'
      }
    },
    Searching: 'Searching...',
    AddManually: 'Not here? {AddManuallyButton}',
    AddManuallyButton: 'Add manually',
    EmptyResult: 'Sorry... we found nothing'
  },
  SideContent: {
    Heading: 'How we will use the information about you',
    LegalParagraph:
      'We will use your data to process your application, to allow us to provide finance products to your business and improve our sites. We may share your data with third parties to achieve these purposes. We will not share your data for marketing purposes. We will keep your data safe in accordance with data protection legislation. Information on how your data may be used is available on our {LegalParagraphButton}',
    LegalParagraphButton: 'Terms and Conditions.',
    ChangeYourMind: 'You can change your mind at any time by emailing {ChangeYourMindEmail}.',
    ChangeYourMindEmail: 'legal@kriya.co'
  },
  Closing: 'By applying for funding, you agree to our {PrivacyPolicyLink}.',
  PrivacyPolicyLink: 'Privacy Policy',
  ClosingLink: ''
}
