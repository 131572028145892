import React from 'react'
import StyledParagraph from './styledParagraph'

type Props = {
  dataTest?: string
} & React.ComponentPropsWithoutRef<'p'>

const Paragraph: React.FC<Props> = (props: Props) => {
  return <StyledParagraph data-test={props.dataTest} {...props}></StyledParagraph>
}

export default Paragraph
