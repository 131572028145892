export const minimumAmountInclusiveInvoiceFinance: string = '£5,000'
export const maximumAmountExclusiveInvoiceFinance: string = '£10,000,000'
export const minimumAmountInclusiveRecoveryLoans: string = '£10,000'
export const maximumAmountExclusiveRecoveryLoans: string = '£500,000'
export const minimumAmountInclusiveBusinessLoan: string = '£10,000'
export const maximumAmountExclusiveBusinessLoan: string = '£500,000'

export const invoiceFinanceMinLimit = 5_000
export const invoiceFinanceMaxLimit = 10_000_000

export const rlsLoanMinLimit = 10_000
export const rlsLoanMaxLimit = 500_000

export const businessLoanMinLimit = 10_000
export const businessLoanMaxLimit = 500_000
