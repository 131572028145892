import React from 'react'
import { IContent } from './IContent'
import { IErrorsState } from '../../../types/IErrorsState'
import { IUnifiedApplicationStepState } from '../../../types/IUnifiedApplicationState'
import { IDispatcher } from '../../../shared/hooks/useUnifiedApplicationStep'
import { UnifiedApplicationActionUpdateAndValidateField } from '../../../reducers/unifiedApplicationActions'
import { tokeniseLabelAsString, IndexableContent } from '../../../shared/content/DynamicContent'
import { FieldContent } from 'src/applyfrontendcontent'
import { YesNoInline, media } from 'src/ui-framework'
import { AllEligibilityContent } from 'src/applyfrontendcontent/types/eligibility'
import styled from 'styled-components'

type AllDynamicEligContent = AllEligibilityContent

interface IEligibilityRendererProps<TState extends IUnifiedApplicationStepState> {
  content: IContent<TState>[]
  dynamicContent: AllDynamicEligContent
  errors: IErrorsState
  state: TState
  dispatcher: IDispatcher
}

type StyledListItemProps = {
  theme: any
}

export const StyledListItem = styled.li<any>`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.fontSize.s};
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0;
  color: ${(props) => props.theme.colors.textGrey900};

  ${media.upToTablet`
      font-size: ${(props: StyledListItemProps) => props.theme.fontSize.xs};
  `}
`

const EligibilityQuestionList = styled('ol')`
  counter-reset: item;
  list-style: none;
  padding: 0;
`

const EligibilityQuestionWrapper = styled(StyledListItem)`
  display: flex;
  counter-increment: item;
  display: block;
  + li {
    margin-top: 32px;
  }
`

const EligibilityQuestionLabel = styled('label')`
  display: flex;
  gap: 16px;

  ::before {
    content: counter(item) '. ';
  }
`

const EligibilityQuestionRadios = styled('div')`
  margin-top: 16px;
`

const EligibilityRenderer = <TState extends IUnifiedApplicationStepState>(p: IEligibilityRendererProps<TState>) => {
  const content = p.dynamicContent

  return (
    <EligibilityQuestionList>
      {p.content.map((c) => {
        const { id } = c
        const label = tokeniseLabelAsString((content as IndexableContent)[c.dynamicContentKey] as FieldContent)

        return (
          <EligibilityQuestionWrapper key={id}>
            <EligibilityQuestionLabel>{label}</EligibilityQuestionLabel>
            <EligibilityQuestionRadios>
              <YesNoInline
                id={id}
                name={id}
                width="154px"
                key={id}
                legend={label}
                required
                errors={(p.errors[id] as string[]) || []}
                onChange={(isChecked: boolean) => {
                  p.dispatcher.action(UnifiedApplicationActionUpdateAndValidateField(id, isChecked))
                }}
                checked={p.state[id] as boolean | undefined}
                direction={'column'}
              />
            </EligibilityQuestionRadios>
          </EligibilityQuestionWrapper>
        )
      })}
    </EligibilityQuestionList>
  )
}

export default EligibilityRenderer
