import { IUnifiedApplicationStepState } from '../../../types/IUnifiedApplicationState'
import { IndexableContent } from '../../../shared/content/DynamicContent'

export enum ContentType {
  YesNo,
  TextArea,
  CurrencyInput,
  CurrencyInputWithoutIndent,
  FileUpload,
  RadioButton,
}

interface IOption {
  label: string
  value: string
}

export interface IContent<TState extends IUnifiedApplicationStepState> {
  type: ContentType
  id: string
  heading?: string
  dynamicContentKey: keyof IndexableContent
  subHeading?: JSX.Element
  condition?: (state: TState) => boolean
  options?: IOption[]
}
