import styled from 'styled-components'

type StyledTextAreaLengthProps = {
  theme: any
  overflows: boolean
}

export const StyledTextAreaLength = styled.span<StyledTextAreaLengthProps>`
  ${(props) => props.overflows && `color: ${props.theme.colors.error}`}
`
