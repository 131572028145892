import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import QueryString from 'query-string'
import { validate as uuidValidate } from 'uuid'

type QueryParams = {
  id?: string
}

const BarclaysReferralRedirect = () => {
  const { search } = useLocation()

  const queryParams: QueryParams = QueryString.parse(search, { decode: false })

  const applicationId = queryParams.id !== undefined && uuidValidate(queryParams?.id) ? queryParams.id : undefined

  return <Redirect to={`/application/${applicationId}/readytoapply`} />
}

export default BarclaysReferralRedirect
