import { colors } from '../../theme/colors'
import styled from 'styled-components'
import { media } from 'src/ui-framework/commonStyles/mediaQueries/responsiveDeviceSize'
import { LinkButton } from 'src/ui-framework'

type ProgressLayoutStyles = {
  future?: boolean
  selected?: boolean
}

export const ProgressList = styled.ol`
  list-style: none;
  font-size: 18px;
  padding-left: 0px;
`
export const StyledProgressItem = styled.li<ProgressLayoutStyles>`
  padding-bottom: ${(props) => props.theme.spacing.spacingS};
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: ${colors.midnight};
`
export const NumberCircle = styled.div<ProgressLayoutStyles>`
  height: 40px;
  font-family: 'Matter', sans-serif !important;
  font-size: 16px;
  font-weight: bold;
  width: 40px;
  line-height: 40px;
  border-radius: 40px;
  float: left;
  margin-right: 8px;
  text-align: center;
  background: ${(props) => (props.selected ? colors.midnight : 'white')};
  color: ${(props) => {
    if (props.future) {
      return props.theme.colors.uiGrey80
    }
    return props.selected ? 'white' : colors.midnight
  }};
  border: 2px solid ${(props) => (props.future ? 'transparent' : colors.midnight)};
`

export const StyledLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
  font-family: ${(props) => props.theme.fontFamily};
  &:visited,
  &:link {
    color: inherit;
  }
  &:focus {
    background-color: ${(props) => props.theme.colors.linkFocus};
    outline: none;
    padding: 0;
  }
  &:hover {
    color: ${(props) => props.theme.colors.uilinkoverBlue};
  }
`
export const StyledSpan = styled.span`
  font-family: ${(props) => props.theme.fontFamily};
`
export const RightContentWrapper = styled.div``
export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: ${(props) => props.theme.spacing.spacingS};
`

export const H1Wrapper = styled.div`
  width: 100%;
`

export const ProgressBarWrapper = styled.div`
  justify-content: center;
  display: flex;
  margin-bottom: 32px;
  margin-top: 16px;

  ${media.upToTablet`  
    margin-bottom: 16px; 
  `}
`

export type MainContentWrapperProps = {
  theme: any
  verticallyExtensible?: boolean
}

export const MainContentWrapper = styled.div<MainContentWrapperProps>`
  justify-content: center;
  display: flex;
  width: 100%;

  ${media.upToTablet`
    ${(props: MainContentWrapperProps) =>
      props.verticallyExtensible &&
      `
      height: calc(100vh - 80px);
      background-color: white;
    `}
  `}
`

export const MainContent = styled.div`
  background-color: white;
  width: 560px;
  padding: 32px 28px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin-bottom: 16px;
  height: fit-content;

  ${media.upToTablet`
    padding: 24px 16px; 
  `}
`

export const FooterWrapper = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;
`

export const BackButton = styled(LinkButton)`
  min-width: 0;
  padding: 0 16px 0 0;
  margin: 0;
  margin-bottom: 32px;
`

export const BackButtonText = styled.span`
  font-size: 14px;
`
