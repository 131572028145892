import { Button, LinkButton, media, Paragraph } from 'src/ui-framework'
import styled from 'styled-components'

export const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  background-color: white;
`

export const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;

  flex-direction: column;
  gap: 29px;
  padding-top: 44px;
  padding-bottom: 44px;

  ${media.upToTablet`
        gap: 0;
        max-width: 560px;
        padding-left: 16px;
        padding-right: 16px;
    `}
`

export const Header = styled.div`
  display: flex;
  justify-content: start;
  gap: 64px;
  align-items: end;
  flex-wrap: wrap;

  ${media.upToLaptop`
        gap: 64px;
    `}

  ${media.upToTablet`
        justify-content: space-between;
    `}
`

export const ContentWrapper = styled.div`
  flex-direction: row;
  display: flex;
`

export const MainContent = styled.div`
  width: 560px;
  max-width: 560px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;

  ${media.upToTablet`
        width: auto;
    `}
`

export const AdditionalContent = styled.div`
  width: 560px;
  flex: 1;

  ${media.upToLaptop`
        display: none;
    `}
`

export const KriyaLogo = styled.img`
  height: 32px;
`

export const BarclaysPartnershipLogo = styled.img`
  height: 56px;
`

export const KriyaHeroImg = styled.img`
  height: 427px;
  width: 560px;
  position: relative;
  z-index: 1;
`

export const RelativePositionWrapper = styled.div`
  position: relative;
`

export const GreenBox = styled.div`
  position: absolute;
  height: 357px;
  width: 514px;
  background-color: #00e043;
  top: -272px;
  z-index: 0;
  left: 246px;
  border-radius: 30px;
`

export const MainContentTexts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 64px;
  padding-right: 20px;
  ${media.upToTablet`
        padding-top: 48px;
        padding-right: 0;
    `}
`

export const WelcomeSpan = styled.span`
  font-size: ${(props: any) => props.theme.fontSize.m};
  font-weight: 500;
  line-height: 28px;
`

export const StyledParagraph = styled(Paragraph)`
  margin: 0;
`

export const ButtonsSection = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 120px;
  ${media.upToTablet`
        flex-direction: column-reverse;
        margin-top: 48px;
    `}
`

export const StyledLinkButton = styled(LinkButton)`
  font-family: ${(props) => props.theme.fontFamily};
  padding: 0;
  font-weight: 600;
  padding: 12px 24px;
  ${media.upToTablet`
        margin: 0 auto;
        display: block;
    `}
`

export const StyledButton = styled(Button)`
  margin: 0;
`
