import React, { useState, useEffect } from 'react'
import { ReadyToApplyProductContent } from './ReadyToApplyContent'
import { RepresentativeJourneyType } from '../../../types/RepresentativeJourneyType'
import { SetHotjarPageData } from './hotjarJourneyTypePageView'
import { DynamicContent, tokeniseOptions } from '../../../shared/content/DynamicContent'
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom'
import { PartnerType } from '../../../types/PartnerType'
import { barclaysLogoDetails, getConfigForPartnerType } from '../../../shared/setPartnerLogo'
import { useGlobalContext } from '../../../GlobalContext'
import {
  BritishBankImage,
  FooterMarketing,
  TrustPilotImageWrapper
} from '../../../commonStyles/components/unifiedJourneyFooter'
import { BritishBankLogo } from '../../../commonStyles/images/images'
import styled from 'styled-components'
import TrustPilotBox from 'src/shared/components/footer/TrustPilot'
import { H1 } from 'src/commonStyles/components/headings'
import { partnerEmail } from 'src/constants'
import {
  AboutUsListWrapper,
  ReadyToApplyMainContentWrapper,
  ReadyToApplySideContentWrapper,
  ReadyToApplyWrapper,
  RequirementsListWrapper,
  StyledHeader,
  StyledLi,
  StyledUl
} from './ReadyToApplyStyles'
import { media } from 'src/ui-framework'
import { ChannelType } from 'src/types/ChannelType'
import useUnifiedApplicationJourney from 'src/shared/hooks/useUnifiedApplicationJourney'
import saveUnifiedApplication from 'src/shared/saveUnifiedApplication'
import { EligibilityOutcome } from 'src/types/EligibilityOutcome'
import { IUnifiedApplicationParams } from 'src/types/IUnifiedApplicationParams'
import { JourneyType } from 'src/types/JourneyType'
import { UnifiedJourneyProductType } from 'src/types/ProductType'

interface PartnerParams {
  id: string
}

interface IApiApplication {
  journey: JourneyType
  isRevolvingCreditSelected: boolean
  isStandardIFSelected: boolean
  representativeJourney: RepresentativeJourneyType
}

const StyledH1 = styled(H1)`
  margin-top: 16px;
  line-height: 36px;
`

const StyledHeading = styled(StyledH1)`
  font-size: 26px;
  padding-left: 0px;
  ${media.upToTablet`
    margin-left: 17px;
    font-size: 20px;
    line-height: 28px;
  `}
`

const Heading = { Component: StyledHeading }
const WelcomePartner = { Component: StyledHeading }

const SamlReadyToApply = (p: RouteComponentProps<IUnifiedApplicationParams>) => {
  const params = useParams<PartnerParams>()
  const history = useHistory()
  const [state] = useUnifiedApplicationJourney(p)
  const { copy, setPartnerData } = useGlobalContext()
  const [repJourneyChoiceErrors, setErrors] = useState<string[]>([])
  const partnerDetails = getConfigForPartnerType(PartnerType.Barclays, ChannelType.Api)

  state.id = params.id
  useEffect(() => {
    if (state.partnerType === PartnerType.Barclays) {
      setPartnerData(barclaysLogoDetails)
    }
  }, [state.partnerType, setPartnerData])

  const content = copy
  let productWiseContent = content.ReadyToApplyInvoiceFinance

  const journeyOptions = tokeniseOptions(productWiseContent.Affiliation!)
  const [repJourney, setRepJourney] = useState<RepresentativeJourneyType>(
    journeyOptions[0].value as RepresentativeJourneyType
  )

  const updateApplication = async (p: IApiApplication) => {
    SetHotjarPageData(state.hasSalesCookie, state.partnerType, state.channelType, p.representativeJourney)

    state.journeyType = p.journey
    if (state.products !== undefined) {
      if (!state.products[UnifiedJourneyProductType.SelectInvoiceDiscounting]) {
        state.products[UnifiedJourneyProductType.SelectInvoiceDiscounting] = {
          isSelected: p.isStandardIFSelected,
          eligibility: EligibilityOutcome.InsufficientData
        }
      } else {
        state.products[UnifiedJourneyProductType.SelectInvoiceDiscounting].isSelected = p.isStandardIFSelected
      }
    }

    state.representativeJourneyType = p.representativeJourney

    const response = await saveUnifiedApplication(state)

    if (!response.success || !response.application) {
      history.push({
        pathname: '/error'
      })
      return
    }
    history.push({
      pathname: `/application/${params.id}`
    })
  }

  const startApplication = () => {
    let application: IApiApplication = {
      journey: JourneyType.BarclaysStandard,
      isRevolvingCreditSelected: false,
      isStandardIFSelected: true,
      representativeJourney: repJourney!
    }
    updateApplication(application)
  }

  const handleStandardClick = () => {
    startApplication()
  }

  const handleEmailUsClick = () => {
    window.location.href = `mailto:${partnerEmail}`
  }

  const handleRepJourneySelect = (value: string) => {
    if (!(value in RepresentativeJourneyType)) return
    setErrors([])
    setRepJourney(value as RepresentativeJourneyType)
  }

  let comp: React.ReactNode

  comp = (
    <>
      <StyledHeader>
        {productWiseContent.Dynamic?.PartnerCompanyName ? (
          <DynamicContent
            components={{
              WelcomePartner
            }}
            content={productWiseContent}
          />
        ) : (
          <DynamicContent
            components={{
              Heading
            }}
            content={productWiseContent}
          />
        )}
      </StyledHeader>
      <ReadyToApplyWrapper>
        <ReadyToApplyMainContentWrapper>
          <ReadyToApplyProductContent
            onStandardClick={handleStandardClick}
            onEmailUsClick={handleEmailUsClick}
            isSalesInitiated={false}
            onRepresentiveSelection={handleRepJourneySelect}
            journeyChoice={repJourney}
            content={productWiseContent}
            errors={repJourneyChoiceErrors || []}
            partnerDetails={partnerDetails}
          />
        </ReadyToApplyMainContentWrapper>
        <ReadyToApplySideContentWrapper>
          <RequirementsListWrapper>
            {content.ReadyToApply.RequirementsHeading}
            <StyledUl>
              {content.ReadyToApply.Requirements?.map((r, i) => (
                <StyledLi key={i}>{r}</StyledLi>
              ))}
            </StyledUl>
          </RequirementsListWrapper>
          <AboutUsListWrapper>
            {content.ReadyToApply.AboutUsHeading}
            <StyledUl>
              {content.ReadyToApply.AboutUsItems?.map((r, i) => (
                <StyledLi key={i}>{r}</StyledLi>
              ))}
            </StyledUl>
          </AboutUsListWrapper>
          <FooterMarketing>
            <BritishBankImage src={BritishBankLogo} alt={content.ReadyToApply.BritishBankImageAltText} />
            <TrustPilotImageWrapper>
              <TrustPilotBox />
            </TrustPilotImageWrapper>
          </FooterMarketing>
        </ReadyToApplySideContentWrapper>
      </ReadyToApplyWrapper>
    </>
  )

  return <>{comp}</>
}

export default SamlReadyToApply
