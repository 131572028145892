import IApplicant from '../types/IApplicant'
import IApplicantState from '../types/IApplicantState'

class IApplicantToIApplicantStateMapperImpl {
  map = (i: IApplicant): IApplicantState => {
    const { name, email, phoneNumber } = i
    return {
      name,
      email,
      phoneNumber,
      addedCompanyManually: i.companyCreatedManually,
    }
  }

  reverseMap = (i: IApplicantState): IApplicant => {
    const { name, email, phoneNumber } = i
    return {
      name,
      email,
      phoneNumber,
      companyCreatedManually: i.addedCompanyManually,
    }
  }
}

const IApplicantToIApplicantStateMapper = new IApplicantToIApplicantStateMapperImpl()
export default IApplicantToIApplicantStateMapper
