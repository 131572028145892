import React, { useState } from 'react'
import styled from 'styled-components'
import ErrorList from '../../ErrorList'
import LabelWrapper from '../labelWrapper'
import InputWrapper from '../inputWrapper'
import TextWrapper from '../textWrapper'
import { media } from '../../../../commonStyles/mediaQueries/responsiveDeviceSize'

const Container = styled.div<any>`
  margin-bottom: 32px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`

type YesNoContainerProps = {
  direction: 'column' | 'row'
  focused?: boolean
  mouseDown: boolean
  errors: string[] | undefined
}

const YesNoContainer = styled.div<YesNoContainerProps>`
  display: flex;
  border-radius: 4px;
  flex-direction: ${(props) => props.direction};
`
const StyledLabelWrapper = styled(LabelWrapper)<any>`
  border: none;
  background-color: transparent;
  padding: 0;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  ${media.upToTablet`
    margin-bottom: 16px;
    &:hover {    
      border: none;
      background-color: transparent;  
    }
  `}
`
type OwnProps = {
  legend: string
  name: string
  onChange: (...args: any[]) => any
  checked?: boolean
  subLegend?: string | React.ReactElement
  width?: string
  animate?: boolean
  required?: boolean
  errors?: string[]
  style?: any
  dataTest?: string
  id?: string
  direction?: 'column' | 'row'
}
type Props = OwnProps

const YesNoInline = (props: Props) => {
  const { width, animate, name, onChange, checked, errors, style, id, dataTest, direction } = props
  const [focused, setFocused] = useState<boolean | undefined>(undefined)
  const [mouseDown, setMouseDown] = useState(false)
  const handleMouseDown = () => {
    setMouseDown(true)
  }
  const handleMouseUp = () => {
    setMouseDown(false)
  }
  const handleOnFocus = (e: React.FocusEvent<any>) => {
    setFocused(checked ?? true)
  }
  const handleOnBlur = () => {
    setFocused(undefined)
  }
  const localOnChange = (e: any) => {
    const checked = e.target.value === 'yes'
    onChange(checked)
    setFocused(checked ?? false)
  }

  return (
    <Container errors={errors} style={style} id={id} data-test={dataTest} role="group">
      <div style={{ width }}>
        <YesNoContainer direction={direction!} mouseDown={mouseDown} errors={errors}>
          <StyledLabelWrapper
            focused={focused === true}
            animate={animate}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            <InputWrapper>
              <input
                name={name}
                id={`${id}-yes`}
                type="radio"
                value="yes"
                onChange={localOnChange}
                checked={checked === true}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                aria-invalid={errors!.length > 0}
                aria-describedby={ErrorList.getDescribedByAttribute(errors!)}
              />
              <i></i>
            </InputWrapper>
            <TextWrapper htmlFor={`${id}-yes`}>Yes</TextWrapper>
          </StyledLabelWrapper>
          <StyledLabelWrapper
            focused={focused === false}
            animate={animate}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            <InputWrapper>
              <input
                name={name}
                id={`${id}-no`}
                type="radio"
                value="no"
                onChange={localOnChange}
                checked={checked === false}
                onFocus={handleOnFocus}
                onBlur={handleOnBlur}
                aria-invalid={errors!.length > 0}
                aria-describedby={ErrorList.getDescribedByAttribute(errors!)}
              />
              <i></i>
            </InputWrapper>
            <TextWrapper htmlFor={`${id}-no`}>No</TextWrapper>
          </StyledLabelWrapper>
        </YesNoContainer>
      </div>
      <div style={{ marginTop: '12px' }}>
        <ErrorList errors={errors} id={id!} />
      </div>
    </Container>
  )
}
YesNoInline.defaultProps = {
  required: false,
  errors: [],
  width: '100%',
  animate: false,
  style: {},
  direction: 'row'
}
export default YesNoInline
