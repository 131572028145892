const getDecimal = (): string => {
  const v = 1.1
  return v.toLocaleString()?.split('1')[1] || '.'
}
const decimal = getDecimal()
const cleanRegex = new RegExp(`[^0-9${decimal}]`, 'gi')

class NumberToUnlimitedLocaleStringImpl {
  map(i: number | undefined): string {
    if (i === undefined) return ''
    return i.toLocaleString()
  }

  reverseMap(i: string, allowNegative: boolean = true, allowFloats: boolean = false): number | undefined {
    let cleanedInput = i.replace(cleanRegex, '')
    if (cleanedInput === '') {
      return undefined
    }
    if (allowNegative && i.indexOf('-') !== -1 && i.indexOf('-') < i.indexOf(cleanedInput[0])) {
      cleanedInput = '-' + cleanedInput
    }

    return Number(cleanedInput)
  }

  cleanString(i: string, allowNegative: boolean = true): string {
    if (i === '') return i
    if (i === '-') return allowNegative ? '-' : ''
    const numValue = this.reverseMap(i, allowNegative)
    return numValue === undefined ? '' : this.map(numValue)
  }
}

const NumberToUnlimitedLocaleString = new NumberToUnlimitedLocaleStringImpl()
export default NumberToUnlimitedLocaleString
