import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ChannelType } from 'src/types/ChannelType'
import { useInitialisationParameters } from './useInitialisationParameters'
import PartnerReadyToApply from './PartnerReadyToApply'
import { IUnifiedApplicationParams } from 'src/types/IUnifiedApplicationParams'
import SamlReadyToApply from './SamlReadyToApply'

const PartnerCustomerReadyToApplyRouter = (p: RouteComponentProps<IUnifiedApplicationParams>) => {
  const initialisationParameters = useInitialisationParameters()

  if (initialisationParameters.channel === ChannelType.Api) {
    return <SamlReadyToApply {...p} />
  }

  return <PartnerReadyToApply {...p}></PartnerReadyToApply>
}

export default PartnerCustomerReadyToApplyRouter
