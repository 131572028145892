import UnifiedApplicationBaseReducer from '../unifiedApplicationBaseReducer'
import {
  IUnifiedApplicationAction,
  UnifiedApplicationActionType,
  UnifiedApplicationActionUpdateState
} from '../unifiedApplicationActions'
import { flattenErrors, IErrorsState } from '../../types/IErrorsState'
import { UnifiedApplicationState } from '../../types/UnifiedApplicationState'
import { getOutcome } from '../../shared/components/eligibilityResultsRenderer/EligibilityResultsRenderer'
import { outcomesLookupOrder } from 'src/pages/unifiedApplicationJourney/eligibility/results/eligible/eligibilityOutcomeLookups'
import IPartnerEligibilityResultsState from 'src/types/partner/IPartnerEligibilityResultsState'
import { tokeniseToString } from 'src/shared/content/DynamicContent'

export default class PartnerEligibilityResultsReducer extends UnifiedApplicationBaseReducer<IPartnerEligibilityResultsState> {
  onLoad = async (
    state: IPartnerEligibilityResultsState,
    dispatch: React.Dispatch<IUnifiedApplicationAction<object>>
  ): Promise<void> => {
    dispatch(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Pristine))
  }

  onValidate = (state: IPartnerEligibilityResultsState): IErrorsState => {
    const content = this.getContent(state)
    let errors: IErrorsState = {
      hasErrors: false
    }

    if (state.notepadReviewed !== true) {
      errors.hasErrors = true
      errors['notepadReviewed'] = [
        tokeniseToString(
          content.EligibilityResult.EligibleForInvoiceFinanceOnlyByPartner?.ConsentQuestions.Notepad.Error || {},
          'Required'
        )
      ]
    }

    if (state.overdraftReviewed !== true) {
      errors.hasErrors = true
      errors['overdraftReviewed'] = [
        tokeniseToString(
          content.EligibilityResult.EligibleForInvoiceFinanceOnlyByPartner?.ConsentQuestions.Overdraft.Error || {},
          'Required'
        )
      ]
    }

    if (state.disclosureReviewed !== true) {
      errors.hasErrors = true
      errors['disclosureReviewed'] = [
        tokeniseToString(
          content.EligibilityResult.EligibleForInvoiceFinanceOnlyByPartner?.ConsentQuestions.Disclosure.Error || {},
          'Required'
        )
      ]
    }

    if (state.bbbInfoReviewed !== true) {
      errors.hasErrors = true
      errors['bbbInfoReviewed'] = [
        tokeniseToString(
          content.EligibilityResult.EligibleForInvoiceFinanceOnlyByPartner?.ConsentQuestions.BbbInfo.Error || {},
          'Required'
        )
      ]
    }

    return flattenErrors(errors)
  }

  handleDefaultAction = (
    prevState: IPartnerEligibilityResultsState,
    newState: IPartnerEligibilityResultsState,
    action: IUnifiedApplicationAction<object>
  ): void => {
    switch (action.type) {
      case UnifiedApplicationActionType.Loaded:
        newState.outcome = getOutcome(newState.products, outcomesLookupOrder)
        newState.state = UnifiedApplicationState.Pristine
        break
    }
  }
  onBeforeSubmit = async (state: IPartnerEligibilityResultsState): Promise<void> => {}
}
