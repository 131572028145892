import { Config } from '../types/locale'
import { footer } from './Footer'
import { linkExpired } from './LinkExpiredError'
import { applicationDeadlineError } from './ApplicationDeadlineError'
import { multipleApplicationError } from './MultipleApplicationError'
import { unauthorisedApplicationError } from './UnauthorisedApplicationError'
import { saveForLater } from './SaveForLater'
import { sharedContent } from './Shared'
import { readyToApply } from './ReadyToApply'
import { readyToApplyInvoiceFinance } from './ReadyToApplyInvoiceFinanceOnly'
import { recoveryLoanFundingNeeds } from './RecoverLoanFundingNeeds'
import { genericError } from './GenericError'
import { partnerReferralIdMissing } from './PartnerReferralIdMissing'
import { Requirements, GenericProductRequirements } from './Requirements'
import { thankYou } from './ThankYou'
import { notSupportedThankYou } from './NotSupportedThankYou'
import { eligibility } from './Eligibility'
import { eligibilityResult } from './EligibilityResult'
import { ineligibilityResult } from './IneligibleResult'
import { unauthenticatedError } from './UnauthenticatedError'

export const sharedCopy: Partial<Config> = {
  Footer: footer,
  LinkExpiredError: linkExpired,
  MultipleApplicationError: multipleApplicationError,
  UnauthorisedError: unauthorisedApplicationError,
  ApplicationDeadlineError: applicationDeadlineError,
  SaveForLater: saveForLater,
  Shared: sharedContent,
  ReadyToApply: readyToApply,
  ReadyToApplyInvoiceFinance: readyToApplyInvoiceFinance,
  RecoveryLoanFundingNeeds: recoveryLoanFundingNeeds,
  GenericError: genericError,
  ExitApplication: null,
  PartnerReferralIdMissing: partnerReferralIdMissing,
  Requirements: Requirements,
  GenericProductRequirements: GenericProductRequirements,
  ThankYou: thankYou,
  NotSupportedThankYou: notSupportedThankYou,
  Eligibility: eligibility,
  EligibilityResult: eligibilityResult,
  Ineligible: ineligibilityResult,
  UnauthenticatedError: unauthenticatedError
}
