import React, { Component } from 'react'

import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

import { default as Navigation } from '../../components/Navigation'
import { default as StyledContainer } from './container'

type OwnProps = {
  classes?: Partial<ClassNameMap<'navigation' | 'logo'>>
  showFrame?: boolean
  navigationItems?: {
    label: string
    url: string
  }[]
  dropdownItems?: {
    label: string
    url: string
  }[]
  username?: string
  partnerData?: {
    linkUrl?: string
    logoAlt?: string
    logoUrl?: string
  }
  contentClasses?: Partial<ClassNameMap<'root'>>
}

type Props = OwnProps & React.ComponentPropsWithoutRef<'div'>

class Layout extends Component<Props> {
  static defaultProps: OwnProps = {
    classes: {},
    showFrame: true,
    navigationItems: undefined,
    dropdownItems: undefined,
    username: undefined,
    partnerData: undefined,
    contentClasses: {}
  }

  render() {
    const { children, showFrame, partnerData, className } = this.props

    const theme = createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 540,
          md: 768,
          lg: 1140,
          xl: 1280
        }
      },
      typography: {
        fontFamily: '"Open Sans", "Arial", sans-serif'
      },
      palette: {
        primary: {
          main: '#0074a3',
          dark: '#005579'
        },
        secondary: {
          main: '#dcd7d7',
          dark: '#b9b9b9'
        }
      }
    })

    const { classes, navigationItems, dropdownItems, username, contentClasses } = this.props

    return (
      <MuiThemeProvider theme={theme}>
        <div className={className} style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
          {showFrame && (
            <Navigation
              classes={classes}
              items={navigationItems}
              username={username}
              dropdownItems={dropdownItems}
              partnerData={partnerData}
            />
          )}

          <StyledContainer classes={contentClasses}>{children}</StyledContainer>
        </div>
      </MuiThemeProvider>
    )
  }
}

export default Layout
