import { FundingNeedsContent } from '../../types/fundingNeeds'

export const fundingNeeds: FundingNeedsContent = {
  Heading: 'We need to know more about your application for invoice finance',
  ShortHeading: 'Funding needs',
  Introduction:
    "Now, tell us a bit about how much funding you want and what you're looking to use it for. Getting to know your funding needs will help us make sure you get the right solution to meet them. Fields with a * are required.",
  FundingReason: {
    Text: 'What do you want to use the funding for?',
    Options: {
      WorkingCapital: {
        Text: 'Working capital'
      },
      Investment: {
        Text: 'Investment/growth'
      },
      RefinancingRLSFacility: {
        Text: 'Refinancing a RLS facility'
      },
      RefinancingMarketFinanceDebt: {
        Text: 'Refinancing Kriya (formerly MarketFinance) debt'
      },
      RefinancingAnyOtherDebt: {
        Text: 'Refinancing any other debt'
      },
      Other: {
        Text: 'Other'
      }
    },
    Error: { Required: 'Please select a reason for funding' }
  },
  ReasonForFunding: {
    Text: 'What do you want to use the funding for?',
    Options: {
      WorkingCapital: {
        Text: 'Working capital'
      },
      Investment: {
        Text: 'Investment/growth'
      },
      RefinancingRLSFacility: {
        Text: 'Refinancing a RLS facility'
      },
      RefinancingMarketFinanceDebt: {
        Text: 'Refinancing Kriya (formerly MarketFinance) debt'
      },
      RefinancingAnyOtherDebt: {
        Text: 'Refinancing any other debt'
      },
      Other: {
        Text: 'Other'
      }
    },
    Error: { Required: 'Please select a reason for funding' }
  },
  FundingOther: {
    Text: '',
    Error: {
      Required: 'Please enter a reason for funding'
    }
  },
  FundingTimeline: {
    Text: 'When do you need the funding?',
    Options: {
      RightNow: { Text: 'Right now' },
      WithinOneMonth: { Text: 'Within 1 month' },
      OneToTwoMonths: { Text: '1-2 months' },
      TwoToThreeMonths: { Text: '2-3 months' },
      LongerThanThreeMonths: { Text: 'Longer than 3 months' },
      DontKnow: { Text: "Don't know" }
    },
    Error: { Required: 'Please select a funding timeline' }
  },
  OtherInfo: {
    Text: "Is there anything else you'd like us to know about your funding requirements? (optional)",
    Tooltip:
      'By providing us with details around the challenges you face in financing your business, we are able to provide a funding solution to better meet your needs.'
  },
  FundingSolutionsTitle: 'Funding Solutions',
  FundingSolutionsLoanGroup: {
    Text: 'Are you interested in taking out a loan?',
    Options: {
      RecoveryLoansScheme: {
        Text: 'Recovery Loan Scheme'
      },
      BusinessLoan: {
        Text: 'Business loan'
      }
    }
  },
  FundingSolutionsInvoiceFinanceGroup: {
    Text: 'Are you interested in taking out invoice financing?',
    Options: {
      InvoiceFinance: {
        Text: 'Invoice finance'
      }
    }
  },
  FundingSolutions: {
    Text: 'Please select the funding solution(s) you’d like to apply for',
    Options: {
      InvoiceFinance: {
        Text: 'Invoice Finance',
        Tooltip:
          'A flexible way to bridge gaps in cash flow by advancing the funds already owed to the company in outstanding invoices.'
      },
      RecoveryLoansScheme: {
        Text: 'Business loan (incl. RLS)'
      },
      BusinessLoan: {
        Text: 'Business Loan'
      }
    },
    Error: { Required: 'Please select at least one funding solution.' }
  },
  InvoiceFinance: {
    BorrowingAmount: {
      Text: 'How much do you want to borrow?',
      Tooltip:
        "Not sure of exactly how much funding is needed? As a guide, the funding limit is usually a similar size to the company's average receivables book or 5-10% of the company's annualised revenue.",
      Error: {
        Required:
          'Please enter a funding amount between {Dynamic.MinimumAmountInclusive} and {Dynamic.MaximumAmountInclusive}'
      }
    }
  },
  RecoveryLoansScheme: {
    BorrowingAmount: {
      Text: 'How much do you want to borrow? We provide business loans from {Dynamic.MinimumAmountInclusive} to {Dynamic.MaximumAmountInclusive}.',
      Error: {
        Required: 'Please enter an amount between {Dynamic.MinimumAmountInclusive} and {Dynamic.MaximumAmountInclusive}'
      }
    },
    Duration: {
      Text: 'What is your preferred loan duration?',
      Options: {
        '24': {
          Text: '2 years'
        },
        '36': {
          Text: '3 years'
        },
        '48': {
          Text: '4 years'
        },
        '60': {
          Text: '5 years'
        },
        '72': {
          Text: '6 years'
        }
      },
      Error: { Required: 'Please select a loan duration' }
    },
    Repayment: {
      Introduction: "You'll make interest only repayments during the first 6 months.",
      SubIntroduction:
        "Before accepting any offer, we'll show you a full loan repayment schedule so you can see exactly what you'll need to pay and when."
    }
  },
  BusinessLoan: {
    BorrowingAmount: {
      Text: 'How much do you want to borrow? We provide business loans from {Dynamic.MinimumAmountInclusive} to {Dynamic.MaximumAmountInclusive}.',
      Error: {
        Required: 'Please enter an amount between {Dynamic.MinimumAmountInclusive} and {Dynamic.MaximumAmountInclusive}'
      }
    },
    Duration: {
      Text: 'What is your preferred loan duration?',
      Options: {
        '12': {
          Text: '1 year'
        },
        '24': {
          Text: '2 years'
        },
        '36': {
          Text: '3 years'
        },
        '48': {
          Text: '4 years'
        },
        '60': {
          Text: '5 years'
        }
      },
      Error: { Required: 'Please select a loan duration' }
    },
    Repayment: {
      Introduction: "You'll make interest only repayments during the first 6 months.",
      SubIntroduction:
        "Before accepting any offer, we'll show you a full loan repayment schedule so you can see exactly what you'll need to pay and when."
    }
  },
  FundingAmountHeading: 'Amount',
  FundingAmount: {
    Text: 'How much funding does the business need?',
    Tooltip:
      'Let us know how much money you need today to meet your finance needs. We’ll work with you to increase your funding over time if you need to. We’ll also suggest additional or alternative solutions if your requirements change.',
    Error: {
      Required:
        'Please enter a funding amount between £{Dynamic.MinimumAmountInclusive} and £{Dynamic.MaximumAmountInclusive}',
      LessThanMinimum: 'Please enter a funding amount greater than {Dynamic.MinimumAmount}',
      GreaterThanMaximum: 'Please enter a funding amount less than {Dynamic.MaximumAmount}',
      Invalid: 'Please enter a valid number'
    }
  },
  MultiProductLoanSelection: {
    Heading: 'Select funding solution',
    Introduction:
      'Please choose either Business loan or Recovery Loan Scheme. You can combine either option with Invoice finance.',
    Body: '',
    Action: 'Are you sure you want to change your selection?',
    CancelButton: 'Cancel',
    SendButton: 'Change Selection'
  },
  Turnover: {
    Text: 'The company’s annual turnover',
    Options: {
      UpTo250: {
        Text: '£100-£250k'
      },
      UpTo500: {
        Text: '£250-500k'
      },
      UpTo1Mil: {
        Text: '£500k-£1m'
      },
      UpTo2Mil: {
        Text: '£1-2m'
      },
      UpTo3Mil: {
        Text: '£2-3m'
      },
      UpTo5Mil: {
        Text: '£3-5m'
      },
      MoreThan5Mil: {
        Text: '>£5m'
      }
    },
    Error: { Required: 'Please select company’s annual turnover' }
  },
  Timeline: {
    Text: 'When do you require funding?',
    Options: {
      Immediate: {
        Text: 'Immediate (within the next 2 weeks)'
      },
      VerySoon: {
        Text: 'Very soon (within the next 30 days)'
      },
      Planning: {
        Text: 'Planning (within the next 60 days)'
      },
      Exploring: {
        Text: 'Exploring options (no specific requirement but keen to understand more about potential solutions)'
      }
    },
    Error: { Required: 'Please select funding timeline' }
  },
  AdditionalInformation: {
    Text: 'Feel free to use this space to share any additional pertinent information, including:',
    Suggestions: [
      'Purpose of funds needed.',
      'Current funding sources for the business.',
      'Specific debtors or invoices requiring funding.'
    ],
    Error: {
      Required: 'Please provide additional information about the business'
    }
  }
}
