import { ReadyToApplyContent } from '../../types/readyToApply'

export const readyToApply: ReadyToApplyContent = {
  Heading: 'Before starting your application',
  Introduction: [
    'Barclays partnered with Kriya to give customers direct access to invoice finance solutions that help you manage cash flow or accelerate growth. Our fast and flexible funding solutions you can choose between are:'
  ],
  ProductBulletPoints: [
    'Non-CBILS invoice finance: Pick and choose which invoice(s) to fund, without having to commit your entire sales ledger'
  ],

  SubIntroduction:
    "Want to learn more? {HelpButton} with a member of our team to chat about which solution works best for you and your business. In any case, we always give you a call once you've sent through your details.",
  Help: "We're always here to help. {HelpButton} to get in touch.",
  HelpButton: 'Click here',
  ApplicationTime:
    "We've kept our application as short as possible, with no obligations or hidden fees. And it doesn’t take more than 10 minutes.",
  ApplyTitle: 'What You Need To Apply',
  Products: {
    InvoiceFinance: {
      Introduction: "For non-CBILS invoice finance, you'll also need:",
      IntroductionToolTip:
        'Unlock the cash tied up in your invoices and boost your working capital. We offer subscription and pay-as-you-go facilities, depending on your cash flow needs. So you can focus on the bigger picture and get on with running your business.',
      ListWithTooltips: [
        {
          Text: 'Bank statements from {Dynamic.InvoiceFinance.BankStatementStartDate} until at least {Dynamic.InvoiceFinance.BankStatementEndDate}'
        }
      ],
      ButtonText: 'Start application',
      BulletPointsImageAltText: 'Requirement'
    }
  },
  Affiliation: {
    Title: 'A bit about you',
    Text: "What's your relationship with the company?",
    Options: {
      Director: { Text: "I'm a director of the limited company" },
      Representative: { Text: "I'm employed by the limited company" }
    },
    Error: {
      Required: "Please tell us your relationship with the business you're applying for"
    }
  },
  RequirementsHeading: 'Please ensure you have the following information ready:',
  Requirements: [
    'Company name and registration number',
    'Applying Director’s date of birth and residential address',
    'Last 3 months of bank statements',
    'Latest statutory accounts'
  ],
  AboutUsHeading: 'About us',
  AboutUsItems: ['10 minutes to apply', 'No obligations', 'No hidden fee'],
  BritishBankImageAltText: 'British Business Bank'
}
