import { LinkExpiredContent } from '../types/linkExpiredError'

export const linkExpired: LinkExpiredContent = {
  Heading: 'Link Expired',
  LinkSentHeading: 'Check your inbox',
  Introduction:
    "It looks like you've used that link once before. No problem, simply click below to get a new link or to start a new application.",
  LinkSent:
    'Your new link is on its way to your inbox. Check your emails to click through and continue your application.',
  SendNewLinkButton: 'Send new secure link',
  StartNewApplicationButton: 'Start new application'
}
