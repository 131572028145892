import { FundingNeedsContent } from 'src/applyfrontendcontent'
import NumberToLocaleString from '../mappers/NumberToLocaleString'
import { getFundingNeedsAmountMinMax } from '../reducers/fundingNeedsAmountMinMax'
import { tokeniseToString } from '../shared/content/DynamicContent'
import { IErrorsState } from '../types/IErrorsState'
import IFundingNeedsState from '../types/IFundingNeedsState'
import { minMaxAmountValidator, MinMaxResult } from './amountValidator'
import { validate as validateRequiredFields, FieldWithErrorMessage } from './formValidator'

export const validateIndividualStateFields = (
  state: IFundingNeedsState,
  content: FundingNeedsContent,
  errors: IErrorsState
) => {
  if (state.rlsLoan && content.RecoveryLoansScheme) {
    const rlsLimits = getFundingNeedsAmountMinMax(
      Object.assign({}, state, {
        invoiceFinance: false
      })
    )
    const loansRequiredFields: Array<FieldWithErrorMessage> = [
      {
        fieldName: 'preferredLoanDuration',
        errorMessage: tokeniseToString(content.RecoveryLoansScheme.Duration.Error || {}, 'Required')
      }
    ]
    const loanErrors = validateRequiredFields(loansRequiredFields, state)
    if (loanErrors['rlsLoanAmount'] && (loanErrors['rlsLoanAmount'] as string[]).length === 0) {
      if (minMaxAmountValidator(state.rlsLoanAmount, rlsLimits.min, rlsLimits.max) !== MinMaxResult.Ok) {
        errors['rlsLoanAmount'] = [
          tokeniseToString(
            content.RecoveryLoansScheme.BorrowingAmount.Error || {},
            'Required',
            content.RecoveryLoansScheme.BorrowingAmount
          )
        ]
      }
    }
    Object.assign(errors, { ...loanErrors, ...errors })
    errors.hasErrors = errors.hasErrors || loanErrors.hasErrors
  }

  if (state.businessLoan && content.BusinessLoan) {
    const businessLoanLimits = getFundingNeedsAmountMinMax(
      Object.assign({}, state, {
        invoiceFinance: false
      })
    )
    const loansRequiredFields: Array<FieldWithErrorMessage> = [
      {
        fieldName: 'preferredLoanDuration',
        errorMessage: tokeniseToString(content.BusinessLoan.Duration.Error || {}, 'Required')
      }
    ]
    const businessLoanErrors = validateRequiredFields(loansRequiredFields, state)
    if (
      businessLoanErrors['businessLoanAmount'] &&
      (businessLoanErrors['businessLoanAmount'] as string[]).length === 0
    ) {
      if (
        minMaxAmountValidator(state.businessLoanAmount, businessLoanLimits.min, businessLoanLimits.max) !==
        MinMaxResult.Ok
      ) {
        errors['businessLoanAmount'] = [
          tokeniseToString(
            content.BusinessLoan.BorrowingAmount.Error || {},
            'Required',
            content.BusinessLoan.BorrowingAmount
          )
        ]
      }
    }
    Object.assign(errors, { ...businessLoanErrors, ...errors })
    errors.hasErrors = errors.hasErrors || businessLoanErrors.hasErrors
  }

  if (state.invoiceFinance || state.rlsLoan || state.businessLoan) {
    let { min, max } = getFundingNeedsAmountMinMax(state)
    var fundingAmount = NumberToLocaleString.reverseMap(state.fundingAmount) || 0

    if (state.isContinueButtonClicked) {
      if (fundingAmount < min) {
        errors['fundingAmount'] = [
          `Please enter a funding amount between £${NumberToLocaleString.map(min)} and £${NumberToLocaleString.map(
            max
          )}`
        ]
        errors.hasErrors = true
      }
    } else {
      if (max < fundingAmount) {
        errors['fundingAmount'] = [
          `Please enter a funding amount between £${NumberToLocaleString.map(min)} and £${NumberToLocaleString.map(
            max
          )}`
        ]
        errors.hasErrors = true
      }
    }
  }

  if (!state.invoiceFinance && !state.rlsLoan && !state.businessLoan) {
    errors['fundingSolutionChoice'] = [tokeniseToString(content.FundingSolutions?.Error || {}, 'Required')]
    errors.hasErrors = true
  }
}
