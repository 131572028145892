import IUnifiedApplicationStateToIUnifiedApplicationStepState from './IUnifiedApplicationStateToIUnifiedApplicationStepState'
import IUnifiedApplicationState from '../types/IUnifiedApplicationState'
import IPartnerReferralRegistrationState from 'src/types/partner/IPartnerReferralRegistrationState'
import { RepresentativeJourneyType } from 'src/types/RepresentativeJourneyType'
import NumberToLocaleString from './NumberToLocaleString'
import { UnifiedJourneyProductType } from 'src/types/ProductType'
import { invoiceFinanceMaxLimit } from 'src/pages/unifiedApplicationJourney/fundingNeeds/fundingAmounts'
import deepCopy from 'src/utils/deepCopy'
import { EligibilityOutcome } from 'src/types/EligibilityOutcome'
import safeNumberConverter from './safeNumberConverter'

class IUnifiedApplicationStateToPartnerReferralRegistrationStateImpl extends IUnifiedApplicationStateToIUnifiedApplicationStepState<IPartnerReferralRegistrationState> {
  map(i: IUnifiedApplicationState): IPartnerReferralRegistrationState {
    const isProducts = i.products !== undefined

    const invoiceFinanceAmount = isProducts
      ? i.products![UnifiedJourneyProductType.SelectInvoiceDiscounting]?.amount || 0
      : 0

    let fundingAmount: number | undefined = Math.max(invoiceFinanceAmount || 0)

    if (fundingAmount === 0 || fundingAmount > invoiceFinanceMaxLimit) {
      fundingAmount = undefined
    }

    return {
      ...this.baseMap(i),
      isDirector: i.representativeJourneyType === RepresentativeJourneyType.Director,
      invoiceFinance: isProducts && i.products![UnifiedJourneyProductType.SelectInvoiceDiscounting]?.isSelected,
      invoiceFinanceAmount: invoiceFinanceAmount?.toString() || '',
      hasTwoFiledAccounts: i.metadata.hasTwoFiledAccounts === undefined ? null : i.metadata.hasTwoFiledAccounts,
      turnover: i.turnover,
      timeline: i.timeline,
      additionalInformation: i.additionalInformation,
      applicationReference: i.applicationReference,
      callTimeSlots: i.callTimeSlots,
      isThreeWayCallRequested: i.isThreeWayCallRequested,
      partnerPhoneNumber: i.partnerPhoneNumber,
      fundingAmount: NumberToLocaleString.map(fundingAmount) || '',
      isExistingCustomer: true
    }
  }

  reverseMap = (i: IPartnerReferralRegistrationState, target: IUnifiedApplicationState, autoSave: boolean): void => {
    // These if statement should never be hit
    // but keep it in for safety
    if (target.products === undefined) {
      target.products = {}
    }

    const products = deepCopy(target.products || {})!

    products[UnifiedJourneyProductType.SelectInvoiceDiscounting] = {
      amount: safeNumberConverter(i.fundingAmount) ? NumberToLocaleString.reverseMap(i.fundingAmount) : 0,
      isSelected: i.invoiceFinance,
      eligibility: EligibilityOutcome.InsufficientData
    }

    target.products = products

    target.representativeJourneyType = i.isDirector
      ? RepresentativeJourneyType.Director
      : RepresentativeJourneyType.Representative
    target.turnover = i.turnover
    target.timeline = i.timeline
    target.additionalInformation = i.additionalInformation
    target.applicationReference = i.applicationReference
    target.callTimeSlots = i.callTimeSlots
    target.isThreeWayCallRequested = i.isThreeWayCallRequested
    target.partnerPhoneNumber = i.partnerPhoneNumber
  }
}

const IUnifiedApplicationStateToPartnerReferralRegistrationState =
  new IUnifiedApplicationStateToPartnerReferralRegistrationStateImpl()
export default IUnifiedApplicationStateToPartnerReferralRegistrationState
