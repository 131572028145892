import http from './AddressApiConfig'

export type AddressSearchApiResponseDataItem = {
  BuildingName: string
  BuildingNumber: string
  Country: string
  County: string
  Line1: string
  Line2: string
  Line3: string
  Line4: string
  Locality: string
  TownOrCity: string
  Label?: string
  Postcode: string
}

export type AddressSearchApiResponse = {
  IsSuccess: boolean
  IsFailure: boolean
  Value: AddressSearchApiResponseDataItem[]
}

class AddressSearchApi {
  async searchByPostCode(
    postCode: string,
    houseNumber: string | undefined = undefined,
    limit: number | undefined = undefined
  ): Promise<AddressSearchApiResponseDataItem[]> {
    try {
      const houseNumberQueryParam = houseNumber ? `?houseNumber=${encodeURIComponent(houseNumber)}` : ''
      const response = await http.get<AddressSearchApiResponse>(
        `/${encodeURIComponent(postCode)}${houseNumberQueryParam}`
      )
      if (response?.data?.IsSuccess && response.data.Value?.length > 0) {
        response.data.Value = response.data.Value.map((v) => {
          return { ...v, Label: getLabel([v.Line1, v.Line2, v.TownOrCity, v.Country]) }
        })

        if (limit) {
          return response.data.Value.slice(0, limit)
        }
        return response.data.Value
      }

      return []
    } catch (error) {
      console.log(`Address search error: ${error}`)
      return []
    }
  }
}

const getLabel = (els: string[]): string => {
  let result = ''
  els.forEach((el) => (result = add(result, el)))
  return result
}

const add = (a: string, b: string): string => {
  return a + (a && b ? ', ' : '') + b
}

export default new AddressSearchApi()
