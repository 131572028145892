import React from 'react'
import { setBankStatementsDate, formatDate } from '../../../shared/components/dynamicDates/dynamicDates'
import { DynamicContent } from '../../../shared/content/DynamicContent'
import { startOfMonth, nMonthsPrior } from '../../../utils/dateCalculator'
import { JourneyType } from '../../../types/JourneyType'
import { Paragraphs } from 'src/applyfrontendcontent'
import { Paragraph } from 'src/ui-framework'

interface IContent {
  content: Paragraphs
  journeyType?: JourneyType
}

export const RecoveryLoans = (p: IContent) => {
  const content = p.content!
  content.Dynamic = {
    BankStatementsFrom:
      p.journeyType === JourneyType.RecoveryLoans
        ? formatDate(startOfMonth(nMonthsPrior(new Date(), 6, false)))
        : setBankStatementsDate(120),
    BankStatementsTo: setBankStatementsDate(p.journeyType === JourneyType.RecoveryLoans ? 14 : 30)
  }

  return (
    <DynamicContent
      content={content}
      components={{
        Paragraphs: { Component: <T extends object>(p: React.PropsWithChildren<T>) => <>{p.children}</> },
        ParagraphsItem: {
          Component: Paragraph
        }
      }}
    />
  )
}
