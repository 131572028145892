import React from 'react'
import Label from '../../../commonStyles/Label'
import ThemedInput, { HelpText, ThemedSymbol } from '../../../commonStyles/ThemedInput'
import { TooltipDiv } from '../../../commonStyles/TooltipDiv'
import ErrorList from '../ErrorList'

type OwnProps = {
  label?: string
  errors: Array<string>
  dataTest?: string
  hotjarUnmask?: boolean
  ariaLabel?: string
  ariaInvalid?: boolean
  ariaDescribedby?: string
  ariaDisabled?: boolean
  ariaReadOnly?: boolean
  symbol?: any
  inputRef: any
  helpText?: string
  leakMargin?: boolean // defaults to true to match existing behaviour
}

type State = {
  focused: boolean
}

type Props = OwnProps & React.ComponentPropsWithoutRef<'input'>

class Input extends React.Component<Props, State> {
  static defaultProps: Props = {
    id: 'input',
    label: '',
    name: 'input',
    value: undefined,
    maxLength: undefined,
    required: false,
    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
    onClick: () => {},
    errors: [],
    type: undefined,
    disabled: false,
    readOnly: false,
    dataTest: undefined,
    pattern: undefined,
    inputMode: undefined,
    hotjarUnmask: false,
    autoComplete: undefined,
    ariaLabel: '',
    ariaDisabled: false,
    ariaReadOnly: false,
    symbol: undefined,
    inputRef: undefined,
    leakMargin: true
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      focused: false
    }

    this.handleOnFocus = this.handleOnFocus.bind(this)
    this.handleOnBlur = this.handleOnBlur.bind(this)
  }

  handleOnFocus(event: React.FocusEvent<HTMLInputElement>) {
    const { onFocus } = this.props
    onFocus && onFocus(event)
    this.setState({ focused: true })
  }

  handleOnBlur(event: React.FocusEvent<HTMLInputElement>) {
    const { onBlur } = this.props
    onBlur && onBlur(event)
    this.setState({ focused: false })
  }

  render() {
    const {
      id,
      label,
      required,
      errors,
      dataTest,
      hotjarUnmask,
      ariaLabel,
      ariaDisabled,
      ariaReadOnly,
      symbol,
      inputRef,
      helpText,
      ariaDescribedby
    } = this.props

    const { focused } = this.state

    return (
      <div data-test={dataTest}>
        {label && (
          <TooltipDiv>
            <Label htmlFor={id}>{label}</Label>
          </TooltipDiv>
        )}
        <div style={{ position: 'relative' }}>
          {symbol && <ThemedSymbol isChar={typeof symbol == 'string'}>{symbol}</ThemedSymbol>}
          <ThemedInput
            {...this.props}
            focused={focused}
            errors={errors}
            onBlur={this.handleOnBlur}
            onFocus={this.handleOnFocus}
            aria-required={required}
            data-hj-allow={hotjarUnmask ? '' : undefined}
            tabIndex={0}
            aria-label={ariaLabel}
            aria-invalid={errors?.length > 0}
            aria-describedby={ariaDescribedby ?? ErrorList.getDescribedByAttribute(errors)}
            aria-disabled={ariaDisabled}
            aria-readonly={ariaReadOnly}
            symbol={symbol}
            ref={(input) => {
              inputRef && inputRef(input)
            }}
          />
          {helpText && <HelpText leakMargin={this.props.leakMargin !== false}>{helpText}</HelpText>}
        </div>
        <ErrorList errors={errors} id={id ?? 'input'} />
      </div>
    )
  }
}

export default Input
