import styled, { InterpolationValue } from 'styled-components'
import { colors } from '../theme/colors'
import { fontSize } from '../theme/fontSize'
import { spacing } from '../theme/spacing'
import HandleErrorAndFocusedColors from './ErrorAndFocusedColors'

type InputStyleProps = {
  focused: boolean
  errors: Array<string>
  theme: any
  symbol?: string | JSX.Element
  leakMargin?: boolean
  helpText?: string
}

const ThemedInput = styled.input<InputStyleProps>`
  ${(props) => props.theme.inputFields as InterpolationValue}
  ${(props) => {
    return props.helpText === undefined && props.leakMargin !== false
      ? { marginBottom: spacing.m }
      : { marginBottom: 0 }
  }}
  padding: ${(props) => props.theme.spacing.s};
  padding-left: 14px;
  width: 100%;
  border-radius: 8px;
  font-family: ${(props) => props.theme.fontFamily};
  box-sizing: border-box;
  box-shadow: none;
  ${(props) => props.symbol && { paddingLeft: '42px' }};
  &:read-only {
    background-color: ${(props) => props.theme.colors.white};
  }
  &:hover {
    background-color: ${(props) => props.theme.colors.grey10};
  }
  ${({ errors, focused, theme }: { errors: string[]; theme: any; focused: boolean }) =>
    HandleErrorAndFocusedColors(errors && errors.length > 0, focused, theme)}
`

type InputSymbolProps = {
  theme: any
  symbol?: string | JSX.Element
  isChar?: boolean
}

export const ThemedSymbol = styled.div<InputSymbolProps>`
  position: absolute;
  top: 12px;
  padding-left: 12px;
  width: 10px;
  font-size: ${(props) => props.theme.fontSize.s};

  ${(props) => {
    return props.isChar ? { top: '15px', paddingLeft: '16px' } : {}
  }}
`

type HelpTextProps = {
  leakMargin: boolean
}

export const HelpText = styled.div<HelpTextProps>`
  font-style: normal;
  font-weight: 400;
  font-size: ${() => fontSize.xs};
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${() => colors.textGrey600};
  margin-top: ${() => spacing.xxs};
  ${(props) => props.leakMargin && { marginBottom: spacing.m }};
`

export default ThemedInput
