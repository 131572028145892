import React from 'react'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const StyledSpan = styled.span`
  display: inline-block;
  width: 44px;
  height: 44px;
  animation: ${rotate} 3s linear infinite;
`

const Spinner = () => {
  return (
    <StyledSpan id="spinner">
      <img src="https://cdn.kriya.co/images/Spinner.svg" alt="Spinner" />
    </StyledSpan>
  )
}

export default Spinner
