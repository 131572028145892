import { media } from 'src/ui-framework'
import styled from 'styled-components'

type ContainerStyleProps = {
  errors: Array<string>
  theme: {}
}

const Container = styled.div<ContainerStyleProps>`
  margin-bottom: ${(props) =>
    props.errors === undefined || props.errors.length === 0 ? props.theme.spacing.spacingS : '0'};
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;

  margin-bottom: 30px;

  ${media.upToTablet`
    margin-bottom: 22px;
  `}
`

export default Container
