import UnifiedApplicationBaseReducer from './unifiedApplicationBaseReducer'
import {
  IUnifiedApplicationAction,
  IUnifiedApplicationActionProductSelected,
  IUnifiedApplicationActionReasonForFundingUpdate,
  UnifiedApplicationActionType,
  UnifiedApplicationActionUpdateState
} from './unifiedApplicationActions'
import { IErrorsState } from '../types/IErrorsState'
import IFundingNeedsState from '../types/IFundingNeedsState'
import { FieldWithErrorMessage, validate as validateRequiredFields } from '../validators/formValidator'
import { UnifiedApplicationState } from '../types/UnifiedApplicationState'
import { reasonForFundingValidator } from '../validators/reasonForFundingValidator'
import { tokeniseToString } from '../shared/content/DynamicContent'
import { validateIndividualStateFields } from '../validators/fundingNeedsValidator'
import { rlsLoan, invoiceFinance, businessLoan } from '../pages/unifiedApplicationJourney/fundingNeeds/productNames'
import NumberToLocaleString from 'src/mappers/NumberToLocaleString'
import { getFundingNeedsAmountMinMax } from './fundingNeedsAmountMinMax'

export default class FundingNeedsReducer extends UnifiedApplicationBaseReducer<IFundingNeedsState> {
  onValidate = (state: IFundingNeedsState): IErrorsState => {
    const content = this.getContent(state).FundingNeeds
    let { min, max } = getFundingNeedsAmountMinMax(state)

    content.FundingAmount.Dynamic = {
      MinimumAmount: `£${NumberToLocaleString.map(min)}`,
      MaximumAmount: `£${NumberToLocaleString.map(max)}`
    }

    const requiredFields: Array<FieldWithErrorMessage> = [
      { fieldName: 'fundingAmount', errorMessage: content.FundingAmount.Error?.Required },
      { fieldName: 'reasonForFunding', errorMessage: content.ReasonForFunding.Error?.Required },
      { fieldName: 'turnover', errorMessage: content.Turnover.Error?.Required },
      { fieldName: 'timeline', errorMessage: content.Timeline.Error?.Required },
      { fieldName: 'additionalInformation', errorMessage: content.AdditionalInformation.Error?.Required }
    ]
    let errors = validateRequiredFields(requiredFields, state)

    if (errors['fundingAmount'] === undefined || (errors['fundingAmount'] as string[]).length === 0) {
      if (state.fundingAmount !== undefined || state.fundingAmount !== '') {
        var fundingAmount = NumberToLocaleString.reverseMap(state.fundingAmount) || 0

        if (fundingAmount < min || fundingAmount > max) {
          errors['fundingAmount'] = [
            `Please enter a funding amount between £${NumberToLocaleString.map(min)} and £${NumberToLocaleString.map(
              max
            )}`
          ]
        }
      }
    }

    validateIndividualStateFields(state, content, errors)

    reasonForFundingValidator(
      state.reasonForFunding,
      state.reasonForFundingOtherDetails,
      errors,
      tokeniseToString(content.FundingOther.Error || {}, 'Required')
    )
    return errors
  }

  onBeforeSubmit = async (state: IFundingNeedsState): Promise<void> => {}

  onLoad = async (
    state: IFundingNeedsState,
    dispatch: React.Dispatch<IUnifiedApplicationAction<object>>
  ): Promise<void> => {
    dispatch(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Pristine))
  }

  handleDefaultAction = (
    prevState: IFundingNeedsState,
    newState: IFundingNeedsState,
    action: IUnifiedApplicationAction<object>
  ): void => {
    switch (action.type) {
      case UnifiedApplicationActionType.Loaded:
        newState.state = UnifiedApplicationState.Pristine
        break
      case UnifiedApplicationActionType.ReasonForFundingUpdate:
        const updateFieldAction = action as IUnifiedApplicationActionReasonForFundingUpdate
        if (updateFieldAction.payload.value !== 'Other') {
          newState.reasonForFundingOtherDetails = ''
          newState.errors['reasonForFundingOtherDetails'] = []
          this.markFieldAsPristine('reasonForFundingOtherDetails', newState)
        }
        this.updateFieldValue(newState, updateFieldAction.payload.field, updateFieldAction.payload.value)
        break

      case UnifiedApplicationActionType.ContinueButtonClicked:
        newState.isContinueButtonClicked = true
        break

      case UnifiedApplicationActionType.ProductSelection:
        const productSelectionAction = action as IUnifiedApplicationActionProductSelected

        if (productSelectionAction.payload?.product === rlsLoan && !newState.rlsLoan) {
          newState.preferredLoanDuration = ''
          newState.errors['rlsLoanAmount'] = undefined
          newState.errors['preferredLoanDuration'] = undefined
        }
        if (productSelectionAction.payload?.product === invoiceFinance && !newState.invoiceFinance) {
          newState.errors['invoiceFinanceAmount'] = undefined
        }
        if (productSelectionAction.payload?.product === businessLoan && !newState.businessLoan) {
          newState.preferredLoanDuration = ''
          newState.errors['businessLoanAmount'] = undefined
          newState.errors['preferredLoanDuration'] = undefined
        }
        break
    }
  }
}
