import { hideHeading, PageIdentifier } from '../../../../../types/PageIdentifier'
import { UnifiedApplicationSteps } from '../../../../../types/UnifiedApplicationSteps'

export const eligibilityResultsPageIdentifier: PageIdentifier = {
  id: '0cb18e00-ef5c-476b-a090-938ba250d1a4',
  title: 'Eligibility Results',
  step: UnifiedApplicationSteps.EligibilityResults,
  order: 29,
  isHidden: true,
  subPageIds: [],
  hidden: hideHeading
}
