export const nMonthsPrior = (from: Date = new Date(), n: number = 15, skipCurrentMonth: boolean): Date => {
  if (n <= 0) throw new Error('n must be greater than 0')
  let m = from.getMonth() - n
  if (skipCurrentMonth === false) {
    ++m
  }
  let y = from.getFullYear()
  if (m < 0) {
    y -= Math.ceil(Math.abs(m) / 11)
    m = 12 + (m % 12)
  }
  const result = Date.UTC(y, m, 1)
  return new Date(result - 86_400_000)
}

export const nMonthsLater = (from: Date = new Date(), n: number = 6): Date => {
  if (n <= 0) throw new Error('n must be greater than 0')
  const m = (from.getMonth() + n + 1) % 12
  let y = from.getFullYear() + Math.floor(n / 12)
  if (m < from.getMonth()) {
    ++y
  }
  const result = Date.UTC(y, m, 1)
  return new Date(result - 86_400_000)
}

export const endOfNextMonth = (d: Date): Date => {
  const m = (d.getMonth() + 2) % 12
  let y = d.getFullYear()
  if (m < d.getMonth()) {
    ++y
  }
  const result = Date.UTC(y, m, 1)
  return new Date(result - 86_400_000)
}

export const endOfMonthNextYear = (d: Date): Date => {
  const m = (d.getMonth() + 1) % 12
  let y = d.getFullYear() + 1
  if (m < d.getMonth()) {
    ++y
  }
  const result = Date.UTC(y, m, 1)
  return new Date(result - 86_400_000)
}

export const startOfMonth = (d: Date): Date => {
  return new Date(Date.UTC(d.getFullYear(), d.getMonth(), 1))
}

export const taxYearForNYearsPrior = (now: Date = new Date(), yearsPrior: number): ITaxYear => {
  const taxMonthApril = 3
  const taxDayStart = 6

  let taxEndWithinCurrentYear = new Date(Date.UTC(now.getFullYear(), taxMonthApril, taxDayStart))

  now.setHours(0, 0, 0, 0)
  taxEndWithinCurrentYear.setHours(0, 0, 0, 0)

  let yearsToSubtract = yearsPrior
  if (now >= taxEndWithinCurrentYear) {
    yearsToSubtract = yearsPrior - 1
  }

  const taxStart = new Date(
    Date.UTC(taxEndWithinCurrentYear.getFullYear() - yearsToSubtract - 1, taxMonthApril, taxDayStart)
  )
  const taxEnd = new Date(
    Date.UTC(taxEndWithinCurrentYear.getFullYear() - yearsToSubtract, taxMonthApril, taxDayStart - 1)
  )

  return {
    start: taxStart,
    end: taxEnd
  }
}

export interface ITaxYear {
  start: Date
  end: Date
}
