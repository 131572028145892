import React from 'react'
import Container from '@material-ui/core/Container'
import { withStyles } from '@material-ui/core/styles'

const styles = {
  root: {
    flex: '1 0 auto',
    padding: 0
  }
}

function StyledContainer(props: any) {
  const { classes, children } = props

  return (
    <Container maxWidth="lg" className={classes.root}>
      {children}
    </Container>
  )
}

export default withStyles(styles)(StyledContainer)
