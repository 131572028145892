import IUnifiedApplicationState, { IUnifiedApplicationStepState } from '../types/IUnifiedApplicationState'
import { UnifiedApplicationState } from '../types/UnifiedApplicationState'

abstract class IUnifiedApplicationStateToIUnifiedApplicationStepState<TState extends IUnifiedApplicationStepState> {
  protected baseMap = (i: IUnifiedApplicationState): IUnifiedApplicationStepState => {
    return {
      state: UnifiedApplicationState.Loading,
      errors: {
        hasErrors: false
      },
      email: i.applicant?.email || '',
      partnerEmail: i.partnerEmail || '',
      journeyType: i.journeyType,
      representativeJourneyType: i.representativeJourneyType,
      hasSalesCookie: i.hasSalesCookie,
      partnerType: i.partnerType || undefined,
      businessType: i.businessType,
      id: i.id,
      partnerIdentifier: i.partnerIdentifier,
      status: i.status,
      channelType: i.channelType,
      hasBackgroundChecksTimeout: i.hasBackgroundChecksTimeout
    }
  }

  abstract map(i: IUnifiedApplicationState): TState
  abstract reverseMap(i: TState, target: IUnifiedApplicationState, autoSave: boolean): void
}

export default IUnifiedApplicationStateToIUnifiedApplicationStepState
