import React, { useRef, useEffect } from 'react'
import { media } from 'src/ui-framework'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  &:focus {
    outline-offset: 2px;
    outline-color: black;
  }
  & div.trustpilot-widget-logo {
    ${media.upToTablet`
    padding-left: 0px !important;
  `}
  }
`

type WidgetTypes = {
  mini: string
  horizontal: string
}
const widgetTypes: WidgetTypes = {
  mini: '53aa8807dec7e10d38f59f32',
  horizontal: '5406e65db0d04a09e042d5fc'
}

type TrustPilotBoxProps = {
  widget?: keyof WidgetTypes
}

const TrustPilotBox = (p: TrustPilotBoxProps) => {
  const trustBoxRef = useRef(null)
  useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    //@ts-ignore => Trustpilot is an object whereas loadFromElement is a function, however no idea about the function return type
    if (window.Trustpilot) window.Trustpilot.loadFromElement(trustBoxRef.current, true)
  }, [])

  return (
    <StyledWrapper onClick={() => (window.location.href = 'https://uk.trustpilot.com/review/kriya.co')}>
      <div
        ref={trustBoxRef}
        className="trustpilot-widget-logo"
        data-locale="en-GB"
        data-template-id={p.widget === undefined ? widgetTypes.mini : widgetTypes[p.widget]}
        data-businessunit-id="63a219862c4ca815c8995bbd"
        data-style-height="80px"
        data-style-width="100%"
        data-theme="light"
        style={{ paddingLeft: '8px' }}
      >
        <a href="https://uk.trustpilot.com/review/kriya.co" target="_blank" rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>
    </StyledWrapper>
  )
}

export default TrustPilotBox
