import { SaveForLaterContent } from '../types/saveForLater'

export const saveForLater: SaveForLaterContent = {
  Heading: 'Save For Later',
  SendLink: {
    Introduction: "If you need to continue the application later we'll send a secure link to this address:",
    Email: {
      Text: 'Email',
      Error: {
        Required: 'Please enter a valid email address'
      }
    },
    CancelButton: 'Cancel',
    SendLinkButton: 'Send link'
  },
  Confirmation: {
    Introduction:
      'Your link is on its way to your designated inbox. Open the email and click through to continue the application.',
    CloseButton: 'Close'
  }
}
