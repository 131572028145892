import React from 'react'
import { H2, H3 } from '../../../commonStyles/components/headings'
import { MainDiv, PageContainer } from '../readyToApply/ReadyToApplyStyles'
import ISupportingDocumentsState from '../../../types/ISupportingDocumentsState'
import { PageIdentifier } from '../../../types/PageIdentifier'
import { UnifiedApplicationSteps } from '../../../types/UnifiedApplicationSteps'
import IUnifiedApplicationStepProps from '../../../types/IUnifiedApplicationStepProps'
import IFileState from '../../../types/IFileState'
import FileUploadWell from '../../../shared/components/fileUpload/FileUploadWell'
import useUnifiedApplicationStep from '../../../shared/hooks/useUnifiedApplicationStep'
import SupportingDocumentsReducer from '../../../reducers/supportingDocumentsReducer'
import capabilities from '../../../fileUploadCapabilities'
import {
  UnifiedApplicationActionFilesUploaded,
  UnifiedApplicationActionType,
  UnifiedApplicationActionUpdateState
} from '../../../reducers/unifiedApplicationActions'
import SaveForLater from '../continueLater/ContinueLaterLink'
import { UnifiedApplicationState } from '../../../types/UnifiedApplicationState'
import ContinueAndSaveLater from '../ContinueAndSaveLater'
import { getSaveForLaterEmail } from '../../../shared/getSaveForLaterEmail'
import { RecoveryLoans } from './SupportingDocumentsContent'
import { formatDate } from '../../../shared/components/dynamicDates/dynamicDates'
import { DynamicContent, tokeniseToString } from '../../../shared/content/DynamicContent'
import styled from 'styled-components'
import UnifiedApplicationStepGuard from '../UnifiedApplicationStepGuard'
import { endOfMonthNextYear, nMonthsPrior, taxYearForNYearsPrior } from '../../../utils/dateCalculator'
import { media, Paragraph } from 'src/ui-framework'

type UploadLaterLinkProps = {
  id: string
}

export const supportingDocumentsPageIdentifier: PageIdentifier = {
  id: '78adaeea-075b-46c0-8a6e-fff9435728ad',
  title: 'Supporting documents',
  step: UnifiedApplicationSteps.SupportingDocuments,
  order: 50,
  isHidden: false,
  subPageIds: []
}

const StyledH3 = styled(H3)`
  margin-top: 0px;
`

const ParagraphList = styled.div`
  & > p {
    margin: 0;
  }
  p:last-child {
    margin-bottom: ${(props) => props.theme.spacing.spacingS};
  }
`
const WrapperDiv = styled.div`
  display: none;

  ${media.xsOnlyLandscapePortrait`
  display: inline-block;
  & #save-for-later-wrapper {
    margin-top:0;
  }
`}
`

const AcceptedFormatsDiv = styled.div`
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.16px;
  color: #606060;
  position: relative;
  top: 0px;
  margin: 0 0 32px 0;
  ${media.upToTablet`
    margin: 0 0 16px 0;
  `}
`

const MessageDiv = styled.div`
  display: none;
  ${media.xsOnlyLandscapePortrait`
display: inline-block;
`}
`

const Para = { Component: Paragraph }
const ParaList = { Component: ParagraphList }
const Heading3 = { Component: H3 }
const Transparent = { Component: React.Fragment }

interface ISupportingDocumentsProps extends IUnifiedApplicationStepProps<ISupportingDocumentsState> {}
const reducer = new SupportingDocumentsReducer(supportingDocumentsPageIdentifier)

const SupportingDocuments = (p: ISupportingDocumentsProps) => {
  const [state, dispatch, content, onSendLink] = useUnifiedApplicationStep<
    ISupportingDocumentsState,
    SupportingDocumentsReducer
  >(reducer, p.initialState, p.applicationIdentifier, p.onSubmit, p.onAutoSave)

  const supportingDocsContent = content.SupportingDocuments

  const UploadLaterLink = (p: UploadLaterLinkProps) => {
    return (
      <WrapperDiv>
        <SaveForLater
          email={getSaveForLaterEmail(state)}
          onSendLink={onSendLink!}
          content={content}
          overrideTitle={supportingDocsContent.SaveForLater}
          overrideModalContent={supportingDocsContent.OverrideUploadLater}
          id={p.id}
        />
      </WrapperDiv>
    )
  }

  const DocumentsMessage = { Component: MessageDiv }

  const getLastFilingAccounts = (
    lastAccountsFilingMadeUptoDate: Date | undefined,
    hasAnyFilings: boolean | undefined
  ) => {
    const genericContent = supportingDocsContent.StatutoryAccounts.GenericCompany
    if (hasAnyFilings === undefined || hasAnyFilings === false) {
      return genericContent
    }
    if (lastAccountsFilingMadeUptoDate !== undefined && lastAccountsFilingMadeUptoDate !== null) {
      const parsedDate = new Date(lastAccountsFilingMadeUptoDate)
      const filingDateUtc = new Date(Date.UTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate()))

      if (
        filingDateUtc < nMonthsPrior(new Date(), 15, true) &&
        supportingDocsContent.StatutoryAccounts.GenericCompanyAddition !== undefined
      ) {
        const extraRequirementContent = supportingDocsContent.StatutoryAccounts.GenericCompanyAddition
        extraRequirementContent.Dynamic = {
          LastFilingDate: formatDate(filingDateUtc),
          ManagementUptoDate: formatDate(endOfMonthNextYear(filingDateUtc))
        }
        return extraRequirementContent
      } else if (filingDateUtc > new Date()) {
        //show generic copy if filing date in the future
        return genericContent
      } else {
        const nonStartupCompany = supportingDocsContent.StatutoryAccounts.NonStartupCompany
        nonStartupCompany.Dynamic = {
          LastFilingDate: formatDate(filingDateUtc)
        }
        return nonStartupCompany
      }
    } else {
      //show startup copy
      return supportingDocsContent.StatutoryAccounts.StartupCompany
    }
  }

  const setDynamicContentForLastTwoHmrcTaxYears = () => {
    if (!supportingDocsContent.HmrcTaxCalculationDocuments) {
      return
    }
    const now = new Date()
    const earliestTaxYear = taxYearForNYearsPrior(now, 2)
    const latestTaxYear = taxYearForNYearsPrior(now, 1)

    supportingDocsContent.HmrcTaxCalculationDocuments!.Dynamic = {
      TaxYearOneStart: formatDate(earliestTaxYear.start),
      TaxYearOneEnd: formatDate(earliestTaxYear.end),
      TaxYearTwoStart: formatDate(latestTaxYear.start),
      TaxYearTwoEnd: formatDate(latestTaxYear.end)
    }
  }

  const filingAccountContent = getLastFilingAccounts(state.lastAccountsFilingMadeUptoDate, state.hasAnyFilings)
  setDynamicContentForLastTwoHmrcTaxYears()

  return (
    <UnifiedApplicationStepGuard currentState={state}>
      <PageContainer>
        <MainDiv>
          <StyledH3>{tokeniseToString(supportingDocsContent.BankAccount, 'Title')}</StyledH3>
          <RecoveryLoans content={supportingDocsContent.BankAccount.NonCbils} journeyType={state.journeyType} />
          <FileUploadWell
            ariaLabel={tokeniseToString(supportingDocsContent.BankAccount, 'Title')}
            key="bankStatements"
            label={''}
            isRequired={true}
            capabilities={capabilities}
            applicationId={state.applicationIdentifier}
            errors={state.errors.bankStatements as string[]}
            onBeforeUpload={(files: IFileState[]) => {
              dispatch.action({
                type: UnifiedApplicationActionType.FilesUploading,
                payload: { field: 'bankStatements', files }
              })
            }}
            onChange={(files: IFileState[]) =>
              dispatch.action(UnifiedApplicationActionFilesUploaded('bankStatements', files))
            }
            value={state.bankStatements || []}
            constraints={state.constraints}
          />
          <AcceptedFormatsDiv>{supportingDocsContent.BankAccount.BankStatements.AcceptedFormats}</AcceptedFormatsDiv>
          {state.isStatutoryAccountsRequired && (
            <>
              <DynamicContent
                content={filingAccountContent}
                components={{
                  Title: Heading3,
                  Introduction: Array.isArray(filingAccountContent.Introduction) ? Transparent : Para,
                  IntroductionItem: Para,
                  BulletPoints: ParaList,
                  BulletPointsItem: Para,
                  Body: Para,
                  Section: Para
                }}
              />
              <FileUploadWell
                ariaLabel={tokeniseToString(filingAccountContent, 'Title')}
                key="statutoryAccounts"
                label={''}
                isRequired={true}
                capabilities={capabilities}
                applicationId={state.applicationIdentifier}
                errors={state.errors.statutoryAccounts as string[]}
                onBeforeUpload={(files: IFileState[]) => {
                  dispatch.action({
                    type: UnifiedApplicationActionType.FilesUploading,
                    payload: { field: 'statutoryAccounts', files }
                  })
                }}
                onChange={(files: IFileState[]) =>
                  dispatch.action(UnifiedApplicationActionFilesUploaded('statutoryAccounts', files))
                }
                value={state.statutoryAccounts || []}
                constraints={state.constraints}
              />
              <AcceptedFormatsDiv>
                {supportingDocsContent.BankAccount.BankStatements.AcceptedFormats}
              </AcceptedFormatsDiv>
            </>
          )}
          {supportingDocsContent.HmrcTaxCalculationDocuments && (
            <>
              <H2>{tokeniseToString(supportingDocsContent.HmrcTaxCalculationDocuments!, 'Title')}</H2>
              <Paragraph>{tokeniseToString(supportingDocsContent.HmrcTaxCalculationDocuments!, 'SubTitle')}</Paragraph>
              <FileUploadWell
                key="hmrcTaxCalculationDocuments"
                label={`* ${tokeniseToString(supportingDocsContent.HmrcTaxCalculationDocuments!, 'Title')}`}
                ariaLabel={tokeniseToString(supportingDocsContent.HmrcTaxCalculationDocuments!, 'Title')}
                isRequired={true}
                capabilities={capabilities}
                applicationId={state.applicationIdentifier}
                errors={state.errors.hmrcTaxCalculationDocuments as string[]}
                onBeforeUpload={(files: IFileState[]) => {
                  dispatch.action({
                    type: UnifiedApplicationActionType.FilesUploading,
                    payload: { field: 'hmrcTaxCalculationDocuments', files }
                  })
                }}
                onChange={(files: IFileState[]) =>
                  dispatch.action(UnifiedApplicationActionFilesUploaded('hmrcTaxCalculationDocuments', files))
                }
                value={state.hmrcTaxCalculationDocuments || []}
                constraints={state.constraints}
              />
              <AcceptedFormatsDiv>
                {supportingDocsContent.BankAccount.BankStatements.AcceptedFormats}
              </AcceptedFormatsDiv>

              <DynamicContent
                content={supportingDocsContent}
                components={{
                  MissingHmrcTaxDocuments: DocumentsMessage,
                  SaveForLater: {
                    Component: UploadLaterLink,
                    props: {
                      id: 'hmrcTaxCalculationDocuments-upload-later'
                    }
                  }
                }}
                ignoreComponents={['SaveForLater']}
              />
            </>
          )}
          <ContinueAndSaveLater
            content={content}
            loading={state.state === UnifiedApplicationState.Submitted}
            email={getSaveForLaterEmail(state)}
            id="supported-documents-submit"
            onSendLink={onSendLink}
            errors={state.errors}
            showSaveLater={true}
            overrideContinueLabel={supportingDocsContent.OverrideContinueButtonLabel}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.preventDefault()
              dispatch.action(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Submitting))
            }}
          />
        </MainDiv>
      </PageContainer>
    </UnifiedApplicationStepGuard>
  )
}

export default SupportingDocuments
