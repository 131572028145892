import React, { useState } from 'react'
import ThemedInput from '../../../commonStyles/ThemedInput'
import Label from '../../../commonStyles/Label'
import Column from '../../Grid/Column'
import Row from '../../Grid/Row'
import ErrorList from '../ErrorList'

type ValueProps = {
  monthsAtAddress?: string
  yearsAtAddress?: string
}

type OwnProps = {
  name?: string
  errors?: string[]
  label?: string
  value: ValueProps
  maxYears?: number
  onChange?: (value: ValueProps) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
}

type Props = OwnProps

const InputTimeAtAddress = (props: Props) => {
  const inputId = 'inputTimeAtAddress'
  const { value, errors, label, onChange } = props
  const { monthsAtAddress, yearsAtAddress } = value ?? {}

  const [isMonthsAtAddressFocused, setIsMonthsAtAddressFocused] = useState<boolean>(false)

  const handleMonthsOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { onFocus } = props
    onFocus && onFocus(event)
    setIsMonthsAtAddressFocused(true)
  }

  const handleMonthsOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur } = props
    onBlur && onBlur(event)
    setIsMonthsAtAddressFocused(false)
  }

  const [isYearsAtAddressFocused, setIsYearsAtAddressFocused] = useState<boolean>(false)

  const handleYearsOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { onFocus } = props
    onFocus && onFocus(event)
    setIsYearsAtAddressFocused(true)
  }

  const handleYearsOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { onBlur } = props
    onBlur && onBlur(event)
    setIsYearsAtAddressFocused(false)
  }

  const checkValue = (value: string, existing: string | undefined, max: number) => {
    if (value === '') {
      return undefined
    } else if (
      isNaN(Number(value)) ||
      Number(value) % 1 !== 0 ||
      Number(value) < 0 ||
      value.indexOf('.') !== -1 ||
      Number(value) > max
    ) {
      return existing
    } else {
      return value
    }
  }

  return (
    <>
      <Row spacing={0}>
        <Column xs={12}>
          <Label>* {label}</Label>
        </Column>
      </Row>
      <Row>
        <Column xs={3} sm={3} md={3} lg={2}>
          <Label htmlFor="years-at-address">Years</Label>
          <ThemedInput
            {...props}
            id="years-at-address"
            data-test="years-at-address"
            name="years-at-address"
            pattern="[0-9*]"
            inputMode="numeric"
            value={yearsAtAddress}
            onChange={(e: any) => {
              const updatedValue = props.value
              updatedValue.yearsAtAddress = checkValue(
                e.target.value,
                props.value.yearsAtAddress,
                props.maxYears ?? 1000
              )
              onChange && onChange(updatedValue)
            }}
            onBlur={handleYearsOnBlur}
            onFocus={handleYearsOnFocus}
            focused={isYearsAtAddressFocused}
            errors={errors ?? []}
            aria-invalid={(errors?.length ?? 0) > 0}
            aria-describedby={ErrorList.getDescribedByAttributes(inputId, errors ?? [])}
          />
        </Column>

        <Column xs={3} sm={3} md={3} lg={2}>
          <Label htmlFor="months-at-address">Months</Label>

          <ThemedInput
            {...props}
            id="months-at-address"
            data-test="months-at-address"
            name="months-at-address"
            pattern="[0-9*]"
            inputMode="numeric"
            value={monthsAtAddress}
            onChange={(e: any) => {
              const updatedValue = props.value
              updatedValue.monthsAtAddress = checkValue(e.target.value, props.value.monthsAtAddress, 11)
              onChange && onChange(updatedValue)
            }}
            onBlur={handleMonthsOnBlur}
            onFocus={handleMonthsOnFocus}
            focused={isMonthsAtAddressFocused}
            errors={errors ?? []}
            aria-invalid={(errors?.length ?? 0) > 0}
            aria-describedby={ErrorList.getDescribedByAttributes(inputId, errors ?? [])}
          />
        </Column>
      </Row>
      <Row>
        <Column xs={12}>
          <div style={{ marginTop: '-8px' }}>
            <ErrorList errors={errors || []} id={inputId} />
          </div>
        </Column>
      </Row>
    </>
  )
}

InputTimeAtAddress.defaultProps = {
  label: '',
  value: {
    yearsAtAddress: undefined,
    monthsAtAddress: undefined
  },
  errors: []
}

export default InputTimeAtAddress
