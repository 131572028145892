import { ICreateApplicationState, ButtonType } from '../types/IInitialisationParameters'
import IApplication from '../types/IApplication'
import { JourneyType } from '../types/JourneyType'
import { IUnifiedProductType, UnifiedJourneyProductType } from '../types/ProductType'
import { EligibilityOutcome } from '../types/EligibilityOutcome'
import { features } from '../features'
import { PartnerType } from '../types/PartnerType'
import { ChannelType } from '../types/ChannelType'

class IICreateApplicationStateToIApplicationImpl {
  map = (i: ICreateApplicationState, applicationIdentifier: string): IApplication => {
    const journeyType = this.getJourneyType(i)
    const products: IUnifiedProductType = {}
    switch (journeyType) {
      case JourneyType.Standard:
      case JourneyType.BarclaysStandard:
        products[UnifiedJourneyProductType.SelectInvoiceDiscounting] = {
          isSelected: true,
          eligibility: EligibilityOutcome.InsufficientData
        }
        break
      case JourneyType.RecoveryLoans:
        products[UnifiedJourneyProductType.RecoveryLoan] = {
          isSelected: false,
          eligibility: EligibilityOutcome.InsufficientData
        }
        products[UnifiedJourneyProductType.SelectInvoiceDiscounting] = {
          isSelected: false,
          eligibility: EligibilityOutcome.InsufficientData
        }
        break
      default:
        throw new Error('No products')
    }

    return {
      applicationIdentifier,
      journeyType,
      products,
      representativeJourneyType: i.representativeJourneyType,
      businessType: i.businessType,
      initiatedBySales: i.initialisationParameters.sales,
      partnerType: this.getPartnerType(i),
      partnerCustomerId: i.initialisationParameters.partnerCustomerId,
      channelType: this.getChannelType(i),
      metadata: {},
      canCallClient: i.canCallClient,
      hasBackgroundChecksTimeout: false
    }
  }

  getJourneyType = (i: ICreateApplicationState): JourneyType => {
    switch (i.buttonType) {
      case ButtonType.Standard:
        if (i.initialisationParameters.partner === PartnerType.Barclays && features.BarclaysJourneyEnabled === true) {
          return JourneyType.BarclaysStandard
        }
        return JourneyType.Standard
      case ButtonType.RecoveryLoan:
        return JourneyType.RecoveryLoans
      default:
        throw new Error('Unknown button')
    }
  }

  getPartnerType = (i: ICreateApplicationState): PartnerType | undefined => {
    if (features.BarclaysJourneyEnabled !== true && i.initialisationParameters.partner === PartnerType.Barclays) {
      return undefined
    }
    return i.initialisationParameters.partner
  }

  getChannelType = (i: ICreateApplicationState): ChannelType | undefined => {
    if (features.BarclaysJourneyEnabled !== true && i.initialisationParameters.partner === PartnerType.Barclays) {
      return undefined
    }
    return i.initialisationParameters.channel
  }
}

const IICreateApplicationStateToIApplication = new IICreateApplicationStateToIApplicationImpl()
export default IICreateApplicationStateToIApplication
