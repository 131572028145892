import React from 'react'
import StyledButton from './styledButton'

type Props = {
  isLoading?: boolean
  color: 'primary' | 'secondary'
  id?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  style?: React.CSSProperties
  children: JSX.Element | JSX.Element[] | string | string[]
  loadingText?: string
  isDisabled?: boolean
}

const Button: React.FC<Props> = (props: Props) => {
  return (
    <StyledButton {...props} classType={props.color} disabled={props.isLoading || props.isDisabled}>
      {!props.isLoading && props.children}
      {props.isLoading && (
        <div style={{ display: 'inline-block' }}>{props.loadingText ? props.loadingText : 'Loading...'}</div>
      )}
    </StyledButton>
  )
}

Button.defaultProps = {
  isLoading: false,
  color: 'primary'
}

export default Button
