import { SupportingDocumentsContent } from '../../types/supportingDocuments'

export const supportingDocuments: SupportingDocumentsContent = {
  Heading: 'Please upload the documents as follows',
  ShortHeading: 'Supporting documents',
  Introduction:
    'Finally, to help us get a better understanding of your business’s financial health we’ll need a few documents to help us with your application. Fields with a * are required.',
  OverrideContinueButtonLabel: 'Submit',
  MissingBankStatementstDocuments: 'No bank statements to hand? {SaveForLater}.',
  MissingStatutoryDocuments: 'No statutory accounts to hand? {SaveForLater}.',
  SaveForLater: 'Upload them later',
  OverrideUploadLater: {
    Heading: 'Finish application later',
    Introduction:
      'Upload documents later using any device, we’ll send a secure link to the address below, simply click to pick up from where you left off:'
  },
  BankAccount: {
    Title: 'Business Bank Account Information',
    NonCbils: {
      Paragraphs: [
        'Please upload statements of all the bank accounts covering the period from {Dynamic.BankStatementsFrom} until at least {Dynamic.BankStatementsTo}.'
      ]
    },
    BankStatements: {
      Text: 'Bank statements',
      Tooltip:
        'We’re happy to accept PDFs or scanned copies of physical documents. Please provide statements for all business bank accounts. Please ensure the file sizes don’t exceed 50MB.',
      Error: {
        Required: 'At least one file must be uploaded',
        Uploading: 'Upload in progress'
      },
      AcceptedFormats: 'Accepted file formats: PDF, PNG, JPEG. Maximum file size: 50 MB'
    }
  },

  StatutoryAccounts: {
    GenericCompany: {
      Title: 'Statutory Accounts',
      Introduction: 'We’ll need your latest unabbreviated accounts that you filed with Companies House.'
    },
    StartupCompany: {
      Title: 'Financial Accounts',
      Introduction:
        "As you haven't yet filed statutory accounts with Companies House, please provide us with one or more of the following:",
      BulletPoints: ['1. Draft or management accounts', '2. Contracts and/or purchase orders', '3. VAT returns'],
      Section: 'To be eligible for the application, these documents should equate to £100,000 revenue or more'
    },
    NonStartupCompany: {
      Title: 'Statutory Accounts',
      Introduction:
        'Please upload your latest accounts filed with Companies House made up to at least {Dynamic.LastFilingDate}',
      Body: 'Accounts must be unabbreviated, showing the Profit and Loss statement, the balance sheet, and the notes, and published in the past 12 months. Unaudited or draft accounts are acceptable'
    },
    GenericCompanyAddition: {
      Title: 'Statutory Accounts',
      Introduction: [
        'Please upload your latest accounts filed with Companies House made up to at least {Dynamic.LastFilingDate}',
        'Accounts must be unabbreviated, showing the Profit and Loss statement, the balance sheet, and the notes, and published in the past 12 months. Unaudited or draft accounts are acceptable'
      ],
      Tooltip: [
        'Accounts must be unabbreviated, showing the Profit and Loss statement, the balance sheet, and the notes, and published in the last 12 months. Unaudited or draft accounts are acceptable.',
        'Management accounts must include the Profit and Loss statement, and the balance sheet.'
      ]
    },
    StatutoryAccounts: {
      Text: 'Statutory accounts',
      Tooltip:
        'Accounts must be unabbreviated, showing the Profit and Loss statement, the balance sheet, and the notes, and published in the last 12 months. Unaudited or draft accounts are acceptable.',
      Error: {
        Required: 'At least one file must be uploaded',
        Uploading: 'Upload in progress'
      }
    }
  }
}
