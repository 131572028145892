import { IToken, IStaticToken, TokenType, IReferenceToken } from './token'
import { deepGetPath } from './deepGet'
import { KeyValue } from 'src/applyfrontendcontent'

enum State {
  StringToken = 'StringToken',
  PotentialStartReference = 'PotentialStartReference',
  PotentialEndReference = 'PotentialEndReference',
  ReferenceToken = 'ReferenceToken'
}

const createStaticToken = (value: string): IStaticToken => {
  return {
    type: TokenType.Static,
    value
  }
}

const createReferenceToken = (name: string, ctx: KeyValue): IReferenceToken => {
  return {
    type: TokenType.Reference,
    name,
    value: deepGetPath(name, ctx)
  }
}

export const tokenise = (input: string, ctx: KeyValue): IToken[] => {
  const ret: IToken[] = []
  let currentValue: string = ''
  let state: State = State.StringToken
  let i = 0
  while (i < input.length) {
    const c = input.charAt(i)
    switch (c) {
    case '{':
      if (state === State.ReferenceToken) {
        throw new Error('nested references not allowed')
      } else if (state === State.StringToken) {
        state = State.PotentialStartReference
      } else if (state === State.PotentialEndReference) {
        state = State.PotentialStartReference
      } else if (state === State.PotentialStartReference) {
        currentValue += c
        state = State.StringToken
      }
      break
    case '}':
      if (state === State.ReferenceToken) {
        // add new reference token to return
        ret.push(createReferenceToken(currentValue, ctx))
        state = State.StringToken
        currentValue = ''
      } else if (state === State.StringToken) {
        state = State.PotentialEndReference
      } else if (state === State.PotentialEndReference) {
        currentValue += c
        state = State.StringToken
      } else if (state === State.PotentialStartReference) {
        throw new Error('no empty references')
      }
      break
    default:
      if (state === State.ReferenceToken) {
        if (c.match(/[a-zA-Z.0-9]/) === null) {
          throw new Error('references can only contain letters and numbers (and dots)')
        }
        currentValue += c
      } else if (state === State.StringToken) {
        currentValue += c
      } else if (state === State.PotentialEndReference) {
        throw new Error('rogue closing reference tag')
      } else if (state === State.PotentialStartReference) {
        // add new token to return
        // add string token with current value and reset
        if (currentValue.length > 0) {
          ret.push(createStaticToken(currentValue))
        }
        state = State.ReferenceToken
        currentValue = c
      }
      break
    }
    ++i
  }

  // at this stage state should be StringToken, anything else is an error
  if (state !== State.StringToken) {
    throw new Error('malformed input')
  } else if (currentValue.length > 0) {
    ret.push(createStaticToken(currentValue))
  }
  return ret
}
