import { RequirementsContent, GenericRequirementContent } from '../types/requirements'
import { ProductType } from '../types/productType'

export const Requirements: RequirementsContent = {
  Heading: 'What you need to apply',

  Products: [
    {
      Index: 0,
      Heading: 'Invoice Finance',
      ProductType: ProductType.InvoiceFinance
    },
    {
      Index: 1,
      Heading: 'Business loan',
      FeatureName: 'BusinessLoanEnabled',
      ProductType: ProductType.BusinessLoan
    }
  ],

  InformationHeading: 'Information',
  Information: [
    {
      Index: 0,
      Heading: 'Company name and registration number'
    },
    {
      Index: 1,
      Heading: 'Applying director date of birth and residential address'
    },
    {
      Index: 2,
      Heading: 'Latest 6 months of bank statements'
    },
    {
      Index: 3,
      Heading: 'Latest statutory accounts or HMRC tax calculation'
    },
    {
      Index: 4,
      Heading: 'Turnover for the calendar year 2019'
    }
  ],

  CloseButton: 'Close'
}

export const GenericProductRequirements: GenericRequirementContent = {
  Dob: 'Date of birth and residential address for the applying director',
  BankStatements: 'Bank statements covering the period from {Dynamic.AccountsFrom} until at least {Dynamic.AccountsTo}',
  StatutoryAccounts: 'Latest statutory accounts',
  Turnover: 'Turnover for the calendar year 2019',
  HMRC: 'HMRC tax calculation of {Dynamic.TaxYearOneStart} to {Dynamic.TaxYearOneEnd} and {Dynamic.TaxYearTwoStart} to {Dynamic.TaxYearTwoEnd}'
}
