import styled from 'styled-components'

import { media, Paragraph } from 'src/ui-framework'
import { ListItems } from '../../../readyToApply/ReadyToApplyStyles'

export const ButtonGroup = styled.div`
  ${media.xsOnly`
  display: flex;
  flex-direction: column;
`}

  margin-top: 15px;
`

export const OptionsParagraph = styled(Paragraph)`
  margin: 0;
`

export const EligibilityListItems = styled(ListItems)`
  display: flex;
  font-weight: 400;
  margin-top: 16px;
  padding-top: 0px;
`
export const EligibilityBulletsUnorderedList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: 24px;
  margin-bottom: 32px;
`
