export type FontSize = {
  xxs: string
  xs: string
  s: string
  m: string
  l: string
  xl: string
  xxl: string
}

export const fontSize: FontSize = {
  xxs: '12px',
  xs: '14px',
  s: '16px',
  m: '20px',
  l: '26px',
  xl: '32px',
  xxl: '40px'
}
