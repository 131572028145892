import { MenuItem } from '@material-ui/core'
import styled from 'styled-components'

export const StyledMenuItemLink = styled.a`
  width: 160px;
  height: 24px;
  padding-left: 10px;
  text-decoration: none;
  color: black;
  &:hover {
    background-color: #cccccc;
  }
  &:focus {
    background-color: #001061;
    color: white !important;
  }
`

export const StyledMenuItem = styled(MenuItem)`
  padding: 0 !important;
  line-height: 1.6;
`

export const PartnerDiv = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  height: 80px;
`

export const StyledNavigationLink = styled.a`
  height: 80px;
  display: flex;

  &:focus {
    outline: none;
  }
`

export const StyledNavigationPartnerLink = styled.div`
  display: block;
  border: 2px solid transparent;
  padding: 4px 5px 3px 5px;

  &:focus {
    outline: none;
  }
`
export const StyledNavigationPartnerImage = styled.img`
  height: auto;
  vertical-align: middle;
  position: sticky;
  top: 20px;
`
