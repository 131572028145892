import SegmentWrapper from '../../../utils/segmentWindow'
import { RepresentativeJourneyType } from '../../../types/RepresentativeJourneyType'
import { PartnerType } from '../../../types/PartnerType'
import { ChannelType } from '../../../types/ChannelType'

export type HotjarPageDataType = {
  sales?: boolean
  partner?: PartnerType
  channel?: ChannelType
  repJourney?: RepresentativeJourneyType
}

export const SetJourneyTypePageView = (params: HotjarPageDataType) => {
  if (params?.sales === true) {
    SegmentWrapper.virtualPageView('sales-impersonation', undefined, true)
  }

  const repJourneyText = params.repJourney ? params.repJourney.toString().toLowerCase() : 'unknown'

  SegmentWrapper.virtualPageView(`user-${repJourneyText}`, undefined, true)

  if (params.partner !== undefined && params.channel !== undefined) {
    SegmentWrapper.virtualPageView(`${`${params.partner}-${params.channel}`.toString().toLowerCase()}`, undefined, true)
  }
}

export const SetHotjarPageData = (
  isSales: boolean,
  partner: PartnerType | undefined,
  channel: ChannelType | undefined,
  representativeJourney: RepresentativeJourneyType | undefined
) => {
  const hotjarPageView: HotjarPageDataType = {
    sales: isSales,
    partner: partner,
    channel: channel,
    repJourney: representativeJourney
  }

  SetJourneyTypePageView(hotjarPageView)
}
