import { useRef, useEffect, useCallback } from 'react'

const useAutoSave = (callback: () => Promise<void>, timeout: number = 3000): (() => void) => {
  const timerRef = useRef<number | undefined>(undefined)

  const cancelTimer = useCallback(() => {
    if (timerRef.current === undefined) {
      return
    }

    clearTimeout(timerRef.current)
    timerRef.current = undefined
  }, [])

  useEffect(() => {
    cancelTimer()

    timerRef.current = window.setTimeout(async () => {
      timerRef.current = undefined
      await callback()
    }, timeout)

    return cancelTimer
  }, [callback, timeout, cancelTimer])

  return cancelTimer
}

export default useAutoSave
