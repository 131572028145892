import styled from 'styled-components'
import { media } from '../../commonStyles/mediaQueries/responsiveDeviceSize'

type StyledParagraphProps = {
  theme: any
}

const StyledParagraph = styled.p<StyledParagraphProps>`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.fontSize.s};
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0;
  color: ${(props) => props.theme.colors.textGrey900};

  ${media.upToTablet`
    line-height: 20px;
    font-size: ${(props: StyledParagraphProps) => props.theme.fontSize.xs};
  `}
`

export default StyledParagraph
