import React from 'react'
import { tokenise } from './tokeniser'
import { IToken, isReferenceToken } from './token'
import {
  ReadyToApplyContent,
  FooterContent,
  AllGetStartedContent,
  KeyValue,
  FieldContent,
  AllFundingNeedsContent,
  RadioButtonContent,
  AllYourCompanyContent,
  AllDirectorDetailsContent,
  AllSupportingDocumentsContent,
  AllSaveForLaterContent,
  Options,
  Product,
  ExitApplicationContent,
  ListItemTooltipContent,
  RequirementsContent,
  ClosingItemContent,
  RequirementInformationItem
} from 'src/applyfrontendcontent'
import { SelectOptions } from '../../types/ISelectOptions'
import { StyledComponent } from 'styled-components'

export type ComponentWithProps<T extends object> = {
  Component:
    | React.FunctionComponent<React.PropsWithChildren<T>>
    | StyledComponent<any, any, object, string | number | symbol>
  props?: T
}
export type BasicComponentWithProps = ComponentWithProps<any>

type ComponentMap = {
  [key: string]: ComponentWithProps<any>
}

type AllContent =
  | ReadyToApplyContent
  | AllFundingNeedsContent
  | Product
  | ListItemTooltipContent[]
  | FooterContent
  | KeyValue
  | undefined
  | string
  | string[]
  | AllGetStartedContent
  | AllYourCompanyContent
  | AllDirectorDetailsContent
  | AllSupportingDocumentsContent
  | AllSaveForLaterContent
  | RadioButtonContent
  | Options
  | ExitApplicationContent
  | ClosingItemContent
  | ClosingItemContent[]
  | RequirementsContent
  | RequirementInformationItem
  | RequirementInformationItem[]

export type IndexableContent = {
  [key: string]: AllContent | IndexableContent
}

interface IDynamicContentProps {
  content: IndexableContent
  components: ComponentMap
  ignoreComponents?: string[]
}

const renderString = (k: string, content: string, components: ComponentMap, ctx: KeyValue) => {
  const tokens = tokenise(content, ctx)
  const Comp = components[k].Component
  const props = components[k].props || {}
  return (
    <Comp key={`dynamic-${k}`} {...props}>
      {tokens.map((t: IToken, i: number) => {
        const key = `dynamic-${k}-${i}`
        if (isReferenceToken(t) && t.name in components) {
          const RefComp = components[t.name].Component
          const refProps = components[t.name].props || {}
          return (
            <RefComp key={key} {...refProps}>
              {t.value}
            </RefComp>
          )
        }

        return <React.Fragment key={key}>{t.value}</React.Fragment>
      })}
    </Comp>
  )
}

const isStringArray = (input: any[]): input is string[] => {
  for (let index in input) {
    if (typeof input[index] !== 'string') return false
  }
  return true
}

export const DynamicContent = (p: IDynamicContentProps) => {
  return (
    <>
      {Object.keys(p.content).map((k: string) => {
        if (!(k in p.components) || (p.ignoreComponents !== undefined && p.ignoreComponents.includes(k))) return null
        const val = p.content[k]
        if (typeof val === 'string') {
          return renderString(k, val, p.components, p.content as KeyValue)
        } else if (Array.isArray(val)) {
          const itemKey = `${k}Item`
          if (!(itemKey in p.components)) return null
          const ArrayRoot = p.components[k].Component
          return (
            <ArrayRoot key={`array-${k}`} {...p.components[k].props}>
              {isStringArray(val) &&
                val.map((s: string, i: number) => {
                  return (
                    <React.Fragment key={`array-${k}-${i}`}>
                      {renderString(itemKey, s, p.components, p.content as KeyValue)}
                    </React.Fragment>
                  )
                })}
            </ArrayRoot>
          )
        }
        return null
      })}
    </>
  )
}

export const tokeniseToString = (
  content: IndexableContent | null,
  property: keyof IndexableContent,
  context?: IndexableContent
): string => {
  if (content === undefined || content === null) throw new Error(`${property} is null/ undefined`)
  const val = content[property]
  if (typeof val !== 'string') throw new Error(`${property}: not a string`)

  const tokens = tokenise(val, (context || content) as KeyValue)
  return tokens
    .map<string>((t: IToken) => {
      return t.value
    })
    .join('')
}

export const tokeniseLabelAsString = (content: FieldContent): string => {
  return tokeniseToString(content, 'Text')
}

export const tokeniseOptions = (content: RadioButtonContent): SelectOptions[] => {
  const ret: SelectOptions[] = []
  for (let value in content.Options) {
    const label = tokeniseLabelAsString(content.Options[value])
    ret.push({
      label,
      value
    })
  }
  return ret
}

export const tokeniseTooltipAsString = (content: FieldContent): string => {
  return tokeniseToString(content, 'Tooltip')
}
