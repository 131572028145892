import React, { useEffect, useState, useCallback } from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import IApplication from '../../types/IApplication'
import { Redirect, useLocation } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { UnifiedApplicationStatus } from '../../types/IUnifiedApplicationState'
import { tokeniseToString } from '../../shared/content/DynamicContent'
import { ICreateApplicationState } from '../../types/IInitialisationParameters'
import { createApplicationFromInitialisation } from '../../shared/saveUnifiedApplication'
import IICreateApplicationStateToIApplication from '../../mappers/IICreateApplicationStateToIApplication'
import { useGlobalContext } from '../../GlobalContext'
import { applyServiceSuffix } from 'src/urlSettingsExports'
import { FlowType } from 'src/types/FlowType'
import ErrorPage from '../error/ErrorPage'
import { ErrorType } from 'src/reducers/unifiedApplicationActions'
import styled from 'styled-components'
import { EligibilityOutcome } from 'src/types/EligibilityOutcome'

const validateStatus = (status: number): boolean => {
  return status === 204 || status === 200 || status === 201
}

export const Wrapper = styled.div`
  padding-left: 64px;
`

const axiosConfig: AxiosRequestConfig = {
  withCredentials: true,

  validateStatus
}

const error = '00000000-0000-0000-0000-000000000000'

const allSelectedProductsIneligible = (app: IApplication) => {
  const products = app.products ?? {}
  const productKeys = Object.keys(products)
  let ineligibileCount = 0
  let selectedCount = 0
  productKeys.forEach((k) => {
    if (!products[k].isSelected) return

    ++selectedCount
    if (
      products[k].eligibility === EligibilityOutcome.Ineligible ||
      products[k].backgroundEligibility === EligibilityOutcome.Ineligible
    ) {
      ++ineligibileCount
    }
  })
  return selectedCount > 0 && ineligibileCount === selectedCount
}

const ApplicationLocator = () => {
  const { state } = useLocation<ICreateApplicationState>()
  const { copy } = useGlobalContext()

  const axiosInstance = axios.create()
  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 401) {
        setUnauthenticated(true)
      }
      throw error
    }
  )

  const createApplication = useCallback(
    async (id: string) => {
      const app = IICreateApplicationStateToIApplication.map(state, id)
      await createApplicationFromInitialisation(axiosInstance, app)
      setApplicationId(id)
    },
    [state, axiosInstance]
  )

  const [applicationId, setApplicationId] = useState<string | undefined>(undefined)
  const [unauthenticated, setUnauthenticated] = useState<boolean>(false)

  useEffect(() => {
    if (state === undefined || applicationId !== undefined) return
    const initialise = async () => {
      try {
        let id = uuidv4()
        if (state.flowType === FlowType.StrategicPartner) {
          await createApplication(id)
        } else {
          const response = await axiosInstance.get<IApplication[]>(`/${applyServiceSuffix()}/applications`, axiosConfig)
          if (response.data) {
            let applicationFound = false
            const journeyType = IICreateApplicationStateToIApplication.getJourneyType(state)
            for (let app of response.data) {
              if (app.status === UnifiedApplicationStatus.Draft) {
                if (
                  app.journeyType === journeyType &&
                  app.representativeJourneyType === state.representativeJourneyType &&
                  app.channelType === state.initialisationParameters.channel &&
                  allSelectedProductsIneligible(app) === false
                ) {
                  applicationFound = true
                  id = app.applicationIdentifier
                  setApplicationId(id)
                  return
                }
              }
              // TODO: if RM Draft
            }
            if (!applicationFound) {
              await createApplication(id)
            }
          } else {
            await createApplication(id)
          }
        }
      } catch {
        setApplicationId(error)
      }
    }

    initialise()
  }, [state, createApplication, unauthenticated, axiosInstance, applicationId])

  // if state is null redirect to ready to apply
  if (state === undefined) {
    return (
      <Redirect
        to={{
          pathname: '/application/readyToApply'
        }}
      />
    )
  }
  if (applicationId === undefined) {
    return <Wrapper>{tokeniseToString(copy.Shared, 'LoadingPage')}</Wrapper>
  } else if (applicationId === error) {
    if (unauthenticated) {
      return (
        <ErrorPage
          content={copy}
          errorType={ErrorType.Unauthenticated}
          partner={state.initialisationParameters.partner}
        ></ErrorPage>
      )
    } else {
      return (
        <>
          <h1>{tokeniseToString(copy.Shared, 'SomethingWentWrongError')}</h1>
          <p>{tokeniseToString(copy.Shared, 'LogError')}</p>
        </>
      )
    }
  }

  return (
    <Redirect
      to={{
        pathname: `/application/${applicationId}`,
        state: {} // we should push the actual IApplication here!!
      }}
    />
  )
}

export default ApplicationLocator
