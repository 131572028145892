import { FileUploadExternalCapabilities } from './shared/components/fileUpload/FileUploadWell'
import axios, { AxiosError } from 'axios'

export enum FileUploadResponseStatus {
  Successful,
  Fail
}

export type UploadFileResponse = {
  status: FileUploadResponseStatus
  fileIdentifier?: string
  errors?: string
  name: string
}

const baseUrl = '/files'

class FileUploadCapabilities implements FileUploadExternalCapabilities {
  private uploadFile = async (fileContainer: string, file: File): Promise<UploadFileResponse> => {
    let data = new FormData()
    const fileName = file.name
    data.append(fileName, file)
    const axiosConfig = { headers: { 'Content-Type': 'multipart/form-data' } }
    try {
      // Note this doesn't useApiPost because it is not in a React-Hook context
      const uploadResult = await axios.post(`${baseUrl}/upload/${fileContainer}/${fileName}`, data, axiosConfig)

      if (uploadResult.status === 201) {
        return { name: file.name, status: FileUploadResponseStatus.Successful, fileIdentifier: uploadResult.data }
      }
      return { name: file.name, status: FileUploadResponseStatus.Fail }
    } catch (err) {
      let result = {
        name: file.name,
        status: FileUploadResponseStatus.Fail,
        errors: 'unknown error'
      }

      const axiosError = err as AxiosError
      if (axiosError.isAxiosError) {
        return {
          ...result,
          errors: axiosError.response?.data?.error || axiosError.response?.statusText || axiosError.message
        }
      }

      if (err instanceof Error) {
        return {
          ...result,
          errors: err.message
        }
      }

      return result
    }
  }

  deleteFile = async (fileContainerId: string, fileId: string) => {
    try {
      const deletedFile = await axios.delete(`${baseUrl}/delete/${fileContainerId}/${fileId}`)
      return deletedFile.status === 200
    } catch {
      return false
    }
  }

  downloadFile = async (fileId: string) => {
    const downloadUrlResult = await axios(`${baseUrl}/download/${fileId}`)

    if (downloadUrlResult.status === 200) {
      window.open(downloadUrlResult.data)
    }
  }

  uploadFiles = async (fileContainer: string, acceptedFiles: any): Promise<Array<UploadFileResponse>> => {
    const successfullyUploadedFiles: Array<UploadFileResponse> = []

    for (let acceptedFile of acceptedFiles) {
      const uploadResult = await this.uploadFile(fileContainer, acceptedFile)
      successfullyUploadedFiles.push(uploadResult)
    }

    return successfullyUploadedFiles
  }
}

const capabilities = new FileUploadCapabilities()

export default capabilities
