import { NotSupportedThankYouContent } from '../types/notSupportedThankYou'

export const notSupportedThankYou: NotSupportedThankYouContent = {
  Heading: 'Thank you',
  Introduction: [
    'Thanks for your interest in selective invoice discounting with Kriya.',
    'Unfortunately we can only support Limited Companies or Limited Liability Partnerships.',
    'If this is you, then {ContinueButton} your application!',
    'If not, you can return to {BarclaysButton} to explore other options.'
  ],
  ContinueButton: 'continue',
  BarclaysButton: 'Barclays'
}
