import IUnifiedApplicationState from '../types/IUnifiedApplicationState'
import deepCopy from '../utils/deepCopy'
import {
  IUnifiedApplicationAction,
  IUnifiedApplicationActionApplicationSaved,
  IUnifiedApplicationActionAutoSave,
  IUnifiedApplicationActionError,
  IUnifiedApplicationActionLoaded,
  IUnifiedApplicationActionNavigateTo,
  IUnifiedApplicationActionStepUpdate,
  UnifiedApplicationActionType
} from './unifiedApplicationActions'
import { UnifiedApplicationState } from '../types/UnifiedApplicationState'
import IApplicationToIUnifiedApplicationState from '../mappers/IApplicationToIUnifiedApplicationState'
import { PageIdentifier } from '../types/PageIdentifier'
import getJourneyById from '../pages/journeyFactory'
import { Journey } from '../pages/journey'
import { isCurrentlyAutoSaving } from '../shared/saveUnifiedApplication'
import { UnifiedApplicationSteps } from '../types/UnifiedApplicationSteps'

const updateStateFromForm = (
  journey: Journey,
  pageIdentifier: PageIdentifier,
  data: IUnifiedApplicationState,
  isAutoSave: boolean,
  nextStep: UnifiedApplicationSteps | undefined
) => {
  if (isAutoSave) {
    data.lastCompletedStep = journey.getPreviousStep(pageIdentifier.id)
  } else {
    var nextPage = journey.getPageFromStep(nextStep)
    var currentLastCompletedOrder = data.lastCompletedStep?.order ?? 0
    if (nextPage !== undefined && nextPage.order >= currentLastCompletedOrder) {
      data.lastCompletedStep = journey.getPreviousStep(nextPage.id)
    } else {
      data.lastCompletedStep = pageIdentifier
    }
  }

  if (
    isAutoSave === false &&
    (data.maxCompletedstep === undefined || data.maxCompletedstep.order < pageIdentifier.order)
  ) {
    data.maxCompletedstep = pageIdentifier
  }
  return data
}

export default (
  prevState: IUnifiedApplicationState,
  action: IUnifiedApplicationAction<object>
): IUnifiedApplicationState => {
  const newState = deepCopy<IUnifiedApplicationState>(prevState) as IUnifiedApplicationState
  const journey = getJourneyById(newState.journeyType, newState.status, newState.channelType)

  switch (action.type) {
    case UnifiedApplicationActionType.Error:
      const errorAction = action as IUnifiedApplicationActionError
      newState.state = UnifiedApplicationState.Error
      newState.errorType = errorAction.payload.type
      break

    case UnifiedApplicationActionType.Navigate:
      const navigateAction = action as IUnifiedApplicationActionNavigateTo
      newState.state = UnifiedApplicationState.Navigate
      newState.nextStep = navigateAction.payload.step
      break

    case UnifiedApplicationActionType.Loaded:
      const loadedAction = action as IUnifiedApplicationActionLoaded
      const stateApplication = IApplicationToIUnifiedApplicationState.map(
        loadedAction.payload.application,
        loadedAction.payload.isNew,
        loadedAction.payload.userRole
      )
      return stateApplication

    case UnifiedApplicationActionType.StepUpdate:
      const stepUpdateAction = action as IUnifiedApplicationActionStepUpdate
      const updatedState = updateStateFromForm(
        journey,
        stepUpdateAction.payload.pageIdentifier,
        stepUpdateAction.payload.data,
        false,
        stepUpdateAction.payload.nextStep
      )
      if (stepUpdateAction.payload.nextStep === undefined) {
        const nextStep = journey.getNextStep(stepUpdateAction.payload.pageIdentifier.id)
        updatedState.nextStep = nextStep?.step
      } else {
        updatedState.nextStep = stepUpdateAction.payload.nextStep
      }
      updatedState.state = UnifiedApplicationState.Submitting
      return updatedState

    case UnifiedApplicationActionType.AutoSaving:
      const autoSaveAction = action as IUnifiedApplicationActionAutoSave
      const stateToReturn = updateStateFromForm(
        journey,
        autoSaveAction.payload.pageIdentifier,
        autoSaveAction.payload.data,
        true,
        undefined
      )
      stateToReturn.state = UnifiedApplicationState.AutoSaving
      return stateToReturn

    case UnifiedApplicationActionType.ApplicationSaved:
      const applicationSavedAction = action as IUnifiedApplicationActionApplicationSaved
      if (newState.state === UnifiedApplicationState.Submitting) {
        const serverApplication =
          applicationSavedAction.payload.application === null
            ? newState
            : IApplicationToIUnifiedApplicationState.map(
                applicationSavedAction.payload.application,
                false,
                prevState.userRole
              )
        // If lastCompletedStepId === journey.last() - then this is a completion
        serverApplication.state =
          serverApplication.lastCompletedStep?.id === journey.last().id
            ? UnifiedApplicationState.Completed
            : UnifiedApplicationState.Navigate
        serverApplication.isNew = applicationSavedAction.payload.success ? false : prevState.isNew
        serverApplication.nextStep = newState.nextStep
        return serverApplication
      } else {
        isCurrentlyAutoSaving(false)
        // After AutoSave
        newState.state = UnifiedApplicationState.Pristine
        newState.isNew = applicationSavedAction.payload.success ? false : prevState.isNew
        return newState
      }

    case UnifiedApplicationActionType.AfterNavigate:
      // This is needed so that we can correctly handle
      // the user pressing the back button within our
      // workflow
      newState.state = UnifiedApplicationState.Pristine
      newState.nextStep = undefined
      break
  }

  return newState
}
