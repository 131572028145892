import React from 'react'
import {
  BackButton,
  BackButtonText,
  FooterWrapper,
  H1Wrapper,
  MainContent,
  MainContentWrapper,
  NumberCircle,
  ProgressBarWrapper,
  StyledLink,
  StyledProgressItem,
  StyledSpan
} from './progressLayoutStyles'
import { H2 } from 'src/commonStyles/components/headings'
import ProgressBar from '../ProgressBar'
import { Footer } from 'src/shared/components/footer'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import { PageComponents } from 'src/types/PageIdentifier'

type ProgressItemProps = {
  label: string
  index: number
  selected?: boolean
  future: boolean
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

export const ProgressItem = ({ label, index, selected, future, onClick }: ProgressItemProps) => (
  <StyledProgressItem future={future}>
    <NumberCircle future={future} selected={selected}>
      {index}
    </NumberCircle>
    {(selected || future) && <StyledSpan>{label}</StyledSpan>}
    {selected || future || (
      <StyledLink onClick={onClick} role="link" href="#">
        {label}
      </StyledLink>
    )}
  </StyledProgressItem>
)

ProgressItem.defaultProps = {
  selected: false
}

type Option = {
  id?: string
  isHidden?: boolean
  order: Number
  pageIds?: string[]
  label: string
  labelShort: string
  hideHeader?: boolean
}

type ProgressLayoutProps = {
  selected?: string
  options: Option[]
  children: React.ReactNode
  onNavigate: (id: string) => void
  hidden?: Array<PageComponents>
  fillParent?: boolean
}

// TODO: Needs to be extracted to main project folder
const ProgressLayout = ({ selected, options, children, onNavigate, hidden, fillParent }: ProgressLayoutProps) => {
  let selectedOption = options.filter(
    (x: Option) => x.id === selected || (x.pageIds && selected !== undefined && x.pageIds.includes(selected))
  )[0]
  if (selectedOption === undefined) {
    selectedOption = {
      label: '',
      labelShort: '',
      order: -1
    }
  }
  const visibleOptions = options.filter((option: Option) => !option.isHidden)
  const handleOnNavigateBack = () => {
    const curOptionIndex = options.findIndex((o) => o.id === selected)
    const prevOptionId = options
      .filter((_, i) => i < curOptionIndex)
      .slice()
      .reverse()
      .find((x) => !x.isHidden)?.id
    if (prevOptionId === undefined) return
    onNavigate(prevOptionId)
  }

  return (
    <>
      {!hidden?.includes(PageComponents.Stepper) && (
        <ProgressBarWrapper>
          <ProgressBar
            steps={visibleOptions.map((o) => ({ displayName: o.labelShort }))}
            currentStep={selectedOption.order.valueOf() - 1}
          ></ProgressBar>
        </ProgressBarWrapper>
      )}
      <MainContentWrapper verticallyExtensible={fillParent}>
        <MainContent>
          {selectedOption.order.valueOf() !== 1 && !hidden?.includes(PageComponents.Back) && (
            <BackButton onClick={handleOnNavigateBack}>
              <div style={{ display: 'flex' }}>
                <KeyboardArrowLeftIcon style={{ fontSize: '20px' }} />
                <BackButtonText>Back</BackButtonText>
              </div>
            </BackButton>
          )}
          {!hidden?.includes(PageComponents.Heading) && (
            <H1Wrapper>
              <H2>{selectedOption.label}</H2>
            </H1Wrapper>
          )}

          {children}
        </MainContent>
      </MainContentWrapper>
      {!hidden?.includes(PageComponents.Footer) && (
        <FooterWrapper>
          <Footer></Footer>
        </FooterWrapper>
      )}
    </>
  )
}
ProgressLayout.defaultProps = {
  sidebarHidden: false,
  RightContent: undefined,
  selected: '',
  hidden: new Array<PageComponents>()
}
export default ProgressLayout
