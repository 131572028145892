import { Config } from '../../types/locale'
import { fundingNeeds } from './FundingNeeds'
import { getStarted } from './GetStarted'
import { supportingDocuments } from './SupportingDocuments'
import { yourCompany } from './YourCompany'
import { directorDetails } from './DirectorDetails'
import { fairProcessingNotice } from './FairProcessingNotice'
import { thankYou } from './ThankYou'
import { eligibilityResult } from './EligibilityResult'

export const representativeJourney: Partial<Config> = {
  FundingNeeds: fundingNeeds,
  GetStarted: getStarted,
  EligibilityResult: eligibilityResult,
  SupportingDocuments: supportingDocuments,
  YourCompany: yourCompany,
  DirectorDetails: directorDetails,
  FairProcessingNotice: fairProcessingNotice,
  ThankYou: thankYou
}
