import { ThankYouContent } from 'src/applyfrontendcontent/types/thankyou'

export const thankYou: ThankYouContent = {
  SuccessMessage: 'This referral has been registered successfully.',
  Heading: 'What happens next?',
  Introduction: [
    'We’ve sent your client a link to complete their application.',
    'A member of the Kriya team will be in touch with them directly to guide them through the application.',
    'If you have any questions, please contact: {PartnerContact}'
  ],
  PartnerContact: 'barclayspartner@kriya.co',
  PartnerContactLink: 'mailto:barclayspartner@kriya.co',
  InvoiceFinanceBulletPoints: [],
  RecoveryLoanBulletPoints: [],
  RecoveryLoanAndInvoiceFinanceBulletPoints: [],
  Closing: ''
}
