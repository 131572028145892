import { IneligibleResultContents } from 'src/applyfrontendcontent/types/eligbilityResult'

export const ineligibilityResult: IneligibleResultContents = {
  Heading: 'Ineligible',
  ShortHeading: 'Ineligible',
  Autorejected: {
    Heading: "We're sorry, your referral has been auto-declined",
    ShortHeading: 'Ineligible',
    Content: [
      'Thank you for completing the initial eligibility form. After reviewing the information provided and running our initial background checks, unfortunately your customer is not currently eligible for funding from Kriya.',
      'Reasons for your customer being deemed ineligible may be due to high risk, credit scoring, director information, or previous business activities/failures.'
    ],
    ContactUs:
      'If you have any questions about the result, please contact {ContactUsEmail} and a member of the team will be happy to assist you.',
    ContactUsEmail: 'barclayspartner@kriya.co',
    ContactUsEmailLink: 'mailto:barclayspartner@kriya.co',
    GoBackButton: 'Go back to eligibility check'
  },
  Default: {
    Heading: "We're sorry",
    ShortHeading: 'Ineligible',
    Content: [
      "Thank you for completing the initial eligibility form. On this occasion, the information you've provided on behalf of your customer does not meet the eligibility criteria."
    ],
    ContactUs:
      'If you have any questions about the result, please contact {ContactUsEmail} and a member of the team will be happy to assist you.',
    ContactUsEmail: 'barclayspartner@kriya.co',
    ContactUsEmailLink: 'mailto:barclayspartner@kriya.co',
    GoBackButton: 'Go back to eligibility check'
  }
}
