import IDateState from '../types/IDateState'

export enum DateValidatorResult {
  Incomplete,
  InvalidDate,
  GreaterThanMax,
  LessThanMin,
  Ok
}

const dateValidator = (date: IDateState | undefined, min?: Date, max?: Date): DateValidatorResult => {
  if (date !== undefined && date.day && date.month && date.year) {
    if (isNaN(date.day) || isNaN(date.month) || isNaN(date.year)) {
      return DateValidatorResult.InvalidDate
    } else {
      const dateObject = new Date(Date.UTC(date.year, date.month - 1, date.day))
      if (
        dateObject.getUTCDate() !== date.day ||
        dateObject.getUTCMonth() + 1 !== date.month ||
        dateObject.getUTCFullYear() !== date.year
      ) {
        return DateValidatorResult.InvalidDate
      } else if (max !== undefined && dateObject > max) {
        return DateValidatorResult.GreaterThanMax
      } else if (min !== undefined && dateObject < min) {
        return DateValidatorResult.LessThanMin
      }
    }

    return DateValidatorResult.Ok
  }

  return DateValidatorResult.Incomplete
}

export default dateValidator
