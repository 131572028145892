import React from 'react'
import { Redirect } from 'react-router-dom'
import { Checkbox } from 'src/ui-framework'
import { UnifiedApplicationSteps } from 'src/types/UnifiedApplicationSteps'
import ContinueAndSaveLater from 'src/pages/unifiedApplicationJourney/ContinueAndSaveLater'
import { eligibilityResultsPageIdentifier } from 'src/pages/unifiedApplicationJourney/eligibility/results/eligible/eligibilityResultsPageIdentifier'
import {
  BasicComponentWithProps,
  DynamicContent,
  tokeniseLabelAsString,
  tokeniseToString
} from 'src/shared/content/DynamicContent'
import { getSaveForLaterEmail } from 'src/shared/getSaveForLaterEmail'
import useUnifiedApplicationStep from 'src/shared/hooks/useUnifiedApplicationStep'
import { EligibilityOutcome } from 'src/types/EligibilityOutcome'
import IUnifiedApplicationStepProps from 'src/types/IUnifiedApplicationStepProps'
import { UnifiedApplicationState } from 'src/types/UnifiedApplicationState'
import { EligibleByPartnerContent } from 'src/applyfrontendcontent/types/eligbilityResult'
import PartnerEligibilityResultsReducer from 'src/reducers/partnerReducers/partnerEligibilityResultsReducer'
import IPartnerEligibilityResultsState from 'src/types/partner/IPartnerEligibilityResultsState'
import UnifiedApplicationStepGuard from 'src/pages/unifiedApplicationJourney/UnifiedApplicationStepGuard'
import {
  Banner,
  ConsentForm,
  ConsentFormHeading,
  ConsentFraudStatementsList,
  ConsentStatementsList,
  ErrorMessage,
  FraudHeading,
  ParagraphStyled,
  StyledHeading,
  SupportRequirementsList
} from './PartnerEligibilityResultsStyles'

const Message: BasicComponentWithProps = {
  Component: Banner,
  props: {
    id: 'message-block',
    style: { marginTop: '0' }
  }
}

const Heading: BasicComponentWithProps = {
  Component: StyledHeading,
  props: {
    id: 'message-block',
    style: { marginTop: '0' }
  }
}

interface IEligibilityResultsProps extends IUnifiedApplicationStepProps<IPartnerEligibilityResultsState> {}
const reducer = new PartnerEligibilityResultsReducer(eligibilityResultsPageIdentifier)

const PartnerEligibilityResults = (p: IEligibilityResultsProps) => {
  const [state, dispatch, content, onSendLink] = useUnifiedApplicationStep<
    IPartnerEligibilityResultsState,
    PartnerEligibilityResultsReducer
  >(reducer, p.initialState, p.applicationIdentifier, p.onSubmit, p.onAutoSave)

  if (state.state === UnifiedApplicationState.Loading) {
    return <p>{tokeniseToString(content.Shared, 'LoadingPage')}</p>
  }

  const productsKeys = Object.keys(state.products)

  const allSelectedProductsPass = productsKeys.every((key) => {
    let value = state.products[key]
    return !value.isSelected || value.eligibility === EligibilityOutcome.Eligible
  })

  let locatedContent: EligibleByPartnerContent | undefined

  if (allSelectedProductsPass && !state.outcome) {
    locatedContent = content.EligibilityResult.EligibleForInvoiceFinanceOnlyByPartner

    if (!locatedContent) {
      return (
        <Redirect
          to={{
            pathname: '/error',
            state: {
              content
            }
          }}
        />
      )
    }

    let components: { [k: string]: any } = {
      Message,
      Heading
    }

    return (
      <UnifiedApplicationStepGuard currentState={state}>
        <DynamicContent content={locatedContent} components={components} />

        {locatedContent.Statements?.Consent && (
          <ConsentStatementsList>
            {locatedContent.Statements.Consent.map((v, i) => (
              <ParagraphStyled key={i}>{v}</ParagraphStyled>
            ))}
          </ConsentStatementsList>
        )}

        {locatedContent.SupportRequirementsHeading && (
          <FraudHeading>{locatedContent.SupportRequirementsHeading}</FraudHeading>
        )}

        {locatedContent.Statements?.SupportRequirements && (
          <SupportRequirementsList>
            {locatedContent.Statements.SupportRequirements.map((v, i) => (
              <ParagraphStyled key={i}>{v}</ParagraphStyled>
            ))}
          </SupportRequirementsList>
        )}

        {locatedContent.ChecksAndFraudHeading && <FraudHeading>{locatedContent.ChecksAndFraudHeading}</FraudHeading>}

        {locatedContent.Statements?.ChecksAndFraud && (
          <ConsentFraudStatementsList>
            {locatedContent.Statements.ChecksAndFraud.map((v, i) => (
              <ParagraphStyled key={i}>{v}</ParagraphStyled>
            ))}
          </ConsentFraudStatementsList>
        )}

        {locatedContent.ConfirmationHeading && (
          <ConsentFormHeading>{locatedContent.ConfirmationHeading}</ConsentFormHeading>
        )}

        <ConsentForm>
          <Checkbox
            checked={state.notepadReviewed}
            name="q-notepad"
            id="q-notepad"
            errors={state.errors.notepadReviewed as string[]}
            hideErrors
            label={tokeniseLabelAsString(locatedContent.ConsentQuestions.Notepad)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch.valueUpdate('notepadReviewed', e.target.checked)
            }}
            required
          />

          <Checkbox
            checked={state.overdraftReviewed}
            name="q-overdraft"
            id="q-overdraft"
            errors={state.errors.overdraftReviewed as string[]}
            hideErrors
            label={tokeniseLabelAsString(locatedContent.ConsentQuestions.Overdraft)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch.valueUpdate('overdraftReviewed', e.target.checked)
            }}
            required
          />

          <Checkbox
            checked={state.disclosureReviewed}
            name="q-disclosure"
            id="q-disclosure"
            errors={state.errors.disclosureReviewed as string[]}
            hideErrors
            label={tokeniseLabelAsString(locatedContent.ConsentQuestions.Disclosure)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch.valueUpdate('disclosureReviewed', e.target.checked)
            }}
            required
          />

          <Checkbox
            checked={state.bbbInfoReviewed}
            name="q-bbb-info"
            id="q-bbb-info"
            errors={state.errors.bbbInfoReviewed as string[]}
            hideErrors
            label={tokeniseLabelAsString(locatedContent.ConsentQuestions.BbbInfo)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch.valueUpdate('bbbInfoReviewed', e.target.checked)
            }}
            required
          />
        </ConsentForm>

        {state.errors.hasErrors && <ErrorMessage>{locatedContent.ErrorAlert}</ErrorMessage>}

        <ContinueAndSaveLater
          content={content}
          loading={state.state === UnifiedApplicationState.Submitted}
          email={getSaveForLaterEmail(state)}
          id="continue-application"
          onSendLink={onSendLink}
          errors={{ hasErrors: false }}
          showSaveLater={false}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault()
            dispatch.valuesUpdate([
              { field: 'skipToStep', value: UnifiedApplicationSteps.ReferralDetails },
              { field: 'state', value: UnifiedApplicationState.Submitting }
            ])
          }}
        />
      </UnifiedApplicationStepGuard>
    )
  }

  return (
    <Redirect
      to={{
        pathname: '/error',
        state: {
          content
        }
      }}
    />
  )
}

export default PartnerEligibilityResults
