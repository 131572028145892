import React from 'react'
import { withStyles } from '@material-ui/core/styles'

// import { theme } from '../../theme/theme'
import StyledNavigation, { Props } from './styledNavigation'
import { colors } from '../../theme/colors'

const styles = (props: any) => ({
  navigation: {
    backgroundColor: colors.midnight,
    marginBottom: props.noMargin ? 0 : 16,
    height: '80px',
    boxShadow: 'none',
    // ideally we should reference the
    // theme.breakpoints
    // but it's very unclear to me how
    // one does that
    '@media (max-width: 768px)': {
      marginBottom: 0
    }
  },
  logo: {
    width: 137,
    height: 'auto',
    verticalAlign: 'middle'
  }
})

const withStylesProps = (styles: any) => (Component: any) => (props: any) => {
  const Comp = withStyles(styles(props))(Component)
  return <Comp {...props} />
}

const Navigation = (props: Props) => <StyledNavigation {...props} />
Navigation.defaultProps = StyledNavigation.defaultProps
export default withStylesProps(styles)(Navigation)
