import { useCallback, useEffect, useRef } from 'react'

const useTimer = (interval: number, callback: () => Promise<boolean>) => {
  const timerRef = useRef<number | undefined>(undefined)
  const cancelTimer = useCallback(() => {
    if (timerRef.current === undefined) {
      return
    }

    window.clearTimeout(timerRef.current)
    timerRef.current = undefined
  }, [])

  const internalCallback = useCallback(async () => {
    timerRef.current = undefined
    if (await callback()) {
      timerRef.current = window.setTimeout(internalCallback, interval)
    }
  }, [callback, interval])

  useEffect(() => {
    cancelTimer()
    timerRef.current = window.setTimeout(internalCallback, interval)
    return cancelTimer
  }, [interval, cancelTimer, internalCallback])

  return cancelTimer
}

export default useTimer
