import React, { useCallback } from 'react'
import IUnifiedApplicationState, { ReferralFlow } from '../../types/IUnifiedApplicationState'
import { PageIdentifier } from '../../types/PageIdentifier'
import {
  IUnifiedApplicationAction,
  UnifiedApplicationActionAutoSave,
  UnifiedApplicationActionStepUpdate,
  UnifiedApplicationStepPayload
} from '../../reducers/unifiedApplicationActions'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { IUnifiedApplicationParams } from '../../types/IUnifiedApplicationParams'
import getJourneyById from '../journeyFactory'
import deepCopy from '../../utils/deepCopy'
import { UnifiedApplicationState } from '../../types/UnifiedApplicationState'
import { JourneyType } from '../../types/JourneyType'
import { UnifiedApplicationSteps } from '../../types/UnifiedApplicationSteps'
import { UnifiedJourneyProductType } from 'src/types/ProductType'
import { EligibilityOutcome } from 'src/types/EligibilityOutcome'
import { ineligiblePageIdentifier } from './eligibility/results/ineligible/ineligiblePageIdentifier'
import { eligibilityLoadingPageIdentifier } from './eligibility/results/loading/eligibilityLoadingPageIdentifier'
import { ChannelType } from 'src/types/ChannelType'

interface IUnifiedApplicationStepComponentProps {
  routeParams: RouteComponentProps<IUnifiedApplicationParams>
  unifiedApplication: IUnifiedApplicationState
  dispatch: React.Dispatch<IUnifiedApplicationAction<object>>
}

const UnifiedApplicationStep = (p: IUnifiedApplicationStepComponentProps) => {
  const { dispatch } = p
  const onAutoSave = useCallback(
    (state: UnifiedApplicationStepPayload, pageIdentifier: PageIdentifier) => {
      if (p.unifiedApplication.state === UnifiedApplicationState.AutoSaving) {
        return
      }
      const j = getJourneyById(p.unifiedApplication.journeyType, state.status, state.channelType)
      const data = deepCopy<IUnifiedApplicationState>(p.unifiedApplication) as IUnifiedApplicationState
      j.getRenderer(pageIdentifier.id)?.mapper.reverseMap(state, data, true)
      dispatch(UnifiedApplicationActionAutoSave(data, pageIdentifier))
    },
    [dispatch, p.unifiedApplication]
  )

  // This needs to be `useCallback` because this is passed down
  // to a useEffect in our custom hook and so would be a different
  // instance each time if we don't
  const submitStep = useCallback(
    (
      state: UnifiedApplicationStepPayload,
      pageIdentifier: PageIdentifier,
      nextStep: UnifiedApplicationSteps | undefined
    ): void => {
      if (p.unifiedApplication.state === UnifiedApplicationState.Submitting) {
        return
      }
      const j = getJourneyById(p.unifiedApplication.journeyType, state.status, state.channelType)
      const data = deepCopy<IUnifiedApplicationState>(p.unifiedApplication) as IUnifiedApplicationState
      j.getRenderer(pageIdentifier.id)?.mapper.reverseMap(state, data, false)
      dispatch(UnifiedApplicationActionStepUpdate(data, pageIdentifier, nextStep))
    },
    [dispatch, p.unifiedApplication]
  )

  if (
    p.unifiedApplication.journeyType === JourneyType.Unknown ||
    (p.unifiedApplication.referralFlow === ReferralFlow.Customer && !p.unifiedApplication.isApplicationStarted)
  ) {
    const to = `/application/${p.routeParams.match.params.id}/readytoapply`
    return <Redirect to={to} />
  }

  if (p.unifiedApplication.channelType === ChannelType.Api && !p.unifiedApplication.isApplicationStarted) {
    const to = `/application/${p.routeParams.match.params.id}/readytoapply?partner=Barclays&channel=Api`
    return <Redirect to={to} />
  }

  const journey = getJourneyById(
    p.unifiedApplication.journeyType,
    p.unifiedApplication.status,
    p.unifiedApplication.channelType
  )
  const pageFromUrl =
    p.routeParams.match.params.step === undefined ? undefined : journey.getPageFromStep(p.routeParams.match.params.step)
  const maxPageAllowed =
    p.unifiedApplication.lastCompletedStep === undefined
      ? journey.first()
      : journey.getNextStep(p.unifiedApplication.lastCompletedStep.id) || journey.last()

  if (pageFromUrl === undefined || pageFromUrl.order > maxPageAllowed.order) {
    const to = `/application/${p.routeParams.match.params.id}/${maxPageAllowed.step}`
    return <Redirect to={to} />
  }

  if (
    p.unifiedApplication.products !== undefined &&
    p.unifiedApplication.lastCompletedStep?.id === eligibilityLoadingPageIdentifier.id &&
    pageFromUrl.id !== ineligiblePageIdentifier.id
  ) {
    const sid = p.unifiedApplication.products[UnifiedJourneyProductType.SelectInvoiceDiscounting] ?? {}
    if (
      sid.isSelected &&
      (sid.eligibility === EligibilityOutcome.Ineligible || sid.backgroundEligibility === EligibilityOutcome.Ineligible)
    ) {
      const to = `/application/${p.routeParams.match.params.id}/${ineligiblePageIdentifier.step}`
      return <Redirect to={to} />
    }
  }

  const renderer = journey.getRenderer(pageFromUrl.id)
  if (renderer === undefined) {
    return null
  }

  return (
    <form noValidate>
      <renderer.Component
        applicationIdentifier={p.unifiedApplication.id}
        initialState={renderer.mapper.map(p.unifiedApplication)}
        onAutoSave={onAutoSave}
        onSubmit={submitStep}
      />
    </form>
  )
}

export default UnifiedApplicationStep
