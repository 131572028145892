export enum FileStatusState {
  Pending,
  Rejected,
  Uploaded,
  Deleted,
}

export default interface IFileState {
  id?: string
  name: string
  status: FileStatusState
  error?: string
}
