import { AsyncTypeahead, Menu } from 'react-bootstrap-typeahead'
import styled from 'styled-components'
import { media, Input } from 'src/ui-framework'

export const OptionDiv = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 24px;
`

export const OptionIconDiv = styled.div`
  align-self: center;
`

export const AbsoluteDiv = styled.div`
  width: 100%;
  position: absolute;
  top: 48px;
  z-index: 10;
`

export const StyledMenuItem = styled.li`
  cursor: pointer;
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  color: ${(props) => props.theme.colors.textGrey900};

  .check-icon {
    opacity: 0;
    margin-top: 2px;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.info};
    color: white;

    .check-icon {
      opacity: 1;
    }
  }
  &:active {
    background-color: ${(props) => props.theme.colors.grey200};
    color: ${(props) => props.theme.colors.textGrey900};

    .check-icon {
      opacity: 1;
    }
  }
`

export const MenuItemsLink = styled.a`
  text-decoration: none;
  color: inherit;
  width: 100%;
  margin: auto 0 auto 8px;
  &:focus {
    outline: none;
  }
`

export const StyledMenu = styled(Menu)`
  width: 100%;
  position: relative;
  box-shadow: none;
  border: solid 1px ${(props) => props.theme.colors.grey300};
  border-radius: ${(props) => (props.hideManualInput ? '8px 8px 8px 8px' : '8px 8px 0px 0px')};
  margin-top: 2px;
  padding-top: 0;
  padding-bottom: 0;
  overflow: auto;
  padding: 8px 4px !important;
  background-color: ${(props) => props.theme.colors.grey50};
`

export const MenuItemTitle = styled.div`
  font-size: ${(props) => props.theme.fontSize.s};

  ${media.upToTablet`
    font-size: ${(props) => props.theme.fontSize.xs};
  `}
`
export const MenuItemSub = styled.div`
  font-size: ${(props) => props.theme.fontSize.s};

  ${media.upToTablet`
    font-size: ${(props) => props.theme.fontSize.xs};
  `}
`
const inputDefault = ({ theme: { inputFields } }) => {
  return inputFields
}

const labelDefault = ({ theme: { label } }) => {
  return label
}

export const StyledTypeahead = styled(AsyncTypeahead)`
  .rbt-menu.dropdown-menu {
    position: relative !important;
    width: auto !important;
    transform: none !important;
    top: 1px;
  }
  & ul {
    list-style-type: none;

    & li {
      &.disabled {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
      }
    }
  }

  & a.dropdown-item {
    text-decoration: none;
    cursor: auto;
    color: ${(props) => props.theme.colors.textGrey900};
  }
  & a.dropdown-item.disabled {
    font-size: ${(props) => props.theme.fontSize.s};

    ${media.upToTablet`
      font-size: ${(props) => props.theme.fontSize.xs};
    `}
  }
  .rbt-menu > .dropdown-item {
    display: none;
  }

  & input.rbt-input-hint {
    display: none;
  }

  & .rbt-input-main {
    ${inputDefault}
    padding: 16px;
    font-family: ${(props) => props.theme.fontFamily};
    &:hover {
      background-color: ${(props) => props.theme.colors.grey10};
    }
    &:read-only {
      background-color: ${(props) => props.theme.colors.white};
    }
    ${(props) => {
      if (props.isInvalid) {
        if (props.isFocused) {
          return props.theme.borderStyles.errorFocused
        }
        return {
          border: `1px solid ${props.theme.colors.error} !important`,
          marginBottom: 0
        }
      } else if (props.isFocused && !props.isInvalid) {
        return props.theme.borderStyles.focused
      } else
        return {
          border: `${inputDefault.border}`
        }
    }}
    margin-bottom: 0;
  }
`

export const StyledMainInput = styled(Input)`
  margin-bottom: 0;
`

export const AddManually = styled.div`
  min-height: 48px;
  background-color: white;
  right: 0;
  left: 0;
  z-index: 9999;
  top: 100%;
  text-align: center;
  position: absolute;
  border: solid 1px ${(props) => props.theme.colors.grey300};
  border-width: 0 1px 1px 1px;
  border-radius: 0px 0px 8px 8px;
  color: ${(props) => props.theme.colors.textGrey900};
  font-size: ${(props) => props.theme.fontSize.s};
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${media.upToTablet`
    font-size: ${(props) => props.theme.fontSize.xs};
  `}
`

export const StyledLabel = styled.label`
  ${labelDefault}
  display: block;
  line-height: 1.33;
`

export const AddManuallyLabel = styled.label`
  ${labelDefault}
  vertical-align: middle;
  font-size: ${(props) => props.theme.fontSize.s};

  ${media.upToTablet`
    font-size: ${(props) => props.theme.fontSize.xs};
  `}
`
