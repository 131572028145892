import { Check } from '@material-ui/icons'
import React from 'react'
import styled from 'styled-components'
import {
  StyleProgressBarRow,
  StyleProgressBarLabelRow,
  StyledProgressBarLabel,
  StyleProgressBarFutureStep,
  StyleProgressBarStepDivider,
  StyleProgressBarCurrentStep,
  StyleProgressBarCompletedStep,
  StyleProgressBarLabelDivider,
  StyledProgressBarMobileLabel
} from './progressBarStyles'

type ProgressBarStep = {
  displayName: string
}

type ProgressBarProps = {
  steps: ProgressBarStep[]
  currentStep: number
}

enum ProgressBarStepState {
  Complete,
  Future,
  Selected
}
type ProgressBarStepInternal = {
  displayName: string
  label: string
  includeDivider: boolean
  state: ProgressBarStepState
}

const buildStep = (s: ProgressBarStep, currentStep: number, i: number): ProgressBarStepInternal => {
  const includeDivider = i > 0
  let state: ProgressBarStepState
  if (i === currentStep) {
    state = ProgressBarStepState.Selected
  } else if (i > currentStep) {
    state = ProgressBarStepState.Future
  } else {
    state = ProgressBarStepState.Complete
  }
  const label = state === ProgressBarStepState.Complete ? '✔' : `${i + 1}`
  return {
    displayName: s.displayName,
    includeDivider,
    label,
    state
  }
}

const maxSteps = 5
const bulkFutureResult = (): ProgressBarStepInternal => ({
  displayName: '···',
  includeDivider: true,
  label: '···',
  state: ProgressBarStepState.Future
})
const bulkCompleteResult = (): ProgressBarStepInternal => ({
  displayName: '···',
  includeDivider: true,
  label: '✔',
  state: ProgressBarStepState.Complete
})

const buildSteps = (p: ProgressBarProps): ProgressBarStepInternal[] => {
  if (p.steps.length <= maxSteps) {
    return p.steps.map((s: ProgressBarStep, i: number): ProgressBarStepInternal => buildStep(s, p.currentStep, i))
  } else {
    let result: ProgressBarStepInternal[] = []
    for (let i = 0; i < p.steps.length; i++) {
      if (i < maxSteps - 3 || i === p.steps.length - 1) {
        result.push(buildStep(p.steps[i], p.currentStep, i))
      }

      if (p.currentStep <= maxSteps - 3 && i === maxSteps - 3) {
        result.push(buildStep(p.steps[i], p.currentStep, i))
        result.push(bulkFutureResult())
      } else if (p.currentStep > maxSteps - 3 && p.currentStep < p.steps.length - 2 && i === p.currentStep) {
        result.push(buildStep(p.steps[i], p.currentStep, i))
        if (p.currentStep === p.steps.length - 3) {
          result.push(buildStep(p.steps[i + 1], p.currentStep, i + 1))
        } else {
          result.push(bulkFutureResult())
        }
      } else if (p.currentStep === p.steps.length - 2 && i === p.currentStep) {
        result.push(bulkCompleteResult())
        result.push(buildStep(p.steps[i], p.currentStep, i))
      } else if (p.currentStep === p.steps.length - 1 && i === p.currentStep - 1) {
        result.push(bulkCompleteResult())
        result.push(buildStep(p.steps[i], p.currentStep, i))
      } else if (p.currentStep >= p.steps.length && i === p.steps.length - 2) {
        result.push(bulkCompleteResult())
        result.push(buildStep(p.steps[i], p.currentStep, i))
      }
    }
    return result
  }
}

const CheckIcon = styled(Check)`
  width: 16px;
  color: white;
`

const ProgressBar: React.FunctionComponent<ProgressBarProps> = (p: ProgressBarProps) => {
  if (p.steps.length === 0 || p.currentStep < 0) {
    return null
  }

  const steps: ProgressBarStepInternal[] = buildSteps(p)

  return (
    <div id="progress-bar">
      <StyleProgressBarRow>
        {steps.map((s: ProgressBarStepInternal, i: number) => {
          return (
            <React.Fragment key={`step-${i}`}>
              {s.includeDivider && <StyleProgressBarStepDivider />}
              {s.state === ProgressBarStepState.Selected && (
                <StyleProgressBarCurrentStep className="progress-current-step">
                  <StyleProgressBarCompletedStep>{s.label}</StyleProgressBarCompletedStep>
                </StyleProgressBarCurrentStep>
              )}
              {s.state === ProgressBarStepState.Future && (
                <StyleProgressBarFutureStep className="progress-future-step">{s.label}</StyleProgressBarFutureStep>
              )}
              {s.state === ProgressBarStepState.Complete && (
                <StyleProgressBarCompletedStep className="progress-completed-step">
                  <CheckIcon></CheckIcon>
                </StyleProgressBarCompletedStep>
              )}
            </React.Fragment>
          )
        })}
      </StyleProgressBarRow>
      <StyleProgressBarLabelRow>
        {steps.map((s: ProgressBarStepInternal, i: number) => (
          <React.Fragment key={`label-${i}`}>
            {i > 0 && <StyleProgressBarLabelDivider />}
            <StyledProgressBarLabel>{s.displayName}</StyledProgressBarLabel>
          </React.Fragment>
        ))}
        {p.currentStep < p.steps.length && (
          <StyledProgressBarMobileLabel>
            {`Step ${p.currentStep + 1}: ${p.steps[p.currentStep]?.displayName}`}
          </StyledProgressBarMobileLabel>
        )}
      </StyleProgressBarLabelRow>
    </div>
  )
}

export default ProgressBar
