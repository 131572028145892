import { media } from 'src/ui-framework/commonStyles/mediaQueries/responsiveDeviceSize'
import { colors } from 'src/ui-framework/theme/colors'
import styled from 'styled-components'

export const StyleProgressBarRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyleProgressBarLabelRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: normal;
  margin-top: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${colors.midnight};
  text-align: center;
  ${media.upToTablet`
        margin-top: 10px;
    `}
`

export const StyledProgressBarLabel = styled.div`
  width: 80px;
  ${media.upToTablet`
        display: none;
    `}
`

export const StyledProgressBarMobileLabel = styled.span`
  ${media.excludeMobile`
        display: none;
    `}
`

const StyleProgressBarStep = styled.div`
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border: 1px solid ${colors.midnight};
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`
export const StyleProgressBarCurrentStep = styled(StyleProgressBarStep)`
  width: 28px;
  height: 28px;
  background: ${colors.white};
  border: 1px solid ${colors.midnight};
`
export const StyleProgressBarCompletedStep = styled(StyleProgressBarStep)`
  background: ${colors.midnight};
  color: ${colors.white};
`

export const StyleProgressBarFutureStep = styled(StyleProgressBarStep)`
  background: ${colors.white};
  color: ${colors.midnight};
`

export const StyleProgressBarStepDivider = styled.div`
  width: 70px;
  background: ${colors.midnight};
  height: 2px;
  ${media.upToTablet`
        width: 24px
    `}
`

export const StyleProgressBarLabelDivider = styled.div`
  width: 16px;
  height: 2px;
  ${media.upToTablet`
        display: none;
    `}
`
