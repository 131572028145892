import { hideAllButHeading, PageIdentifier } from 'src/types/PageIdentifier'
import { UnifiedApplicationSteps } from 'src/types/UnifiedApplicationSteps'

export const partnerReferralRegistrationPageIdentifier: PageIdentifier = {
  id: 'e6cdbb0f-837c-4839-a3c9-bf1950724768',
  title: 'Referral details',
  step: UnifiedApplicationSteps.ReferralDetails,
  order: 40,
  isHidden: false,
  subPageIds: [],
  hidden: hideAllButHeading
}
