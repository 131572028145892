import styled from 'styled-components'

type LabelStyleProps = {
  theme: any
}

const Label = styled.label<LabelStyleProps>`
  ${(props) => props.theme.label}
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.fontSize.xs};
  display: block;
  line-height: 18px;
`

export default Label
