import { UnifiedApplicationSteps } from '../../../types/UnifiedApplicationSteps'
import { PageIdentifier } from '../../../types/PageIdentifier'
import { eligibilityResultsPageIdentifier } from './results/eligible/eligibilityResultsPageIdentifier'
import { ineligiblePageIdentifier } from './results/ineligible/ineligiblePageIdentifier'
import { eligibilityLoadingPageIdentifier } from './results/loading/eligibilityLoadingPageIdentifier'

export const eligibilityPageIdentifier: PageIdentifier = {
  id: '4ee7589a-3743-46b3-85c2-b4327ab8e1b5',
  title: 'Eligibility',
  step: UnifiedApplicationSteps.Eligibility,
  order: 25,
  isHidden: false,
  subPageIds: [eligibilityLoadingPageIdentifier.id, eligibilityResultsPageIdentifier.id, ineligiblePageIdentifier.id]
}
