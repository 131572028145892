import { media } from 'src/ui-framework'
import styled from 'styled-components'

export const InputDateWrapper = styled.div``

export const StyledSelect = styled.div`
  ${media.upToTablet`
    flex: 1;
  `}
`
