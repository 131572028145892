import IUnifiedApplicationStateToPartnerEligibilityResultsState from 'src/mappers/IUnifiedApplicationStateToPartnerEligibilityResultsState'
import IUnifiedApplicationStateToPartnerReferralRegistrationState from 'src/mappers/IUnifiedApplicationStateToPartnerReferralRegistrationState'
import IUnifiedApplicationStateToUnifiedEligibilityResultsState from 'src/mappers/IUnifiedApplicationStateToUnifiedEligibilityResultsState'
import IUnifiedApplicationStateToUnifiedEligibilityState from 'src/mappers/IUnifiedApplicationStateToUnifiedEligibilityState'
import UnifiedEligibility from 'src/pages/unifiedApplicationJourney/eligibility'
import { eligibilityPageIdentifier } from 'src/pages/unifiedApplicationJourney/eligibility/eligibilityPageIdentifier'
import { eligibilityResultsPageIdentifier } from 'src/pages/unifiedApplicationJourney/eligibility/results/eligible/eligibilityResultsPageIdentifier'
import EligibilityResultIneligible from 'src/pages/unifiedApplicationJourney/eligibility/results/ineligible'
import { ineligiblePageIdentifier } from 'src/pages/unifiedApplicationJourney/eligibility/results/ineligible/ineligiblePageIdentifier'
import EligibilityLoading from 'src/pages/unifiedApplicationJourney/eligibility/results/loading'
import { eligibilityLoadingPageIdentifier } from 'src/pages/unifiedApplicationJourney/eligibility/results/loading/eligibilityLoadingPageIdentifier'
import IUnifiedApplicationStateToIGetStartedState from '../../../mappers/IUnifiedApplicationStateToIGetStartedState'
import { IJourneyStepRenderer } from '../../../types/IJourneyStepRenderer'
import {
  hideAll,
  hideAllButBack,
  hideAllButHeading,
  hideStepperFooter,
  PageIdentifier
} from '../../../types/PageIdentifier'
import deepCopy from '../../../utils/deepCopy'
import { getPageHeading, getPageShortHeading, JourneyDefinition } from '../../journey'
import GetStarted from '../../unifiedApplicationJourney/getStarted/GetStarted'
import getStartedPageIdentifier from '../../unifiedApplicationJourney/getStarted/getStartedPageIdentifier'
import PartnerEligibilityResults from './RM/Eligibility/Results/Eligible/partnerEligibilityResults'
import PartnerReferralRegistration from './RM/ReferralRegistration'
import { partnerReferralRegistrationPageIdentifier } from './RM/ReferralRegistration/partnerReferralRegistrationIdentifier'

const getStartedCustomPage = deepCopy(getStartedPageIdentifier) as PageIdentifier
getStartedCustomPage.hidden = hideAllButHeading

const eligibilityCustomPage = deepCopy(eligibilityPageIdentifier) as PageIdentifier
eligibilityCustomPage.hidden = hideStepperFooter

const eligibilityLoadingCustomPage = deepCopy(eligibilityLoadingPageIdentifier) as PageIdentifier
eligibilityLoadingCustomPage.hidden = hideAll

const eligibleCustomPage = deepCopy(eligibilityResultsPageIdentifier) as PageIdentifier
eligibleCustomPage.hidden = hideAllButBack

const ineligibleCustomPage = deepCopy(ineligiblePageIdentifier) as PageIdentifier
ineligibleCustomPage.hidden = hideAll

export const barclaysStandardJourney: JourneyDefinition[] = [
  {
    page: getStartedCustomPage,
    renderer: {
      Component: GetStarted,
      mapper: IUnifiedApplicationStateToIGetStartedState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('GetStarted'),
    pageShortHeading: getPageShortHeading('GetStarted')
  },
  {
    page: deepCopy(eligibilityCustomPage) as PageIdentifier,
    renderer: {
      Component: UnifiedEligibility,
      mapper: IUnifiedApplicationStateToUnifiedEligibilityState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('Eligibility'),
    pageShortHeading: getPageShortHeading('Eligibility')
  },
  {
    page: deepCopy(eligibilityLoadingCustomPage) as PageIdentifier,
    renderer: {
      Component: EligibilityLoading,
      mapper: IUnifiedApplicationStateToUnifiedEligibilityResultsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('Eligibility'),
    pageShortHeading: getPageShortHeading('Eligibility')
  },
  {
    page: deepCopy(ineligibleCustomPage) as PageIdentifier,
    renderer: {
      Component: EligibilityResultIneligible,
      mapper: IUnifiedApplicationStateToUnifiedEligibilityResultsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('Ineligible'),
    pageShortHeading: getPageShortHeading('Ineligible')
  },
  {
    page: deepCopy(eligibleCustomPage) as PageIdentifier,
    renderer: {
      Component: PartnerEligibilityResults,
      mapper: IUnifiedApplicationStateToPartnerEligibilityResultsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('EligibilityResult'),
    pageShortHeading: getPageShortHeading('EligibilityResult')
  },
  {
    page: deepCopy(partnerReferralRegistrationPageIdentifier) as PageIdentifier,
    renderer: {
      Component: PartnerReferralRegistration,
      mapper: IUnifiedApplicationStateToPartnerReferralRegistrationState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('PartnerReferralDetails'),
    pageShortHeading: getPageShortHeading('PartnerReferralDetails')
  }
]
