import { spacing } from 'src/ui-framework/theme/spacing'
import styled from 'styled-components'

type StyledCheckboxProps = {
  invalid?: boolean
  theme: any
}

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  position: relative;
  > input {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  > input + div > svg {
    height: 20px;
    margin-right: ${spacing.xs};
  }

  > input + label {
    height: 20px;
    display: flex;
    cursor: pointer;
    position: relative;
  }
`

type StyledCheckboxRectProps = {
  theme: any
  errors?: string[]
}

export const StyledCheckboxRect = styled.rect<StyledCheckboxRectProps>`
  stroke: ${(props) => {
    return props.errors && props.errors.length > 0 ? props.theme.colors.error : props.theme.colors.midnight
  }};
  stroke-width: 2;
`

type StyledCheckboxSvgProps = {
  theme: any
  errors?: string[]
}

export const StyledCheckboxSvg = styled.svg<StyledCheckboxSvgProps>`
  position: sticky;
  min-width: 20px;
`

type StyledCheckboxLabelProps = {
  theme: any
  errors?: string[]
  focused: boolean
}

export const StyledCheckboxLabel = styled.div<StyledCheckboxLabelProps>`
  display: flex;
  ${(props) =>
    !props.focused &&
    `&:hover::before {
        position: absolute;
        left: -5px;
        top: -5px;
        height: 30px;
        width: 30px;
        background-color: ${props.theme.colors.green30};
        content: '';
        border-radius: 30px;

        opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 0.1s;
    }`}

  ${(props) =>
    props.focused &&
    `
        &::before {
            position: absolute;
            left: -8px;
            top: -8px;
            height: 36px;
            width: 36px;
            background-color: ${props.theme.colors.green30};
            content: '';
            border-radius: 36px;

            opacity: 1;
            animation-name: fadeInOpacity;
            animation-iteration-count: 1;
            animation-timing-function: ease-in;
            animation-duration: 0.1s;
        }
    `}

    @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`
