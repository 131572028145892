import { ReadyToApplyContent } from '../../types/readyToApply'

export const readyToApplyInvoiceFinance: ReadyToApplyContent = {
  SalesHeading: 'You are now in sales impersonation mode. ',
  Heading: 'Start a new application',
  Introduction: [],
  SubIntroduction:
    "If you'd like to chat to our team about your finance needs and the solutions best suited to your business before applying simply {HelpButton} to arrange a call.",
  Help: "We're always here to help. {HelpButton} to get in touch.",
  HelpButton: 'Click here',
  DoubleCheck: "Double check that you're ready before you start.",
  RequirementsHeading: 'Please ensure you have the following information ready:',
  Requirements: [
    'Company name and registration number',
    "Applying Director's date of birth and residential address",
    'Last 3 months of bank statements',
    'Latest statutory accounts'
  ],
  AboutUsHeading: 'About us',
  AboutUsItems: ['10 minutes to apply', 'No obligations', 'No hidden fee'],
  Products: {
    InvoiceFinance: {
      ButtonText: 'Start application'
    }
  },
  Affiliation: {
    Title: 'Who is applying for the company?',
    Text: "What is the customer's relationship with the company?",
    Options: {
      Director: { Text: 'They are a director' },
      Representative: { Text: 'They are employed by the company' }
    },
    Tooltip:
      "Knowing whether you're a registered director with Companies House, or someone else applying on behalf of the business, enables us to give you the simplest application form.",
    Error: {
      Required: "Please tell us the customer's relationship with the business you're applying for"
    }
  },
  Closing: ['You can save and continue later at any time.'],
  BritishBankImageAltText: 'British Business Bank'
}
