import IApplication from '../types/IApplication'
import IUnifiedApplicationState, { ReferralFlow, UnifiedApplicationStatus } from '../types/IUnifiedApplicationState'
import { UnifiedApplicationState } from '../types/UnifiedApplicationState'
import IApplicantToIApplicantStateMapper from './IApplicantToIApplicantState'
import IAddressToIAddressState from './IAddressToIAddressState'
import StringToIFileState from './StringToIFileState'
import IDateStateToIsoDateMapper from './IDateStateToIsoDateMapper'
import { JourneyType } from '../types/JourneyType'
import getJourneyById from '../pages/journeyFactory'
import { RepresentativeJourneyType } from '../types/RepresentativeJourneyType'
import { cookieExists } from '../utils/cookieHelper'
import { ChannelType } from 'src/types/ChannelType'
import { UserRole } from 'src/types/UserRole'

class IApplicationToIUnifiedApplicationStateImpl {
  map = (i: IApplication, isNew: boolean, userRole: UserRole): IUnifiedApplicationState => {
    // We need to default to Standard here
    // so that all existing applications work
    const journeyType = i.journeyType || JourneyType.Unknown
    const journey = getJourneyById(journeyType, i.status)
    const lastCompletedStep =
      i.lastCompletedStep === undefined ? undefined : journey.getPageFromIdentifier(i.lastCompletedStep)
    const maxCompletedstep =
      i.maxCompletedStep === undefined ? undefined : journey.getPageFromIdentifier(i.maxCompletedStep)

    let partnerFlow: ReferralFlow | undefined
    if (i.channelType === ChannelType.RelationshipManager) {
      partnerFlow =
        i.status === UnifiedApplicationStatus.PartnerDraft ? ReferralFlow.RelationshipManager : ReferralFlow.Customer
    }
    return {
      isNew,
      journeyType,
      id: i.applicationIdentifier,
      companyIdentifier: i.companyIdentifier,
      companyName: i.companyName,
      companyNumber: i.companyNumber,
      initialCompanyNumber: i.initialCompanyNumber,
      state: UnifiedApplicationState.Pristine,
      applicant: i.applicant === undefined ? undefined : IApplicantToIApplicantStateMapper.map(i.applicant),
      registeredAddress:
        i.registeredAddress === undefined ? undefined : IAddressToIAddressState.map(i.registeredAddress),
      tradingAddress: i.tradingAddress === undefined ? undefined : IAddressToIAddressState.map(i.tradingAddress),
      lastCompletedStep,
      fundingNeedsDetails: i.fundingNeedsDetails,
      reasonForFunding: i.reasonForFunding,
      reasonForFundingOtherDetails: i.reasonForFundingOtherDetails,
      whenFundsAreNeeded: i.whenFundsAreNeeded,
      products: i.products,
      website: i.website,
      partnerEmail: i.partnerEmail,
      representativeJourneyType: i.representativeJourneyType || RepresentativeJourneyType.Director,
      hasSalesCookie: cookieExists('sales'),
      partnerType: i.partnerType,
      channelType: i.channelType,
      director: {
        name: i.director?.name,
        dateOfBirth: IDateStateToIsoDateMapper.reverseMap(i.director?.dateOfBirth),
        email: i.director?.email,
        phoneNumber: i.director?.phoneNumber,
        address: IAddressToIAddressState.map(i.director?.address),
        yearsAtAddress: i.director?.daysAtAddress && Math.floor(i.director?.daysAtAddress / 365),
        monthsAtAddress: i.director?.daysAtAddress && (i.director?.daysAtAddress % 365) / 30,
        legalsConsentedTime: i.director?.legalsConsentedTime
      },
      bankStatements: StringToIFileState.map(i.bankStatements || []),
      statutoryAccounts: StringToIFileState.map(i.statutoryAccounts || []),
      additionalDocuments: StringToIFileState.map(i.additionalDocuments || []),
      additionalInformation: i.additionalInformation,
      hmrcTaxCalculationDocuments: StringToIFileState.map(i.hmrcTaxCalculations || []),
      status: i.status || UnifiedApplicationStatus.Unknown,
      generalEligibility: i.generalEligibility,
      maxCompletedstep,
      metadata: i.metadata || {},
      businessValidity: i.businessValidity,
      businessType: i.businessType,
      partnerIdentifier: i.partnerIdentifier,
      canCallClient: i.canCallClient,
      applicationReference: i.applicationReference,
      callTimeSlots: i.callTimeSlots,
      isThreeWayCallRequested: i.isThreeWayCallRequested,
      turnover: i.turnover,
      timeline: i.timeline,
      partnerPhoneNumber: i.partnerPhoneNumber,
      isApplicationStarted: !!i.lastCompletedStep,
      referralFlow: partnerFlow,
      userRole: userRole,
      hasBackgroundChecksTimeout: i.hasBackgroundChecksTimeout,
      backgroundChecksRequestedTime: i.backgroundChecksRequestedTime
    }
  }

  reverseMap = (i: IUnifiedApplicationState): IApplication => {
    return {
      applicationIdentifier: i.id,
      companyIdentifier: i.companyIdentifier,
      companyName: i.companyName,
      companyNumber: i.companyNumber,
      initialCompanyNumber: i.initialCompanyNumber,
      applicant: i.applicant === undefined ? undefined : IApplicantToIApplicantStateMapper.reverseMap(i.applicant),
      registeredAddress:
        i.registeredAddress === undefined ? undefined : IAddressToIAddressState.reverseMap(i.registeredAddress),
      tradingAddress: i.tradingAddress === undefined ? undefined : IAddressToIAddressState.reverseMap(i.tradingAddress),
      // We can't send `undefined` here as the ecma standard strips that out of the JSON
      // so we send a GUID that doesn't exist in our journey
      // really we should use null but this is a huge change unfortunately... lesson learnt
      lastCompletedStep: i.lastCompletedStep?.id || '00000000-0000-0000-0000-000000000000',
      maxCompletedStep: i.maxCompletedstep?.id || '00000000-0000-0000-0000-000000000000',
      fundingNeedsDetails: i.fundingNeedsDetails,
      reasonForFunding: i.reasonForFunding,
      reasonForFundingOtherDetails: i.reasonForFundingOtherDetails,
      whenFundsAreNeeded: i.whenFundsAreNeeded,
      products: i.products,
      website: i.website,
      representativeJourneyType: i.representativeJourneyType,
      director: {
        name: i.director?.name,
        dateOfBirth: IDateStateToIsoDateMapper.map(i.director?.dateOfBirth),
        email: i.director?.email,
        phoneNumber: i.director?.phoneNumber,
        address: IAddressToIAddressState.reverseMap(i.director?.address),
        daysAtAddress:
          i.director?.yearsAtAddress !== undefined && i.director?.monthsAtAddress !== undefined
            ? i.director?.yearsAtAddress * 365 + 30 * i.director?.monthsAtAddress
            : undefined,
        legalsConsentedTime: i.director?.legalsConsentedTime
      },
      bankStatements: StringToIFileState.reverseMap(i.bankStatements || []),
      statutoryAccounts: StringToIFileState.reverseMap(i.statutoryAccounts || []),
      additionalDocuments: StringToIFileState.reverseMap(i.additionalDocuments || []),
      additionalInformation: i.additionalInformation,
      hmrcTaxCalculations: StringToIFileState.reverseMap(i.hmrcTaxCalculationDocuments || []),
      status: i.status === UnifiedApplicationStatus.Unknown ? undefined : i.status,
      journeyType: i.journeyType,
      generalEligibility: i.generalEligibility,
      metadata: i.metadata,
      businessValidity: i.businessValidity,
      businessType: i.businessType,
      canCallClient: i.canCallClient,
      applicationReference: i.applicationReference,
      callTimeSlots: i.callTimeSlots,
      isThreeWayCallRequested: i.isThreeWayCallRequested,
      turnover: i.turnover,
      timeline: i.timeline,
      partnerPhoneNumber: i.partnerPhoneNumber,
      hasBackgroundChecksTimeout: i.hasBackgroundChecksTimeout,
      backgroundChecksRequestedTime: i.backgroundChecksRequestedTime
    }
  }
}

const IApplicationToIUnifiedApplicationState = new IApplicationToIUnifiedApplicationStateImpl()
export default IApplicationToIUnifiedApplicationState
