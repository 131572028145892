import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { FunctionComponent } from 'react'
import IApplicationUrlParams from '../../types/IApplicationUrlParams'
import NotFound from '../../pages/error/NotFound'
import { validate as uuidValidate } from 'uuid'

export default <TParam extends IApplicationUrlParams>(
  props: RouteComponentProps<TParam>,
  C: FunctionComponent<any>,
) => {
  const { params } = props.match

  if (!uuidValidate(params.id) || params.id === '00000000-0000-0000-0000-000000000000') {
    return <NotFound />
  }

  return <C {...props} />
}
