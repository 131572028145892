import { media } from 'src/ui-framework/commonStyles/mediaQueries/responsiveDeviceSize'
import styled from 'styled-components'
import { colors } from '../../theme/colors'

type StyledAnchorLinkProps = {
  autoFontSize?: boolean
}

const StyledAnchorLink = styled.a<StyledAnchorLinkProps>`
  font-family: 'Matter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  ${media.upToTablet`
    font-size: 14px;`}
  line-height: 20px;
  color: ${colors.linkDefault};
  text-decoration: underline;
  cursor: pointer;
  border: 1px solid transparent;
  &:hover {
    color: ${colors.linkHover};
  }
  &:active {
    color: ${colors.linkActive};
  }
  &:focus {
    color: ${colors.linkFocused};
    outline: none;
    border: 1px solid ${colors.linkFocusedBorder};
    padding: 0;
  }
`

export default StyledAnchorLink
