import UnifiedApplicationBaseReducer from '../unifiedApplicationBaseReducer'
import {
  IUnifiedApplicationAction,
  UnifiedApplicationActionType,
  UnifiedApplicationActionUpdateState
} from '../unifiedApplicationActions'
import { IErrorsState } from '../../types/IErrorsState'
import { UnifiedApplicationState } from '../../types/UnifiedApplicationState'
import { getOutcome } from '../../shared/components/eligibilityResultsRenderer/EligibilityResultsRenderer'
import IUnifiedEligibilityResultsState from 'src/types/unifiedJourney/IUnifiedEligibilityResultsState'
import { outcomesLookupOrder } from 'src/pages/unifiedApplicationJourney/eligibility/results/eligible/eligibilityOutcomeLookups'

export default class UnifiedEligibilityResultsReducer extends UnifiedApplicationBaseReducer<IUnifiedEligibilityResultsState> {
  onLoad = async (
    state: IUnifiedEligibilityResultsState,
    dispatch: React.Dispatch<IUnifiedApplicationAction<object>>
  ): Promise<void> => {
    dispatch(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Pristine))
  }

  onValidate = (state: IUnifiedEligibilityResultsState): IErrorsState => {
    const errors: IErrorsState = {
      hasErrors: false
    }
    return errors
  }

  handleDefaultAction = (
    prevState: IUnifiedEligibilityResultsState,
    newState: IUnifiedEligibilityResultsState,
    action: IUnifiedApplicationAction<object>
  ): void => {
    switch (action.type) {
      case UnifiedApplicationActionType.Loaded:
        newState.outcome = getOutcome(newState.products, outcomesLookupOrder)
        newState.state = UnifiedApplicationState.Pristine
        break
    }
  }
  onBeforeSubmit = async (state: IUnifiedEligibilityResultsState): Promise<void> => {}
}
