import { ReadyToApplyContent } from '../../types/readyToApply'

export const readyToApplyInvoiceFinance: ReadyToApplyContent = {
  Heading: 'Before starting your application',
  Introduction: [],
  SubIntroduction:
    "Want to learn more? {HelpButton} with a member of our team to chat about which invoice finance solution works best for you and your business. In any case, we always give you a call once you've sent through your details.",
  Help: "We're always here to help. {HelpButton} to get in touch.",
  HelpButton: 'Click here',
  ApplicationTime: undefined,
  RequirementsMainHeading: undefined,
  LatestRequirements: [
    'Company name and UK registration number for the applying company',
    'Date of birth and residential address for the applying director',
    'Statutory Accounts',
    'Bank statements covering the period from {Dynamic.InvoiceFinance.BankStatementStartDate} until at least {Dynamic.InvoiceFinance.BankStatementEndDate}'
  ],
  Products: {
    InvoiceFinance: {
      Introduction: 'In addition, non-CBILS Invoice Finance applications need:',
      IntroductionToolTip:
        'Unlock the cash tied up in your invoices and boost your working capital. We offer subscription and pay-as-you-go facilities, depending on your cash flow needs. So you can focus on the bigger picture and get on with running your business.',
      ListWithTooltips: [
        {
          Text: 'Bank statements covering the period from {Dynamic.InvoiceFinance.BankStatementStartDate} until at least {Dynamic.InvoiceFinance.BankStatementEndDate}'
        }
      ],
      ButtonText: 'Start application'
    }
  },
  Affiliation: {
    Title: "What's your relationship with the company?",
    Text: "What's your relationship with the company?",
    Options: {
      Director: { Text: "I'm a director of the limited company" },
      Representative: { Text: "I'm employed by the limited company" }
    },
    Tooltip:
      "Knowing whether you're a registered director with Companies House, or someone else applying on behalf of the business, enables us to give you the simplest application form.",
    Error: {
      Required: "Please tell us your relationship with the business you're applying for"
    }
  },
  Closing: ['You can save and continue later at any time.'],
  RequirementsHeading: 'Please ensure you have the following information ready:',
  Requirements: ['Company name and registration number', "Applying Director's date of birth and residential address"],
  AboutUsHeading: 'About us',
  AboutUsItems: ['5 minutes to apply', 'No obligations', 'No hidden fee'],
  BritishBankImageAltText: 'British Business Bank'
}
