import { ReadyToApplyContent } from '../types/readyToApply'

export const readyToApplyInvoiceFinance: ReadyToApplyContent = {
  Heading: 'Before starting your application',
  Introduction: [],
  SubIntroduction: 'Please note we currently only accept applications from limited companies.',
  Help: "We're always here to help. {HelpButton} to get in touch.",
  HelpButton: 'Click here',
  Products: {
    InvoiceFinance: {
      ButtonText: 'Start application'
    }
  },
  Affiliation: {
    Title: "What's your relationship with the company?",
    Text: "What's your relationship with the company?",
    Options: {
      Director: { Text: "I'm a director of the limited company" },
      Representative: { Text: "I'm employed by the limited company" }
    },
    Error: {
      Required: "Please tell us your relationship with the business you're applying for"
    }
  },
  Closing: ['You can save and continue later at any time.'],
  RequirementsHeading: 'Please ensure you have the following information ready:',
  Requirements: [
    'Company name and registration number',
    "Applying Director's date of birth and residential address",
    'Last 3 months of bank statements',
    'Latest statutory accounts'
  ],
  AboutUsHeading: 'About us',
  AboutUsItems: ['10 minutes to apply', 'No obligations', 'No hidden fee'],
  ClosingItems: [],
  BritishBankImageAltText: 'British Business Bank'
}
