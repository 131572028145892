import { colors, media } from 'src/ui-framework'
import styled from 'styled-components'

export const MainContentWrapper = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;
`

export const MainContent = styled.div`
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  height: fit-content;
  padding: 48px 32px;
  gap: 10px;
  border-radius: 8px;
  background: ${colors.white};
  width: 560px;
  top: 104px;
  ${media.upToTablet`  
    padding: 32px 16px;
    gap: 32px;
    left: 0px;
    top: 80px;
    gap: 32px;    
    width: 100%;
  `}
`
