import React from 'react'
import styled from 'styled-components'
import { MdClose as UnstyledCloseIcon } from 'react-icons/md'
import IFileState, { FileStatusState } from '../../../types/IFileState'
import { CloseImage } from '../../../commonStyles/images/images'
import FileIcon from './FileIcon'
import { colors } from 'src/ui-framework'

export const CloseIcon = styled(UnstyledCloseIcon)`
  float: right;
  cursor: pointer;
`

export const StyledIcon = styled.button`
  float: right;
  color: ${colors.textGrey900};
  max-height: 24px;
  cursor: pointer;
  border-radius: 50%;
  outline: none;
  padding: 0;
  border: transparent;
  background-color: transparent;
  &:hover {
    background-color: ${(props) => props.theme.colors.focus.backgroundColor};
  }
  &:focus {
    border: solid 2px #001061;
    background-color: ${(props) => props.theme.colors.focus.backgroundColor};
  }
`

const FileListUl = styled.ul`
  padding-left: 0;
  margin: 0;
  border: solid 1px #ededed;
  border-top: none;
`

export const FileItem = styled.li`
  list-style-type: none;
  padding: 8px 16px;
  border-top: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
interface IFileNameProps {
  success: boolean
}

const FileName = styled.a<IFileNameProps>`
  color: ${(props) => (props.success ? colors.textGrey900 : colors.error)};
  font-size: 14px;
  overflow: hidden;
  margin-left: 5px;
  display: inline;
  padding: 2px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Matter';
  letter-spacing: 0.16px;
`

const FileNameWrapper = styled.div`
  display: flex;
  align-items: center;
`

export type UploadFile = {
  id: string
  name: string
  well: string
}

type FilesListProps = {
  files: Array<IFileState>
  onDeleteFile: Function
}

const FileList = ({ files, onDeleteFile }: FilesListProps) => {
  return (
    <FileListUl>
      {files
        .filter((f) => f.status === FileStatusState.Uploaded)
        .map((file: IFileState) => (
          <FileItem key={file.id}>
            <FileNameWrapper>
              <FileIcon color={colors.textGrey900} />
              <FileName data-test={file.name} success={true}>
                {file.name}
                {file.error && ` - ${file.error}`}
              </FileName>
            </FileNameWrapper>
            <StyledIcon onClick={() => onDeleteFile(file.id)} tabIndex={0}>
              <img src={CloseImage} alt="Delete" height="20px" />
            </StyledIcon>
          </FileItem>
        ))}
      {files
        .filter((f) => f.status === FileStatusState.Rejected)
        .map((file: IFileState) => (
          <FileItem key={file.id}>
            <FileName data-test={file.name} success={false}>
              {file.name} - {file.error}
            </FileName>
          </FileItem>
        ))}
    </FileListUl>
  )
}

export default FileList
