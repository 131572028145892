import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getCopy } from 'src/applyfrontendcontent'
import { BarclaysPartnership, KriyaHeroLanding, KriyaLogoMidnight } from 'src/commonStyles/images/images'
import { DynamicContent } from 'src/shared/content/DynamicContent'
import { getConfigForPartnerType } from 'src/shared/setPartnerLogo'
import { ChannelType } from 'src/types/ChannelType'
import { FlowType } from 'src/types/FlowType'
import { ButtonType, ICreateApplicationState } from 'src/types/IInitialisationParameters'
import { PartnerType } from 'src/types/PartnerType'
import { RepresentativeJourneyType } from 'src/types/RepresentativeJourneyType'
import { AnchorLink } from 'src/ui-framework'
import { useInitialisationParameters } from '../../../../unifiedApplicationJourney/readyToApply/useInitialisationParameters'
import { useApplicationPageTracker } from '../../../../../trackPages'
import {
  AdditionalContent,
  BarclaysPartnershipLogo,
  ButtonsSection,
  CenteredContainer,
  ContentWrapper,
  GreenBox,
  Header,
  KriyaHeroImg,
  KriyaLogo,
  MainContent,
  MainContentTexts,
  RelativePositionWrapper,
  StyledButton,
  StyledParagraph,
  WelcomeSpan,
  Wrapper
} from './StrategicPartnerReadyToApplyStyles'
import { SetHotjarPageData } from 'src/pages/unifiedApplicationJourney/readyToApply/hotjarJourneyTypePageView'

interface PartnerParams {
  partnerName: PartnerType
}

const WelcomePartner = { Component: WelcomeSpan }
const Introduction = { Component: StyledParagraph }
const SubIntroduction = { Component: StyledParagraph }

const StrategicPartnerReadyToApply = () => {
  const params = useParams<PartnerParams>()
  const initialisationParameters = useInitialisationParameters()
  initialisationParameters.channel = ChannelType.RelationshipManager
  initialisationParameters.partner = params.partnerName

  const history = useHistory<ICreateApplicationState>()
  const partnerDetails = getConfigForPartnerType(params.partnerName, ChannelType.RelationshipManager)

  useApplicationPageTracker('Ready to apply RM', null)

  const startApplication = () => {
    const createApplicationState: ICreateApplicationState = {
      initialisationParameters,
      buttonType: ButtonType.Standard,
      representativeJourneyType: RepresentativeJourneyType.Director,
      canCallClient: false,
      flowType: FlowType.StrategicPartner
    }

    history.push({
      pathname: '/application',
      state: createApplicationState
    })

    SetHotjarPageData(
      initialisationParameters.sales,
      initialisationParameters.partner,
      initialisationParameters.channel,
      undefined
    )
  }

  const ContinueButton = {
    Component: StyledButton,
    props: {
      type: 'primary',
      id: 'continue-standard-application',
      onClick: startApplication
    }
  }

  const copy = getCopy(navigator.language, {
    partner: partnerDetails?.partnerType,
    representativeType: RepresentativeJourneyType.Director,
    sales: false,
    flowType: FlowType.StrategicPartner
  })

  return (
    <>
      <Wrapper>
        <CenteredContainer>
          <Header>
            <KriyaLogo src={KriyaLogoMidnight}></KriyaLogo>
            {/* TODO: Update to support other partners to be more flexible */}
            <BarclaysPartnershipLogo src={BarclaysPartnership}></BarclaysPartnershipLogo>
          </Header>
          <ContentWrapper>
            <MainContent>
              <MainContentTexts>
                <DynamicContent
                  components={{
                    WelcomePartner,
                    Introduction,
                    CheckListLinkLabel: {
                      Component: AnchorLink,
                      props: {
                        target: '_blank',
                        href: copy.ReadyToApplyPartner.CheckListLink,
                        rel: 'noopener noreferrer'
                      }
                    },
                    SubIntroduction,
                    PartnerHelpEmail: {
                      Component: AnchorLink,
                      props: {
                        target: '_blank',
                        href: `mailto:${copy.ReadyToApplyPartner.PartnerHelpEmail}`,
                        rel: 'noopener noreferrer'
                      }
                    }
                  }}
                  ignoreComponents={['CheckListLinkLabel', 'PartnerHelpEmail']}
                  content={copy.ReadyToApplyPartner}
                />
              </MainContentTexts>
              <ButtonsSection>
                <DynamicContent
                  components={{
                    ContinueButton
                  }}
                  content={copy.ReadyToApplyPartner}
                />
              </ButtonsSection>
            </MainContent>
            <AdditionalContent>
              <KriyaHeroImg src={KriyaHeroLanding}></KriyaHeroImg>
              <RelativePositionWrapper>
                <GreenBox></GreenBox>
              </RelativePositionWrapper>
            </AdditionalContent>
          </ContentWrapper>
        </CenteredContainer>
      </Wrapper>
    </>
  )
}

export default StrategicPartnerReadyToApply
