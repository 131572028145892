import React, { useState } from 'react'
import QueryString from 'query-string'
import { H1 } from '../../commonStyles/components/headings'
import * as styles from '../unifiedApplicationJourney/readyToApply/ReadyToApplyStyles'
import { useHistory, useParams } from 'react-router-dom'
import IApplicationUrlParams from '../../types/IApplicationUrlParams'
import axios from 'axios'
import { DynamicContent, tokeniseToString } from '../../shared/content/DynamicContent'
import { Config } from 'src/applyfrontendcontent'
import { Row, Hidden, Column, Button, Paragraph } from 'src/ui-framework'
import { applyServiceSuffix } from 'src/urlSettingsExports'

interface ILinkExpiredErrorProps {
  content: Config
}

const Heading = { Component: H1 }
const ParagraphContent = { Component: Paragraph }

const LinkExpiredError = (p: ILinkExpiredErrorProps) => {
  const [emailSent, setEmailSent] = useState<boolean>(false)
  const history = useHistory()
  const params = useParams<IApplicationUrlParams>()
  const content = p.content.LinkExpiredError

  const queryParams = QueryString.parse(history.location.search, { decode: false })
  const key = typeof queryParams?.key === 'string' ? queryParams.key : undefined

  const startNewApplication = () => {
    history.push('/application/readytoapply')
  }

  const sendSecureLink = async () => {
    setEmailSent(await ContinueLaterWithKey(params.id, key!))
  }

  const ContinueLaterWithKey = async (id: string, key: string): Promise<boolean> => {
    try {
      const response = await axios.post(
        `/${applyServiceSuffix()}/applications/${id}/continueLaterWithKey?key=${encodeURIComponent(key)}`
      )
      return response.status === 200
    } catch {
      return false
    }
  }

  return (
    <Row>
      <Hidden xsDown>
        <Column sm={3} />
      </Hidden>
      <Column xs={12} sm={6}>
        {emailSent === false && (
          <>
            <DynamicContent
              content={content}
              components={{
                Heading,
                Introduction: ParagraphContent
              }}
            />
            <styles.ButtonGroup>
              {key !== undefined && (
                <Button color="primary" onClick={sendSecureLink}>
                  {tokeniseToString(content, 'SendNewLinkButton')}
                </Button>
              )}
              <Button color="secondary" onClick={startNewApplication}>
                {tokeniseToString(content, 'StartNewApplicationButton')}
              </Button>
            </styles.ButtonGroup>
          </>
        )}
        {emailSent && (
          <DynamicContent
            content={content}
            components={{
              LinkSentHeading: Heading,
              LinkSent: ParagraphContent
            }}
          />
        )}
      </Column>
    </Row>
  )
}

export default LinkExpiredError
