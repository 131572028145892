import { UnauthorisedErrorContent } from '../types/unauthorisedApplicationError'

export const unauthorisedApplicationError: UnauthorisedErrorContent = {
  Heading: "You're not able to access this application",
  Introduction:
    'We need to validate that you have access to this application, please click the button below to send a secure link to the email address that was entered on the application.',
  ForbiddenIntro:
    'It looks like we don’t have your email address on file to send you a secure link, please click the button below to start a new application.',
  SecureLinkButton: 'Send secure link',
  NewApplicationButton: 'Start new application',
  CheckYourInboxHeading: 'Check your inbox',
  CheckYourInboxParagraph:
    'You can now continue this application by clicking on the secure link that has been emailed to you.'
}
