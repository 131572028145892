import React from 'react'
import { colors, media } from 'src/ui-framework'
import styled from 'styled-components'

const ErrorPage = styled.div`
  text-align: center;
  background-color: ${colors.midnight};
  width: 100vw;
  height: calc(100vh - 80px);
  margin-top: -16px;
  margin-left: 0px;
  position: absolute;
  left: 0%;
  ${media.upToTablet`
    height: calc(100vh - 64px);
  `}
`

const ErrorContainer = styled.div`
  position: absolute;
  top: calc(50vh - 95px);
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`

const ErrorCode = styled.div`
  font-size: 62px;
  color: ${colors.white};
  ${media.upToTablet`
    font-size: 50px;
  `}
`

const TagLine = styled.div`
  font-family: 'Matter';
  font-size: 32px;
  line-height: 40px;
  color: ${colors.white};
  margin-top: 48px;
  ${media.upToTablet`
    font-size: 16px;
    line-height: 22px;
  `}
`

const PurpleBlock = styled.div`
  background-color: ${colors.purple};
  width: 240px;
  height: 8px;
  display: inline-block;
  ${media.upToTablet`
    width: 80px;
  `}
`

const GreenBlock = styled.div`
  background-color: ${colors.green};
  width: 240px;
  height: 8px;
  display: inline-block;
  margin-left: 8px;
  margin-right: 8px;
  ${media.upToTablet`
    width: 80px;
  `}
`

const YellowBlock = styled.div`
  background-color: ${colors.yellow};
  width: 240px;
  height: 8px;
  display: inline-block;

  ${media.upToTablet`
    width: 80px;
  `}
`

const BlocksContainer = styled.div`
  margin-top: 48px;
`

const NotFound = () => (
  <ErrorPage>
    <ErrorContainer>
      <ErrorCode>404</ErrorCode>
      <BlocksContainer>
        <PurpleBlock></PurpleBlock>
        <GreenBlock></GreenBlock>
        <YellowBlock></YellowBlock>
      </BlocksContainer>
      <TagLine>Sorry we can't find this page.</TagLine>
    </ErrorContainer>
  </ErrorPage>
)

export default NotFound
