import IUnifiedApplicationState, {
  UnifiedApplicationDataValue,
  IUnifiedApplicationStepState
} from '../types/IUnifiedApplicationState'
import IApplication from '../types/IApplication'
import IGetStartedState from '../types/IGetStartedState'
import IDirectorDetailsState from '../types/IDirectorDetailsState'
import IUnifiedApplicationEligibilityState from '../types/IUnifiedApplicationEligibilityState'
import ISupportingDocumentsState from '../types/ISupportingDocumentsState'
import { UnifiedApplicationState } from '../types/UnifiedApplicationState'
import ICompaniesApiResponse from '../types/ICompaniesApiResponse'
import { PageIdentifier } from '../types/PageIdentifier'
import IFundingNeedsState from '../types/IFundingNeedsState'
import IYourCompanyState from '../types/IYourCompanyState'
import IFileState from '../types/IFileState'
import IFile from '../types/IFile'
import IUploadFileConstraints from '../types/IUploadFileConstraints'
import { UnifiedApplicationSteps } from '../types/UnifiedApplicationSteps'
import { ILatestFilingAccountsResponse } from '../types/ILatestFilingAccountsResponse'
import IAddressState from '../types/IAddressState'
import IRecoveryLoansEligibilityResultsState from '../types/recoveryLoansSchemeTypes/IRecoveryLoansEligibilityResultsState'
import IRecoveryLoansEligibilityState from '../types/recoveryLoansSchemeTypes/IRecoveryLoansEligibilityState'
import IPartnerReferralRegistrationState from 'src/types/partner/IPartnerReferralRegistrationState'
import { UserRole } from 'src/types/UserRole'

export enum UnifiedApplicationActionType {
  // Shared
  Loaded = 'action/Loaded',
  Error = 'action/Error',
  UpdateField = 'action/UpdateField',
  StepUpdate = 'action/StepUpdate',
  AfterNavigate = 'action/AfterNavigate',
  UpdateFields = 'action/UpdateFields',
  ValidateField = 'action/ValidateField',
  ValidateFields = 'action/ValidateFields',
  UpdateAndValidateField = 'action/UpdateAndValidateField',
  ApplicationSaved = 'action/ApplicationSaved',
  FilesUploading = 'action/FilesUploading',
  FilesUploaded = 'action/FilesUploaded',
  Navigate = 'action/Navigate',
  AutoSaving = 'action/AutoSaving',
  EligibilitySkip = 'action/EligibilitySkip',

  // Specific
  GetStartedAddCompanyManually = 'action/GetStartedAddCompanyManually',
  AcceptTermsAndConditions = 'action/AcceptTermsAndConditions',
  ReasonForFundingUpdate = 'action/ReasonForFundingUpdate',
  ContinueButtonClicked = 'action/ContinueButtonClicked',
  DirectorDetailsSelectDirector = 'action/DirectorDetailsSelectDirector',
  MultipleProductSelection = 'action/MultipleProductSelection',
  ProductSelection = 'action/ProductSelection'
}

export interface IUnifiedApplicationAction<TPayload extends object> {
  type: UnifiedApplicationActionType
  payload: TPayload
}

export interface IProductPayload {
  product: string
}

export interface IUpdateFieldPayload {
  field: string
  value: UnifiedApplicationDataValue
}

interface IUpdateFieldsPayload {
  fields: Array<IUpdateFieldPayload>
}

interface ILoadedPayload {
  isNew: boolean
  application: IApplication
  userRole: UserRole
}

export type UnifiedApplicationStepPayload =
  | IGetStartedState
  | IYourCompanyState
  | IDirectorDetailsState
  | IUnifiedApplicationEligibilityState
  | ISupportingDocumentsState
  | IFundingNeedsState
  | IRecoveryLoansEligibilityResultsState
  | IRecoveryLoansEligibilityState
  | IUnifiedApplicationStepState
  | IPartnerReferralRegistrationState

interface IUpdateFilesPayload {
  field: string
  files: Array<IFileState>
}

interface IStepUpdatePayload {
  pageIdentifier: PageIdentifier
  data: IUnifiedApplicationState
  nextStep?: UnifiedApplicationSteps
}

export interface IUnifiedApplicationActionUpdateField extends IUnifiedApplicationAction<IUpdateFieldPayload> {
  type: UnifiedApplicationActionType.UpdateField
}

interface IValidateFieldPayload {
  field: string
}

interface IValidateFieldsPayload {
  fields: string[]
}

export interface IUpdateAndValidateFieldPayload {
  field: string
  value: UnifiedApplicationDataValue
}

export enum ErrorType {
  Generic = 'ErrorType/Generic',
  Unauthorized = 'ErrorType/Unauthorized',
  Unauthenticated = 'ErrorType/Unauthenticated',
  Forbidden = 'ErrorType/Forbidden'
}

export interface IErrorPayload {
  type: ErrorType
}

export interface IUnifiedApplicationActionValidateField extends IUnifiedApplicationAction<IValidateFieldPayload> {
  type: UnifiedApplicationActionType.ValidateField
}

export interface IUnifiedApplicationActionValidateFields extends IUnifiedApplicationAction<IValidateFieldsPayload> {
  type: UnifiedApplicationActionType.ValidateFields
}

export interface IUnifiedApplicationActionUpdateAndValidateField
  extends IUnifiedApplicationAction<IUpdateAndValidateFieldPayload> {
  type: UnifiedApplicationActionType.UpdateAndValidateField
}

export interface IUnifiedApplicationActionLoaded extends IUnifiedApplicationAction<ILoadedPayload> {
  type: UnifiedApplicationActionType.Loaded
}

export interface IUnifiedApplicationActionError extends IUnifiedApplicationAction<IErrorPayload> {
  type: UnifiedApplicationActionType.Error
}

export interface IUnifiedApplicationActionStepUpdate extends IUnifiedApplicationAction<IStepUpdatePayload> {
  type: UnifiedApplicationActionType.StepUpdate
}

export interface IUnifiedApplicationActionAutoSave extends IUnifiedApplicationAction<IStepUpdatePayload> {
  type: UnifiedApplicationActionType.AutoSaving
}

export interface IUnifiedApplicationActionAfterNavigate extends IUnifiedApplicationAction<object> {
  type: UnifiedApplicationActionType.AfterNavigate
}

export interface IUnifiedApplicationActionUpdateFields extends IUnifiedApplicationAction<IUpdateFieldsPayload> {
  type: UnifiedApplicationActionType.UpdateFields
}

export interface IFilesUploadingAction extends IUnifiedApplicationAction<IUpdateFilesPayload> {
  type: UnifiedApplicationActionType.FilesUploading
}

interface IGetStartedLoaded {
  company: ICompaniesApiResponse
}

export interface IUnifiedApplicationActionGetStartedLoaded extends IUnifiedApplicationAction<IGetStartedLoaded> {
  type: UnifiedApplicationActionType.Loaded
}

interface ISupportingDocumentsLoaded {
  files: IFile[]
  constraints: IUploadFileConstraints
  latestCompanyFilings: ILatestFilingAccountsResponse | undefined
}

export interface IUnifiedApplicationActionSupportingDocumentsLoaded
  extends IUnifiedApplicationAction<ISupportingDocumentsLoaded> {
  type: UnifiedApplicationActionType.Loaded
}

export interface IUnifiedApplicationActionRecoveryLoansEligibilityResultsLoaded
  extends IUnifiedApplicationAction<object> {
  type: UnifiedApplicationActionType.Loaded
}

export interface IUnifiedApplicationActionUnifiedEligibilityResultsLoaded extends IUnifiedApplicationAction<object> {
  type: UnifiedApplicationActionType.Loaded
}

interface IApplicationSavedPayload {
  success: boolean
  application: IApplication | null
}

export interface IUnifiedApplicationActionApplicationSaved extends IUnifiedApplicationAction<IApplicationSavedPayload> {
  type: UnifiedApplicationActionType.ApplicationSaved
}

interface INavigateToPayload {
  step: UnifiedApplicationSteps
}

export interface IUnifiedApplicationActionNavigateTo extends IUnifiedApplicationAction<INavigateToPayload> {
  type: UnifiedApplicationActionType.Navigate
}

export interface IUnifiedApplicationGetStartedAddCompanyManuallyAction extends IUnifiedApplicationAction<object> {
  type: UnifiedApplicationActionType.GetStartedAddCompanyManually
}

export interface IUnifiedApplicationActionAcceptTermsConditions extends IUnifiedApplicationAction<object> {
  type: UnifiedApplicationActionType.AcceptTermsAndConditions
}

interface IFilesUploadedPayload {
  field: string
  value: IFileState[]
}

export interface IUnifiedApplicationActionFilesUploadedAction extends IUnifiedApplicationAction<IFilesUploadedPayload> {
  type: UnifiedApplicationActionType.FilesUploaded
}

export interface IUnifiedApplicationActionReasonForFundingUpdate
  extends IUnifiedApplicationAction<IUpdateFieldPayload> {
  type: UnifiedApplicationActionType.ReasonForFundingUpdate
}

export interface IUnifiedApplicationActionMultipleProductsSelected
  extends IUnifiedApplicationAction<IValidateFieldPayload> {
  type: UnifiedApplicationActionType.MultipleProductSelection
}

export interface IUnifiedApplicationActionProductSelected extends IUnifiedApplicationAction<IProductPayload> {
  type: UnifiedApplicationActionType.ProductSelection
}

export interface IUnifiedApplicationActionContinueButtonClicked extends IUnifiedApplicationAction<object> {
  type: UnifiedApplicationActionType.ContinueButtonClicked
}

interface IDirectorDetailsSelectDirectorPayload {
  director: string
}

export interface IUnifiedApplicationActionSelectDirector
  extends IUnifiedApplicationAction<IDirectorDetailsSelectDirectorPayload> {
  type: UnifiedApplicationActionType.DirectorDetailsSelectDirector
}

export interface IYourCompanyLoadedPayload {
  changeRegisteredAddress: boolean
  useRegisteredAddress: boolean
  registeredAddress?: IAddressState
}

export interface IUnifiedApplicationActionYourCompanyLoaded
  extends IUnifiedApplicationAction<IYourCompanyLoadedPayload> {
  type: UnifiedApplicationActionType.Loaded
}

export const UnifiedApplicationActionSelectDirector = (director: string): IUnifiedApplicationActionSelectDirector => {
  return {
    type: UnifiedApplicationActionType.DirectorDetailsSelectDirector,
    payload: {
      director
    }
  }
}

export const UnifiedApplicationActionLoaded = (
  application: IApplication,
  isNew: boolean,
  userRole: UserRole
): IUnifiedApplicationActionLoaded => ({
  type: UnifiedApplicationActionType.Loaded,
  payload: {
    isNew,
    application,
    userRole
  }
})

export const UnifiedApplicationActionError = (
  errorType: ErrorType = ErrorType.Generic
): IUnifiedApplicationActionError => ({
  type: UnifiedApplicationActionType.Error,
  payload: {
    type: errorType
  }
})

export const UnifiedApplicationActionStepUpdate = (
  data: IUnifiedApplicationState,
  pageIdentifier: PageIdentifier,
  nextStep: UnifiedApplicationSteps | undefined = undefined
): IUnifiedApplicationActionStepUpdate => ({
  type: UnifiedApplicationActionType.StepUpdate,
  payload: {
    pageIdentifier,
    data,
    nextStep
  }
})

export const UnifiedApplicationActionAutoSave = (
  data: IUnifiedApplicationState,
  pageIdentifier: PageIdentifier
): IUnifiedApplicationActionAutoSave => ({
  type: UnifiedApplicationActionType.AutoSaving,
  payload: {
    pageIdentifier,
    data
  }
})

export const UnifiedApplicationActionAfterNavigate = (): IUnifiedApplicationActionAfterNavigate => ({
  type: UnifiedApplicationActionType.AfterNavigate,
  payload: {}
})

export const UnifiedApplicationActionUpdateField = (
  field: string,
  value: UnifiedApplicationDataValue
): IUnifiedApplicationActionUpdateField => ({
  type: UnifiedApplicationActionType.UpdateField,
  payload: {
    field,
    value
  }
})

export const UnifiedApplicationActionValidateField = (field: string): IUnifiedApplicationActionValidateField => ({
  type: UnifiedApplicationActionType.ValidateField,
  payload: {
    field
  }
})

export const UnifiedApplicationActionUpdateAndValidateField = (
  field: string,
  value: UnifiedApplicationDataValue
): IUnifiedApplicationActionUpdateAndValidateField => ({
  type: UnifiedApplicationActionType.UpdateAndValidateField,
  payload: {
    field,
    value
  }
})

export const UnifiedApplicationActionValidateFields = (fields: string[]): IUnifiedApplicationActionValidateFields => ({
  type: UnifiedApplicationActionType.ValidateFields,
  payload: {
    fields
  }
})

export const UnifiedApplicationActionUpdateFields = (
  fields: IUpdateFieldPayload[]
): IUnifiedApplicationActionUpdateFields => ({
  type: UnifiedApplicationActionType.UpdateFields,
  payload: {
    fields
  }
})

export const UnifiedApplicationActionUpdateState = (
  value: UnifiedApplicationState
): IUnifiedApplicationActionUpdateField => {
  return UnifiedApplicationActionUpdateField('state', value)
}

export const UnifiedApplicationActionApplicationSaved = (
  success: boolean,
  application: IApplication | null
): IUnifiedApplicationActionApplicationSaved => ({
  type: UnifiedApplicationActionType.ApplicationSaved,
  payload: {
    success,
    application
  }
})

export const UnifiedApplicationActionNavigateTo = (
  step: UnifiedApplicationSteps
): IUnifiedApplicationActionNavigateTo => ({
  type: UnifiedApplicationActionType.Navigate,
  payload: {
    step
  }
})

export const UnifiedApplicationActionGetStartedAddCompanyManually =
  (): IUnifiedApplicationGetStartedAddCompanyManuallyAction => ({
    type: UnifiedApplicationActionType.GetStartedAddCompanyManually,
    payload: {}
  })

export const UnifiedApplicationActionAcceptTermsConditions = (): IUnifiedApplicationActionAcceptTermsConditions => ({
  type: UnifiedApplicationActionType.AcceptTermsAndConditions,
  payload: {}
})

export const UnifiedApplicationActionFilesUploaded = (
  field: string,
  value: IFileState[]
): IUnifiedApplicationActionFilesUploadedAction => ({
  type: UnifiedApplicationActionType.FilesUploaded,
  payload: {
    field,
    value
  }
})

interface IEligibilitySkipPayload extends IUnifiedApplicationAction<object> {
  type: UnifiedApplicationActionType.EligibilitySkip
}

export const UnifiedApplicationActionEligibilitySkip = (): IEligibilitySkipPayload => ({
  type: UnifiedApplicationActionType.EligibilitySkip,
  payload: {}
})

export const UnifiedApplicationActionReasonForFundingUpdate = (
  field: string,
  value: string
): IUnifiedApplicationActionReasonForFundingUpdate => {
  return {
    type: UnifiedApplicationActionType.ReasonForFundingUpdate,
    payload: {
      field,
      value
    }
  }
}

export const UnifiedApplicationActionContinueButtonClicked = (): IUnifiedApplicationActionContinueButtonClicked => {
  return {
    type: UnifiedApplicationActionType.ContinueButtonClicked,
    payload: {}
  }
}

export const UnifiedApplicationActionMultipleProductSelection = (
  field: string
): IUnifiedApplicationActionMultipleProductsSelected => {
  return {
    type: UnifiedApplicationActionType.MultipleProductSelection,
    payload: { field }
  }
}

export const UnifiedApplicationActionProductSelection = (product: string): IUnifiedApplicationActionProductSelected => {
  return {
    type: UnifiedApplicationActionType.ProductSelection,
    payload: { product }
  }
}

export const UnifiedApplicationActionIRecoveryLoansEligibilityResultsLoaded =
  (): IUnifiedApplicationActionRecoveryLoansEligibilityResultsLoaded => {
    return {
      type: UnifiedApplicationActionType.Loaded,
      payload: {}
    }
  }

export const UnifiedApplicationActionUnifiedEligibilityResultsLoaded =
  (): IUnifiedApplicationActionUnifiedEligibilityResultsLoaded => {
    return {
      type: UnifiedApplicationActionType.Loaded,
      payload: {}
    }
  }
