import React, { ChangeEvent } from 'react'
import { MainDiv, PageContainer, ClosingWrapper, Subtext, H3 } from './GetStartedStyles'
import InputLayout from '../../../commonStyles/components/inputLayout'
import { IUpdateFieldPayload, UnifiedApplicationActionUpdateState } from '../../../reducers/unifiedApplicationActions'
import IGetStartedState from '../../../types/IGetStartedState'
import CompanySearch, { CompanySearchResult } from '../../../shared/components/companySearch/CompanySearch'
import IAddressState from '../../../types/IAddressState'
import { UnifiedApplicationState } from '../../../types/UnifiedApplicationState'
import useUnifiedApplicationStep from '../../../shared/hooks/useUnifiedApplicationStep'
import GetStartedReducer from '../../../reducers/getStartedReducer'
import IUnifiedApplicationStepProps from '../../../types/IUnifiedApplicationStepProps'
import UnifiedApplicationStepGuard from '../UnifiedApplicationStepGuard'
import { unknownCompanyIdentifier } from '../../../shared/companyDataApis'
import ContinueAndSaveLater from '../ContinueAndSaveLater'
import getStartedPageIdentifier from './getStartedPageIdentifier'
import { DynamicContent, tokeniseLabelAsString, BasicComponentWithProps } from '../../../shared/content/DynamicContent'
import { getSharedCopy } from 'src/applyfrontendcontent'
import { Input, Paragraph, AnchorLink, Checkbox, colors, media } from 'src/ui-framework'
import styled from 'styled-components'
import { spacing } from 'src/ui-framework/theme/spacing'
import { ReadOnlyGetStarted } from './ReadOnlyGetStarted'
import { ChannelType } from 'src/types/ChannelType'
import { UnifiedApplicationStatus } from 'src/types/IUnifiedApplicationState'

interface IGetStartedProps extends IUnifiedApplicationStepProps<IGetStartedState> {}
const reducer = new GetStartedReducer(getStartedPageIdentifier)

type HasReadOnly = {
  isReadOnly: boolean
  isRmJourney: boolean
}

const ContinueWrapper = styled.div<HasReadOnly>`
  margin-top: ${spacing.xl};

  ${media.upToTablet`    
    margin-top: ${spacing.l};
  `}
`

const PhoneNumberWrapper = styled.div<HasReadOnly>`
  margin-bottom: ${(props) => (props.isRmJourney ? '16px' : 0)};
`

const Closing: BasicComponentWithProps = {
  Component: Paragraph,
  props: {
    style: {
      marginBottom: '16px'
    }
  }
}

const ClosingLink: BasicComponentWithProps = {
  Component: AnchorLink,
  props: {
    target: '_blank',
    href: getSharedCopy(navigator.language).TermsAndConditionsUrl,
    rel: 'noopener noreferrer'
  }
}

const PrivacyPolicyLink: BasicComponentWithProps = {
  Component: AnchorLink,
  props: {
    target: '_blank',
    href: getSharedCopy(navigator.language).PrivacyPolicyUrl,
    rel: 'noopener noreferrer'
  }
}

const CanWeCallDescription = styled.div<any>`
  font-weight: 400;
  font-size: ${(props) => props.theme.fontSize.s};
  line-height: 24px;
  margin-top: ${spacing.xs};
  color: ${colors.textGrey600};

  ${media.upToTablet`
    font-size: ${(props: any) => props.theme.fontSize.xs};
  `}
`

const CanWeCallBox = styled.div<any>`
  margin-top: 16px;
`

const GetStarted = (p: IGetStartedProps) => {
  const [state, dispatch, content] = useUnifiedApplicationStep<IGetStartedState, GetStartedReducer>(
    reducer,
    p.initialState,
    p.applicationIdentifier,
    p.onSubmit,
    p.onAutoSave
  )

  const getStartedContent = content.GetStarted
  const onCompanySearchChange = (value: CompanySearchResult) => {
    const address: IAddressState = {
      addressLine1: value.address?.addressLine1 || '',
      addressLine2: value.address?.addressLine2 || '',
      city: value.address?.city || '',
      postCode: value.address?.postCode || '',
      country: value.address?.country || '',
      region: '',
      houseNumber: ''
    }

    const updates: IUpdateFieldPayload[] = [
      { field: 'address.country', value: value.address?.country || '' },
      { field: 'companyName', value: value.companyName },
      { field: 'companyNumber', value: value.companyNumber },
      { field: 'companyIdentifier', value: unknownCompanyIdentifier }
    ]

    if (!state.addCompanyManually) {
      updates.push({ field: 'resetCompanyAddress', value: true })
      updates.push({ field: 'resetDirector', value: true })
      Object.keys(address).forEach((f) => {
        updates.push({
          field: `address.${f}`,
          value: address[f]
        })
      })
    } else if (state.companyNumber !== value.companyNumber) {
      updates.push({ field: 'resetCompanyAddress', value: true })
      updates.push({ field: 'resetDirector', value: true })
    }

    dispatch.valuesUpdate(updates)
    // :'( I hate this but it's the
    // only way to do it for now
    if (state.companyName === '' && value.companyName !== '') {
      dispatch.validateField('companyName')
    }
  }

  // TODO : Move to mapper
  const companySearchResult: CompanySearchResult = {
    companyName: state.companyName || '',
    companyNumber: state.companyNumber || '',
    address: {
      addressLine1: state.address?.addressLine1,
      city: state.address?.city,
      postCode: state.address?.postCode,
      country: state.address?.country
    }
  }

  const isRelationshipManager = (): boolean => {
    if (state.status === UnifiedApplicationStatus.PartnerDraft) {
      return true
    }
    return false
  }

  const company = (
    <CompanySearch
      onChange={onCompanySearchChange}
      value={companySearchResult}
      errors={state.errors}
      dispatch={dispatch.action}
      addManually={state.addCompanyManually}
      content={content.GetStarted}
      isCompanyReadonly={state.isCompanyReadonly}
    />
  )

  // TODO: move to global state
  const isRelationshipManagerFlow = state.status !== UnifiedApplicationStatus.PartnerDraft
  const isReadOnly = state.channelType === ChannelType.RelationshipManager && isRelationshipManagerFlow

  return (
    <UnifiedApplicationStepGuard currentState={state}>
      <PageContainer>
        <MainDiv>
          {isReadOnly && <ReadOnlyGetStarted formState={state} content={getStartedContent} />}
          {!isReadOnly && (
            <>
              {isRelationshipManager() && <H3>{getStartedContent.ShortHeading}</H3>}
              <InputLayout>{company}</InputLayout>

              <InputLayout>
                <Input
                  id="name"
                  label={tokeniseLabelAsString(getStartedContent.Name)}
                  name="name"
                  autoComplete="name"
                  maxLength={100}
                  required
                  errors={state.errors['name'] as string[]}
                  value={state.name}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch.valueUpdate('name', e.target.value)
                  }}
                  onBlur={() => {
                    dispatch.validateField('name')
                  }}
                />
              </InputLayout>
              {isRelationshipManager() && <Subtext>{getStartedContent.Name.Hint}</Subtext>}
              <InputLayout>
                <Input
                  id="email-address"
                  label={tokeniseLabelAsString(getStartedContent.EmailAddress)}
                  name="email-address"
                  type="email"
                  autoComplete="email"
                  required
                  value={state.email}
                  errors={state.errors['email'] as string[]}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch.valueUpdate('email', e.target.value)
                  }}
                  onBlur={() => {
                    dispatch.validateField('email')
                  }}
                  readOnly={state.isReadOnly}
                  ariaReadOnly={state.isReadOnly}
                />
              </InputLayout>

              <InputLayout lg={12} xs={12} md={12}>
                <PhoneNumberWrapper isReadOnly={isReadOnly} isRmJourney={isRelationshipManager()}>
                  <Input
                    id="phone-number"
                    label={tokeniseLabelAsString(getStartedContent.PhoneNumber)}
                    name="phone-number"
                    type="tel"
                    autoComplete="tel"
                    required
                    value={state.phoneNumber}
                    errors={state.errors['phoneNumber'] as string[]}
                    placeholder={getStartedContent.PhoneNumber.Placeholder}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch.valueUpdate('phoneNumber', e.target.value)
                    }}
                    onBlur={() => {
                      dispatch.validateField('phoneNumber')
                    }}
                  />
                </PhoneNumberWrapper>
              </InputLayout>

              {getStartedContent.CanWeCallYourClient && (
                <CanWeCallBox>
                  <Checkbox
                    checked={state.canCallClient}
                    name="can-we-call"
                    id="can-we-call"
                    label={tokeniseLabelAsString(getStartedContent.CanWeCallYourClient)}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      dispatch.valueUpdate('canCallClient', e.target.checked)
                    }}
                    style={{ marginBottom: 0, flexGrow: 10, marginTop: 0 }}
                  />
                  <CanWeCallDescription>{getStartedContent.CanWeCallYourClient.Description}</CanWeCallDescription>
                </CanWeCallBox>
              )}
              {!isRelationshipManager() && (
                <ClosingWrapper>
                  <DynamicContent
                    content={getStartedContent}
                    components={{
                      Closing,
                      ClosingLink,
                      PrivacyPolicyLink
                    }}
                    ignoreComponents={['ClosingLink', 'PrivacyPolicyLink']}
                  />
                </ClosingWrapper>
              )}
            </>
          )}

          <ContinueWrapper isReadOnly={isReadOnly} isRmJourney={isRelationshipManager()}>
            <ContinueAndSaveLater
              content={content}
              loading={state.state === UnifiedApplicationState.Submitted}
              id="get-started-submit"
              showSaveLater={false}
              errors={state.errors}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.preventDefault()
                dispatch.action(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Submitting))
              }}
            />
          </ContinueWrapper>
        </MainDiv>
      </PageContainer>
    </UnifiedApplicationStepGuard>
  )
}

export default GetStarted
