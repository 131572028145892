import styled from 'styled-components'
import { media } from 'src/ui-framework'

export const H1 = styled.h1`
  margin: 0;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.textGrey900};
  font-family: 'Matter', 'Roboto Condensed', sans-serif !important;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;

  ${media.upToTablet`
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  `}

  ${media.upToLaptop`
    padding-left: 17px
  `}
`

export const H2 = styled.h2`
  font-family: 'Matter', 'Roboto Condensed', sans-serif !important;
  font-weight: 600;
  font-size: 20px;
  color: ${(props) => props.theme.colors.textGrey900};
  margin-bottom: ${(props) => props.theme.spacing.xl};
  margin-top: 0;

  ${media.upToTablet`
    font-size: 16px;
    line-height: 24px;
    margin-bottom: ${(props) => props.theme.spacing.xl};
  `}
`

export const H3 = styled.h3`
  font-family: 'Matter', 'Roboto Condensed', sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.16px;
  color: ${(props) => props.theme.colors.textGrey900};

  ${media.upToTablet`
    font-size: 14px;
    line-height: 20px;
  `}
`
