import { PartnerReferralDetailsContent } from 'src/applyfrontendcontent/types/partnerReferralDetails'

export const partnerReferralDetails: PartnerReferralDetailsContent = {
  Heading: 'To register referral, please provide additional information below on behalf of your customer',
  ShortHeading: 'Eligibility',
  CustomerHeading: 'Customer and company details',
  ApplicationDetailsHeading: 'Application details',
  Turnover: {
    Text: 'The company’s annual turnover',
    Options: {
      UpTo250: {
        Text: '£100-£250k'
      },
      UpTo500: {
        Text: '£250-500k'
      },
      UpTo1Mil: {
        Text: '£500k-£1m'
      },
      UpTo2Mil: {
        Text: '£1-2m'
      },
      UpTo3Mil: {
        Text: '£2-3m'
      },
      UpTo5Mil: {
        Text: '£3-5m'
      },
      MoreThan5Mil: {
        Text: '>£5m'
      }
    },
    Error: { Required: 'Please select company’s annual turnover' }
  },
  FundingAmountHeading: 'Amount',
  FundingAmount: {
    Text: 'How much funding does your customer need?',
    Error: {
      Required: 'Please enter a funding amount',
      LessThanMinimum: 'Please enter a funding amount greater than {Dynamic.MinimumAmount}',
      GreaterThanMaximum: 'Please enter a funding amount less than {Dynamic.MaximumAmount}',
      Invalid: 'Please enter a valid number'
    }
  },
  Timeline: {
    Text: 'When does your customer require funding?',
    Options: {
      Immediate: {
        Text: 'Immediate (within the next 2 weeks)'
      },
      VerySoon: {
        Text: 'Very soon (within the next 30 days)'
      },
      Planning: {
        Text: 'Planning (within the next 60 days)'
      },
      Exploring: {
        Text: 'Exploring options (no specific requirement but keen to understand more about potential solutions)'
      }
    },
    Error: { Required: 'Please select funding timeline' }
  },
  PartnerCustomer: 'The prospect is an existing Barclays customer',
  ApplicationReference: {
    Text: 'CIS number',
    Error: {
      Required: 'Please enter CIS number',
      Invalid: 'Please enter a valid 10 digit CIS number'
    }
  },
  CallTimeSlots: {
    Text: 'Please indicate 3 time slots that our team can call them to discuss the application.',
    Error: {
      Required: 'Please enter time slots for a call'
    }
  },
  ThreewayCallConfirmation: 'I want to join the call with the customer and the Kriya team',
  AdditionalInformation: {
    Text: 'Feel free to use this space to share any additional pertinent information, including:',
    Suggestions: [
      'Purpose of funds needed.',
      'Current funding sources for the business.',
      'Specific debtors or invoices requiring funding.',
      'Any initial objections or issues flagged by the client.'
    ],
    Error: {
      Required: 'Please provide additional information about the referred business'
    }
  },
  PartnerPhone: {
    Text: 'Your mobile number',
    Error: {
      Required: 'Please enter your phone number',
      Invalid: 'Please enter a valid phone number'
    }
  }
}
