import { UnifiedJourneyProductType, IUnifiedProductType } from '../../../types/ProductType'
import { EligibilityOutcome } from '../../../types/EligibilityOutcome'
import { Config } from 'src/applyfrontendcontent'

export const getNextSteps = (products: IUnifiedProductType, content: Config): Array<string> | undefined => {
  if (!products) {
    return undefined
  }

  const nextStepsLookup = [
    {
      requiredProducts: [UnifiedJourneyProductType.RecoveryLoan, UnifiedJourneyProductType.SelectInvoiceDiscounting],
      nextSteps: content.ThankYou.RecoveryLoanAndInvoiceFinanceBulletPoints
    },
    {
      requiredProducts: [UnifiedJourneyProductType.SelectInvoiceDiscounting],
      nextSteps: content.ThankYou.InvoiceFinanceBulletPoints
    },
    {
      requiredProducts: [UnifiedJourneyProductType.RecoveryLoan],
      nextSteps: content.ThankYou.RecoveryLoanBulletPoints
    }
  ]

  for (let i = 0; i < nextStepsLookup.length; i++) {
    const lookupData = nextStepsLookup[i]
    if (
      lookupData.requiredProducts.every((productType: UnifiedJourneyProductType) =>
        hasSelectedProduct(products, productType)
      )
    ) {
      return lookupData.nextSteps
    }
  }

  return undefined
}

export const hasRecoveryLoan = (products: IUnifiedProductType): boolean => {
  return hasSelectedProduct(products, UnifiedJourneyProductType.RecoveryLoan)
}

function hasSelectedProduct(products: IUnifiedProductType, productToFind: UnifiedJourneyProductType): boolean {
  return (
    productToFind in products &&
    products[productToFind].isSelected &&
    products[productToFind].eligibility === EligibilityOutcome.Eligible
  )
}
