import { PartnerConfig } from '../GlobalContext'
import { PartnerType } from '../types/PartnerType'
import { features } from '../features'
import { ChannelType } from '../types/ChannelType'

export type PartnerDetails = {
  config: PartnerConfig
  partnerType: PartnerType | undefined
}

export const barclaysLogoDetails: PartnerConfig = {
  linkUrl: 'https://www.kriya.co/work-with-us/financial-institutions',
  logoAlt: 'Barclays Logo',
  logoUrl: 'https://cdn.kriya.co/images/main-nav-barclayspartnership.svg'
}

export const getConfigForPartnerType = (
  partnerType: PartnerType | undefined,
  channel: ChannelType | undefined
): PartnerDetails | undefined => {
  if (!channel) {
    return undefined
  }

  switch (channel) {
    case ChannelType.Digital:
      if (partnerType === PartnerType.Barclays && features.BarclaysJourneyEnabled) {
        return {
          config: barclaysLogoDetails,
          partnerType
        }
      }
      return undefined
    case ChannelType.RelationshipManager:
    case ChannelType.Api:
      return partnerType === PartnerType.Barclays
        ? {
            config: barclaysLogoDetails,
            partnerType
          }
        : undefined
    default:
      return undefined
  }
}
