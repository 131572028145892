import { Config } from '../../../../types/locale'
import { readyToApply } from '../../ReadyToApply'
import { supportingDocuments } from '../../SupportingDocuments'
import { thankYou } from './ThankYou'
import { readyToApplyInvoiceFinance } from '../../ReadyToApplyInvoiceFinanceOnly'
import { fundingNeeds } from '../../FundingNeeds'
import { getStarted } from '../../GetStarted'
import { eligibilityResult } from '../../EligibilityResult'
import { eligibility } from './Eligibility'
import { directorDetails } from './DirectorDetails'

export const directorJourneyBarclays: Partial<Config> = {
  ReadyToApply: readyToApply,
  ReadyToApplyInvoiceFinance: readyToApplyInvoiceFinance,
  SupportingDocuments: supportingDocuments,
  FundingNeeds: fundingNeeds,
  ThankYou: thankYou,
  GetStarted: getStarted,
  Eligibility: eligibility,
  EligibilityResult: eligibilityResult,
  DirectorDetails: directorDetails
}
