import { sharedCopy } from './en-GB'
import { directorJourney } from './en-GB/DirectorJourney'
import { representativeJourney } from './en-GB/RepresentativeJourney'
import { directorJourneySales } from './en-GB/Sales/DirectorJourney'
import { representativeJourneySales } from './en-GB/Sales/RepresentativeJourney'
import { directorJourneyBarclays } from './en-GB/BarclaysJourney/Digital/DirectorJourney'
import { representativeJourneyBarclays } from './en-GB/BarclaysJourney/Digital/RepresentativeJourney'
import {
  ConditionInput,
  Config,
  ConfigPart,
  RepresentativeType,
  Locale,
  Node,
  PartnerType,
  FlowType,
  ChannelType
} from './types/locale'
import { SharedContent } from './types/shared'
import { JourneyIdentifier } from './types/journeyIdentifier'
import { referralJourneyBarclays } from './en-GB/BarclaysJourney/RM/Referrer'
import { directorReferredJourneyBarclays } from './en-GB/BarclaysJourney/RM/DirectorJourney'
import { representativeReferredJourneyBarclays } from './en-GB/BarclaysJourney/RM/RepresentativeJourney'

const defaultLocale: keyof Locale = 'en-gb'

type PartnerChannels = ChannelType.RelationshipManager | 'Default'

const partnerJourneys: {
  [key in PartnerType]: { [key in PartnerChannels]: { [key in RepresentativeType]?: Partial<Config> } }
} = {
  Barclays: {
    Default: {
      Director: directorJourneyBarclays,
      Representative: representativeJourneyBarclays
    },
    RelationshipManager: {
      Director: directorReferredJourneyBarclays,
      Representative: representativeReferredJourneyBarclays
    }
  }
}

const partnerReferralJourneys: { [key in PartnerType]: Partial<Config> } = {
  Barclays: referralJourneyBarclays
}

const partnerReferralSalesJourneys: { [key in PartnerType]: Partial<Config> } = {
  // Change if sales texts need to be updated
  Barclays: referralJourneyBarclays
}

const getPartnerNodes = (representativeType: RepresentativeType): Node[] => {
  return Object.keys(partnerJourneys)
    .flatMap((key) => {
      const partnerType = key as PartnerType

      return Object.keys(partnerJourneys[partnerType]).map((channel) => {
        const channelType = channel as PartnerChannels
        const content = partnerJourneys[partnerType][channelType][representativeType]
        return {
          condition: (input: ConditionInput) =>
            input.partner === partnerType &&
            input.sales === false &&
            (input.channelType === channelType ||
              (input.channelType !== ChannelType.RelationshipManager && channelType === 'Default')),
          content: content
        } as Node
      })
    })
    .filter((r) => r.content != null)
}

const getPartnerReferralNodes = (): Node[] => {
  return getPartnerReferralConditions(partnerReferralJourneys, false)
}

const getPartnerReferralSalesNodes = (): Node[] => {
  return getPartnerReferralConditions(partnerReferralSalesJourneys, true)
}
function getPartnerReferralConditions(journeys: { [key in PartnerType]: Partial<Config> }, sales: boolean): Node[] {
  return Object.keys(journeys)
    .map((key) => {
      const partnerType = key as PartnerType

      const content = journeys[partnerType]
      return {
        condition: (input: ConditionInput) =>
          input.partner === partnerType && input.sales === sales && input.flowType === FlowType.StrategicPartner,
        content: content
      } as Node
    })
    .filter((r) => r.content != null)
}

const allCopy: Locale = {
  'en-gb': {
    children: [
      {
        children: [
          {
            condition: (input: ConditionInput) => input.sales === true,
            content: directorJourneySales
          },
          ...getPartnerNodes(RepresentativeType.Director)
        ],
        condition: (input: ConditionInput) => input.representativeType === RepresentativeType.Director,
        content: directorJourney
      },
      {
        children: [
          {
            condition: (input: ConditionInput) => input.sales === true,
            content: representativeJourneySales
          },
          ...getPartnerNodes(RepresentativeType.Representative)
        ],
        condition: (input: ConditionInput) => input.representativeType === RepresentativeType.Representative,
        content: representativeJourney
      },
      ...getPartnerReferralNodes(),
      ...getPartnerReferralSalesNodes()
    ],
    condition: () => true,
    content: sharedCopy
  }
}

const isValidLocale = (i: string): i is keyof Locale => {
  return i in allCopy
}

const isRepresentativeType = (i: string): i is RepresentativeType => {
  return i in RepresentativeType
}

const isPartnerType = (i: string | undefined): i is PartnerType => {
  return i !== undefined && i in PartnerType
}

const buildConfig = (node: Node, input: ConditionInput, results: ConfigPart): void => {
  if (node.condition(input) === false) return

  const baseConfig: ConfigPart = { ...node.content }
  for (let key in baseConfig) {
    results[key] = baseConfig[key]
  }

  for (let child of node.children || []) {
    buildConfig(child, input, results)
  }
}

const getCacheKey = (locale: keyof Locale, condition: ConditionInput): string => {
  return `${locale.toLowerCase()}:${condition.representativeType}:${condition.sales}:${condition.partner || ''}:${
    condition.businessType || ''
  }:${condition.flowType || ''}:${condition.channelType || ''} `
}

type BuildCache = {
  [k: string]: Config
}

const buildCache: BuildCache = {}

const defaultRepresentativeType: RepresentativeType = RepresentativeType.Director
const defaultPartnerType: PartnerType | undefined = undefined

const buildCopyForLocale = (locale: keyof Locale, journeyIdentifier: JourneyIdentifier): Config => {
  const condition: ConditionInput = {
    representativeType: isRepresentativeType(journeyIdentifier.representativeType)
      ? journeyIdentifier.representativeType
      : defaultRepresentativeType,
    sales: journeyIdentifier.sales,
    partner: isPartnerType(journeyIdentifier.partner) ? journeyIdentifier.partner : defaultPartnerType,
    businessType: journeyIdentifier.businessType,
    flowType: journeyIdentifier.flowType,
    channelType: journeyIdentifier.channelType
  }
  const cacheKey = getCacheKey(locale, condition)
  if (cacheKey in buildCache) {
    return buildCache[cacheKey]
  }
  const copy = allCopy[locale]
  const result: ConfigPart = {}
  buildConfig(copy, condition, result)
  buildCache[cacheKey] = result as Config
  return result as Config
}

export const getCopy = (locale: string, journeyIdentifier: JourneyIdentifier): Config => {
  const lowerCaseLocale = locale.toLowerCase()
  return buildCopyForLocale(isValidLocale(lowerCaseLocale) ? lowerCaseLocale : defaultLocale, journeyIdentifier)
}

export const getSharedCopy = (locale: string): SharedContent => {
  const lowerCaseLocale = locale.toLowerCase()
  const sharedConfig = isValidLocale(lowerCaseLocale) ? allCopy[lowerCaseLocale] : allCopy[defaultLocale]

  if (sharedConfig.content.Shared === undefined) {
    throw new Error('No shared content')
  }

  return sharedConfig.content.Shared
}
