import React from 'react'
import ErrorList from '../ErrorList'
import Label from '../../../commonStyles/Label'
import { SelectDiv } from '../../../commonStyles/ThemedSelect'
import { default as SelectReact } from 'react-select'
import { Control, IndicatorSeparator, DropdownIndicator, MenuList, Option, Input } from './themedSelect'

type SelectProps = {
  dataTest?: string
  onChange: (e: any) => void
  onBlur?: (e: any) => void
  defaultText?: string
  options: {
    label?: string
    value?: number | string
  }[]
  errors?: Array<string>
  label?: string
  required?: boolean
  id?: string
  value: string | number
  name: string
  theme?: any
  hideOptionIcon?: boolean
  hideErrors?: boolean
}

export const Select = (prop: SelectProps) => {
  const {
    id,
    label,
    required,
    options,
    defaultText,
    errors,
    dataTest,
    value,
    name,
    onChange,
    onBlur,
    hideOptionIcon,
    hideErrors
  } = prop

  const handleOnBlur = (event: any) => {
    onBlur && onBlur(event)
  }

  const customOptions = options.map((option, index) => {
    return { value: option.value, label: option.label }
  })
  const optionsList = [{ value: '', label: defaultText, isDisabled: required }, ...customOptions]

  return (
    <div data-test={dataTest}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <SelectDiv>
        <SelectReact
          id={`${id}_select`}
          inputId={id}
          name={name}
          options={optionsList}
          onChange={onChange}
          blurInputOnSelect={true}
          closeMenuOnScroll={true}
          value={optionsList.find((v) => v.value === value)}
          onBlur={handleOnBlur}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: 'inherit',
              boxShadow: 'none',
              paddingLeft: '6px',
              ':hover': {
                borderColor: 'inherit',
                backgroundColor: 'inherit'
              }
            }),
            option: (base) => ({
              ...base,
              backgroundColor: 'none',
              color: 'auto',
              paddingLeft: hideOptionIcon ? '15px' : '36px'
            })
          }}
          components={{ Control, IndicatorSeparator, DropdownIndicator, MenuList, Option, Input }}
          // Dirty hack due to limitations of react-select library
          //@ts-ignore
          errors={errors}
          hideOptionIcon={hideOptionIcon}
        />
      </SelectDiv>
      <ErrorList errors={hideErrors ? [] : errors} id={id ?? ''} />
    </div>
  )
}

Select.defaultProps = {
  id: 'select',
  name: 'select',
  required: false,
  label: '',
  errors: [],
  defaultText: 'Please select',
  options: [],
  onChange: () => {},
  onBlur: () => {},
  value: '',
  disabled: false,
  dataTest: undefined,
  hideOptionIcon: false
}

export default Select
