import React, { useState, useEffect } from 'react'
import { ReadyToApplyProductContent } from './ReadyToApplyContent'
import { useInitialisationParameters } from './useInitialisationParameters'
import { RepresentativeJourneyType } from '../../../types/RepresentativeJourneyType'
import { SetHotjarPageData } from './hotjarJourneyTypePageView'
import { DynamicContent, tokeniseOptions } from '../../../shared/content/DynamicContent'
import { useApplicationPageTracker } from '../../../trackPages'
import { ICreateApplicationState, ButtonType } from '../../../types/IInitialisationParameters'
import { useHistory } from 'react-router-dom'
import { getCopy } from 'src/applyfrontendcontent'
import { PartnerType } from '../../../types/PartnerType'
import { cookieExists } from '../../../utils/cookieHelper'
import { getConfigForPartnerType } from '../../../shared/setPartnerLogo'
import { useGlobalContext } from '../../../GlobalContext'
import {
  BritishBankImage,
  FooterMarketing,
  TrustPilotImageWrapper
} from '../../../commonStyles/components/unifiedJourneyFooter'
import { BritishBankLogo } from '../../../commonStyles/images/images'
import styled from 'styled-components'
import TrustPilotBox from 'src/shared/components/footer/TrustPilot'
import { H1 } from 'src/commonStyles/components/headings'
import { partnerEmail } from 'src/constants'
import {
  AboutUsListWrapper,
  ReadyToApplyMainContentWrapper,
  ReadyToApplySideContentWrapper,
  ReadyToApplyWrapper,
  RequirementsListWrapper,
  StyledHeader,
  StyledLi,
  StyledUl
} from './ReadyToApplyStyles'
import { media } from 'src/ui-framework'
import { ChannelType } from 'src/types/ChannelType'
import { FlowType } from 'src/types/FlowType'

const StyledH1 = styled(H1)`
  margin-top: 16px;
  line-height: 36px;
`

const StyledHeading = styled(StyledH1)`
  font-size: 26px;
  padding-left: 0px;
  ${media.upToTablet`
    margin-left: 17px;
    font-size: 20px;
    line-height: 28px;
  `}
`

const Heading = { Component: StyledHeading }
const WelcomePartner = { Component: StyledHeading }

const ReadyToApply = () => {
  const initialisationParameters = useInitialisationParameters()
  const history = useHistory<ICreateApplicationState>()
  const [repJourneyChoiceErrors, setErrors] = useState<string[]>([])
  const { copy, setCopy, setPartnerData } = useGlobalContext()

  useApplicationPageTracker('Ready to apply', null)

  const partnerDetails = getConfigForPartnerType(initialisationParameters.partner, initialisationParameters.channel)
  const content = copy

  let productWiseContent = content.ReadyToApplyInvoiceFinance

  if (partnerDetails?.partnerType === PartnerType.Barclays) {
    productWiseContent = copy.ReadyToApplyInvoiceFinance
  }

  const journeyOptions = tokeniseOptions(productWiseContent.Affiliation!)
  const [repJourney, setRepJourney] = useState<RepresentativeJourneyType>(
    journeyOptions[0].value as RepresentativeJourneyType
  )

  useEffect(() => {
    if (partnerDetails?.partnerType !== undefined) {
      const newCopy = getCopy(navigator.language, {
        representativeType: RepresentativeJourneyType.Director,
        sales: cookieExists('sales'),
        partner: partnerDetails?.partnerType
      })
      setCopy(newCopy)
      setPartnerData(partnerDetails.config)
    }
  }, [partnerDetails, setCopy, setPartnerData])

  const handleRepJourneySelect = (value: string) => {
    if (!(value in RepresentativeJourneyType)) return
    setErrors([])
    setRepJourney(value as RepresentativeJourneyType)
  }

  const handleEmailUsClick = () => {
    window.location.href = `mailto:${partnerEmail}`
  }

  const startApplication = (buttonType: ButtonType) => {
    const createApplicationState: ICreateApplicationState = {
      initialisationParameters,
      buttonType,
      representativeJourneyType: repJourney!,
      canCallClient: false,
      flowType:
        initialisationParameters.channel === ChannelType.RelationshipManager
          ? FlowType.StrategicPartner
          : FlowType.Standard
    }

    history.push({
      pathname: '/application',
      state: createApplicationState
    })

    SetHotjarPageData(
      initialisationParameters.sales,
      initialisationParameters.partner,
      initialisationParameters.channel,
      repJourney
    )
  }

  const handleStandardClick = () => {
    startApplication(ButtonType.Standard)
  }

  let comp: React.ReactNode

  comp = (
    <>
      <StyledHeader>
        {productWiseContent.Dynamic?.PartnerCompanyName ? (
          <DynamicContent
            components={{
              WelcomePartner
            }}
            content={productWiseContent}
          />
        ) : (
          <DynamicContent
            components={{
              Heading
            }}
            content={productWiseContent}
          />
        )}
      </StyledHeader>
      <ReadyToApplyWrapper>
        <ReadyToApplyMainContentWrapper>
          <ReadyToApplyProductContent
            onStandardClick={handleStandardClick}
            onEmailUsClick={handleEmailUsClick}
            isSalesInitiated={initialisationParameters.sales}
            onRepresentiveSelection={handleRepJourneySelect}
            journeyChoice={repJourney}
            content={productWiseContent}
            errors={repJourneyChoiceErrors || []}
            partnerDetails={partnerDetails}
          />
        </ReadyToApplyMainContentWrapper>
        <ReadyToApplySideContentWrapper>
          <RequirementsListWrapper>
            {content.ReadyToApply.RequirementsHeading}
            <StyledUl>
              {content.ReadyToApply.Requirements?.map((r, i) => (
                <StyledLi key={i}>{r}</StyledLi>
              ))}
            </StyledUl>
          </RequirementsListWrapper>
          <AboutUsListWrapper>
            {content.ReadyToApply.AboutUsHeading}
            <StyledUl>
              {content.ReadyToApply.AboutUsItems?.map((r, i) => (
                <StyledLi key={i}>{r}</StyledLi>
              ))}
            </StyledUl>
          </AboutUsListWrapper>
          <FooterMarketing>
            <BritishBankImage src={BritishBankLogo} alt={content.ReadyToApply.BritishBankImageAltText} />
            <TrustPilotImageWrapper>
              <TrustPilotBox />
            </TrustPilotImageWrapper>
          </FooterMarketing>
        </ReadyToApplySideContentWrapper>
      </ReadyToApplyWrapper>
    </>
  )

  return <>{comp}</>
}

export default ReadyToApply
