import styled from 'styled-components'
import { device, media } from '../../commonStyles/mediaQueries/responsiveDeviceSize'
import { colors } from '../../theme/colors'

type ButtonStyleProps = {
  isLoading?: boolean
  classType: 'primary' | 'secondary'
}

const StyledButton = styled.button<ButtonStyleProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;
  margin: 10px 0;
  min-width: 157px;
  height: 48px;
  font-size: 16px;

  background: ${colors.buttonDefault};
  color: ${colors.white};
  border-radius: 100px;

  flex: none;
  order: 0;
  flex-grow: 0;

  ${({ classType, isLoading }) => handleButtonType(classType, isLoading)}

  @media ${device.tabletS} {
    min-width: 120px;
  }

  ${media.upToTablet`
    height: 44px;
    font-size: 14px;
    min-width: 100%;
    order: 1;
  `}
`

const handleButtonType = (type: 'primary' | 'secondary', isLoading?: boolean) => {
  switch (type) {
    default:
    case 'primary':
      return `
          background-color: ${colors.buttonDefault}
          color: ${colors.white};
          border-color: transparent;
          box-shadow: 0 0 0 1px ${colors.buttonDefault}}
          border-style: solid;
          font-family: 'Matter';
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
          white-space: nowrap;

        &:hover {
          background-color: ${colors.buttonHover};
          cursor: pointer;
        }

        &:active {
          background-color: ${colors.buttonActive};          
        }        
  
        &:focus {           
          box-shadow: ${isLoading === false ? `0 0 0 4px ${colors.buttonFocusedBorder}` : 'none'};
          border-style: solid;
          background-color: ${isLoading === false ? colors.buttonFocused : 'inherit'};
          color: ${isLoading === false ? colors.white : 'inherit'};
          outline: none;
        }

        &:disabled {
          background-color: ${colors.grey100};
          color: ${colors.grey300};
          border: none;
          box-shadow: none;
          cursor: auto;
        }
      `
    case 'secondary':
      return `
      background-color: ${colors.buttonOutlineDefault};
      color: ${colors.buttonOutlineDefaultText};
      border-color: transparent;
      box-shadow: 0 0 0 1px;
      border-style: solid;
      font-family: 'Matter';
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      white-space: nowrap;

      &:hover {
        background-color: ${colors.buttonHover};
        color: ${colors.white};
        cursor: pointer;
      }

      &:active {
        background-color: ${colors.buttonActive};
        color: ${colors.white};
      }      

      &:focus {
        box-shadow: ${isLoading === false ? `0 0 0 3px ${colors.buttonFocusedBorder}` : 'none'};
        border-style: solid;                
        background-color: ${isLoading === false ? colors.buttonFocused : 'inherit'};     
        outline: none;        
        color: ${colors.white};                       
      }
    `
  }
}

export default StyledButton
