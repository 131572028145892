import { colors } from './colors'

export type Focused = {
  borderColor?: string
  boxShadow?: string
  border: string
  backgroundColor: string
}

export type BorderStyles = {
  focused: Focused
  errorFocused: Focused
}

export const borderStyles: BorderStyles = {
  focused: {
    boxShadow: '0 0 4px 2px rgba(0, 0, 0, 0.25)',
    border: `solid 1px ${colors.buttonActive}`,
    backgroundColor: colors.white
  },
  errorFocused: {
    border: `solid 1px ${colors.error}`,
    boxShadow: '0 0 4px 2px rgba(0, 0, 0, 0.25)',
    backgroundColor: colors.white
  }
}
