import { EligibilityContent } from '../types/eligibility'

export const eligibility: EligibilityContent = {
  Heading: 'Eligibility',
  ShortHeading: 'Eligibility',
  Introduction: 'Are {All} of the below points applicable to your company?',
  All: 'ALL',
  OffersB2bServices: {
    Text: 'Do you provide products or services to other businesses?',
    Options: {
      Yes: { Text: 'Yes' },
      No: { Text: 'No' }
    },
    Error: { Required: 'Please select an answer' }
  },
  TurnoverExceeds100k: {
    Text: 'Does the business have a minimum turnover of £100,000 p.a. or annualised income from current year’s trading?',
    Options: {
      Yes: { Text: 'Yes' },
      No: { Text: 'No' }
    },
    Error: { Required: 'Please select an answer' }
  },
  FundingNeededIn60d: {
    Text: 'Do you require funding in the next 60 days?',
    Options: {
      Yes: { Text: 'Yes' },
      No: { Text: 'No' }
    },
    Error: { Required: 'Please select an answer' }
  },
  InvoicesUpfront: {
    Text: 'Are the invoices you are looking to fund raised in arrears (upon completed work or for staged payments) and/or upfront via an online checkout?',
    Options: {
      Yes: { Text: 'Yes' },
      No: { Text: 'No' }
    },
    Error: { Required: 'Please select an answer' }
  },
  InvoicesInDate: {
    Text: 'Are the outstanding invoices you want to fund in date and not overdue?',
    Options: {
      Yes: { Text: 'Yes' },
      No: { Text: 'No' }
    },
    Error: { Required: 'Please select an answer' }
  },
  FundingExceeds25k: {
    Text: 'Do you have a minimum funding requirement of £25K?',
    Options: {
      Yes: { Text: 'Yes' },
      No: { Text: 'No' }
    },
    Error: { Required: 'Please select an answer' }
  },
  InvoicesTermsExceed15d: {
    Text: 'Do the invoices you wish to fund, have payment terms of more than 15 days?',
    Options: {
      Yes: { Text: 'Yes' },
      No: { Text: 'No' }
    },
    Error: { Required: 'Please select an answer' }
  },
  InvoicesEachExceed10k: {
    Text: 'Are the invoices you wish to fund more than £10K in value?',
    Options: {
      Yes: { Text: 'Yes' },
      No: { Text: 'No' }
    },
    Error: { Required: 'Please select an answer' }
  },
  InvoiceFinanceDiscussionAwareness: {
    Text: "Do you have awareness that we will be discussing Kriya's Selective Invoice Finance facility, alongside B2B Payment solutions?",
    Options: {
      Yes: { Text: 'Yes' },
      No: { Text: 'No' }
    },
    Error: { Required: 'Please select an answer' }
  },
  ExtendCreditSolutionNeeded: {
    Text: 'Do you have the need for the access to a funding solution that extends credit based on new or outstanding invoices?',
    Options: {
      Yes: { Text: 'Yes' },
      No: { Text: 'No' }
    },
    Error: { Required: 'Please select an answer' }
  }
}
