import IFileState, { FileStatusState } from '../types/IFileState'

class StringToIFileStateImpl {
  map = (i: string[]): IFileState[] => {
    return i.map((id) => {
      return {
        id: id,
        name: 'unknown',
        status: FileStatusState.Deleted,
      }
    })
  }

  reverseMap = (i: IFileState[]): string[] => {
    return i.map((s) => s.id!)
  }
}

const StringToIFileState = new StringToIFileStateImpl()
export default StringToIFileState
