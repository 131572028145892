import React from 'react'
import { OnChangeValue } from 'react-select'
import { media } from 'src/ui-framework/commonStyles/mediaQueries/responsiveDeviceSize'
import { spacing } from 'src/ui-framework/theme/spacing'
import styled from 'styled-components'
import Label from '../../../commonStyles/Label'
import ErrorList from '../ErrorList'
import Select from '../Select'
import { InputDateWrapper, StyledSelect } from './themedDatePortion'

export type ValueProps = {
  day?: number
  month?: number
  year?: number
}

type OwnInputDateProps = {
  id: string
  name?: string
  required?: boolean
  onChange?: (value: ValueProps) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  errors: string[]
  label?: string
  value?: ValueProps
}

type InputDateProps = OwnInputDateProps

const DayStyledSelect = styled(StyledSelect)`
  min-width: 90px;
  ${media.upToLaptop`
    min-width: 69px;
  `}
  ${media.xsOnly`
    min-width: initial;
  `}
`

const MonthStyledSelect = styled(StyledSelect)`
  min-width: 130px;
  margin-left: ${spacing.xs};
  ${media.upToLaptop`
    min-width: 123px;
  `}
  ${media.xsOnly`
    min-width: initial;
    margin: ${spacing.xs} 0 0 0;
  `}
`

const YearStyledSelect = styled(StyledSelect)`
  min-width: 90px;
  margin-left: ${spacing.xs};
  ${media.upToLaptop`
    min-width: 80px;
  `}
  ${media.xsOnly`
    min-width: initial;
    margin: ${spacing.xs} 0 0 0;
  `}
`

const DateComponentWrapper = styled.div`
  display: flex;
  justifycontent: flex-start;
  ${media.xsOnly`
    flex-direction: column;
  `}
`

const InputDate = (props: InputDateProps) => {
  const { errors, label, id, value, onChange, onBlur } = props
  const { day, month, year } = value!

  const dayOptions = Array.from({ length: 31 }, (v, k) => k + 1).map((v) => {
    return { value: v, label: `${v}` }
  })

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const monthOptions = monthNames.map((v, i) => {
    return { value: i + 1, label: `${v}` }
  })

  const currentYear = new Date().getFullYear()
  const yearOptions = Array.from({ length: 90 }, (v, k) => k + 1).map((v, i) => {
    return { value: currentYear - 10 - v, label: `${currentYear - 10 - v}` }
  })

  return (
    <>
      <InputDateWrapper id={id}>
        {label && <Label htmlFor={id}>{label}</Label>}
        <DateComponentWrapper>
          <DayStyledSelect>
            <Select
              id="day"
              name="director-day"
              defaultText="Day"
              hideOptionIcon={true}
              options={dayOptions}
              value={day}
              onChange={(e: OnChangeValue<any, any>) => {
                onChange && onChange({ ...value, day: e.value })
              }}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                onBlur && onBlur(e)
              }}
              errors={errors}
              hideErrors={true}
            />
          </DayStyledSelect>
          <MonthStyledSelect>
            <Select
              id="month"
              name="director-month"
              defaultText="Month"
              hideOptionIcon={true}
              options={monthOptions}
              value={month}
              onChange={(e: OnChangeValue<any, any>) => {
                onChange && onChange({ ...value, month: e.value })
              }}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                onBlur && onBlur(e)
              }}
              errors={errors}
              hideErrors={true}
            />
          </MonthStyledSelect>
          <YearStyledSelect>
            <Select
              id="year"
              name="director-year"
              defaultText="Year"
              hideOptionIcon={true}
              options={yearOptions}
              value={year}
              onChange={(e: OnChangeValue<any, any>) => {
                onChange && onChange({ ...value, year: e.value })
              }}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                onBlur && onBlur(e)
              }}
              errors={errors}
              hideErrors={true}
            />
          </YearStyledSelect>
        </DateComponentWrapper>
      </InputDateWrapper>
      <ErrorList errors={errors} id={id ?? ''} />
    </>
  )
}

InputDate.defaultProps = {
  id: 'input-date',
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
  errors: [],
  label: '',
  value: {}
}

export default InputDate
