import { EligibilityOutcome } from './EligibilityOutcome'

export enum UnifiedJourneyProductType {
  Loans = '376b4fe0-e85d-4d11-86df-d0eb36961b11',
  SelectInvoiceDiscounting = '06b092d6-bb17-4b4e-8b16-83360f9482b6', // Standard IF
  RecoveryLoan = '2dc12978-d761-4dd0-872e-4b0c10d0b5f8'
}

export interface IUnifiedProduct {
  eligibility: EligibilityOutcome
  backgroundEligibility?: EligibilityOutcome
  isSelected: boolean
  amount?: number
}

export interface IUnifiedProductType {
  [Key: string]: IUnifiedProduct
}
