import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router'
import axios, { AxiosRequestConfig } from 'axios'
import { Paragraph, AnchorLink, media, colors, LinkButton } from 'src/ui-framework'
import { H1 } from '../../../commonStyles/components/headings'
import NextSteps from './NextSteps'
import IApplication from '../../../types/IApplication'
import { ErrorType } from '../../../reducers/unifiedApplicationActions'
import { UnifiedApplicationStatus } from '../../../types/IUnifiedApplicationState'
import { Redirect } from 'react-router-dom'
import { DynamicContent, tokeniseToString } from '../../../shared/content/DynamicContent'
import { getCopy, Config } from 'src/applyfrontendcontent'
import { useApplicationPageTracker } from '../../../trackPages'
import { useGlobalContext } from '../../../GlobalContext'
import { map } from '../../../mappers/IApplicationToJourneyIdentifier'
import { hasRecoveryLoan } from './NextStepsLookup'
import { BoxedNote } from '../../../commonStyles/components/boxedNote'
import { applyServiceSuffix } from 'src/urlSettingsExports'
import styled from 'styled-components'
import { spacing } from 'src/ui-framework/theme/spacing'
import { MainContent, MainContentWrapper } from './ThankYouPageStyles'
import ErrorPage from 'src/pages/error/ErrorPage'
import { ChannelType } from 'src/types/ChannelType'
import { barclaysStandardJourney } from 'src/pages/partnerJourney/barclaysJourney/barclaysJourneys'
import { UserRole } from 'src/types/UserRole'
import { FlowType } from 'src/types/FlowType'
import { PartnerType } from 'src/types/PartnerType'

export interface ThankYouParams {
  id: string
}

const axiosConfig: AxiosRequestConfig = {
  withCredentials: true,
  validateStatus: (status: number): boolean => {
    return status >= 200 && status < 500
  }
}

const styledH1 = styled(H1)`
  ${media.upToLaptop`
    padding-left: 0px;
  `}
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: ${colors.textGrey900};
  margin-bottom: ${spacing.xl};
  ${media.upToTablet`
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  `}
`

const IntroductionWrapper = styled.div`
  margin-top: ${spacing.xl};
  ${media.upToTablet`
    line-height: 20px;
    letter: 0.16px;    
    margin-top: ${spacing.xl};
  `}
`

const ThankYouWrapper = styled.div`
  ${media.upToTablet`
    line-height: 20px;
    letter: 0.16px;    
    line-height: 20px;
  `}
`

const StyledSuccessMessage = styled.div`
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  padding: 16px 34px;
  gap: 10px;

  /* $success-8% */

  background: ${colors.success08};
  /* $success-30% */

  border: 1px solid ${colors.success30};
  border-radius: 8px;
  color: ${colors.success};

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  ${media.upToTablet`
    padding: 16px 24px;
  `}
`

const Heading = { Component: styledH1 }
const Introduction = {
  Component: <T extends object>(p: React.PropsWithChildren<T>) => <div id="you-are-done-copy">{p.children}</div>
}

const StyledThankYouTextParagraph = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  ${media.upToTablet`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.16px;
  `}
`

const SuccessMessage = { Component: StyledSuccessMessage }

const Closing = { Component: StyledThankYouTextParagraph }
const ClosingRecoveryLoan = { Component: StyledThankYouTextParagraph }
const IntroductionItem = { Component: StyledThankYouTextParagraph }
const PartnerEmailLink = styled(LinkButton)``

interface IStateWithContent {
  copy?: Config
}

const Thankyou = () => {
  const [application, setApplication] = useState<IApplication>()
  const [unauthorised, setUnauthorised] = useState<boolean>()
  const [isUserRM, setIsUserRM] = useState<boolean>()
  const params = useParams<ThankYouParams>()
  const { copy, setCopy } = useGlobalContext()

  const location = useLocation<IStateWithContent>()
  useApplicationPageTracker('Thank you', params.id)

  useEffect(() => {
    axios.get<IApplication>(`/${applyServiceSuffix()}/applications/${params.id}`, axiosConfig).then((res) => {
      if (res.status === 403 || res.status === 401) {
        setUnauthorised(true)
      } else if (res.status === 200) {
        const userRole: UserRole = res.headers['user'] || UserRole.Standard
        const isRm = userRole === UserRole.RelationshipManager
        setIsUserRM(isRm)

        const copyDataQuery = map(res.data)
        copyDataQuery.flowType = isRm ? FlowType.StrategicPartner : FlowType.Standard
        const newCopy = getCopy(navigator.language, copyDataQuery)
        setCopy(newCopy)
        setApplication(res.data)
      }
    })
  }, [params, setIsUserRM, setApplication, setCopy])

  useEffect(() => {
    if (application === undefined || application.status !== UnifiedApplicationStatus.Submitted) return
    window.sessionStorage.removeItem('helpFields')
    window.sessionStorage.removeItem('sessionFields')
  }, [application])

  const isBarclaysRmFlowCompleted =
    application !== undefined &&
    application.channelType === ChannelType.RelationshipManager &&
    ((application.lastCompletedStep !== barclaysStandardJourney[barclaysStandardJourney.length - 1].page.id &&
      application.status !== UnifiedApplicationStatus.PartnerDraft) ||
      (application.lastCompletedStep === barclaysStandardJourney[barclaysStandardJourney.length - 1].page.id &&
        application.status === UnifiedApplicationStatus.PartnerDraft))

  if (unauthorised === true) {
    const unauthContent = location.state?.copy === undefined ? copy : location.state.copy
    return <ErrorPage errorType={ErrorType.Unauthorized} content={unauthContent} />
  } else if (application === undefined) {
    return <p>{tokeniseToString(copy.Shared, 'LoadingPage')}</p>
  } else if (
    (isUserRM && !isBarclaysRmFlowCompleted && application.status === UnifiedApplicationStatus.PartnerDraft) ||
    (!isUserRM && application.status !== UnifiedApplicationStatus.Submitted)
  ) {
    return <Redirect to={`/application/${application.applicationIdentifier}`} />
  }

  let comp: React.ReactNode
  comp = (
    <>
      <div>
        {copy.ThankYou.SuccessMessage && (
          <DynamicContent
            content={copy.ThankYou}
            components={{
              SuccessMessage
            }}
          />
        )}
        <IntroductionWrapper>
          <DynamicContent
            content={copy.ThankYou}
            components={{
              Heading,
              Introduction,
              IntroductionItem,
              PartnerContact: {
                Component: PartnerEmailLink,
                props: {
                  autoFontSize: true,
                  onClick: () => {
                    window.location.href = copy.ThankYou.PartnerContactLink!
                  }
                }
              }
            }}
            ignoreComponents={['PartnerContact']}
          />
        </IntroductionWrapper>
        {copy.ThankYou.ReferralTitle && copy.ThankYou.ReferralDescription ? (
          <BoxedNote>
            <div>
              <Paragraph style={{ marginBottom: 0, fontWeight: 600, marginTop: 10 }} id="referral-title">
                {copy.ThankYou.ReferralTitle}
              </Paragraph>
              <DynamicContent
                content={copy.ThankYou}
                components={{
                  ReferralDescription: {
                    Component: <T extends object>(p: React.PropsWithChildren<T>) => (
                      <Paragraph id="referral-description" style={{ fontWeight: 400, marginTop: 10, marginBottom: 10 }}>
                        {p.children}
                      </Paragraph>
                    )
                  },
                  FindOutMore: {
                    Component: <T extends object>(x: React.PropsWithChildren<T>) => (
                      <AnchorLink
                        href={copy.ThankYou.FindOutMoreLink!}
                        target="_blank"
                        id="find-out-more-link"
                        style={{ fontSize: '16px' }}
                      >
                        {x.children}
                      </AnchorLink>
                    )
                  }
                }}
                ignoreComponents={['FindOutMore']}
              />
            </div>
          </BoxedNote>
        ) : null}

        {application.products && application.partnerType && application.partnerType === PartnerType.Barclays && (
          <NextSteps products={application.products} content={copy} />
        )}
        {application.products && hasRecoveryLoan(application.products!) && (
          <DynamicContent
            content={copy.ThankYou}
            components={{
              ClosingRecoveryLoan
            }}
          />
        )}
        <ThankYouWrapper>
          <DynamicContent
            content={copy.ThankYou}
            components={{
              Closing
            }}
          />
        </ThankYouWrapper>
      </div>
    </>
  )

  return (
    <MainContentWrapper>
      <MainContent>{comp}</MainContent>
    </MainContentWrapper>
  )
}

export default Thankyou
