export enum UnifiedApplicationSteps {
  GetStarted = 'get-started',
  FundingNeeds = 'funding-needs',
  DirectorDetails = 'director-details',
  Eligibility = 'eligibility',
  EligibilityNI = 'eligibility-ni',
  EligibilityResultsLoading = 'eligibility-loading',
  EligibilityResults = 'eligibility-results',
  EligibilityResultsIneligible = 'ineligible',
  SupportingDocuments = 'supporting-documents',
  YourCompany = 'your-company',
  ThankYou = 'thank-you',
  ReferralDetails = 'referral-details'
}
