import { JourneyDefinition, getPageHeading, getPageShortHeading } from '../journey'
import deepCopy from '../../utils/deepCopy'
import getStartedPageIdentifier from '../unifiedApplicationJourney/getStarted/getStartedPageIdentifier'
import { PageIdentifier } from '../../types/PageIdentifier'
import GetStarted from '../unifiedApplicationJourney/getStarted/GetStarted'
import IUnifiedApplicationStateToIGetStartedState from '../../mappers/IUnifiedApplicationStateToIGetStartedState'
import { IJourneyStepRenderer } from '../../types/IJourneyStepRenderer'
import IUnifiedApplicationStateToIDirectorDetailsState from '../../mappers/IUnifiedApplicationStateToIDirectorDetailsState'
import IUnifiedApplicationStateToISupportingDocumentsState from '../../mappers/IUnifiedApplicationStateToISupportingDocumentsState'
import DirectorDetails, {
  directorDetailsPageIdentifier
} from '../unifiedApplicationJourney/directorDetails/DirectorDetails'
import SupportingDocuments, {
  supportingDocumentsPageIdentifier
} from '../unifiedApplicationJourney/supportingDocuments/SupportingDocuments'
import FundingNeeds from '../unifiedApplicationJourney/fundingNeeds/FundingNeeds'
import IUnifiedApplicationStateToIFundingNeedsState from '../../mappers/IUnifiedApplicationStateToFundingNeedsState'
import { fundingNeedsPageIdentifier } from '../unifiedApplicationJourney/fundingNeeds/fundingNeedsPageIdentifier'

export const recoveryLoansJourney: JourneyDefinition[] = [
  {
    page: deepCopy(getStartedPageIdentifier) as PageIdentifier,
    renderer: {
      Component: GetStarted,
      mapper: IUnifiedApplicationStateToIGetStartedState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('GetStarted'),
    pageShortHeading: getPageShortHeading('GetStarted')
  },
  {
    page: deepCopy(fundingNeedsPageIdentifier) as PageIdentifier,
    renderer: {
      Component: FundingNeeds,
      mapper: IUnifiedApplicationStateToIFundingNeedsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('FundingNeeds'),
    pageShortHeading: getPageShortHeading('FundingNeeds')
  },
  {
    page: deepCopy(directorDetailsPageIdentifier) as PageIdentifier,
    renderer: {
      Component: DirectorDetails,
      mapper: IUnifiedApplicationStateToIDirectorDetailsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('DirectorDetails'),
    pageShortHeading: getPageShortHeading('DirectorDetails')
  },
  {
    page: deepCopy(supportingDocumentsPageIdentifier) as PageIdentifier,
    renderer: {
      Component: SupportingDocuments,
      mapper: IUnifiedApplicationStateToISupportingDocumentsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('SupportingDocuments'),
    pageShortHeading: getPageShortHeading('SupportingDocuments')
  }
]
