import React, { useState } from 'react'
import IAddressState from '../../../types/IAddressState'
import InputLayout from '../../../commonStyles/components/inputLayout'
import { AddressSearchContent } from 'src/applyfrontendcontent'
import { tokeniseLabelAsString, tokeniseToString } from '../../content/DynamicContent'
import AddressSearch from './AddressSearch'
import { StyledLinkButton } from '../../../pages/unifiedApplicationJourney/continueLater/ContinueLaterLink'
import { features } from '../../../features'
import { Input } from 'src/ui-framework'
import { spacing } from 'src/ui-framework/theme/spacing'
import styled from 'styled-components'

const BiggestGap = styled.div`
  margin-top: ${spacing.xl};
`

type AddressFormProps = {
  id: string
  property: string
  value: IAddressState
  errors: any
  content: AddressSearchContent
  onChange: (field: string, value: string) => void
  onBlur: (field: string) => void
  hotjarUnmask: boolean
}

const AddressForm = (props: AddressFormProps) => {
  const address: IAddressState = props.value
  const [isManual, setIsManual] = useState(
    !features.AddressLookupEnabled ||
      address.addressLine1 !== '' ||
      address.addressLine2 !== '' ||
      address.city !== '' ||
      address.country !== '' ||
      address.postCode !== '' ||
      address.region !== ''
  )

  const errors = props.errors || {}

  const onAddressChanged = (field: string, value: string) => {
    props.onChange(`${props.property}.${field}`, value)
  }

  const onBlurred = (field: string) => {
    props.onBlur(`${props.property}.${field}`)
  }

  const onSearchClosed = () => {
    setIsManual(true)
  }

  const onAddressSearchChanged = (selectedValue: IAddressState) => {
    setIsManual(true)
    updateFullAddress(selectedValue)
  }

  const updateFullAddress = (selectedValue: IAddressState) => {
    onAddressChanged('addressLine1', selectedValue.addressLine1)
    onBlurred('addressLine1')
    onAddressChanged('addressLine2', selectedValue.addressLine2)
    onBlurred('addressLine2')
    onAddressChanged('city', selectedValue.city)
    onBlurred('city')
    onAddressChanged('country', selectedValue.country)
    onBlurred('country')
    onAddressChanged('postCode', selectedValue.postCode)
    onBlurred('postCode')
    onAddressChanged('region', selectedValue.region)
    onBlurred('region')
  }

  if (isManual) {
    return (
      <div id={props.id}>
        {features.AddressLookupEnabled && (
          <InputLayout>
            <StyledLinkButton
              style={{ fontWeight: '400', margin: '0' }}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()
                setIsManual(false)
              }}
            >
              {props.content.UseAddressFinder}
            </StyledLinkButton>
          </InputLayout>
        )}
        <div style={{ marginTop: spacing.l }}>
          <InputLayout>
            <Input
              id="address-line-1"
              label={tokeniseLabelAsString(props.content.AddressLineOne)}
              name="address-line-1"
              autoComplete="address-line1"
              errors={errors[`${props.property}.addressLine1`] || []}
              value={address.addressLine1}
              required={true}
              onChange={(e: any) => {
                onAddressChanged('addressLine1', e.target.value)
              }}
              onBlur={() => onBlurred('addressLine1')}
              hotjarUnmask={props.hotjarUnmask}
            />

            <Input
              id="address-line-2"
              label={tokeniseLabelAsString(props.content.AddressLineTwo)}
              name="address-line-2"
              autoComplete="address-line2"
              value={address.addressLine2}
              onChange={(e: any) => {
                onAddressChanged('addressLine2', e.target.value)
              }}
              onBlur={() => onBlurred('addressLine2')}
              hotjarUnmask={props.hotjarUnmask}
            />

            <Input
              id="city"
              label={tokeniseLabelAsString(props.content.City)}
              name="city"
              errors={errors[`${props.property}.city`] || []}
              value={address.city}
              required={true}
              onChange={(e: any) => {
                onAddressChanged('city', e.target.value)
              }}
              onBlur={() => onBlurred('city')}
              hotjarUnmask={props.hotjarUnmask}
            />
          </InputLayout>
          <InputLayout>
            <Input
              id="region"
              label={tokeniseLabelAsString(props.content.Region)}
              name="region"
              value={address.region}
              onChange={(e: any) => {
                onAddressChanged('region', e.target.value)
              }}
              onBlur={() => onBlurred('region')}
              hotjarUnmask={props.hotjarUnmask}
            />

            <Input
              id="country"
              label={tokeniseLabelAsString(props.content.Country)}
              name="country"
              autoComplete="country"
              errors={errors[`${props.property}.country`] || []}
              value={address.country}
              required={true}
              onChange={(e: any) => {
                onAddressChanged('country', e.target.value)
              }}
              onBlur={() => onBlurred('country')}
              hotjarUnmask={props.hotjarUnmask}
            />
          </InputLayout>

          <InputLayout>
            <Input
              style={{ margin: 0 }}
              id="postcode"
              label={tokeniseLabelAsString(props.content.Postcode)}
              placeholder={props.content.Postcode.Placeholder}
              name="postcode"
              autoComplete="postal-code"
              errors={errors[`${props.property}.postCode`] || []}
              value={address.postCode}
              required={true}
              onChange={(e: any) => {
                onAddressChanged('postCode', e.target.value)
              }}
              onBlur={() => onBlurred('postCode')}
              hotjarUnmask={props.hotjarUnmask}
            />
          </InputLayout>
        </div>
      </div>
    )
  }

  return (
    <div id={props.id}>
      <AddressSearch
        onChange={onAddressSearchChanged}
        onClose={onSearchClosed}
        content={props.content}
        errors={errors[`${props.property}.postCode`] || []}
      />
      <BiggestGap>
        <StyledLinkButton
          style={{ fontWeight: '400', margin: 0 }}
          id="cs-add-manually"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            const cleanUp: IAddressState = {
              addressLine1: '',
              addressLine2: '',
              city: '',
              country: '',
              region: '',
              postCode: ''
            }
            onAddressSearchChanged(cleanUp)
          }}
        >
          {tokeniseToString(props.content, 'AddManually')}
        </StyledLinkButton>
      </BiggestGap>
    </div>
  )
}

export default AddressForm
