import { PageIdentifier } from '../../../types/PageIdentifier'
import { UnifiedApplicationSteps } from '../../../types/UnifiedApplicationSteps'

export const fundingNeedsPageIdentifier: PageIdentifier = {
  id: '1fa4432f-37c7-4e25-96ac-17585738734a',
  title: 'Funding needs',
  step: UnifiedApplicationSteps.FundingNeeds,
  order: 20,
  isHidden: false,
  subPageIds: [],
}
