import styled from 'styled-components'
import HandleErrorAndFocusedColors from './ErrorAndFocusedColors'

type TextAreaStyleProps = {
  focused: boolean
  errors: Array<string>
  theme: any
}

const ThemedTextArea = styled.textarea<TextAreaStyleProps>`
  width: 100%;
  font-size: ${(props) => props.theme.fontSize.s};
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.grey900};
  border: solid 1px ${(props) => props.theme.colors.grey200};
  border-radius: 8px;
  padding: 10px 12px 10px 12px;
  font-family: ${(props) => props.theme.fontFamily};
  box-sizing: border-box;
  outline: none;
  resize: none;
  display: block;
  &:hover {
    background-color: ${(props) => props.theme.colors.grey10};
  }

  ${({ errors, focused, theme }) => HandleErrorAndFocusedColors(errors && errors.length > 0, focused, theme)}

  box-shadow: none;
`

export default ThemedTextArea
