export const setBankStatementsDate = (daysToBeDeducted: number): string => {
  let currentDate = new Date().setDate(new Date().getDate() - daysToBeDeducted)
  return formatDate(new Date(currentDate))
}

export const formatDate = (currentDate: Date): string => {
  let monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  let day = currentDate.getDate()

  let monthIndex = currentDate.getMonth()
  let monthName = monthNames[monthIndex]

  let year = currentDate.getFullYear()

  return `${day} ${monthName} ${year}`
}
