import { Config } from '../../../../types/locale'
import { eligibility } from './Eligibility'
import { eligibilityResult } from './EligibilityResult'
import { getStarted } from './GetStarted'
import { ineligibilityResult } from './IneligibleResult'
import { multipleApplicationError } from '../MultipleApplicationError'
import { readyToApplyPartner } from './ReadyToApplyPartner'
import { partnerReferralDetails } from './ReferralDetails'
import { thankYou } from './ThankYou'
import { unauthenticatedError } from './UnauthenticatedError'

export const referralJourneyBarclays: Partial<Config> = {
  GetStarted: getStarted,
  ReadyToApplyPartner: readyToApplyPartner,
  Eligibility: eligibility,
  Ineligible: ineligibilityResult,
  EligibilityResult: eligibilityResult,
  PartnerReferralDetails: partnerReferralDetails,
  ThankYou: thankYou,
  UnauthenticatedError: unauthenticatedError,
  MultipleApplicationError: multipleApplicationError
}
