import IUnifiedApplicationStateToIDirectorDetailsState from 'src/mappers/IUnifiedApplicationStateToIDirectorDetailsState'
import IUnifiedApplicationStateToISupportingDocumentsState from 'src/mappers/IUnifiedApplicationStateToISupportingDocumentsState'
import DirectorDetails, {
  directorDetailsPageIdentifier
} from 'src/pages/unifiedApplicationJourney/directorDetails/DirectorDetails'
import SupportingDocuments, {
  supportingDocumentsPageIdentifier
} from 'src/pages/unifiedApplicationJourney/supportingDocuments/SupportingDocuments'
import IUnifiedApplicationStateToIGetStartedState from '../../../mappers/IUnifiedApplicationStateToIGetStartedState'
import { IJourneyStepRenderer } from '../../../types/IJourneyStepRenderer'
import { PageIdentifier } from '../../../types/PageIdentifier'
import deepCopy from '../../../utils/deepCopy'
import { getPageHeading, getPageShortHeading, JourneyDefinition } from '../../journey'
import GetStarted from '../../unifiedApplicationJourney/getStarted/GetStarted'
import getStartedPageIdentifier from '../../unifiedApplicationJourney/getStarted/getStartedPageIdentifier'

export const barclaysCustomerJourney: JourneyDefinition[] = [
  {
    page: deepCopy(getStartedPageIdentifier) as PageIdentifier,
    renderer: {
      Component: GetStarted,
      mapper: IUnifiedApplicationStateToIGetStartedState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('GetStarted'),
    pageShortHeading: getPageShortHeading('GetStarted')
  },
  {
    page: deepCopy(directorDetailsPageIdentifier) as PageIdentifier,
    renderer: {
      Component: DirectorDetails,
      mapper: IUnifiedApplicationStateToIDirectorDetailsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('DirectorDetails'),
    pageShortHeading: getPageShortHeading('DirectorDetails')
  },
  {
    page: deepCopy(supportingDocumentsPageIdentifier) as PageIdentifier,
    renderer: {
      Component: SupportingDocuments,
      mapper: IUnifiedApplicationStateToISupportingDocumentsState
    } as IJourneyStepRenderer,
    pageHeading: getPageHeading('SupportingDocuments'),
    pageShortHeading: getPageShortHeading('SupportingDocuments')
  }
]
