import { media } from '../../../commonStyles/mediaQueries/responsiveDeviceSize'
import styled from 'styled-components'

type TextWrapperStyleProps = {
  theme: {}
}

const TextWrapper = styled.label<TextWrapperStyleProps>`
  display: flex;
  align-items: center;
  font-family: 'Matter';
  cursor: pointer;
  margin-top: 2px;
  ${media.upToTablet`
    margin-top: 1px;
  `}
`

export default TextWrapper
