import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import UploadDocumentsBlurb from './UploadDocumentsBlurb'
import FilesList from './FilesList'
import { DropzoneOptions, useDropzone, DropEvent } from 'react-dropzone'
import IFileState, { FileStatusState } from '../../../types/IFileState'
import IUploadFileConstraints from '../../../types/IUploadFileConstraints'
import { FileUploadResponseStatus, UploadFileResponse } from '../../../fileUploadCapabilities'
import { v4 as uuidv4 } from 'uuid'
import { colors, ErrorList } from 'src/ui-framework'

type BrandedDropzoneProps = {
  compact: boolean
  focused: boolean
  errors: Array<string>
  disabled: boolean
  hasFiles: boolean
}

const LabelText = styled.label`
  font-family: 'Matter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.16px;
  color: ${colors.textGrey900};
`

const BrandedDropzone = styled.div<BrandedDropzoneProps>`
  background-color: ${colors.grey10};
  padding: 50px 30px;
  border: solid 1px ${colors.grey200};
  border-radius: 8px;
  border-bottom-left-radius: ${(props: BrandedDropzoneProps) => (props.hasFiles ? '0px' : '8px')};
  border-bottom-right-radius: ${(props: BrandedDropzoneProps) => (props.hasFiles ? '0px' : '8px')};
  cursor: pointer;
  outline: none;
  height: 65px;
  color: ${(props: BrandedDropzoneProps) => (props.disabled ? colors.buttonTextOnDisabled : colors.textGrey600)};

  ${(props) => {
    if (props.errors && props.errors.length > 0 && props.focused === false) {
      return {
        border: `1px solid ${colors.error}`,
        marginBottom: 0
      }
    }
  }}

  :active {
    border: solid 1px ${colors.buttonActive};
  }

  ${(props) =>
    props.focused &&
    `
      border: solid 1px ${colors.buttonActive};
    `}

  :hover {
    background-color: ${(props: BrandedDropzoneProps) => (props.disabled ? colors.grey10 : colors.grey100)};
  }
`

const FileUploadWrapper = styled.div``

type FileUploadProps = {
  capabilities: FileUploadExternalCapabilities
  applicationId: string
  label: string
  errors: Array<string>
  value: Array<IFileState>
  onChange: (files: Array<IFileState>) => void
  onBeforeUpload: (filesToUpload: IFileState[]) => void
  constraints: IUploadFileConstraints
  ariaLabel: string
}

export interface FileUploadExternalCapabilities {
  // TODO: These should NOT know about the containerIdentifier
  // They should simply be passed the file(s) that it needs to do something with
  uploadFiles: (containerIdentifier: string, files: Array<File>) => Promise<Array<UploadFileResponse>>
  deleteFile: (containerIdentifier: string, fileId: string) => Promise<boolean>
}

export const FileUploadWell = (props: FileUploadProps) => {
  const {
    capabilities: { deleteFile, uploadFiles },
    applicationId,
    label,
    value,
    onChange,
    onBeforeUpload,
    errors,
    constraints,
    ariaLabel
  } = props

  const onDrop = async (acceptedFiles: File[], rejectedFiles: File[], e: DropEvent) => {
    onBeforeUpload(acceptedFiles.map((x) => ({ name: x.name, status: FileStatusState.Pending })))
    const uploadedFilesResult = await uploadFiles(applicationId, acceptedFiles)

    // To match existing behaviour we remove any failed files on the next update
    // We can also ignore any Pending as we are about to process them
    const changedValue = [
      ...value.filter((f) => f.status === FileStatusState.Uploaded || f.status === FileStatusState.Deleted)
    ]

    if (uploadedFilesResult.length > 0) {
      const mapped: Array<IFileState> = uploadedFilesResult.map((x) => ({
        id: x.fileIdentifier,
        name: x.name,
        status: x.status === FileUploadResponseStatus.Successful ? FileStatusState.Uploaded : FileStatusState.Rejected,
        error: x.errors
      }))

      mapped.forEach((f) => changedValue.push(f))
    }

    if (rejectedFiles.length > 0) {
      // Useless dropzone doesn't give you a reason for rejection
      // copy logic from server side for now
      rejectedFiles.forEach((f: File) => {
        let error: string
        if (f.size > constraints.maxSizeInMb * 1024 * 1024) {
          error = `${f.name} exceeds maximum size of ${constraints.maxSizeInMb}Mb`
        } else {
          error = `${f.name} does not have valid extension`
        }

        changedValue.push({ name: f.name, status: FileStatusState.Rejected, error: error })
      })
    }
    setFocus(false)
    onChange(changedValue)
  }

  const onDelete = async (fileId: string) => {
    const isFileDeleteSuccess = deleteFile(applicationId, fileId)
    // To match existing behaviour we remove any failed files on the next update
    // we also need to maintain the reference so we soft delete the file
    const newValue = [...value.filter((x) => x.status !== FileStatusState.Rejected)]
    newValue.forEach((f) => {
      if (f.id !== fileId) {
        return
      }

      f.status = FileStatusState.Deleted
    })
    onChange(newValue)
    if ((await isFileDeleteSuccess) === false) {
      newValue.forEach((f) => {
        if (f.id !== fileId) {
          return
        }

        f.status = FileStatusState.Uploaded
        f.error = 'Failed to delete'
      })
      onChange(newValue)
    }
  }

  const compactUploadRegion = value.filter((x) => x.status === FileStatusState.Uploaded).length > 0
  const isUploading = value.filter((x) => x.status === FileStatusState.Pending).length > 0

  const dropZoneOptions: DropzoneOptions = {
    onDrop,
    disabled: isUploading,
    maxSize: constraints.maxSizeInMb * 1024 * 1024,
    accept: constraints.validFileExtensions
  }

  const [focus, setFocus] = useState(false)
  const { getRootProps, getInputProps } = useDropzone(dropZoneOptions)
  const [id] = useState(uuidv4())

  const handleOnFocus = () => {
    setFocus(true)
  }

  const handleOnBlur = () => {
    setFocus(false)
  }

  return (
    <div style={errors && errors.length > 0 ? {} : { marginBottom: '0px', marginTop: '16px' }}>
      {label !== '' && (
        <div>
          <LabelText>{label}</LabelText>
        </div>
      )}
      <FileUploadWrapper data-test={`${applicationId}FileUpload`}>
        <BrandedDropzone
          compact={compactUploadRegion}
          {...getRootProps()}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          focused={focus}
          disabled={isUploading}
          errors={errors || []}
          hasFiles={
            value.filter((f) => f.status === FileStatusState.Uploaded || f.status === FileStatusState.Rejected).length >
            0
          }
        >
          <input
            {...getInputProps()}
            disabled={isUploading}
            aria-invalid={errors?.length > 0}
            aria-describedby={ErrorList.getDescribedByAttribute(errors || [])}
            aria-label={ariaLabel}
          />
          <UploadDocumentsBlurb compact={compactUploadRegion} uploadInProgress={isUploading} focused={focus} />
        </BrandedDropzone>
        <FilesList
          files={value.filter((f) => f.status === FileStatusState.Uploaded || f.status === FileStatusState.Rejected)}
          onDeleteFile={async (fileId: string) => await onDelete(fileId)}
        />
        {errors && <ErrorList errors={errors || []} id={id} />}
      </FileUploadWrapper>
    </div>
  )
}

FileUploadWell.defaultProps = {
  isRequired: false,
  showValidationMessage: false,
  validationMessage: 'Please upload document(s)'
}

FileUploadWell.propTypes = {
  applicationId: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  showValidationMessage: PropTypes.bool,
  validationMessage: PropTypes.string
}

export default FileUploadWell
