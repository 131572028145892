import styled from 'styled-components'
import { Representative } from '../images/images'
import { media } from 'src/ui-framework'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.uiGrey10};
  margin-bottom: 0px;
  margin-top: 2em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  overflow: hidden;
  flex-shrink: 0;
`

export const FooterGrid = styled.div`
  display: grid;
  color: ${(props) => props.theme.colors.uiGrey80};
  grid-column-gap: 16px;
  padding: 35px 0;
  align-items: center;
  @media (min-width: 700px) {
    grid-template-columns: 80px 398px;
  }
`

export const RepresentativeImage = styled.div`
  border-radius: 50%;
  height: 80px;
  width: 80px;
  float: left;
  background-size: contain;
  background-image: url(${Representative});
`

export const ContactList = styled.ul`
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  font-size: ${(props) => props.theme.fontSize.fontXs};
  word-break: break-word;
`

export const ContactLink = styled.li`
  font-weight: 700;
  padding-top: 5px;
`

export const Timings = styled.li`
  color: ${(props) => props.theme.colors.uiGrey80};
`
export const PhoneNumber = styled.a`
  font-size: ${(props) => props.theme.fontSize.fontXs};
  color: ${(props) => props.theme.colors.uilinkBlue};
  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: ${(props) => props.theme.colors.uilinkoverBlue};
  }
`

export const BritishBankImage = styled.img`
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
`
export const TrustPilotImageWrapper = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  align-items: center;
  display: flex;
  margin-left: 51px;
  max-width: 130px;

  ${media.upToTablet`
    float: none;
    margin-left: 32px;
  `}
`
export const FooterWrapper = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;
  min-height: 100px;
`

export const FooterContent = styled.div`
  display: flex;
  background-color: white;
  width: 560px;
  padding: 24px 28px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  margin-bottom: 16px;
  flex-direction: column;

  ${media.upToTablet`
    padding: 24px 16px; 
  `}
`

export const FooterText = styled.div`
  justify-content: center;
  width: 100%;
`

export const FooterMarketing = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`
