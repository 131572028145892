interface IAPIURLMapping {
  [Key: string]: string
}

const apiUrlMapping: IAPIURLMapping = {
  'apply.kriya.dev': 'https://api.kriya.dev',
  'apply.kriya.co': 'https://api.kriya.co',
  localhost: 'https://api.kriya.dev'
}

const apiBaseUrl = (currentUrl: string): string => {
  const deploymentHost = new URL(currentUrl).hostname
  return apiUrlMapping[deploymentHost]
}

export default apiBaseUrl
