import { IErrorsState } from '../types/IErrorsState'

export type Fields = {
  [Key: string]: any
}

export type FieldWithErrorMessage = {
  fieldName: string
  errorMessage?: string
}

export const validate = (requiredFields: Array<FieldWithErrorMessage>, fields: Fields): IErrorsState => {
  let errors: IErrorsState = {
    hasErrors: false
  }
  for (let requiredField of requiredFields) {
    const fieldValue = fields[requiredField.fieldName]
    const errorMessage = requiredField.errorMessage ?? 'Please enter a value for this field'

    if (
      fieldValue === undefined ||
      fieldValue === null ||
      (fieldValue.toString && (fieldValue.toString() as string).trim() === '')
    ) {
      errors[requiredField.fieldName] = [errorMessage]
      errors.hasErrors = true
    } else if (Array.isArray(fieldValue) && fieldValue.length === 0) {
      errors[requiredField.fieldName] = [errorMessage]
      errors.hasErrors = true
    } else {
      errors[requiredField.fieldName] = []
    }
  }
  return errors
}
