import QueryString from 'query-string'
import { cookieExists, setCookie, deleteCookie } from '../../../utils/cookieHelper'
import { PartnerType } from '../../../types/PartnerType'
import { ChannelType } from '../../../types/ChannelType'
import { IInitialisationParameters } from '../../../types/IInitialisationParameters'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import IApplicationUrlParams from '../../../types/IApplicationUrlParams'

export const useInitialisationParameters = (): IInitialisationParameters => {
  const params = useParams<IApplicationUrlParams>()

  const initialisationParams = useMemo<IInitialisationParameters>(() => {
    const paramKey = QueryString.parse(window.location.search, { decode: false })
    const tags: { [Key: string]: string } = {}
    for (let p in paramKey) {
      const val = paramKey[p]
      if (Array.isArray(val) && val.length > 0) {
        tags[p] = val[val.length - 1]
      } else if (typeof val === 'string') {
        tags[p] = val
      } else {
        tags[p] = ''
      }
    }

    const findType = (type: typeof ChannelType | typeof PartnerType, value: string): typeof type | undefined => {
      const foundKey = Object.keys(type).find((key) => key?.toLowerCase() === value?.toLowerCase())
      if (foundKey) {
        return type[foundKey as keyof typeof type]
      }
      return undefined
    }

    const partnerType: PartnerType | undefined = findType(PartnerType, tags.partner) as PartnerType | undefined
    const channelType: ChannelType | undefined = findType(ChannelType, tags.channel) as ChannelType | undefined

    const initialisationParams: IInitialisationParameters = {
      product: tags.product,
      journey: tags.journey,
      sales: tags.sales !== undefined,
      partner: tags.partner !== undefined && partnerType ? (partnerType as PartnerType) : undefined,
      channel: tags.channel !== undefined && channelType ? (channelType as ChannelType) : undefined,
      dashboardId: params.id,
      partnerCustomerId: tags.clientBusinessCustomerId
    }

    if (initialisationParams.sales && !cookieExists('sales')) {
      setCookie('sales', '1', 20 * 365)
    }

    if (paramKey?.removeSalesCookie !== undefined) {
      initialisationParams.sales = false
      deleteCookie('sales', '1')
    }

    if (cookieExists('sales')) {
      initialisationParams.sales = true
    }

    return initialisationParams
  }, [params.id])

  return initialisationParams
}
