import IApplicantState from './IApplicantState'
import IAddressState from './IAddressState'
import { UnifiedApplicationState } from './UnifiedApplicationState'
import { IErrorsState } from './IErrorsState'
import { PageIdentifier } from './PageIdentifier'
import { IUnifiedProductType } from './ProductType'
import IFieldState from './IFieldState'
import IDirectorState from './IDirectorState'
import IDateState from './IDateState'
import IFileState from './IFileState'
import IUploadFileConstraints from './IUploadFileConstraints'
import { JourneyType } from './JourneyType'
import { ErrorType } from '../reducers/unifiedApplicationActions'
import { ITimeAtAddress } from './IDirectorDetailsState'
import { RepresentativeJourneyType } from '../types/RepresentativeJourneyType'
import { PartnerType } from './PartnerType'
import { ChannelType } from './ChannelType'
import { IApplicationMetadata } from './IApplicationMetadata'
import { IJourneyIdentifier } from './IJourneyIdentifier'
import { UnifiedApplicationSteps } from './UnifiedApplicationSteps'
import { IOutcomeOptions } from '../shared/components/eligibilityResultsRenderer/EligibilityResultsRenderer'
import { IBusinessValidity } from './IBusinessValidity'
import { BusinessType } from './BusinessType'
import { Turnover } from './Turnover'
import { Timeline } from './Timeline'
import { UserRole } from './UserRole'

export type UnifiedApplicationDataValue =
  | UnifiedApplicationState
  | string
  | undefined
  | IAddressState
  | IErrorsState
  | boolean
  | IFieldState
  | IDateState
  | Array<string>
  | IFileState[]
  | IUploadFileConstraints
  | number
  | ITimeAtAddress
  | Date
  | null
  | IOutcomeOptions

export interface IUnifiedApplicationStepState extends IJourneyIdentifier {
  [Key: string]: UnifiedApplicationDataValue
  state: UnifiedApplicationState
  status: UnifiedApplicationStatus
  errors: IErrorsState
  fieldState?: IFieldState
  skipToStep?: UnifiedApplicationSteps
  email: string
  partnerEmail?: string
  businessType?: BusinessType
  id?: string
  partnerIdentifier?: string
  channelType?: ChannelType
  hasBackgroundChecksTimeout?: boolean
  backgroundChecksRequestedTime?: Date
}

export enum UnifiedApplicationStatus {
  Unknown = 'Unknown',
  Draft = 'Draft',
  Submitted = 'Submitted',
  PartnerDraft = 'PartnerDraft'
}

export enum ReferralFlow {
  RelationshipManager,
  Customer
}

export default interface IUnifiedApplicationState {
  errorType?: ErrorType
  nextStep?: string
  state: UnifiedApplicationState
  status: UnifiedApplicationStatus
  id: string
  isNew: boolean
  journeyType: JourneyType
  generalEligibility?: boolean
  companyIdentifier?: string
  companyName?: string
  initialCompanyNumber?: string
  companyNumber?: string
  applicant?: IApplicantState
  registeredAddress?: IAddressState
  tradingAddress?: IAddressState
  lastCompletedStep?: PageIdentifier
  reasonForFunding?: string
  whenFundsAreNeeded?: string
  fundingNeedsDetails?: string
  reasonForFundingOtherDetails?: string
  products?: IUnifiedProductType
  website?: string
  director?: IDirectorState
  bankStatements?: IFileState[]
  statutoryAccounts?: IFileState[]
  additionalDocuments?: IFileState[]
  additionalInformation?: string
  hmrcTaxCalculationDocuments?: IFileState[]
  partnerEmail?: string
  representativeJourneyType: RepresentativeJourneyType
  hasSalesCookie: boolean
  maxCompletedstep?: PageIdentifier
  partnerType?: PartnerType
  channelType?: ChannelType
  metadata: IApplicationMetadata
  businessValidity?: IBusinessValidity
  businessType?: BusinessType
  partnerIdentifier?: string
  canCallClient?: boolean
  notepadReviewed?: boolean
  overdraftReviewed?: boolean
  disclosureReviewed?: boolean
  bbbInfoReviewed?: boolean
  turnover?: Turnover
  timeline?: Timeline
  applicationReference?: string
  callTimeSlots?: string
  isThreeWayCallRequested?: boolean
  partnerPhoneNumber?: string
  isApplicationStarted?: boolean
  referralFlow?: ReferralFlow
  userRole: UserRole
  hasBackgroundChecksTimeout?: boolean
  // TODO: make Date()
  backgroundChecksRequestedTime?: string
}
