import React, { useState, useEffect } from 'react'
import axios, { AxiosRequestConfig } from 'axios'
import { Features, features } from './features'
import { applyServiceSuffix } from './urlSettingsExports'

const axiosConfig: AxiosRequestConfig = {
  validateStatus: (status: number) => true
}

const WithFeatures = (p: React.PropsWithChildren<object>) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    const load = async () => {
      const getFeatures = await axios.get<Features>(`/${applyServiceSuffix()}/features`, axiosConfig)
      if (getFeatures.status === 200 && getFeatures.data !== undefined) {
        for (let feature in getFeatures.data) {
          features[feature] = getFeatures.data[feature]
        }
      }
      setLoaded(true)
    }
    load()
  }, [])
  return <>{loaded && React.Children.only(p.children)}</>
}
export default WithFeatures
