import React from 'react'
import UnifiedApplicationStepGuard from '../../unifiedApplicationJourney/UnifiedApplicationStepGuard'
import ContinueAndSaveLater from '../../unifiedApplicationJourney/ContinueAndSaveLater'
import { UnifiedApplicationActionUpdateState } from '../../../reducers/unifiedApplicationActions'
import { UnifiedApplicationState } from '../../../types/UnifiedApplicationState'
import useUnifiedApplicationStep from '../../../shared/hooks/useUnifiedApplicationStep'
import IUnifiedApplicationStepProps from '../../../types/IUnifiedApplicationStepProps'
import { tokeniseToString } from '../../../shared/content/DynamicContent'
import EligibilityRenderer from '../../../shared/components/eligibilityRenderer/EligibilityRenderer'
import { getSaveForLaterEmail } from '../../../shared/getSaveForLaterEmail'
import IUnifiedEligibilityState from 'src/types/unifiedJourney/IUnifiedEligibilityState'
import { eligibilityPageIdentifier } from './eligibilityPageIdentifier'
import UnifiedEligibilityReducer from 'src/reducers/unifiedReducers/unifiedEligibilityReducer'
import eligibilityContent from './eligibilityContext'
import { ReadRequirementsAlert } from './styledEligibility'
import { UnifiedApplicationStatus } from 'src/types/IUnifiedApplicationState'

interface IUnifiedEligibilityProps extends IUnifiedApplicationStepProps<IUnifiedEligibilityState> {}
const reducer = new UnifiedEligibilityReducer(eligibilityPageIdentifier)

const UnifiedEligibility = (p: IUnifiedEligibilityProps) => {
  const [state, dispatch, content, onSendLink] = useUnifiedApplicationStep<
    IUnifiedEligibilityState,
    UnifiedEligibilityReducer
  >(reducer, p.initialState, p.applicationIdentifier, p.onSubmit, p.onAutoSave)

  const eligibilityDynamicContent = content.Eligibility

  if (!!state.skipToStep) {
    return <p>{tokeniseToString(content.Shared, 'LoadingPage')}</p>
  }

  return (
    <UnifiedApplicationStepGuard currentState={state}>
      {state.status === UnifiedApplicationStatus.PartnerDraft && (
        <ReadRequirementsAlert>{eligibilityDynamicContent.RequirementsRead}</ReadRequirementsAlert>
      )}

      <EligibilityRenderer
        content={eligibilityContent}
        dynamicContent={eligibilityDynamicContent}
        errors={state.errors}
        state={state}
        dispatcher={dispatch}
      />

      <ContinueAndSaveLater
        content={content}
        loading={state.state === UnifiedApplicationState.Submitted}
        email={getSaveForLaterEmail(state)}
        id="eligibility-submit"
        onSendLink={onSendLink}
        errors={state.errors}
        showSaveLater={false}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.preventDefault()
          dispatch.action(UnifiedApplicationActionUpdateState(UnifiedApplicationState.Submitting))
        }}
      />
    </UnifiedApplicationStepGuard>
  )
}

export default UnifiedEligibility
