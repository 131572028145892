import { ApplicationDeadlineErrorContent } from '../types/applicationDeadlineError'

export const applicationDeadlineError: ApplicationDeadlineErrorContent = {
  Heading: 'CBILS APPLICATIONS ARE NOW CLOSED',
  Introduction: 'Unfortunately we’re no longer processing CBILS applications as the scheme has closed.',
  SubHeading: 'STILL WANT TO ACCESS THE CASH TIED UP IN YOUR INVOICES?',
  GetInTouch:
    'You might be too late for CBILS, but invoice finance could still be the perfect solution to boost your cash flow. So {GetInTouchLink} with us to chat about your options.',
  GetInTouchLink: 'get in touch',
  GetInTouchEmail: 'barclayspartner@kriya.co',
  Closing:
    'As a reminder, you can use our invoice finance facility to tap into the money you’re owed sooner. It’s the perfect cash flow boost, whether you need to cover supplier costs or fund a little R&D. Fast, flexible, easy to use.'
}
