import styled from 'styled-components'

type ContainerStyleProps = {
  theme: any
  errors: Array<string>
}

type TextWrapperStyleProps = {
  theme: any
}

type LabelWrapperStyleProps = {
  theme: any
  animate: boolean
  focused: boolean
  mouseDown: boolean
  errors: Array<string>
  onMouseDown: (event: React.MouseEvent<HTMLLabelElement>) => void
  onMouseUp: (event: React.MouseEvent<HTMLLabelElement>) => void
}

type InputWrapperStyleProps = {
  theme: any
}

type LegendStyleProps = {
  theme: any
}

type OuterWrapperStyleProps = {
  theme: any
  width: string
}

export const Container = styled.div<ContainerStyleProps>`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
`

export const TextWrapper = styled.label<TextWrapperStyleProps>`
  display: flex;
  align-items: center;
  font-family: ${(props) => props.theme.fontFamily};
`

export const LabelWrapper = styled.label<LabelWrapperStyleProps>`
  display: flex;
  cursor: pointer;
  flex: 1 1 50%;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;

  & > div > input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
  }

  & > div > i {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    transition: ${(props) => (props.animate ? '0.2s' : 'none')};
    background: ${(props) => props.theme.colors.uiGrey05} url('https://cdn.kriya.co/images/checkbox-unchecked.svg')
      no-repeat center;
  }

  & > div > input:checked + i {
    background: url('https://cdn.kriya.co/images/checkbox-checked.svg') no-repeat center;
  }
`

export const InputWrapper = styled.div<InputWrapperStyleProps>``

export const OuterWrapper = styled.div<OuterWrapperStyleProps>`
  width: ${(props) => props.width};
`

export const Legend = styled.legend<LegendStyleProps>`
  margin-bottom: ${(props) => props.theme.spacing.spacingXxs};
`
