import React from 'react'

type OwnProps = {
  item:
    | {
        url: string
        label: string
      }
    | (() => JSX.Element)
  style?: {
    textDecoration?: string
    color?: string
  }
  className?: string
}

export default function MenuItemComponent(props: OwnProps) {
  let ItemComponent: () => JSX.Element
  if (typeof props.item === 'function') {
    ItemComponent = props.item
  } else {
    const { url, label } = props.item
    ItemComponent = () => (
      <a style={props.style} href={url} className={props.className}>
        {label}
      </a>
    )
  }

  return <ItemComponent />
}
