import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { ErrorList, Hidden, Button, media } from 'src/ui-framework'
import SaveForLater from './continueLater/ContinueLaterLink'
import { IErrorsState } from '../../types/IErrorsState'
import { Spinner } from 'spin.js'
import { tokeniseToString } from '../../shared/content/DynamicContent'
import { getSharedCopy, Config } from 'src/applyfrontendcontent'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.upToTablet`
    display: block;
  `}
`

interface IContinueAndSaveLaterProps {
  id: string
  loading: boolean
  email?: string
  onSendLink?: (email: string) => Promise<boolean>
  content: Config
  showSaveLater: boolean
  errors: IErrorsState
  overrideContinueLabel?: string
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const ContinueAndSaveLater = (p: IContinueAndSaveLaterProps) => {
  const { errors, id } = p
  const hasErrors = errors.hasErrors && errors.globalErrors && errors.globalErrors.length > 0

  const spinnerRef = useRef<HTMLDivElement | null>(null)
  const spinner = useRef<Spinner>(
    new Spinner({
      radius: 4,
      width: 2,
      length: 4,
      color: 'white'
    })
  )

  useEffect(() => {
    if (spinnerRef.current === null) {
      return
    }
    if (p.loading) {
      spinner.current.spin(spinnerRef.current)
    } else {
      spinner.current.stop()
    }
  }, [p.loading])

  return (
    <>
      <Wrapper>
        <Button
          color="primary"
          id={p.id}
          style={{ margin: 0, order: 'unset' }}
          isLoading={p.loading}
          onClick={p.onClick}
          loadingText={
            p.overrideContinueLabel === undefined
              ? tokeniseToString(getSharedCopy(navigator.language), 'ContinueButton')
              : p.overrideContinueLabel
          }
        >
          <div style={{ display: 'inline-block' }}>
            {p.overrideContinueLabel === undefined
              ? tokeniseToString(getSharedCopy(navigator.language), 'ContinueButton')
              : p.overrideContinueLabel}
          </div>
        </Button>
        {hasErrors && (
          <Hidden mdUp>
            <ErrorList id={id} errors={errors.globalErrors} />
          </Hidden>
        )}
        {p.showSaveLater && <SaveForLater email={p.email!} onSendLink={p.onSendLink!} content={p.content} />}
      </Wrapper>
      {hasErrors && (
        <Hidden smDown>
          <ErrorList id={id} errors={errors.globalErrors} />
        </Hidden>
      )}
    </>
  )
}

export default ContinueAndSaveLater
