import React from 'react'
import { StyledCheckbox, StyledCheckboxLabel, StyledCheckboxRect, StyledCheckboxSvg } from './styledCheckbox'

type OwnProps = {
  label?: string
  id: string
  errors?: string[]
  focused: boolean
  required?: boolean
} & React.ComponentPropsWithoutRef<'input'>

type Props = OwnProps

const Checkbox2 = (props: Props) => {
  const { checked, errors, onChange, onFocus, onBlur, focused, required } = props

  return (
    <>
      <StyledCheckbox className="promoted-checkbox">
        <input
          id={props.id}
          type="checkbox"
          checked={props.checked}
          className="promoted-input-checkbox"
          style={{ width: '100%', height: '100%' }}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          required={required}
        />
        <StyledCheckboxLabel focused={focused}>
          <StyledCheckboxSvg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            {!checked && <StyledCheckboxRect x="1" y="1" width="18" height="18" rx="3" fill="#fff" errors={errors} />}
            {checked && (
              <>
                <rect x="1" y="1" width="18" height="18" rx="3" fill="#00283C" />
                <path
                  d="M7.60002 12.925L4.45002 9.77501L3.40002 10.825L7.60002 15.025L16.6 6.02501L15.55 4.97501L7.60002 12.925Z"
                  fill="white"
                />
                <rect x="1" y="1" width="18" height="18" rx="3" stroke="#00283C" strokeWidth="2" />
              </>
            )}
          </StyledCheckboxSvg>
          {props.label}
        </StyledCheckboxLabel>
      </StyledCheckbox>
    </>
  )
}

Checkbox2.defaultProps = {}

export default Checkbox2
