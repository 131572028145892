import React, { useContext, useEffect, useState } from 'react'

import { Theme } from '@marketfinance/ui-framework-public'
import { withAITracking } from '@microsoft/applicationinsights-react-js'
import axios from 'axios'
import { Route, Router, Switch } from 'react-router'
import { Redirect, RouteComponentProps } from 'react-router-dom'

import { Theme as ThemeV2 } from 'src/ui-framework'
import { ThemeContext } from 'styled-components'
import ContinueLater from './pages/unifiedApplicationJourney/continueLater/ContinueLater'
import BarclaysReferralRedirect from './pages/unifiedApplicationJourney/readyToApply/BarclaysReferralRedirect'
import { AppLayout } from './AppLayout'
import { ApplicationImpersonator } from './ApplicationImpersonator'
import history from './browserHistory'
import ErrorPage from './pages/error/ErrorPage'
import { ExitApplication } from './pages/error/ExitApplication'
import NotFound from './pages/error/NotFound'
import ApplicationLocator from './pages/unifiedApplicationJourney/applicationLocator'
import ReadyToApplyRouter from './pages/unifiedApplicationJourney/readyToApply/ReadyToApplyRouter'
import ThankYou from './pages/unifiedApplicationJourney/thankyou/Thankyou'
import UnifiedApplication from './pages/unifiedApplicationJourney/unifiedApplication'
import withApplicationValidator from './shared/components/withApplicationValidator'
import IApplicationUrlParams from './types/IApplicationUrlParams'
import { IUnifiedApplicationParams } from './types/IUnifiedApplicationParams'
import apiBaseUrl from './utils/apiBaseUrl'
import { telemetryService, trackAppInsightsEvent } from './utils/appInsights/AppInsights'
import instrumentationKey from './utils/appInsights/appInsightsKeyMapping'
import WithFeatures from './withFeatures'
import StrategicPartnerReadyToApply from './pages/partnerJourney/barclaysJourney/RM/ReadyToApply/StrategicPartnerReadyToApply'
import PartnerCustomerReadyToApplyRouter from './pages/unifiedApplicationJourney/readyToApply/PartnerCustomerReadyToApplyRouter'

const trackError = (e: ErrorEvent): void => {
  trackAppInsightsEvent('UajError', {
    message: e.message,
    error: JSON.stringify(e.error),
    location: `${e.filename}:${e.lineno}:${e.colno}`,
    target: e.target
  })
}

function App() {
  return (
    <Theme>
      <ThemedApp></ThemedApp>
    </Theme>
  )
}

function ThemedApp() {
  const theme = useContext(ThemeContext)
  const [loadingBaseUrl, setLoadingBaseUrl] = useState(true)

  useEffect(() => {
    const baseUrl = apiBaseUrl(window.location.href)

    axios.defaults.baseURL = baseUrl
    telemetryService.initialize(instrumentationKey(window.location.href), history)
    setLoadingBaseUrl(false)
  }, [])

  useEffect(() => {
    window.addEventListener('error', trackError)
    return () => {
      window.removeEventListener('error', trackError)
    }
  }, [])

  if (loadingBaseUrl) return <></>

  const ValidatedUnifiedApplication = (props: RouteComponentProps<IUnifiedApplicationParams>) =>
    withApplicationValidator(props, UnifiedApplication)
  const ValidatedApplicationImpersonator = (props: RouteComponentProps<IApplicationUrlParams>) =>
    withApplicationValidator(props, ApplicationImpersonator)
  const RedirectWithQueryString = (props: RouteComponentProps) => (
    <Redirect
      to={{
        search: props.location.search,
        pathname: '/application/readytoapply'
      }}
      exact
    />
  )

  return (
    <ThemeV2 localTheme={theme}>
      <Router history={history}>
        <WithFeatures>
          <Switch>
            <Route path="/application/partner/:partnerName" component={StrategicPartnerReadyToApply} exact />
            <Route path="/">
              <AppLayout>
                <Switch>
                  <Route path="/" exact component={RedirectWithQueryString} />
                  <Route path="/application/readytoapply" component={ReadyToApplyRouter} exact />
                  <Route path="/application/:id/readytoapply" component={PartnerCustomerReadyToApplyRouter} exact />
                  <Route
                    path="/application/barclays/referral-complete/:id?"
                    component={BarclaysReferralRedirect}
                    exact
                  />
                  <Route path="/application/:id/dashboardreadytoapply" component={ReadyToApplyRouter} exact />
                  <Route path="/application/:id/thank-you" component={ThankYou} exact />
                  <Route path="/error" component={ErrorPage} exact />
                  <Route path="/exit" component={ExitApplication} exact />
                  <Route path="/application/:id/continue" component={ContinueLater} exact />
                  <Route path="/application/:id/:step?" component={ValidatedUnifiedApplication} exact />
                  <Route path="/application" component={ApplicationLocator} exact />
                  <Route path="/impersonate/:id" component={ValidatedApplicationImpersonator} exact />
                  <Route component={NotFound} />
                </Switch>
              </AppLayout>
            </Route>
          </Switch>
        </WithFeatures>
      </Router>
    </ThemeV2>
  )
}

const reactPlugin = telemetryService && telemetryService.reactPlugin

export default withAITracking(reactPlugin, App, 'MF App', 'float-footer')
