import { GenericErrorContent } from '../types/genericError'

export const genericError: GenericErrorContent = {
  Heading: 'Oh dear, it looks like something went wrong.',
  Introduction: 'We’ve logged the error and will get it fixed as soon as possible.',
  ErrorMessage: "If you'd like help with anything get in touch by emailing us at {Dynamic.ErrorEmail}",
  GenericEmail: 'sales@kriya.co',
  InvoiceFinanceEmail: 'cbilsinvoicefinance@kriya.co',
  LoansEmail: 'cbilsloans@kriya.co',
  InvalidDate: 'Please enter a valid date'
}
