import axios from 'axios'
import apiBaseUrl from '../../../utils/apiBaseUrl'

export const AddressApiBaseUrl = `${apiBaseUrl(window.location.href)}/addresslookup/find-address`

const http = axios.create({
  baseURL: AddressApiBaseUrl,
  headers: {
    'Content-type': 'application/json'
  }
})

export default http
