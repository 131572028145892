import { SaveForLaterContent } from '../../types/saveForLater'

export const saveForLater: SaveForLaterContent = {
  Heading: 'Save For Later',
  SendLink: {
    Introduction:
      "If you're ready to send this application on to the customer, we'll send a secure link to this address:",
    Email: {
      Text: "Customer's email",
      Error: { Required: 'Please enter a valid email address' }
    },
    CancelButton: 'Cancel',
    SendLinkButton: 'Send link'
  },
  Confirmation: {
    Introduction:
      'The link is on its way to your designated inbox. The customer can open the email and click through to continue the application when they are ready.',
    CloseButton: 'Close'
  }
}
