import { PageIdentifier } from '../../../types/PageIdentifier'
import { UnifiedApplicationSteps } from '../../../types/UnifiedApplicationSteps'

const getStartedPageIdentifier: PageIdentifier = {
  id: '72f091a5-1edc-49e2-a53a-cbea5e3b3286',
  title: 'Get started',
  step: UnifiedApplicationSteps.GetStarted,
  order: 10,
  isHidden: false,
  subPageIds: [],
}

export default getStartedPageIdentifier
