export enum TokenType {
  Static,
  Reference,
}

export interface IToken {
  type: TokenType
  value: string
}

export interface IStaticToken extends IToken {
  type: TokenType.Static
}

export interface IReferenceToken extends IToken {
  type: TokenType.Reference
  name: string
}

export const isStaticToken = (i: IToken): i is IStaticToken => {
  return i.type === TokenType.Static
}

export const isReferenceToken = (i: IToken): i is IReferenceToken => {
  return i.type === TokenType.Reference
}
