import { KeyValue } from 'src/applyfrontendcontent'

export const deepGetPath = (path: string, obj: KeyValue): string => {
  // path can only be [a-zA-Z0-9.]
  let current: KeyValue = obj
  const segments = path.split('.')
  for (let index in segments) {
    if (!(segments[index] in current)) {
      throw new Error(`missing value for: ${path}`)
    }
    current = current[segments[index]] as KeyValue
  }

  if (typeof current === 'string') return current
  throw new Error('not a string')
}
