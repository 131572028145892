import React, { useCallback, useEffect } from 'react'
import UnifiedEligibilityResultsReducer from 'src/reducers/unifiedReducers/unifiedEligibilityResultsReducer'
import useUnifiedApplicationStep from 'src/shared/hooks/useUnifiedApplicationStep'
import IUnifiedApplicationStepProps from 'src/types/IUnifiedApplicationStepProps'
import IUnifiedEligibilityResultsState from 'src/types/unifiedJourney/IUnifiedEligibilityResultsState'
import { eligibilityLoadingPageIdentifier } from './eligibilityLoadingPageIdentifier'
import styled from 'styled-components'
import { colors, spacing } from 'src/ui-framework'
import TrustPilotBox from 'src/shared/components/footer/TrustPilot'
import useTimer from 'src/shared/hooks/useTimer'
import axios, { AxiosRequestConfig } from 'axios'
import IApplication from 'src/types/IApplication'
import { applyServiceSuffix } from 'src/urlSettingsExports'
import { EligibilityOutcome } from 'src/types/EligibilityOutcome'
import { UnifiedApplicationState } from 'src/types/UnifiedApplicationState'
import { UnifiedJourneyProductType } from 'src/types/ProductType'
import { UnifiedApplicationSteps } from 'src/types/UnifiedApplicationSteps'
import { IUnifiedProductType } from 'src/types/ProductType'
import { features } from 'src/features'
import Spinner from './Spinner'
import { IUpdateFieldPayload } from 'src/reducers/unifiedApplicationActions'

interface IEligibilityResultsProps extends IUnifiedApplicationStepProps<IUnifiedEligibilityResultsState> {}
const reducer = new UnifiedEligibilityResultsReducer(eligibilityLoadingPageIdentifier)

export const Wrapper = styled.div`
  padding-left: 64px;
`

const FullScreenDialog = styled.dialog`
  width: 100vw;
  z-index: 99999;
  position: absolute;
  left: 0;
  top: 0;
  border: none;
  height: 100vh;
  margin: 0;
  padding: 0;
  text-align: center;
`

const H4 = styled.h4`
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  color: ${colors.textGrey900};
  margin: 0;
`

const Pp = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.textGrey600};
  margin: ${spacing.xxxl} 0 0 0;
`

const Section = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const EligibilityLoading = (p: IEligibilityResultsProps) => {
  const [state, dispatch] = useUnifiedApplicationStep<
    IUnifiedEligibilityResultsState,
    UnifiedEligibilityResultsReducer
  >(reducer, p.initialState, p.applicationIdentifier, p.onSubmit, p.onAutoSave)

  const { applicationIdentifier } = p

  const handleRedirect = useCallback(
    (
      invoiceFinanceEligibility: EligibilityOutcome,
      hasBackgroundChecksTimeout: boolean,
      additionalUpdates?: IUpdateFieldPayload[]
    ) => {
      let skipToStep: UnifiedApplicationSteps
      if (hasBackgroundChecksTimeout) {
        skipToStep = UnifiedApplicationSteps.EligibilityResults
      } else {
        switch (invoiceFinanceEligibility) {
          case EligibilityOutcome.Ineligible:
            skipToStep = UnifiedApplicationSteps.EligibilityResultsIneligible
            break
          case EligibilityOutcome.Eligible:
            skipToStep = UnifiedApplicationSteps.EligibilityResults
            break
          default:
            return
        }
      }

      const fieldUpdates: IUpdateFieldPayload[] = [{ field: 'skipToStep', value: skipToStep }]
      additionalUpdates?.forEach((fieldUpdate) => fieldUpdates.push(fieldUpdate))
      fieldUpdates.push({ field: 'state', value: UnifiedApplicationState.Submitting })
      dispatch.valuesUpdate(fieldUpdates)
    },
    [dispatch]
  )

  const checkBackgroundEligibilityComplete = useCallback(
    (products: IUnifiedProductType | undefined, backgroundChecksRequestedTime: Date | undefined): boolean => {
      if (products === undefined) {
        return false
      }

      let checksComplete: boolean = true
      let timeouted: boolean = false

      const presentTime = new Date()
      const checksStartedTime = backgroundChecksRequestedTime

      Object.keys(products).forEach((productId: string) => {
        const product = products[productId]
        if (product.isSelected) {
          checksComplete =
            checksComplete &&
            product.eligibility !== undefined &&
            product.eligibility !== EligibilityOutcome.InsufficientData
        }
      })

      const isOverdue =
        !checksStartedTime ||
        presentTime.getTime() - checksStartedTime.getTime() > features.LendingFlowTimeoutInMilliseconds
      if (!checksComplete && isOverdue) {
        dispatch.valueUpdate('hasBackgroundChecksTimeout', true)
        timeouted = true
      }

      if (checksComplete || timeouted) {
        const additionUpdate = [{ field: 'products', value: products }]
        handleRedirect(
          products[UnifiedJourneyProductType.SelectInvoiceDiscounting].eligibility!,
          timeouted,
          additionUpdate
        )
      }

      return checksComplete
    },
    [handleRedirect, dispatch]
  )

  const timerCallback = useCallback(async (): Promise<boolean> => {
    const axiosConfig: AxiosRequestConfig = {
      withCredentials: true
    }
    try {
      const response = await axios.get<IApplication>(
        `/${applyServiceSuffix()}/applications/${applicationIdentifier}`,
        axiosConfig
      )

      return !checkBackgroundEligibilityComplete(response.data.products, state.backgroundChecksRequestedTime)
    } catch {}
    return true
  }, [applicationIdentifier, checkBackgroundEligibilityComplete, state.backgroundChecksRequestedTime])

  const cancelTimer = useTimer(5000, timerCallback)
  const { products } = state
  const formState = state.state
  useEffect(() => {
    if (formState !== UnifiedApplicationState.Pristine) {
      return
    }
    // if background feature enabled check elibgibility in state
    // if not ready useTimer
    // if disabled call handleRedirect passing in prodcuts from state
    if (features.EnableEligibilityCheckViaLendingFlow) {
      if (checkBackgroundEligibilityComplete(products, state.backgroundChecksRequestedTime)) {
        cancelTimer()
      }
    } else {
      cancelTimer()
      handleRedirect(products[UnifiedJourneyProductType.SelectInvoiceDiscounting].eligibility, false)
    }
  }, [
    products,
    formState,
    cancelTimer,
    checkBackgroundEligibilityComplete,
    handleRedirect,
    state.backgroundChecksRequestedTime
  ])

  return (
    <>
      {features.EnableEligibilityCheckViaLendingFlow && (
        <FullScreenDialog open>
          <Section>
            <div style={{ width: '50vw' }}>
              <H4>Checking your eligibility</H4>
              <div style={{ margin: '64px 0 0 0' }}>
                <Spinner />
              </div>
              <Pp>This can take up to 60 seconds to load. Please don't close the window.</Pp>
              <img
                style={{ marginTop: '64px', height: '32px' }}
                src="https://cdn.kriya.co/images/Kriya-Midnight-Medium.svg"
                alt="Kriya logo"
              ></img>
              <div style={{ marginTop: '24px' }}>
                <TrustPilotBox widget="horizontal" />
              </div>
            </div>
          </Section>
        </FullScreenDialog>
      )}
      {!features.EnableEligibilityCheckViaLendingFlow && <Wrapper>Loading...</Wrapper>}
    </>
  )
}

export default EligibilityLoading
