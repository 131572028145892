import { UnifiedJourneyProductType } from '../../../../../types/ProductType'
import { EligibilityOutcome } from '../../../../../types/EligibilityOutcome'
import { IOutcomeOptions } from '../../../../../shared/components/eligibilityResultsRenderer/EligibilityResultsRenderer'

export const RlsAndInvoiceFailRlsOnly: IOutcomeOptions = {
  content: 'AppliedForRLSEligibleForInvoiceFinance',
  trigger: [
    {
      productType: UnifiedJourneyProductType.RecoveryLoan,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Ineligible, EligibilityOutcome.InsufficientData]
    },
    {
      productType: UnifiedJourneyProductType.Loans,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Eligible]
    },
    {
      productType: UnifiedJourneyProductType.SelectInvoiceDiscounting,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Eligible]
    }
  ],
  results: [
    {
      product: 'RLSBusinessLoan',
      isEligible: false
    },
    {
      product: 'BusinessLoan',
      isEligible: true
    },
    {
      product: 'InvoiceFinance',
      isEligible: true
    }
  ],
  addProductOnContinue: null
}

export const BusinessLoanRlsAndInvoiceFinanceBusinessLoanAndRlsFail: IOutcomeOptions = {
  content: 'AppliedForBusinessLoanEligibleForInvoiceFinance',
  trigger: [
    {
      productType: UnifiedJourneyProductType.Loans,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Ineligible, EligibilityOutcome.InsufficientData]
    },
    {
      productType: UnifiedJourneyProductType.RecoveryLoan,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Ineligible, EligibilityOutcome.InsufficientData]
    },
    {
      productType: UnifiedJourneyProductType.SelectInvoiceDiscounting,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Eligible]
    }
  ],
  results: [
    {
      product: 'RLSBusinessLoan',
      isEligible: false
    },
    {
      product: 'BusinessLoan',
      isEligible: false
    },
    {
      product: 'InvoiceFinance',
      isEligible: true
    }
  ],
  addProductOnContinue: null
}

export const BusinessLoanAndInvoiceFinanceBusinessLoanOnlyFail: IOutcomeOptions = {
  content: 'AppliedForBusinessLoanEligibleForInvoiceFinance',
  trigger: [
    {
      productType: UnifiedJourneyProductType.Loans,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Ineligible, EligibilityOutcome.InsufficientData]
    },
    {
      productType: UnifiedJourneyProductType.RecoveryLoan,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Eligible]
    },
    {
      productType: UnifiedJourneyProductType.SelectInvoiceDiscounting,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Eligible]
    }
  ],
  results: [
    {
      product: 'BusinessLoan',
      isEligible: false
    },
    {
      product: 'InvoiceFinance',
      isEligible: true
    },
    {
      product: 'RLSBusinessLoan',
      isEligible: true
    }
  ],
  addProductOnContinue: null
}

export const RlsOnlyFail: IOutcomeOptions = {
  content: 'AppliedForRLSEligibleForBusinessLoan',
  trigger: [
    {
      productType: UnifiedJourneyProductType.RecoveryLoan,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Ineligible, EligibilityOutcome.InsufficientData]
    },
    {
      productType: UnifiedJourneyProductType.Loans,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Eligible]
    }
  ],
  results: [
    {
      product: 'RLSBusinessLoan',
      isEligible: false
    },
    {
      product: 'BusinessLoan',
      isEligible: true
    }
  ],
  addProductOnContinue: null
}

export const BusinessLoanOnlyFail: IOutcomeOptions = {
  content: 'AppliedForBusinessLoan',
  trigger: [
    {
      productType: UnifiedJourneyProductType.Loans,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Ineligible, EligibilityOutcome.InsufficientData]
    }
  ],
  results: [
    {
      product: 'BusinessLoan',
      isEligible: false
    }
  ],
  addProductOnContinue: null
}

export const BusinessLoanAndRlsFail: IOutcomeOptions = {
  content: 'AppliedForBusinessLoan',
  trigger: [
    {
      productType: UnifiedJourneyProductType.Loans,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Ineligible, EligibilityOutcome.InsufficientData]
    },
    {
      productType: UnifiedJourneyProductType.RecoveryLoan,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Ineligible, EligibilityOutcome.InsufficientData]
    }
  ],
  results: [
    {
      product: 'BusinessLoan',
      isEligible: false
    },
    {
      product: 'RLSBusinessLoan',
      isEligible: false
    }
  ],
  addProductOnContinue: null
}

export const AppliedForBusinessLoanAndRLSFailedForBusinessLoan: IOutcomeOptions = {
  content: 'AppliedForRLSEligibleForBusinessLoan',
  trigger: [
    {
      productType: UnifiedJourneyProductType.Loans,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Ineligible, EligibilityOutcome.InsufficientData]
    },
    {
      productType: UnifiedJourneyProductType.RecoveryLoan,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Eligible]
    }
  ],
  results: [
    {
      product: 'BusinessLoan',
      isEligible: false
    },
    {
      product: 'RLSBusinessLoan',
      isEligible: true
    }
  ],
  addProductOnContinue: null
}

export const InvoiceFinanceOnlyFail: IOutcomeOptions = {
  content: 'AppliedForBusinessLoan',
  trigger: [
    {
      productType: UnifiedJourneyProductType.SelectInvoiceDiscounting,
      isSelected: true,
      eligibilityStates: [EligibilityOutcome.Ineligible, EligibilityOutcome.InsufficientData]
    }
  ],
  results: [
    {
      product: 'InvoiceFinance',
      isEligible: false
    }
  ],
  addProductOnContinue: null
}

export const outcomesLookupOrder: Array<IOutcomeOptions> = [
  BusinessLoanAndInvoiceFinanceBusinessLoanOnlyFail,
  BusinessLoanRlsAndInvoiceFinanceBusinessLoanAndRlsFail,
  RlsAndInvoiceFailRlsOnly,
  AppliedForBusinessLoanAndRLSFailedForBusinessLoan,
  RlsOnlyFail,
  BusinessLoanAndRlsFail,
  BusinessLoanOnlyFail,
  InvoiceFinanceOnlyFail
]
