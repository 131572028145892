import React, { ReactNode } from 'react'
import Row from '../../Grid/Row'
import Column from '../../Grid/Column'
import { Container, InputWrapper, LabelWrapper, OuterWrapper } from '../../../commonStyles/ThemedCheckbox'
import ErrorList from '../ErrorList'
import Checkbox2 from './Checkbox2'

type OwnProps = {
  label: string
  legend?: string
  subLegend?: string | React.ReactElement
  animate: boolean
  errors: Array<string>
  hideErrors?: boolean
  dataTest?: string
  width?: string
  rightComponent?: ReactNode
} & React.ComponentPropsWithoutRef<'input'>

type State = {
  focused: boolean
  pristine: boolean
  mouseDown: boolean
}

type Props = OwnProps & typeof Checkbox.defaultProps

class Checkbox extends React.Component<Props, State> {
  static defaultProps: OwnProps = {
    onChange: () => {},
    name: 'checkbox',
    label: 'checkbox',
    checked: false,
    legend: undefined,
    subLegend: undefined,
    animate: false,
    required: false,
    errors: [],
    hideErrors: false,
    style: {},
    id: 'checkbox',
    dataTest: undefined,
    width: '100%'
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      pristine: true,
      focused: false,
      mouseDown: false
    }

    this.handleOnFocus = this.handleOnFocus.bind(this)
    this.handleOnBlur = this.handleOnBlur.bind(this)
  }

  handleOnFocus(_: React.FocusEvent<HTMLInputElement>) {
    this.setState({ pristine: false, focused: true })
  }

  handleOnBlur(_: React.FocusEvent<HTMLInputElement>) {
    this.setState({ pristine: false, focused: false })
  }

  handleMouseDown = (_: React.MouseEvent<HTMLLabelElement>) => {
    this.setState({ mouseDown: true })
  }
  handleMouseUp = (_: React.MouseEvent<HTMLLabelElement>) => {
    this.setState({ mouseDown: false })
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onChange && this.props.onChange(event)
  }

  render() {
    const { width, animate, errors, style, id, dataTest, label, checked, rightComponent, hideErrors, required } =
      this.props
    const { focused, mouseDown } = this.state

    return (
      <>
        <Container {...this.props} errors={errors ?? []} style={style} id={id} data-test={dataTest} role="group">
          <OuterWrapper width={width || ''}>
            <LabelWrapper
              htmlFor={`${id}-check`}
              animate={animate}
              focused={focused}
              mouseDown={mouseDown}
              errors={errors}
              onMouseDown={this.handleMouseDown}
              onMouseUp={this.handleMouseUp}
            >
              <InputWrapper>
                <Checkbox2
                  checked={checked}
                  focused={focused}
                  onFocus={this.handleOnFocus}
                  onBlur={this.handleOnBlur}
                  aria-checked={checked}
                  onChange={this.handleChange}
                  id={`${id}-check`}
                  label={label}
                  errors={errors}
                  aria-invalid={(errors?.length ?? 0) > 0}
                  aria-describedby={ErrorList.getDescribedByAttribute(errors)}
                  required={required}
                ></Checkbox2>
              </InputWrapper>
              <Row spacing={0} justify="space-between" alignItems="center" style={{ flex: '1' }}>
                <Column></Column>
                {rightComponent && <Column style={{ display: 'flex', justifyContent: 'end' }}>{rightComponent}</Column>}
              </Row>
            </LabelWrapper>
          </OuterWrapper>
          {!hideErrors && <ErrorList errors={errors} id={id ?? ''} />}
        </Container>
      </>
    )
  }
}

export default Checkbox
