import { YourCompanyContent } from '../../types/yourCompany'

export const yourCompany: YourCompanyContent = {
  Heading: 'The Company',
  Introduction:
    'Please provide us with some important contact information for the company.  Fields with a * are required.',
  CompanyWebsite: {
    Text: 'Company website (optional)',
    Tooltip:
      "Accessing the company's website gives us a better idea of the industry they operate in and the products or services they provide so we can make sure they get the right funding solution for their needs.",
    Error: {
      Invalid: 'Invalid website address'
    }
  },
  ChangeRegisteredAddress: {
    Title: 'Registered Address at Companies House',
    ExpandControlButton: 'Change registered address',
    AddressLineOne: {
      Text: 'Address line 1',
      Error: { Required: 'Please enter the first line of your address' }
    },
    AddressLineTwo: {
      Text: 'Address line 2 (optional)'
    },
    City: {
      Text: 'City',
      Error: { Required: 'Please enter your city' }
    },
    Region: {
      Text: 'Region, state or county (optional)'
    },
    Country: {
      Text: 'Country',
      Error: { Required: 'Please enter your country' }
    },
    Postcode: {
      Text: 'Postcode',
      Error: {
        Required: 'Please enter your postcode',
        Invalid: 'Invalid postcode',
        Incomplete: 'Please input the full postcode',
        NotFound: 'Please enter a valid postcode'
      }
    },
    HouseNumber: {
      Text: 'House or flat number (optional)'
    },
    Searching: 'Searching...',
    AddManually: 'Not here? {AddManuallyButton}',
    AddManuallyButton: 'Add manually',
    UseAddressFinder: 'Use address finder',
    AddressInfoNotSelected: 'Please select available address information',
    EmptyResult: 'Sorry... we found nothing'
  },
  ChangeTradingAddress: {
    Title: "Company's Trading Address",
    ExpandControlButton: 'Change trading address',
    AddressLineOne: {
      Text: 'Address line 1',
      Error: { Required: 'Please enter the first line of your address' }
    },
    AddressLineTwo: {
      Text: 'Address line 2 (optional)'
    },
    City: {
      Text: 'City',
      Error: { Required: 'Please enter your city' }
    },
    Region: {
      Text: 'Region, state or county (optional)'
    },
    Country: {
      Text: 'Country',
      Error: { Required: 'Please enter your country' }
    },
    Postcode: {
      Text: 'Postcode',
      Error: {
        Required: 'Please enter your postcode',
        Invalid: 'Invalid postcode',
        Incomplete: 'Please input the full postcode',
        NotFound: 'Please enter a valid postcode'
      }
    },
    HouseNumber: {
      Text: 'House or flat number (optional)'
    },
    Searching: 'Searching...',
    AddManually: 'Not here? {AddManuallyButton}',
    AddManuallyButton: 'Add manually',
    UseAddressFinder: 'Use address finder',
    AddressInfoNotSelected: 'Please select available address information',
    EmptyResult: 'Sorry... we found nothing'
  },
  AddressesMatch: 'Same as registered address'
}
