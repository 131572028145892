import { PageIdentifier } from '../types/PageIdentifier'
import { IJourneyStepRenderer } from '../types/IJourneyStepRenderer'
import { Config } from 'src/applyfrontendcontent'
import { tokeniseToString } from '../shared/content/DynamicContent'
import { UnifiedApplicationSteps } from '../types/UnifiedApplicationSteps'

type PageIdentifierWrapper = {
  page: PageIdentifier
  next?: PageIdentifier
  previous?: PageIdentifier
  renderer: IJourneyStepRenderer
  pageHeading: (content: Config) => string
  pageShortHeading: (content: Config) => string
}

type PageIdentifierLookup = {
  [Key: string]: PageIdentifierWrapper
}

export type JourneyDefinition = {
  page: PageIdentifier
  renderer: IJourneyStepRenderer
  pageHeading: (content: Config) => string
  pageShortHeading: (content: Config) => string
}

export const getPageHeading = (page: keyof Config): ((c: Config) => string) => {
  return (content: Config): string => tokeniseToString(content[page], 'Heading')
}

export const getPageShortHeading = (page: keyof Config): ((c: Config) => string) => {
  return (content: Config): string => tokeniseToString(content[page], 'ShortHeading')
}

export class Journey {
  private lookup: PageIdentifierLookup
  constructor(public pages: JourneyDefinition[]) {
    pages.sort((a, b) => a.page.order - b.page.order)
    this.lookup = {}
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i]
      const tmp: PageIdentifierWrapper = {
        page: page.page,
        next: i < pages.length - 1 ? pages[i + 1].page : undefined,
        previous: i > 0 ? pages[i - 1].page : undefined,
        renderer: page.renderer,
        pageHeading: page.pageHeading,
        pageShortHeading: page.pageShortHeading
      }
      this.lookup[page.page.id] = tmp
      this.lookup[page.page.step] = tmp
      if (i === 0) {
        this.lookup.__first = tmp
      } else if (i === pages.length - 1) {
        this.lookup.__last = tmp
      }
    }
  }

  getRenderer = (id: string) => this.getByKey(id)?.renderer
  getPageFromStep = (step: UnifiedApplicationSteps | undefined): Readonly<PageIdentifier> | undefined =>
    this.getByKey(step)?.page
  getPageFromIdentifier = (identifier: string): Readonly<PageIdentifier> | undefined => this.getByKey(identifier)?.page
  first = (): Readonly<PageIdentifier> => this.lookup.__first.page
  last = (): Readonly<PageIdentifier> => this.lookup.__last.page
  getNextStep = (id: string): PageIdentifier | undefined => this.getByKey(id)?.next
  getPreviousStep = (id: string): PageIdentifier | undefined => this.getByKey(id)?.previous
  private getByKey = (key: string | undefined): PageIdentifierWrapper | undefined =>
    key !== undefined && key in this.lookup ? this.lookup[key] : undefined
}
