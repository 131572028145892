import React from 'react'
import { H1, H2 } from '../../commonStyles/components/headings'
import { useGlobalContext } from '../../GlobalContext'
import { DynamicContent, tokeniseToString } from '../../shared/content/DynamicContent'
import { Row, Hidden, Column, Paragraph, AnchorLink } from 'src/ui-framework'

const ApplicationDeadlineError = () => {
  const { copy } = useGlobalContext()
  return (
    <Row>
      <Hidden xsDown>
        <Column sm={3} />
      </Hidden>
      <Column xs={12} sm={6}>
        <DynamicContent
          content={copy.ApplicationDeadlineError}
          components={{
            Heading: { Component: H1 },
            Introduction: {
              Component: Paragraph
            },
            SubHeading: {
              Component: H2
            },
            GetInTouch: {
              Component: Paragraph
            },
            GetInTouchLink: {
              Component: AnchorLink,
              props: {
                href: `mailto:${tokeniseToString(copy.ApplicationDeadlineError, 'GetInTouchEmail')}`
              }
            },
            Closing: {
              Component: Paragraph
            }
          }}
          ignoreComponents={['GetInTouchLink']}
        />
      </Column>
    </Row>
  )
}

export default ApplicationDeadlineError
