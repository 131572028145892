import React from 'react'
import StyledAnchorLink from './styledAnchorLink'

type Props = {
  href: string
  target?: string
  rel?: string
  autoFontSize?: boolean
} & React.ComponentPropsWithoutRef<'a'>

const AnchorLink: React.FC<Props> = (props: Props) => {
  return <StyledAnchorLink {...props} role="link" />
}

export default AnchorLink
