import React, { Component } from 'react'

import { Grid, GridDirection, GridItemsAlignment, GridJustification, GridSpacing } from '@material-ui/core'

type OwnProps = {
  direction?: GridDirection
  justify?: GridJustification
  alignItems?: GridItemsAlignment
  spacing?: GridSpacing
  style?: React.CSSProperties
  className?: string
}

type Props = OwnProps & typeof Row.defaultProps

class Row extends Component<Props> {
  static defaultProps: OwnProps = {
    direction: 'row',
    justify: 'flex-start',
    alignItems: 'flex-start',
    style: {},
    className: '',
    spacing: 2
  }

  render() {
    const { style, className, children, direction, justify, alignItems, spacing } = this.props

    return (
      <Grid
        container
        style={style}
        className={className}
        direction={direction}
        justifyContent={justify}
        alignItems={alignItems}
        spacing={spacing}
      >
        {children}
      </Grid>
    )
  }
}

export default Row
