export type Spacing = {
  xxxs: string
  xxs: string
  xs: string
  s: string
  m: string
  l: string
  xl: string
  xxl: string
  xxxl: string
}

export const spacing: Spacing = {
  xxxs: '2px',
  xxs: '4px',
  xs: '8px',
  s: '12px',
  m: '16px',
  l: '24px',
  xl: '32px',
  xxl: '40px',
  xxxl: '48px'
}
