import { GetStartedContent } from 'src/applyfrontendcontent/main'

export const getStarted: GetStartedContent = {
  Heading: 'Eligibility assessment (1/2)',
  ShortHeading: 'Company details',
  Introduction: "Let's get started with your basic contact information.",
  Name: {
    Text: 'Your customer’s full name',
    Error: { Required: 'Please enter your name' },
    Hint: 'Please do not include any title such as Mr or Mrs'
  },
  EmailAddress: {
    Text: 'Your customer’s email address',
    Error: {
      Required: 'Please enter your email address',
      EmailMatchesPartner: 'Please enter the applying directors details',
      InvalidFormat: 'Please enter a valid email address'
    }
  },
  PhoneNumber: {
    Text: 'Your customer’s phone number',
    Error: { Required: 'Please enter your phone number' },
    Placeholder: 'eg. 07123456789'
  },
  CompanySearch: {
    CompanyName: {
      Text: 'Full company name or registration number',
      Error: { Required: 'Please enter your full company name or registration number' },
      Placeholder: 'Type something'
    },
    CompanyNameManual: {
      Text: 'Your company name',
      Error: { Required: 'Please enter your full company name' }
    },
    CompanyNumber: {
      Text: 'Company registration number',
      Tooltip: 'This is the unique 8 figure number assigned by Companies House when the company was formed.',
      Error: {
        Required: 'Please enter your company number',
        LengthIncorrect: 'Please enter exactly 8 characters'
      }
    },
    Searching: 'Searching...',
    AddManually: 'Not here? {AddManuallyButton}',
    AddManuallyButton: 'Add manually',
    EmptyResult: 'Sorry... we found nothing'
  },
  SideContent: {
    Heading: 'How we will use the information about you',
    LegalParagraph:
      'We will use your data to process your application, to allow us to provide finance products to your business and improve our sites. We may share your data with third parties to achieve these purposes. We will not share your data for marketing purposes. We will keep your data safe in accordance with data protection legislation. Information on how your data may be used is available on our {LegalParagraphButton}',
    LegalParagraphButton: 'Terms and Conditions.',
    ChangeYourMind: 'You can change your mind at any time by emailing {ChangeYourMindEmail}.',
    ChangeYourMindEmail: 'legal@kriya.co'
  },
  Closing: 'By applying for funding, you agree to our {ClosingLink} and {PrivacyPolicyLink}.',
  PrivacyPolicyLink: 'Privacy Policy',
  ClosingLink: 'Terms and Conditions'
}
