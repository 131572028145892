import { RadioButtonContent } from 'src/applyfrontendcontent'
import React from 'react'
import { H2 } from '../../../commonStyles/components/headings'
import InputLayout from '../../../commonStyles/components/inputLayout'
import { DynamicContent, tokeniseOptions } from '../../../shared/content/DynamicContent'
import { RadioButton } from 'src/ui-framework'

interface RepresentativeJourneyProps {
  onRepresentiveSelection: (value: string) => void
  journeyChoice?: string
  errors: Array<string>
  content: RadioButtonContent
}

const Title = { Component: H2 }

export const RepresentativeJourney = (p: RepresentativeJourneyProps) => {
  const options = tokeniseOptions(p.content)

  if (!p.journeyChoice) {
    p.onRepresentiveSelection(options[0].value)
  }

  return (
    <>
      <DynamicContent
        content={p.content}
        components={{
          Title
        }}
      />
      <InputLayout lg={12} xs={12} md={12} sm={12}>
        <RadioButton
          name="representativeJourneyType"
          id="representativeJourneyType"
          required
          value={p.journeyChoice}
          options={options}
          errors={p.errors || []}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            p.onRepresentiveSelection(e.target.value)
          }}
        />
      </InputLayout>
    </>
  )
}
