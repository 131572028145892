import React, { Component } from 'react'

import { Drawer as MaterialDrawer, ModalProps, PaperProps, SlideProps } from '@material-ui/core'
import List from '@material-ui/core/List'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles } from '@material-ui/core/styles'
import { TransitionProps } from '@material-ui/core/transitions/transition'

import MenuItemComponent from './MenuItemComponent'

const styles = {
  drawer: {
    minWidth: 250
  }
}

type OwnProps = {
  anchor?: 'left' | 'top' | 'right' | 'bottom'
  classes?: {
    drawer?: string
  }
  elevation?: number
  ModalProps?: Partial<ModalProps>
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void
  open?: boolean
  PaperProps?: Partial<PaperProps>
  SlideProps?: Partial<SlideProps>
  transitionDuration?: TransitionProps['timeout']
  variant?: 'permanent' | 'persistent' | 'temporary'
  items?: (
    | {
        label: string
        url: string
      }
    | (() => JSX.Element)
  )[]
}

type Props = OwnProps & typeof Drawer.defaultProps

class Drawer extends Component<Props> {
  static defaultProps: OwnProps = {
    anchor: 'right',
    classes: {},
    elevation: 16,
    ModalProps: {},
    onClose: () => {},
    open: false,
    PaperProps: {},
    SlideProps: {},
    transitionDuration: { enter: 200, exit: 200 },
    variant: 'temporary',
    items: []
  }

  render() {
    const {
      anchor,
      classes,
      elevation,
      ModalProps,
      onClose,
      open,
      PaperProps,
      SlideProps,
      transitionDuration,
      variant,
      items
    } = this.props

    return (
      <MaterialDrawer
        anchor={anchor}
        elevation={elevation}
        ModalProps={ModalProps}
        onClose={onClose}
        open={open}
        PaperProps={PaperProps}
        SlideProps={SlideProps}
        transitionDuration={transitionDuration}
        variant={variant}
      >
        <List className={(classes ?? {}).drawer}>
          {(items ?? []).map((item, index) => (
            <MenuItem id={`menu-item-${index}`} key={`menu-item-${index}`}>
              <MenuItemComponent item={item} style={{ textDecoration: 'none', color: 'black' }} />
            </MenuItem>
          ))}
        </List>
      </MaterialDrawer>
    )
  }
}

export default withStyles(styles)(Drawer)
