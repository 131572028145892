import React, { useMemo } from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import { UnifiedApplicationSteps } from '../../types/UnifiedApplicationSteps'
import ErrorPage from '../error/ErrorPage'
import { UnifiedApplicationActionNavigateTo } from '../../reducers/unifiedApplicationActions'
import { UnifiedApplicationState } from '../../types/UnifiedApplicationState'
import useUnifiedApplicationJourney from '../../shared/hooks/useUnifiedApplicationJourney'
import UnifiedApplicationStep from './UnifiedApplicationStep'
import { IUnifiedApplicationParams } from '../../types/IUnifiedApplicationParams'
import getJourneyById from '../journeyFactory'
import { UnifiedApplicationStatus } from '../../types/IUnifiedApplicationState'
import { tokeniseToString } from '../../shared/content/DynamicContent'
import { useGlobalContext } from '../../GlobalContext'
import { IRepresentativeJourneyInfoType } from '../../types/IRepresentativeJourneyInfoType'
import { ProgressLayout } from 'src/ui-framework'
import { UserRole } from 'src/types/UserRole'
import styled from 'styled-components'

interface IProgressOption {
  id: string
  label: string
  labelShort: string
  order: number
  isHidden: boolean
  pageIds: Array<string>
}

export const Wrapper = styled.div`
  padding-left: 64px;
`

const UnifiedApplication = (p: RouteComponentProps<IUnifiedApplicationParams>) => {
  const [state, dispatch, content] = useUnifiedApplicationJourney(p)
  const { copy } = useGlobalContext()
  const journey = getJourneyById(state.journeyType, state.status, state.channelType)
  const options = useMemo(() => {
    let pageIndex = 1
    return journey.pages.map(
      (j) =>
        ({
          id: j.page.id,
          label: j.pageHeading(content),
          labelShort: j.pageShortHeading(content),
          order: j.page.isHidden ? pageIndex : pageIndex++,
          isHidden: j.page.isHidden,
          pageIds: j.page.subPageIds
        } as IProgressOption)
    )
  }, [journey, content])

  const navigateToStep = (id: string): void => {
    const page = journey.getPageFromIdentifier(id)
    if (page === undefined) {
      return
    }
    dispatch(UnifiedApplicationActionNavigateTo(page.step as UnifiedApplicationSteps))
  }

  if (state.state === UnifiedApplicationState.Loading) {
    return <Wrapper>{tokeniseToString(copy.Shared, 'LoadingPage')}</Wrapper>
  }

  const repJourneyInfo: IRepresentativeJourneyInfoType = {
    partnerIdentifier: state.partnerIdentifier,
    representativeJourneyType: state.representativeJourneyType
  }

  if (state.state === UnifiedApplicationState.Error) {
    return <ErrorPage errorType={state.errorType} content={content} representativeJourneyInfo={repJourneyInfo} />
  }

  if (
    state.state === UnifiedApplicationState.Completed ||
    state.status === UnifiedApplicationStatus.Submitted ||
    (state.status === UnifiedApplicationStatus.Draft && state.userRole === UserRole.RelationshipManager)
  ) {
    return (
      <Redirect
        to={{
          pathname: `/application/${state.id}/thank-you`,
          state: {
            content
          }
        }}
      />
    )
  }

  if (state.state === UnifiedApplicationState.Navigate && p.match.params.step !== state.nextStep) {
    if (state.nextStep === undefined) {
      return <ErrorPage content={content} />
    } else {
      return <Redirect push to={state.nextStep} />
    }
  }

  return (
    <ProgressLayout
      options={options}
      selected={journey.getPageFromStep(p.match.params.step)?.id}
      onNavigate={navigateToStep}
      hidden={journey.getPageFromStep(p.match.params.step)?.hidden}
      fillParent={journey.getPageFromStep(p.match.params.step)?.fillParent}
    >
      <UnifiedApplicationStep routeParams={p} unifiedApplication={state} dispatch={dispatch} />
    </ProgressLayout>
  )
}

export default UnifiedApplication
