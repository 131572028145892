import React, { useContext } from 'react'
import { Config, getCopy } from 'src/applyfrontendcontent'
import { cookieExists } from './utils/cookieHelper'

export type PartnerConfig = {
  logoUrl: string
  logoAlt: string
  linkUrl?: string
}

const defaultCopy = getCopy(navigator.language, {
  representativeType: '',
  sales: cookieExists('sales'),
  partner: ''
})

type GlobalContextType = {
  copy: Config
  setCopy: (c: Config) => void
  partnerData: PartnerConfig | undefined
  setPartnerData: (partner: PartnerConfig) => void
}

export const GlobalContext = React.createContext<GlobalContextType>({
  copy: defaultCopy,
  setCopy: () => {},
  partnerData: undefined,
  setPartnerData: () => {}
})

export const useGlobalContext = () => useContext(GlobalContext)
