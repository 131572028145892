export const setCookie = (key: string, value: string, expiryDays: number): void => {
  let maxDate = new Date()
  maxDate.setDate(maxDate.getDate() + expiryDays)
  document.cookie = `${key}=${value};path=/;expires=${maxDate}`
}

export const deleteCookie = (key: string, value: string): void => {
  document.cookie = `${key}=${value};path=/;expires=${new Date(0)}`
}

export const cookieExists = (key: string): boolean => {
  var cookieIdentifier = key + '='
  var cookies = document.cookie.split(';')
  for (var i = 0; i < cookies.length; i++) {
    var cookieContent = cookies[i]
    if (cookieContent.trim().indexOf(cookieIdentifier) === 0) {
      return true
    }
  }
  return false
}
