export default interface IUploadFileConstraints {
  maxSizeInMb: number
  validFileExtensions: string[]
}

export const isIUploadFileConstraints = (input: any): input is IUploadFileConstraints => {
  return (
    input.maxSizeInMb !== undefined &&
    input.validFileExtensions !== undefined &&
    Array.isArray(input.validFileExtensions)
  )
}

export const defaultFileConstraints: IUploadFileConstraints = {
  maxSizeInMb: Number.MAX_SAFE_INTEGER,
  validFileExtensions: [],
}
