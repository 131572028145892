import React from 'react'
import { Row, Column } from 'src/ui-framework'
import PropTypes from 'prop-types'

const InputLayout = ({ md, xs, sm, lg, children }) => {
  return (
    <Row spacing={0}>
      <Column md={md} xs={xs} sm={sm} lg={lg}>
        {children}
      </Column>
    </Row>
  )
}

InputLayout.propTypes = {
  md: PropTypes.number,
  xs: PropTypes.number,
  sm: PropTypes.number,
  lg: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired
}

InputLayout.defaultProps = {
  md: 12,
  sm: 12,
  xs: 12,
  lg: 12
}

export default InputLayout
