import styled from 'styled-components'

type InputWrapperStyleProps = {
  theme: {}
}

const InputWrapper = styled.div<InputWrapperStyleProps>`
  margin-right: 8px;
  cursor: pointer;
`
export default InputWrapper
