import { media } from 'src/ui-framework'
import styled from 'styled-components'

type OuterWrapperStyleProps = {
  index: number
  theme: any
}

const OuterWrapper = styled.div<OuterWrapperStyleProps>`
  margin-top: ${(props) => (props.index === 0 ? '0' : props.theme.spacing.xxs)};

  ${media.upToTablet`
    margin-top: 0;
  `}
`

export default OuterWrapper
