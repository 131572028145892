import React from 'react'
import { UnifiedApplicationState } from '../../../../../types/UnifiedApplicationState'
import useUnifiedApplicationStep from '../../../../../shared/hooks/useUnifiedApplicationStep'
import IUnifiedApplicationStepProps from '../../../../../types/IUnifiedApplicationStepProps'
import { BasicComponentWithProps, DynamicContent, tokeniseToString } from '../../../../../shared/content/DynamicContent'
import { CongratulatoryNote } from '../../../../../shared/components/contentNote/ContentNote'
import { Redirect } from 'react-router-dom'
import { EligibilityOutcome } from '../../../../../types/EligibilityOutcome'
import { formatDate, setBankStatementsDate } from '../../../../../shared/components/dynamicDates/dynamicDates'
import { EligibleContent } from 'src/applyfrontendcontent'
import { nMonthsPrior, startOfMonth, taxYearForNYearsPrior } from '../../../../../utils/dateCalculator'
import ContinueAndSaveLater from '../../../../unifiedApplicationJourney/ContinueAndSaveLater'
import { getSaveForLaterEmail } from '../../../../../shared/getSaveForLaterEmail'
import styled from 'styled-components'
import { media } from 'src/ui-framework'
import { EligibilityBulletsUnorderedList, OptionsParagraph } from './styles'
import { eligibilityResultsPageIdentifier } from './eligibilityResultsPageIdentifier'
import IUnifiedEligibilityResultsState from 'src/types/unifiedJourney/IUnifiedEligibilityResultsState'
import UnifiedEligibilityResultsReducer from 'src/reducers/unifiedReducers/unifiedEligibilityResultsReducer'
import { UnifiedApplicationSteps } from 'src/types/UnifiedApplicationSteps'

const CheckedListItem = styled.li`
  font-size: ${(props: any) => props.theme.fontSize.s};
  line-height: 24px;

  ${media.upToTablet`
    font-size: ${(props: any) => props.theme.fontSize.xs};
  `}
`

const DynamicOptionsParagraph: BasicComponentWithProps = {
  Component: OptionsParagraph,
  props: {
    id: 'options',
    style: { marginBottom: '24px' }
  }
}

const Message: BasicComponentWithProps = {
  Component: CongratulatoryNote,
  props: {
    id: 'message-block',
    style: { marginTop: '0' }
  }
}
const BulletCheckedPoints: BasicComponentWithProps = {
  Component: EligibilityBulletsUnorderedList,
  props: {
    id: 'product-checklist'
  }
}

const BulletCheckedPointsItem = (p: React.PropsWithChildren<EligibleContent>) => (
  <>
    <CheckedListItem>{p.children}</CheckedListItem>
  </>
)
interface IEligibilityResultsProps extends IUnifiedApplicationStepProps<IUnifiedEligibilityResultsState> {}
const reducer = new UnifiedEligibilityResultsReducer(eligibilityResultsPageIdentifier)

const EligibilityResults = (p: IEligibilityResultsProps) => {
  const [state, dispatch, content, onSendLink] = useUnifiedApplicationStep<
    IUnifiedEligibilityResultsState,
    UnifiedEligibilityResultsReducer
  >(reducer, p.initialState, p.applicationIdentifier, p.onSubmit, p.onAutoSave)

  if (state.state === UnifiedApplicationState.Loading) {
    return <p>{tokeniseToString(content.Shared, 'LoadingPage')}</p>
  }

  const productsKeys = Object.keys(state.products)

  const allSelectedProductsPass = productsKeys.every((key) => {
    let value = state.products[key]
    return (
      !value.isSelected ||
      value.eligibility === EligibilityOutcome.Eligible ||
      value.backgroundEligibility === EligibilityOutcome.Eligible
    )
  })

  let locatedContent: EligibleContent | undefined

  const now = new Date()
  const earliestTaxYear = taxYearForNYearsPrior(now, 2)
  const latestTaxYear = taxYearForNYearsPrior(now, 1)

  const DynamicDates = {
    AccountsFrom: formatDate(startOfMonth(nMonthsPrior(new Date(), 6, false))),
    AccountsTo: setBankStatementsDate(14),
    TaxYearOneStart: formatDate(earliestTaxYear.start),
    TaxYearOneEnd: formatDate(earliestTaxYear.end),
    TaxYearTwoStart: formatDate(latestTaxYear.start),
    TaxYearTwoEnd: formatDate(latestTaxYear.end)
  }

  if (allSelectedProductsPass && !state.outcome) {
    locatedContent = content.EligibilityResult.EligibleForInvoiceFinanceOnly

    if (!locatedContent) {
      return (
        <Redirect
          to={{
            pathname: '/error',
            state: {
              content
            }
          }}
        />
      )
    }

    locatedContent.Dynamic = DynamicDates

    let components: { [k: string]: any } = {
      Text: DynamicOptionsParagraph,
      RequirementCheckedPoints: BulletCheckedPoints,
      RequirementCheckedPointsItem: {
        Component: BulletCheckedPointsItem,
        props: locatedContent
      }
    }
    if (locatedContent.Message?.length) {
      components.Message = Message
    }

    return (
      <>
        <DynamicContent content={locatedContent} components={components} />

        <ContinueAndSaveLater
          content={content}
          loading={state.state === UnifiedApplicationState.Submitted}
          email={getSaveForLaterEmail(state)}
          id="continue-application"
          onSendLink={onSendLink}
          errors={state.errors}
          showSaveLater={false}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.preventDefault()
            dispatch.valuesUpdate([
              { field: 'skipToStep', value: UnifiedApplicationSteps.DirectorDetails },
              { field: 'state', value: UnifiedApplicationState.Submitting }
            ])
          }}
        />
      </>
    )
  }

  return (
    <Redirect
      to={{
        pathname: '/error',
        state: {
          content
        }
      }}
    />
  )
}

export default EligibilityResults
