import React, { Component } from 'react'

import { Hidden as MaterialHidden } from '@material-ui/core'

type OwnProps = {
  lgDown?: boolean
  lgUp?: boolean
  mdDown?: boolean
  mdUp?: boolean
  smDown?: boolean
  smUp?: boolean
  xlDown?: boolean
  xlUp?: boolean
  xsDown?: boolean
  xsUp?: boolean
}

type Props = OwnProps & typeof Hidden.defaultProps

class Hidden extends Component<Props> {
  static defaultProps: OwnProps = {
    lgDown: false,
    lgUp: false,
    mdDown: false,
    mdUp: false,
    smDown: false,
    smUp: false,
    xlDown: false,
    xlUp: false,
    xsDown: false,
    xsUp: false
  }

  render() {
    const { children, lgDown, lgUp, mdDown, mdUp, smDown, smUp, xlDown, xlUp, xsDown, xsUp } = this.props

    return (
      <MaterialHidden
        lgDown={lgDown}
        lgUp={lgUp}
        mdDown={mdDown}
        mdUp={mdUp}
        smDown={smDown}
        smUp={smUp}
        xlDown={xlDown}
        xlUp={xlUp}
        xsDown={xsDown}
        xsUp={xsUp}
      >
        {children}
      </MaterialHidden>
    )
  }
}

export default Hidden
