import React, { useState } from 'react'
import ErrorList from '../ErrorList'
import ThemedTextArea from '../../../commonStyles/ThemedTextArea'
import { TooltipDiv } from '../../../commonStyles/TooltipDiv'
import Label from '../../../commonStyles/Label'
import { StyledTextAreaLength } from './styledTextArea'
import Row from '../../Grid/Row'
import Column from '../../Grid/Column'

type OwnProps = {
  label?: string
  value?: string
  gutterBottom?: boolean
  errors?: Array<string>
  hotjarUnmask?: boolean
  id: string
  maxLength: number
} & React.ComponentPropsWithoutRef<'textarea'>

type Props = OwnProps

const TextArea = (props: Props) => {
  const { id, label, required, errors, maxLength, hotjarUnmask, onChange, onFocus, onBlur, value } = props

  const [isFocused, setIsFocused] = useState(false)
  const [currentLength, setCurrentLength] = useState<number>(value?.length ?? 0)

  const handleOnFocus = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    onFocus && onFocus(event)
    setIsFocused(true)
  }

  const handleOnBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    onBlur && onBlur(event)
    setIsFocused(false)
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(event)
    setCurrentLength(event.target.textLength)
  }

  return (
    <>
      {label && (
        <TooltipDiv>
          <Label htmlFor={id}>{label}</Label>
        </TooltipDiv>
      )}

      <ThemedTextArea
        {...props}
        maxLength={maxLength}
        focused={isFocused}
        onBlur={handleOnBlur}
        onFocus={handleOnFocus}
        onChange={handleOnChange}
        aria-required={required}
        errors={errors ?? []}
        data-hj-allow={hotjarUnmask ? '' : undefined}
        aria-invalid={(errors ?? []).length > 0}
        aria-describedby={ErrorList.getDescribedByAttributes(id, errors ?? [])}
      />

      <Row style={{ margin: 0 }}>
        <Column style={{ flex: 1, padding: 0 }} zeroMinWidth>
          <ErrorList errors={errors} id={id} />
        </Column>
        <Column style={{ padding: 0, marginTop: '4px', marginRight: '15px', fontSize: '12px' }}>
          <StyledTextAreaLength overflows={currentLength > maxLength}>{currentLength}</StyledTextAreaLength> /{' '}
          {maxLength}
        </Column>
      </Row>
    </>
  )
}

TextArea.defaultProps = {
  id: 'text-area',
  name: 'text-area',
  required: false,
  disabled: false,
  errors: [],
  gutterBottom: true,
  label: '',
  value: undefined,
  rows: 5,
  hotjarUnmask: false,
  onFocus: () => {},
  onBlur: () => {},
  onKeyDown: () => {},
  onChange: () => {},
  maxLength: 500
}

export default TextArea
