import { ReadyToApplyPartnerContent } from '../../../../types/readyToApplyPartner'

export const readyToApplyPartner: ReadyToApplyPartnerContent = {
  WelcomePartner: "Assess your customer's eligibility and register your referral",

  Introduction:
    'Before you continue, please ensure you have used the {CheckListLinkLabel} to assess your customer’s eligibility.',
  CheckListLinkLabel: 'pre-referral checklist',
  CheckListLink:
    'https://uploads-ssl.webflow.com/636ab0d02afef85a427a795a/6582c90009d645f8669fec10_Kriya%20x%20Barclays%20Pre-Referral%20Checklist.pdf',

  SubIntroduction:
    'If you have any question, please email {PartnerHelpEmail} with a preferred call-back time for your customers and a brief description of their needs. One of our Partnership Managers will get back to you to discuss the next steps.',
  PartnerHelpEmail: 'barclayscoverage@kriya.co',

  ContinueButton: 'Start'
}
