import { DirectorDetailsContent } from '../../../../types/directorDetails'

export const directorDetails: DirectorDetailsContent = {
  Heading: 'We need to know more about the director of the company',
  ShortHeading: 'Director details',
  Introduction:
    'Now, tell us a bit about the applying director. Having this information upfront helps us make a decision about your application as quickly as possible. Fields with a * are required.',
  WhichDirector: {
    Automated: {
      Text: 'Which director are you applying for?',
      Error: { Required: 'Please enter their name' },
      Placeholder: 'Please select'
    },
    Manual: {
      Text: 'Which director are you applying for?',
      Error: { Required: 'Please enter their name' },
      Placeholder: 'Please enter director full name'
    }
  },
  DateOfBirth: {
    Text: "Director's date of birth",
    Day: 'Day',
    Month: 'Month',
    Year: 'Year',
    Error: {
      Required: 'Please enter their date of birth',
      Over18: 'You must be at least 18 years old to apply',
      Invalid: 'Please enter a valid date'
    }
  },
  ContactDetails: {
    Heading: "This director's contact details",
    DirectorEmail: {
      Text: "Director's email address",
      Error: {
        Required: 'Please enter their email address',
        Invalid: 'Please enter a valid email address'
      }
    },
    DirectorPhoneNumber: {
      Text: "Director's mobile phone number",
      Error: {
        Required: 'Please enter their phone number',
        Invalid: 'Please enter a valid phone number'
      }
    }
  },
  ResidentialAddress: {
    Title: "This director's residential address",
    AddressLineOne: {
      Text: 'Address line 1',
      Error: { Required: 'Please enter the first line of their address' }
    },
    AddressLineTwo: {
      Text: 'Address line 2 (optional)'
    },
    City: {
      Text: 'City',
      Error: { Required: 'Please enter their city' }
    },
    Region: {
      Text: 'Region, state or county (optional)'
    },
    Country: {
      Text: 'Country',
      Error: { Required: 'Please enter their country' }
    },
    Postcode: {
      Text: 'Postcode',
      Error: {
        Required: 'Please enter their postcode',
        Invalid: 'Invalid postcode',
        Incomplete: 'Please input the full postcode',
        NotFound: 'Please enter a valid postcode'
      }
    },
    HouseNumber: {
      Text: 'House or flat number (optional)',
      Tooltip: 'Entering the number can speed up the search'
    },
    Searching: 'Searching...',
    AddManually: 'Enter address manually here',
    AddManuallyButton: 'Add manually',
    UseAddressFinder: 'Use address finder',
    AddressInfoNotSelected: 'Please select available address information',
    EmptyResult: 'Sorry... we found nothing'
  },
  TimeAtAddress: {
    Text: 'How long has the director lived at this address?',
    Years: 'Years',
    Months: 'Months',
    Error: {
      Required: 'Please enter a value',
      Invalid: 'Please enter a valid number of years and months',
      CorrelateWithDob: 'Time at address must correlate with date of birth'
    }
  },
  TermsAndConditions: {
    Text: 'By continuing, you agree with our Privacy Policy to process your application',
    PrivacyPolicy: 'Read our {PrivacyPolicyButton}',
    PrivacyPolicyButton: 'Privacy Policy',
    Error: {
      Required: 'Please accept the privacy policy'
    }
  },
  ContinueButton: 'Continue'
}
