import { useCallback, useEffect, useRef, useState } from 'react'
import React from 'react'
import { AddressSearchContent } from 'src/applyfrontendcontent'
import { tokeniseLabelAsString, tokeniseTooltipAsString, tokeniseToString } from '../../content/DynamicContent'
import IAddressState from '../../../types/IAddressState'
import InputLayout from '../../../commonStyles/components/inputLayout'
import AddressSearchApi, { AddressSearchApiResponseDataItem } from './AddressSearchApi'
import { Input, Typeahead } from 'src/ui-framework'

const enterKeyCode = 13

type AddressSearchProps = {
  onChange: (i: IAddressState) => void
  onClose: () => void
  errors: Array<string> | string
  content: AddressSearchContent
}

const AddressSearch = (props: AddressSearchProps) => {
  const [results, setResults] = useState<AddressSearchApiResponseDataItem[]>()
  const [loading, setLoading] = useState(false)
  const [focused, setFocused] = useState(false)
  const [open, setOpen] = useState(false)
  const [houseNumber, setHouseNumber] = useState('')
  const [postCode, setPostCode] = useState('')
  const [searchingMessage, setSearchingMessage] = useState([props.content.Searching])
  const errors = (props.errors as string[]) || []
  const timeOutRef: { current: NodeJS.Timeout | null } = useRef(null)

  const [postCodeErrors, setErrors] = useState({
    postCode: errors,
    hasErrors: errors.length > 0
  })
  useEffect(() => {
    setErrors({
      postCode: errors,
      hasErrors: errors.length > 0
    })
  }, [errors])

  const handle = (e: any) => {
    if (e.target.id !== 'postcode-input') {
      setFocused(false)
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handle)

    return (): void => {
      document.removeEventListener('click', handle)
    }
  }, [])

  useEffect(() => {
    if (!focused && !postCodeErrors.hasErrors && postCode.length > 0) {
      const msg = [tokeniseToString(props.content || {}, 'AddressInfoNotSelected')]
      setErrors({
        hasErrors: true,
        postCode: msg
      })
    }
  }, [focused, postCodeErrors.hasErrors, postCode, props.content])

  const handleHouseNumberChange = (houseNumber: string) => {
    setHouseNumber(houseNumber)
  }

  const handlePostCodeChange = (postCode: string) => {
    setPostCode(postCode)
  }

  const validatePostCode = (value: string) => {
    const regex = new RegExp(
      '^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$',
      'i'
    )
    return regex.test(value)
  }

  const setErrorMessage = useCallback(
    (error: string) => {
      const msg = [tokeniseToString(props.content.Postcode.Error || {}, error)]
      setSearchingMessage(msg)
      setErrors({
        hasErrors: true,
        postCode: msg
      })
    },
    [props.content.Postcode.Error]
  )

  useEffect(() => {
    if (timeOutRef.current) {
      clearTimeout(timeOutRef.current)
      timeOutRef.current = null
    }

    if (postCode.length === 0) return

    setOpen(true)
    setLoading(true)
    setResults(undefined)

    if (postCode.length < 5) {
      setErrorMessage('Incomplete')
      return
    }

    if (!validatePostCode(postCode)) {
      setErrorMessage('Invalid')
      return
    }

    setErrors({
      hasErrors: false,
      postCode: []
    })

    setSearchingMessage([props.content.Searching])

    const loadAddress = () => {
      timeOutRef.current = setTimeout(async () => {
        try {
          const searchResult = await AddressSearchApi.searchByPostCode(postCode, houseNumber, 100)

          setResults(searchResult)
          setLoading(false)

          if (!searchResult || searchResult.length === 0) {
            setErrorMessage('NotFound')
          }
        } catch (error) {
          setLoading(false)
          setOpen(false)
        }
      }, 500)
    }
    loadAddress()
  }, [houseNumber, postCode, props.content.Searching, props.errors, setErrorMessage])

  const handleChange = useCallback(
    (selectedValue: AddressSearchApiResponseDataItem) => {
      setOpen(false)

      const result: IAddressState = {
        addressLine1: selectedValue.Line1,
        addressLine2: selectedValue.Line2,
        city: selectedValue.TownOrCity,
        country: selectedValue.Country,
        region: selectedValue.County,
        postCode: selectedValue.Postcode
      }

      props.onChange(result)
    },
    [props]
  )

  const handleLiClick = useCallback(
    (selectedValue: any) => {
      handleChange(selectedValue)
    },
    [handleChange]
  )

  const handleKeyCapture = useCallback(
    (e: React.KeyboardEvent<HTMLLIElement>, selectedValue: any) => {
      if (e.keyCode === enterKeyCode) {
        e.preventDefault()
        handleChange(selectedValue)
      }
    },
    [handleChange]
  )

  const id = 'address-search'

  return (
    <InputLayout>
      <Input
        id="address-house-number"
        label={tokeniseLabelAsString(props.content.HouseNumber)}
        name="address-house-number"
        autoComplete="address-housenumber"
        value={houseNumber}
        required={false}
        maxLength={10}
        onChange={(e: any) => {
          handleHouseNumberChange(e.target.value)
        }}
        helpText={tokeniseTooltipAsString(props.content.HouseNumber)}
      />

      <Typeahead
        labelKey="postcode"
        inputId={'postcode-input'}
        id={id}
        emptyLabel={props.content.EmptyResult}
        label={tokeniseLabelAsString(props.content.Postcode)}
        placeholder={props.content.Postcode.Placeholder}
        isInvalid={postCodeErrors.hasErrors}
        isFocused={focused}
        isLoading={loading}
        errors={postCodeErrors.postCode}
        isOpen={open}
        handleLiClick={handleLiClick}
        handleKeyCapture={handleKeyCapture}
        onSearch={handlePostCodeChange}
        onBlur={() => {
          setFocused(false)
        }}
        options={results}
        onChange={(selected: any) => handleChange(selected)}
        onFocus={() => setFocused(true)}
        searchText={searchingMessage}
        itemContentTitle={(result: any) => result.Label}
        filterBy={(a: any) => {
          return a.Line1 !== undefined && a.Country !== undefined
        }}
        required
      />
    </InputLayout>
  )
}

export default AddressSearch
