import React from 'react'
import { IUnifiedProductType } from '../../../types/ProductType'
import { getNextSteps } from './NextStepsLookup'
import { Config } from 'src/applyfrontendcontent'
import styled from 'styled-components'
import { media } from 'src/ui-framework'

export interface INextStepProps {
  products: IUnifiedProductType
  content: Config
}

type StyledListItemProps = {
  theme: any
}

export const StyledListItem = styled.li<any>`
  font-family: ${(props) => props.theme.fontFamily};
  font-size: ${(props) => props.theme.fontSize.s};
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 0;
  color: ${(props) => props.theme.colors.textGrey900};

  ${media.upToTablet`
    font-size: ${(props: StyledListItemProps) => props.theme.fontSize.xs};
  `}
`

export const StyledList = styled.ul<any>`
  margin-bottom: 0;
  margin-top: 8px;
  padding-left: 28px;

  ${media.upToTablet`
    font-size: ${(props: StyledListItemProps) => props.theme.fontSize.xs};
    margin-bottom: 0;
  `}
`

const NextSteps = (props: INextStepProps) => {
  const nextSteps = getNextSteps(props.products, props.content)

  return (
    <>
      {nextSteps && nextSteps.length !== 0 && (
        <StyledList id="next-steps">
          {nextSteps.map((step, index) => (
            <StyledListItem key={index}>{step}</StyledListItem>
          ))}
        </StyledList>
      )}
    </>
  )
}

export default NextSteps
