import { ThankYouContent } from '../../../../types/thankyou'

export const thankYou: ThankYouContent = {
  SuccessMessage: 'The application has been successfully submitted',
  Heading: 'What happens next?',
  Introduction: [
    "We'll review the information provided and be in touch shortly to discuss the next steps.",
    'We’ll keep you updated on the progress of your application at every stage. Please keep an eye on your inbox as we may need to ask you for more information.'
  ],
  InvoiceFinanceBulletPoints: [],
  RecoveryLoanBulletPoints: [],
  RecoveryLoanAndInvoiceFinanceBulletPoints: [],
  Closing: 'Thank you for your application. We look forward to speaking to you soon.'
}
