import React from 'react'
import { LinkButton } from 'src/ui-framework'
import {
  BritishBankImage,
  TrustPilotImageWrapper,
  FooterWrapper,
  FooterContent,
  FooterMarketing
} from '../../../commonStyles/components/unifiedJourneyFooter'
import { BritishBankLogo } from '../../../commonStyles/images/images'
import styled from 'styled-components'
import { useGlobalContext } from '../../../GlobalContext'
import TrustPilotBox from './TrustPilot'

export const HelpStyledLinkButton = styled(LinkButton)`
  font-family: ${(props) => props.theme.fontFamily};
  padding: 0;
`

const Footer = () => {
  const { copy } = useGlobalContext()
  const localeContent = copy

  return (
    <>
      <FooterWrapper>
        <FooterContent>
          <FooterMarketing>
            <BritishBankImage src={BritishBankLogo} alt={localeContent.Footer.BritishBankImageAltText} />
            <TrustPilotImageWrapper>
              <TrustPilotBox />
            </TrustPilotImageWrapper>
          </FooterMarketing>
        </FooterContent>
      </FooterWrapper>
    </>
  )
}

export default Footer
