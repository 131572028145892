import { H2 } from 'src/commonStyles/components/headings'
import { CongratulatoryNote } from 'src/shared/components/contentNote/ContentNote'
import { media, Paragraph } from 'src/ui-framework'
import styled from 'styled-components'

export const Banner = styled(CongratulatoryNote)<any>`
  margin-bottom: ${(props: any) => props.theme.spacing.xxxl};

  ${media.upToTablet`
    margin-bottom: ${(props: any) => props.theme.spacing.xl};
  `}
`

export const StyledHeading = styled(H2)`
  margin-bottom: ${(props: any) => props.theme.spacing.xl};

  ${media.upToTablet`
    font-size: ${(props: any) => props.theme.fontSize.s};
  `}
`

export const FraudHeading = styled.strong<any>`
  margin-bottom: ${(props: any) => props.theme.spacing.xl};
  display: block;

  ${media.upToTablet`
    font-size: ${(props: any) => props.theme.fontSize.xs};
  `}
`

export const ParagraphStyled = styled(Paragraph)<any>`
  margin-bottom: ${(props: any) => props.theme.spacing.l};

  ${media.upToTablet`
    line-height: 20px;
    margin-bottom: ${(props: any) => props.theme.spacing.m};
  `}
`

export const ConsentFormHeading = styled(H2)`
  margin-bottom: ${(props: any) => props.theme.spacing.xxl};

  ${media.upToTablet`
    margin-bottom: ${(props: any) => props.theme.spacing.xl}; 
  `}
`

export const ConsentForm = styled.div<any>`
  display: flex;
  flex-direction: column;
  gap: ${(props: any) => props.theme.spacing.l};
  margin-bottom: ${(props: any) => props.theme.spacing.xl};

  ${media.upToTablet`
      margin-bottom: 54px;  
    `}
`

export const ConsentStatementsList = styled.div`
  margin-bottom: ${(props: any) => props.theme.spacing.xl};
`

export const SupportRequirementsList = styled.div`
  margin-bottom: ${(props: any) => props.theme.spacing.xl};
`

export const ConsentFraudStatementsList = styled.div`
  margin-bottom: ${(props: any) => props.theme.spacing.xl};

  ${media.upToTablet`
    margin-bottom: 64px;  
  `}
`

export const ErrorMessage = styled.span<any>`
  display: block;
  font-size: ${(props) => props.theme.fontSize.s};
  color: ${(props) => props.theme.colors.error};
  font-weight: 600;
  margin-bottom: ${(props: any) => props.theme.spacing.xxxl};

  ${media.upToTablet`
      margin-bottom: 54px;  
      font-size: ${(props: any) => props.theme.fontSize.xs};
  `}
`
