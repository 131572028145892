const onBeforeUnload = (e: BeforeUnloadEvent): void => {
  // See Example: https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
  e.preventDefault()
  e.returnValue = ''
}

// This MUST live outside the reducer because it is truely global
// yet there is no restrictions on the number of instantiations of the reducer
let eventListenerSet: boolean = false
export default (enabled: boolean): void => {
  if (enabled && !eventListenerSet) {
    eventListenerSet = true
    window.addEventListener('beforeunload', onBeforeUnload)
  } else if (!enabled && eventListenerSet) {
    eventListenerSet = false
    window.removeEventListener('beforeunload', onBeforeUnload)
  }
}
