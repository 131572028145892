import React, { useState } from 'react'
import styled from 'styled-components'
import {
  StyledDialog,
  StyledDialogTitle,
  StyledHeading,
  StyledDialogContent,
  StyledActions,
  StyledIconButton,
  ModalSecondaryButton,
  ModalButton
} from '../../../commonStyles/components/modal'
import SegmentWrapper from '../../../utils/segmentWindow'
import InputLayout from '../../../commonStyles/components/inputLayout'
import emailValidator from '../../../validators/emailValidator'
import { cookieExists } from '../../../utils/cookieHelper'
import { Redirect } from 'react-router-dom'
import { Config } from 'src/applyfrontendcontent'
import { CloseImage } from '../../../commonStyles/images/images'
import { OverrideModalContent } from './ContinueLaterLink'
import { colors, Input, media } from 'src/ui-framework'

interface ParaProps {
  marginTop: number
  marginBottom: number
}

interface IContinueLaterModalProps {
  displayModal: boolean
  onClose: () => void
  onSendLink: (email: string) => Promise<boolean | void>
  email: string
  status: boolean | void
  content: Config
  overrideModalContent?: OverrideModalContent
}

const DialogContentPara = styled.p<ParaProps>`
  line-height: 24px;
  margin-top: ${(props) => `${props.marginTop}px`};
  margin-bottom: ${(props) => `${props.marginBottom}px`};

  ${media.upToTablet`
    line-height: 20px;
    font-size: 14px;
  `}
`

const StyledHeadingContinueLater = styled(StyledHeading)`
  font-size: 26px;
  font-weight: 500;
  line-height: 36px;
  font-style: normal;
  color: ${colors.textGrey900};
  ${media.upToTablet`
    font-size: 20px;
  `}
`

const StyledDialogContentContinueLater = styled(StyledDialogContent)`
  max-width: 464px;
  color: ${colors.textGrey700};
`

const StyledModalButtonContinueLater = styled(ModalButton)`
  ${media.upToTablet`
    margin-bottom: 16px;
  `}
  ${media.upToTablet`
    width: 100%;
  `}
`

const StyledModalSecondaryButton = styled(ModalSecondaryButton)`
  margin-right: 0;
  ${media.upToTablet`
    width: 100%;
  `}
`

const StyledActionsContinueLater = styled(StyledActions)`
  height: 36px;
  ${media.upToTablet`
    height: 100%;
  `}
`

const StyledInputLayoutContinueLater = styled(InputLayout)`
  color: ${colors.textGrey900};
`

export default (p: IContinueLaterModalProps) => {
  const [emailAddress, setEmailAddress] = useState(p.email)
  const [items, setItems] = useState<string[]>([])
  const content = p.content.SaveForLater

  const validate = (fieldValue: string) => {
    if (!emailValidator(fieldValue)) {
      if (content.SendLink.Email.Error?.Required !== undefined) {
        setItems([content.SendLink.Email.Error.Required])
      }
    } else setItems([])
  }

  const handleOnBlur = (fieldValue: string) => {
    validate(fieldValue)
  }

  const hasSalesCookie = cookieExists('sales')

  const continueLaterRedirection = () => {
    const isInternalEmail = emailAddress.toLowerCase().endsWith('@kriya.co')

    if (hasSalesCookie === false || isInternalEmail) {
      return (
        <DialogContentPara marginTop={0} marginBottom={0} aria-live="polite">
          Your link is on its way to your designated inbox. Open the email and click through to continue the
          application.
        </DialogContentPara>
      )
    }

    if (hasSalesCookie && isInternalEmail === false) {
      return <Redirect to="/exit" />
    }
  }

  return (
    <StyledDialog
      open={p.displayModal}
      onClose={p.onClose}
      scroll="paper"
      id="continue-later-modal"
      role="dialog"
      aria-labelledby="continue-later-title"
      aria-describedby="continue-later-content"
      TransitionProps={{
        onExited: () => setEmailAddress(p.email)
      }}
    >
      <StyledDialogTitle disableTypography>
        <StyledHeadingContinueLater id="continue-later-title">
          {p.overrideModalContent !== undefined ? p.overrideModalContent.Heading : 'Save for later'}
        </StyledHeadingContinueLater>
        <StyledIconButton onClick={p.onClose}>
          <img src={CloseImage} alt="Cancel" />
        </StyledIconButton>
      </StyledDialogTitle>
      <StyledDialogContentContinueLater id="continue-later-content">
        {p.status === true ? (
          continueLaterRedirection()
        ) : (
          <>
            <DialogContentPara marginTop={0} marginBottom={16}>
              {p.overrideModalContent !== undefined
                ? p.overrideModalContent.Introduction
                : 'If you would like to continue your application later, we will send a secure link to your preferred email address below'}
            </DialogContentPara>
            <StyledInputLayoutContinueLater lg={12} sm={12} xs={12} md={12}>
              <Input
                id="continue-later-email"
                label="Please enter an email address"
                name="continue-later-email"
                required
                value={emailAddress}
                errors={items || []}
                style={{ margin: 0 }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmailAddress(e.target.value)
                }}
                onBlur={() => handleOnBlur(emailAddress)}
              />
            </StyledInputLayoutContinueLater>
          </>
        )}
      </StyledDialogContentContinueLater>
      <StyledActionsContinueLater disableSpacing>
        {p.status === false && (
          <StyledModalButtonContinueLater
            color="primary"
            id="continue-later-send-link"
            onClick={() => {
              items.length === 0 && p.onSendLink(emailAddress)
              SegmentWrapper.virtualPageView('save-for-later-submit', undefined, true)
            }}
          >
            Send link
          </StyledModalButtonContinueLater>
        )}

        <StyledModalSecondaryButton color="secondary" id="continue-later-cancel" onClick={p.onClose}>
          {p.status ? 'Close' : 'Cancel'}
        </StyledModalSecondaryButton>
      </StyledActionsContinueLater>
    </StyledDialog>
  )
}
