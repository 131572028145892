const HandleErrorAndFocusedColors = (
  hasErrors: boolean,
  focused: boolean | undefined,
  theme: any | undefined,
  mouseDown = false
) => {
  if (hasErrors) {
    if (focused || mouseDown) {
      return {
        ...theme.borderStyles.errorFocused,
        marginBottom: 0
      }
    }
    return {
      border: `1px solid ${theme.colors.error}`,
      marginBottom: 0
    }
  } else {
    if (focused || mouseDown) {
      return theme.borderStyles.focused
    }
    return {
      border: `${theme.inputFields.border}`
    }
  }
}

HandleErrorAndFocusedColors.defaultProps = {
  focused: false,
  hasErrors: false,
  theme: {}
}

export default HandleErrorAndFocusedColors
